import {
  GET_USER_ID,
  USER_IS_LOGGED,
  GET_DEVICE_INFO,
  TIMEOUT_LOGOUT,
  GET_VERSION_TAG,
  GET_VERSION_TAG_SUCCESS,
  GET_SUPPORTED_LANGUAGES,
  GET_SUPPORTED_LANGUAGES_SUCCESS
} from '../constants';

export function getUserId(userId) {
  return {
    type: GET_USER_ID,
    payload: userId
  };
}

export function userIsLogged() {
  return {
    type: USER_IS_LOGGED
  };
}

export function getDeviceInfo(payload) {
  return {
    type: GET_DEVICE_INFO,
    payload
  };
}

export function timeOut(payload) {
  return {
    type: TIMEOUT_LOGOUT,
    payload
  };
}

export function getVersionTag() {
  return {
    type: GET_VERSION_TAG
  };
}

export function getVersionTagSuccess(payload) {
  return {
    type: GET_VERSION_TAG_SUCCESS,
    payload
  };
}

export function getSupportedLanguages() {
  return {
    type: GET_SUPPORTED_LANGUAGES
  };
}

export function getSupportedLanguagesSuccess(payload) {
  return {
    type: GET_SUPPORTED_LANGUAGES_SUCCESS,
    payload
  };
}
