import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CoursesList from '../courses_list/list_main';
import Profile from '../preferences/';
import Resources from '../resources/resources_main';
import Achievements from '../achievements/achievements_main';
import {
  toggleCourses,
  toggleResources,
  toggleProfile,
  toggleAchievements
} from '../../actions/me';
import { loadPreferencesData } from '../../actions/preferences';
import ToggleContainer from '../../components/toggle_container/toggle_container';
import configData from '../../config.json';
import {
  loadContext,
  reLoadContext,
  openFullScreenFrame,
  closeFullScreenFrame
} from '../../actions/main_course';
import { displayWizenoze, closeWizenoze } from '../../actions/me';
import { hashHistory } from 'react-router';
import { Plugins } from '@capacitor/core';
import './me-main.scss';
import meTabRightBranding from '../../../images/meTabRightBranding.svg';
import meTabLeftBranding from '../../../images/meTabLeftBranding.svg';
import ActivityTrackingManager from '../../lib/activity_tracking';
import apiClient from '../../lib/celled_client';
import { updatePushToken } from '../../actions/push_notifications';
import { logout } from '../../actions/login';
import { getVersionTag } from '../../actions/main_app';

const { Browser } = Plugins;

class Me extends Component {
  constructor() {
    super();
    this.coursesHandler = this.coursesHandler.bind(this);
    this.resourcesHandler = this.resourcesHandler.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
    this.openCapacitorBrowser = this.openCapacitorBrowser.bind(this);
    this.openWizenoze = this.openWizenoze.bind(this);
    this.closeWizenoze = this.closeWizenoze.bind(this);
    this.achievementsHandler = this.achievementsHandler.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.state = {
      visitedUrlCount: 0,
      goingBack: false
    };
  }

  async openCapacitorBrowser() {
    const { fullScreenFrameUrl } = this.props.mainCourse;
    if (fullScreenFrameUrl === null) return;

    await Browser.open({ url: fullScreenFrameUrl });
    this.props.closeFullScreenFrame(); // close right away to clear ref to the previously opened URL
  }

  openWizenoze(url) {
    this.props.openWizenoze(url);
  }

  closeWizenoze() {
    this.setState({ visitedUrlCount: 0 });
    this.props.closeWizenoze();
  }

  coursesHandler() {
    this.props.toggleCourses();
  }

  resourcesHandler() {
    this.props.toggleResources();
  }

  profileHandler() {
    this.props.toggleProfile();
  }

  achievementsHandler() {
    this.props.toggleAchievements();
  }

  componentDidMount() {
    this.props.loadPreferencesData(this.props.userId);
    document.title = 'My Cell-Ed Courses and Preferences • Essential Skills on the Go';
    hashHistory.listen(() => {
      this.props.closeWizenoze();
    });
    this.props.getVersionTag();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.mainCourse.fullScreenFrameUrl !== prevProps.mainCourse.fullScreenFrameUrl) {
      await this.openCapacitorBrowser();
    }
  }

  handleLogout() {
    // disable activity tracking
    const activityManager = new ActivityTrackingManager();
    activityManager.stop();

    console.log('handling logout');
    // clear push notifications token
    this.props.updatePushToken(this.props.userId, '');

    // logout
    apiClient.logout(this.props.userId);
    this.props.logout();

    // clear credentials from cache
    window.localStorage.removeItem('celledCredentials');
    console.log('cleared credentials from cache');

    // back to login page
    hashHistory.push('/');
  }

  render() {
    const liteMode = configData.liteMode;
    const resources = this.props.resources;
    if (this.props.me.displayWizenoze === false) {
      return (
        <div className="me-page me-page-container">
          <div className="me-page-main-content">
            <h1 className="me-page__title">{this.props.strings['CHECK YOUR PROGRESS!']}</h1>
            <ToggleContainer
              title={this.props.strings.Courses}
              show={this.props.me.showCourses}
              toggle={this.coursesHandler}>
              <CoursesList
                loadCourses={!this.props.location.query.show}
                courseId={this.props.location.query.courseId}
              />
            </ToggleContainer>
            {resources && resources.length !== 0 && (
              <ToggleContainer
                title={this.props.strings.Resources}
                show={this.props.me.showResources}
                toggle={this.resourcesHandler}>
                <Resources />
              </ToggleContainer>
            )}
            {this.props.achievements && this.props.achievements.length !== 0 && (
              <ToggleContainer
                title={this.props.strings.Achievements}
                show={this.props.me.showAchievements}
                toggle={this.achievementsHandler}>
                <Achievements
                  achievements={this.props.achievements}
                  language={this.props.language}
                />
              </ToggleContainer>
            )}
            <ToggleContainer
              title={this.props.strings.Preferences}
              show={this.props.me.showProfile}
              toggle={this.profileHandler}>
              <Profile versionTag={this.props.versionTag} />
            </ToggleContainer>
          </div>

          <div className="me-page__section-logout">
            <button
              title={this.props.strings['LOGOUT']}
              onClick={() => this.handleLogout()}
              onKeyPress={this.handleLogout}
              className="button logoutButton">
              {this.props.strings['LOGOUT']}
            </button>
          </div>

          {!liteMode && (
            <div className="me-page__footer">
              <div className="me-page__footer-image-container">
                <img className="me-page__footer-image-left" src={meTabLeftBranding} role="presentation" alt="" />
                <img className="me-page__footer-image-right" src={meTabRightBranding} role="presentation" alt="" />
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <div className="closefullScreenFrame">
            {this.state.visitedUrlCount > 1 && (
              <span
                onClick={this.iFrameGoBack}
                className="back-button"
                aria-label={this.props.strings['Go Back']}
                alt={this.props.strings['Go Back']}>
                <i className="material-icons">chevron_left</i>
                {this.props.strings['Back']}&nbsp;
              </span>
            )}
            <button
              title={this.props.strings['Close']}
              onClick={this.closeWizenoze}
              onKeyPress={this.closeWizenoze}
              className="button wzCloseButton">
              {this.props.strings['Close']}
            </button>
          </div>
        </div>
      );
    }
  }
}

Me.defaultProps = {
  strings: {
    'CHECK YOUR PROGRESS!': 'CHECK YOUR PROGRESS!',
    Courses: 'Courses',
    Resources: 'Resources',
    Preferences: 'Preferences',
    Achievements: 'Achievements',
    'Log in information': 'Log in information',
    'Live Coach': 'Live Coach',
    Back: 'Back',
    Close: 'Close',
    'Go Back': 'Go Back',
    'Close Search Results': 'Close Search Results',
    LOGOUT: 'LOGOUT'
  }
};

function mapStateToProps(state) {
  return {
    me: state.me,
    userId: state.mainApp.userId,
    resources: state.preferences.preferencesData.resources,
    mainCourse: state.mainCourse || [],
    achievements: state.achievements.achievementsList,
    language: state.i18n.language,
    versionTag: state.mainApp.versionTag
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleCourses,
      toggleResources,
      toggleProfile,
      toggleAchievements,
      loadPreferencesData,
      loadContext,
      reLoadContext,
      displayWizenoze,
      closeWizenoze,
      openFullScreenFrame,
      closeFullScreenFrame,
      updatePushToken,
      logout,
      getVersionTag
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Me);
