import { CLEAR_SINGLE_NOTIFICATION, CLEAR_ALL_NOTIFICATIONS, ADD_NOTIFICATION } from '../constants';

const initialState = {
	notifications: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ADD_NOTIFICATION:
			return { ...state, ...checkForNotificationStack(action.payload, state) };
		case CLEAR_SINGLE_NOTIFICATION:
			return { ...state, notifications: deleteSingleNotification(state.notifications, action.payload) };
		case CLEAR_ALL_NOTIFICATIONS:
			return { ...state, notifications: [] };
		default:
			return state;
	}
}

function checkForNotificationStack (notification, state) {
	
/**
 * check if notification of the same type exist
 * in case it does and should be unique, it should replace it
 * otherwise just add the notification to the stack
 */

	notification.id = state.notifications.length;
	
	if (notification.unique) {
		var newNotificationsStack =	state.notifications.filter(function (oldNotification) {
			return oldNotification.type !== notification.type;
		});
		
		newNotificationsStack.push(notification);
		
		return ({
			notifications: newNotificationsStack,
		});
		
	}
	
	return {
		notifications: [...state.notifications, notification],
	};
}

function deleteSingleNotification (notifications, notificationId) {
	return notifications.filter(notification => {
		return notification.id !== notificationId;
	});
}
