import React, { Component } from 'react';
import { clearSingleNotification } from '../../actions/notifications'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Notification from '../../components/notifications/single_notification';
import Translate from '../../hocs/translate';

class Notifications extends Component {

	renderNotifications () {
		return this.props.notifications.map((notification, index) => {
			return (
				<Notification
					key={index}
					message={this.props.strings ? this.props.strings[notification.message] : notification.message}
					style={notification.className}
					clearNotification={this.props.clearSingleNotification}
					notificationId={notification.id}
					avoidUINotification={notification.avoidUINotification}
				/>
			); 
		});
	}

	render () {		
		return (
			<div className="notification-stack">
				{this.renderNotifications()}
			</div>
		);
	}
}

function mapStateToProps (state) {
	return { notifications: state.notifications.notifications };
}

function mapDispatchToProps (dispatch) {
	return bindActionCreators({ clearSingleNotification }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Translate('Notifications')(Notifications));
