import { LOAD_COURSES_LIST, TOGGLE_CERTIFICATE, TOGGLE_COURSE_ATTENDANCES } from '../constants';

export function loadCoursesList(userId) {
	return {
		type: LOAD_COURSES_LIST,
		payload: {
			userId, courseId: undefined
		}
	}
}

export function toggleCertificate(courseAttendanceId) {
	return {
		type: TOGGLE_CERTIFICATE,
		payload: courseAttendanceId,
	}
}

export function toggleCourseAttendance(courseName) {
	return {
		type: TOGGLE_COURSE_ATTENDANCES,
		payload: courseName,
	}
}
