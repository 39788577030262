//
//  SpecialFeature.js
//  Cell-Ed
//
//  -a special feature is a component that can only be seen by
//  specific pre-defined users under specific accounts.
//  -to be used for A/B testing, where functionality is
//  enabled only for *some* learners.
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadPreferencesData } from '../../actions/preferences';

class SpecialFeature extends Component {
  componentDidMount() {
    this.loadPreferencesIfNeeded();
  }

  loadPreferencesIfNeeded() {
    if (!this.props.specialFeatures && this.props.userId) {
      //if we have no preferences loaded
      this.props.loadPreferencesData(this.props.userId); //fetch (if any)
    }
  }

  render() {
    const specialFeatures = this.props.specialFeatures;
    if (!this.props.id || !specialFeatures) return null; //don't render if props are incomplete

    const isAuthorizedToDisplay = specialFeatures.includes(this.props.id);
    if (isAuthorizedToDisplay) {
      return this.props.children;
    }

    return null;
  }
}

SpecialFeature.defaultProps = {
  id: '', //the passed id must match the feature name that we are requesting
};

function mapStateToProps(state) {
  return {
    userId: state.mainApp.userId,
    specialFeatures: state.preferences.preferencesData.specialFeatures || null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadPreferencesData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialFeature);
