import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleCourseDetails } from '../../actions/main_course';
import { playAudioControl } from '../../actions/audio';
import {
  loadCourseDetails,
  switchCourseRequest,
  switchLessonRequest,
  switchCourse,
  toggleCourseConfirmationModal,
  toggleLessonConfirmationModal
} from '../../actions/course_details';
import { hashHistory } from 'react-router';
import { toggleCertificate } from '../../actions/courses_list';
import { jump } from '../../actions/main_course';
import Certificate from '../../components/courses_list/certificate';
import LessonsList from '../../components/course_details/lesssons_list';
import Translate from '../../hocs/translate';
import _ from 'lodash';
import classNames from 'classnames';
import Slider from 'react-slick';
import Modal from '../../components/modal/modal_main';
import BackButton from '../../components/buttons/back_button';
import './course-detail.scss';

class CourseDetail extends Component {
  constructor() {
    super();
    this.toggleCourseDetails = this.toggleCourseDetails.bind(this);
    this.continueOrStartCourse = this.continueOrStartCourse.bind(this);
    this.showConfirmDialog = this.showConfirmDialog.bind(this);
    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.jump = this.jump.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      selectedCourseName: null,
      nextAction: ''
    };
  }

  showConfirmDialog(course) {
    this.setState({
      selectedCourseName: course.name,
      nextAction: course.nextAction
    });
  }

  confirm() {
    this.props.switchCourse(this.props.location.query.courseCode);
    this.setState({
      selectedCourseName: null,
      nextAction: ''
    });
    this.props.toggleCourseConfirmationModal();
    hashHistory.push('/active_course');
    this.props.playAudioControl();
  }

  cancel() {
    this.setState({
      selectedCourseName: null,
      nextAction: ''
    });
    this.props.toggleCourseConfirmationModal();
  }

  componentDidMount() {
    this.props.loadCourseDetails(
      this.props.userInformation.userId,
      this.props.location.query.courseCode
    );
  }

  countFinishedLessons(courseData) {
    let lessonsTackedCount = 0;

    courseData.forEach((lesson) => {
      let lessonTacked = lesson.modules.every((module) => {
        return module.took;
      });

      if (lessonTacked) {
        lessonsTackedCount += 1;
      }
    });

    return lessonsTackedCount;
  }

  continueOrStartCourse() {
    this.setState({ selectedCourseName: this.props.courseDetails.data.name });
    this.props.switchCourseRequest();
  }

  toggleCourseDetails() {
    this.props.toggleCourseDetails();
    this.props.playAudioControl();
  }

  goBack() {
    this.props.router.goBack();
  }

  jump(url) {
    this.props.jump(url);
    hashHistory.push('/active_course');
  }

  render() {
    const progressData = this.props.courseDetails.data.progress;
    const settings = {
      dots: true,
      arrows: true,
      speed: 500
    };

    const strings = this.props.strings;
    let progressBarStyle = classNames({
      'progress-bar-progress': true,
      'progress-bar-completed': progressData && progressData.percentage === 100
    });
    let progressBarContainerStyle = classNames({
      'progress-bar-container': true,
      'progress-bar-container-completed': progressData && progressData.percentage === 100
    });
    let progressBarPercentageText = progressData
      ? progressData.percentage === 100
        ? strings.COMPLETED
        : `${progressData.percentage}%`
      : null;

    switch (this.props.courseDetails.loaded) {
      case 'success':
        const courseAttendancesCompleted = _.filter(
          this.props.courseDetails.data.courseAttendances,
          (ca) => ca.completed
        );
        return (
          <div className="course-details-container">
            {this.props.courseDetails.showCourseConfirmationModal && (
              <Modal>
                <div className="course-details-modal">
                  <h3 className="course-details-modal__headline">
                    {this.props.strings['Switch Course Start']}
                  </h3>
                  <p className="course-details-modal__course-name">
                    {this.state.selectedCourseName}
                  </p>
                  <div className="course-details-modal__buttons">
                    <button
                      alt={this.props.strings['NO']}
                      className="button cl-box-button-close"
                      onClick={() => this.cancel()}>
                      {this.props.strings['NO']}
                    </button>
                    <button
                      alt={this.props.strings['YES']}
                      className="button cl-box-button-confirmation"
                      onClick={() => this.confirm()}>
                      {this.props.strings['YES']}
                    </button>
                  </div>
                </div>
              </Modal>
            )}
            <div className="course-details-wrapper">
              <div className="course-details-header">
                <BackButton onClick={this.goBack} />

                <h2 className="course-details-title">
                  {this.props.courseDetails.data.displayTitle || this.props.courseDetails.data.name}
                </h2>
              </div>

              <div className="course-details-progress">
                {progressData && progressData.percentage > 0 && (
                  <div className={progressBarContainerStyle}>
                    <div className="progress-bar-labels">{progressBarPercentageText}</div>
                    <div
                      className={progressBarStyle}
                      style={{
                        width: `${(progressData !== null && progressData.percentage) || 0}%`
                      }}></div>
                  </div>
                )}
                <button
                  onClick={this.continueOrStartCourse}
                  className="start-button"
                  aria-label={`start ${this.props.courseDetails.data.name} course`}>
                  {this.props.strings.START}
                </button>
              </div>
              {!_.isEmpty(courseAttendancesCompleted) && (
                <div className="course-details-certificate">
                  <Slider {...settings}>
                    {courseAttendancesCompleted.map((ca, key) => (
                      <Certificate key={key} courseAttendanceId={ca._id} />
                    ))}
                  </Slider>
                </div>
              )}
              <h3 className="course-details-subtitle">{this.props.strings.Units}</h3>
              <LessonsList
                lessons={this.props.courseDetails.data.lessons}
                switchLessonRequest={this.props.switchLessonRequest}
                showLessonConfirmationModal={this.props.courseDetails.showLessonConfirmationModal}
                toggleLessonConfirmationModal={this.props.toggleLessonConfirmationModal}
                jump={this.jump}
              />
            </div>
          </div>
        );
      case 'loading':
        return (
          <div className="course-details-container">
            <h3 className="course-details-container__message">
              {this.props.strings['Loading...']}
            </h3>
          </div>
        );
      default:
        return (
          <div className="course-details-container">
            <h3 className="course-details-container__message">
              {this.props.strings['A problem occurred loading the course details']}
            </h3>
            <button
              className="course-details-container__message-button"
              onClick={this.toggleCourseDetails}>
              {this.props.strings['Click here to get back to your course.']}
            </button>
          </div>
        );
    }
  }
}

CourseDetail.defaultProps = {
  strings: {
    START: 'START',
    Continue: 'Continue',
    'units completed': 'Units Completed',
    Units: 'Units',
    'Loading...': 'Loading...',
    'A problem occurred loading the course details':
      'A problem occurred loading the course details',
    'Click here to get back to your course.': 'Click here to get back to your course.',
    PROGRESS: 'PROGRESS',
    COMPLETED: 'COMPLETED',
    'Switch Course Continue': 'Are you sure you want to continue with:',
    'Switch Course Start': 'Are you sure you want to start:',
    'Switch Course Redo': 'Are you sure you want to redo:',
    YES: 'YES',
    NO: 'NO'
  }
};

function mapStateToProps(state) {
  return {
    courseDetails: state.courseDetails,
    userInformation: state.mainApp,
    courseCode: state.mainCourse.moduleData.courseCode,
    isAudioPlaying: state.audio.isPlaying
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleCourseDetails,
      toggleCertificate,
      toggleCourseConfirmationModal,
      toggleLessonConfirmationModal,
      switchCourseRequest,
      switchLessonRequest,
      switchCourse,
      playAudioControl,
      loadCourseDetails,
      jump
    },
    dispatch
  );
}

export default Translate('CourseDetail')(
  connect(mapStateToProps, mapDispatchToProps)(CourseDetail)
);
