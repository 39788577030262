import React from 'react';
import { Router, Route, hashHistory, IndexRoute } from 'react-router';
import { auth } from './lib/auth';
import App from './containers/app';
import NotFound from './components/others/404_not_found';
import SignupConfirm from './containers/signup/signup_confirm';
import SimpleLogin from './containers/simple_login/simple_login_container';
import ActiveCourse from './containers/active_course/courses_main';
import Translate from './hocs/translate';
import Me from './containers/me/me_main';
import Help from './containers/help/help_main';
import CourseDetail from './containers/course_detail/course_detail';
import CourseGroupSubtopics from './containers/courses_list/subtopics_main';
import CoursesList from './containers/courses_list/courses_list';
import ResetPassword from './containers/reset_password/reset_password';
import LoginHelpForm from './containers/login_help_form/index.js';
// import Chatty from './containers/chatty/';
import config from './config.json';
import ReactGA from 'react-ga';

/**
 * Google analytics configuration.
 * Initializing GA and Tracking Pageviews.
 */
ReactGA.initialize(localStorage.getItem('GA_ID'));
/**
 * Time and path vars.
 */
let startTime,
  pathname = null;
/**
 * Router listen
 */
hashHistory.listen((location) => {
  if (location.pathname !== pathname) {
    if (startTime && pathname) {
      const endTime = new Date().getTime();
      const timeOnPage = endTime - startTime;
      /**
       * Send page timing as an event.
       */
      ReactGA.set({ page: location.pathname, timing: timeOnPage });
      /**
       * Set new page view.
       */
      ReactGA.pageview(location.pathname);
      /**
       * Location
       */
      ReactGA.event({
        category: 'Pages',
        action: 'Change of route',
        label: location.pathname
      });
      /**
       * Resend Authentication listener
       */
      sendUserId();
    }
    /**
     * Init time and path vars.
     */
    startTime = new Date().getTime();
    pathname = location.pathname;
  }
});
/**
 * This method sends userId to GA
 */
function sendUserId() {
  let credentials = auth();
  if (credentials && credentials.celledUserId) {
    ReactGA.set({ userId: credentials.celledUserId });
  } else {
    ReactGA.set({ userId: null });
  }
}

function requireAuth(nextState, replace) {
  let routeIfNotLogged = config.isEasyLogin ? '/easy-login' : '/';
  if (!auth()) {
    /**
     * The user is not authenticated, he will be redirected to the login. We report this to GA.
     */
    ReactGA.event({
      category: 'Pages',
      action: 'Redirect',
      label: 'Need to authenticate'
    });
    replace({
      pathname: routeIfNotLogged,
      state: { nextPathname: nextState.location.pathname }
    });
  }
}

function redirectIfLogged(nextState, replace) {
  if (auth()) {
    /**
     * The user is authenticated, he will be redirected to the courses page. We report this to GA.
     */
    ReactGA.event({
      category: 'Pages',
      action: 'Redirect',
      label: 'Already authenticated'
    });

    replace({
      pathname: '/active_course'
    });
  } else {
    // browserHistory.push({ pathname: `${config.celledApi.baseUrl}/` });
  }
}

export default (
  <Router history={hashHistory}>
    <Route path="/" component={Translate('App')(App)}>
      <IndexRoute component={SimpleLogin} onEnter={redirectIfLogged} />

      <Route path="/simple-login" component={SimpleLogin} onEnter={redirectIfLogged} />

      <Route
        path="/signup-confirm"
        component={Translate('SignupConfirm')(SignupConfirm)}
        onEnter={redirectIfLogged}
      />

      <Route
        path="/active_course"
        component={Translate('ActiveCourse')(ActiveCourse)}
        onEnter={requireAuth}
      />
      <Route path="/me" component={Translate('Me')(Me)} onEnter={requireAuth} />
      <Route
        path="/subtopics"
        component={Translate('CourseGroupSubtopics')(CourseGroupSubtopics)}
        onEnter={requireAuth}
      />
      <Route
        path="/courses_list"
        component={Translate('CoursesList')(CoursesList)}
        onEnter={requireAuth}
      />
      <Route
        path="/course_details"
        component={Translate('CourseDetail')(CourseDetail)}
        onEnter={requireAuth}
      />
      <Route path="/help" component={Translate('Help')(Help)} onEnter={requireAuth} />
      <Route path="/reset_password" component={Translate('ResetPassword')(ResetPassword)} />
      <Route path="/login-help-form" component={LoginHelpForm} />
      {/* <Route path="/chatty" component={Chatty} /> */}
    </Route>
    <Route path="*" component={NotFound} />
  </Router>
);
