import {
	STEP_EVENTS,
	SAVE_LEARNER_LAST_TIME
} from '../constants';
import _ from 'lodash';

const initialState = {
	learnerShouldPromptForActivityPermissions: false,
	activeTimerStartDate: null, // used by inline_form_timer.js
	learnerlastTime: ''
};

export default function (state = initialState, action) {
	let celledCredentials = localStorage.getItem("celledCredentials");
	switch (action.type) {
		case STEP_EVENTS.PLATFORM_CLIENT_ACTIVITY_TRACKING_CONSENTED:
			// Capacitor.platform.startsWith('android') && window.open("android://cell-ed/fitness-permissions/", '_blank');
			return { ...state, learnerShouldPromptForActivityPermissions: true };
		case STEP_EVENTS.PLATFORM_CLIENT_EXERCISE_TIMER_START_EVENT:
			// Capacitor.platform.startsWith('android') && window.open(`android://cell-ed/activity-timer-start/${celledCredentials}`, '_blank');
			const startDate = _.get(action, 'payload.params.startDate', null);
			return { ...state, activeTimerStartDate: startDate || new Date() }; 
		case STEP_EVENTS.PLATFORM_CLIENT_EXERCISE_TIMER_END_EVENT:
			// Capacitor.platform.startsWith('android') && window.open("android://cell-ed/activity-timer-stop", '_blank');
			return { ...state, activeTimerStartDate: null };
		case SAVE_LEARNER_LAST_TIME:
			return { ...state, learnerlastTime: action.payload };
		default:
			return state
	}
}
