import { take } from 'redux-saga/effects';
import {
  // Sign Up
  CHANGE_LANGUAGE,
  SIMPLE_LOGIN_NEW_LEARNER,
  SIMPLE_LOGIN_ENTER_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  // Home Page
  PLAY_PAUSE_AUDIO,
  JUMP,
  CLICK_PROGRESS_AUDIO_PROGRESS_BAR,
  LOAD_HISTORY_NEXT_PAGE,
  // Help Page
  TOGGLE_LIVE_COACH,
  TOGGLE_CONTACT_US,
  SEND_EMAIL,
  TOGGLE_FAQ,
  // Me page
  TOGGLE_COURSES,
  TOGGLE_RESOURCES,
  TOGGLE_PROFILE,
  LOGOUT,
} from '../constants';
import ReactGA from 'react-ga';

export function* sendGoogleAnalyticsEvents() {
  while (true) {
    const nextAction = yield take('*');
    try {
      switch (nextAction.type) {
        /**
         * Sign Up Events
         */
        case CHANGE_LANGUAGE:
          ReactGA.event({
            category: 'Sign Up',
            action: `Change language`,
            label: nextAction.payload,
          });
          break;
        case SIMPLE_LOGIN_NEW_LEARNER:
          ReactGA.event({
            category: 'Sign Up',
            action: 'Create',
            label: 'Learner',
          });
          break;
        case SIMPLE_LOGIN_ENTER_PASSWORD:
          ReactGA.event({
            category: 'Sign Up',
            action: 'Login',
            label: 'Learner',
          });
          break;
        case FORGOT_PASSWORD_REQUEST:
          ReactGA.event({
            category: 'Sign Up',
            action: 'Reset password',
            label: 'Learner',
          });
          break;
        /**
         * Home Page Events
         */
        case PLAY_PAUSE_AUDIO:
          ReactGA.event({
            category: 'Home Page',
            action: 'Click',
            label: 'Play Button',
          });
          break;
        case JUMP:
          ReactGA.event({
            category: 'Home Page',
            action: 'Click',
            label:
              nextAction.payload && nextAction.payload.next
                ? 'Next Lesson Button'
                : 'Previous Lesson Button',
          });
          break;
        case CLICK_PROGRESS_AUDIO_PROGRESS_BAR:
          ReactGA.event({
            category: 'Home Page',
            action: 'Click',
            label: 'Progress Audio Bar',
          });
          break;
        case LOAD_HISTORY_NEXT_PAGE:
          ReactGA.event({
            category: 'Home Page',
            action: 'Click',
            label: 'Show Previous Messages Button',
          });
          break;
        /**
         * Help Page Events
         */
        case TOGGLE_LIVE_COACH:
          ReactGA.event({
            category: 'Help Page',
            action: 'Show / Hide',
            label: 'Text Or Call My Coach',
          });
          break;
        case TOGGLE_CONTACT_US:
          ReactGA.event({
            category: 'Help Page',
            action: 'Show / Hide',
            label: 'Email My Coach',
          });
          break;
        case SEND_EMAIL:
          ReactGA.event({
            category: 'Help Page',
            action: 'Click',
            label: 'Send Message Button',
          });
          break;
        case TOGGLE_FAQ:
          ReactGA.event({
            category: 'Help Page',
            action: 'Show / Hide',
            label: 'Questions',
          });
          break;
        /**
         * Me Page Events
         */
        case TOGGLE_COURSES:
          ReactGA.event({
            category: 'Me Page',
            action: 'Show / Hide',
            label: 'Courses',
          });
          break;
        case TOGGLE_RESOURCES:
          ReactGA.event({
            category: 'Me Page',
            action: 'Show / Hide',
            label: 'Resources',
          });
          break;
        case TOGGLE_PROFILE:
          ReactGA.event({
            category: 'Me Page',
            action: 'Show / Hide',
            label: 'Preferences',
          });
          break;
        case LOGOUT:
          ReactGA.event({
            category: 'Me Page',
            action: 'Click',
            label: 'Logout button',
          });
          break;
        default:
        // needs default case
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export default function* rootGoogleAnalyticsSaga() {
  yield [sendGoogleAnalyticsEvents()];
}
