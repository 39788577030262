import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Translate from '../../hocs/translate';
import MultimediaElement from '../active_course/chat/multimedia_element_resource';

class Resource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceExpanded: false,
      url: ''
    };
    this.toggleResourceExpansion = this.toggleResourceExpansion.bind(this);
  }

  toggleResourceExpansion(url) {
    this.setState((prevState) => {
      return { resourceExpanded: !prevState.resourceExpanded, url: url };
    });
  }

  render() {
    if (this.state.resourceExpanded) {
      return (
        <div className="resource-pdf-container">
          <div className="resource-embed-pdf-container asset-pdf">
            <iframe
              src={this.state.url}
              onClick={this.toggleResourceExpansion}
              type="application/pdf"
              width="100%"
              height="100%"
              aria-label="pdf"
              title={this.props.multimediaObject.title}></iframe>
          </div>
        </div>
      );
    } else {
      if (this.props.multimediaObject.type === 'pdf') {
        return (
          <MultimediaElement
            multimediaObject={this.props.multimediaObject}
            onClick={this.toggleResourceExpansion}
          />
        );
      } else if (this.props.multimediaObject.type === 'web') {
        return (
          <MultimediaElement
            multimediaObject={this.props.multimediaObject}
            onClick={this.props.openFullScreenFrame}
          />
        );
      } else {
        return (
          <MultimediaElement
            multimediaObject={this.props.multimediaObject}
            onClick={this.props.toggleImageExpansion}
          />
        );
      }
    }
  }
}

Resource.propTypes = {
  multimediaObject: PropTypes.object.isRequired,
  toggleImageExpansion: PropTypes.func.isRequired,
  displayWizenoze: PropTypes.func
};

export default Translate('Resource')(Resource);
