import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import IconPlay from '../assets/icon_play';
import IconPause from '../assets/icon_pause';
import './play-button.scss';

export class PlayButton extends Component {
  constructor() {
    super();
    this.state = { glow: false, hover: false };
    this.play = this.play.bind(this);
    this.playOnEnterKeyPress = this.playOnEnterKeyPress.bind(this);
  }

  // Add glowing style to button if audio is loaded and not played
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.audio.currentTime === 0 && nextProps.audio.duration > 0.1 && !this.state.glow) {
      this.setState((prevState) => ({ ...prevState, glow: true }));
    }
    if (nextProps.audio.currentTime !== 0 && this.state.glow) {
      this.setState((prevState) => ({ ...prevState, glow: false }));
    }
  }

  componentDidMount() {
    window.addEventListener('keypress', this.playOnEnterKeyPress);
  }

  playOnEnterKeyPress(event) {
    // play/pause feature on enter
    if (this.props.audio.audios.length > 0) {
      let key = event.keyCode || event.charCode;
      if (key === 32 && document.activeElement.nodeName !== 'INPUT') {
        this.play();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.playOnEnterKeyPress, false);
  }

  play() {
    if (
      this.props.audio.audios &&
      this.props.audio.audios &&
      !this.props.audio.buffering &&
      this.props.audio.canPlay
    ) {
      this.props.playPauseAudio();
    }
    if (this.props.audio.playModal && this.props.audio.canPlay) {
      // deja quitar el modal una vez cargados todos los audios
      this.props.removeAudioModal();
    }
  }

  renderAudioControl(audioData) {
    if (audioData.canPlay) {
      return audioData.isPlaying ? (
        <IconPause fill={this.state.hover ? '#c32d1f' : '#ee433c'} />
      ) : (
        <IconPlay fill={this.state.hover ? '#c32d1f' : '#ee433c'} />
      );
    } else if (!audioData.canPlay) {
      if (audioData.audios.length === 0) {
        return <IconPlay fill={this.state.hover ? '#c32d1f' : '#ee433c'} />;
      } else {
        return <div className="play-button__loading" />;
      }
    }
  }

  render() {
    const buttonStyles = classNames({
      glowing: this.state.glow && this.props.audio.canPlay,
      disable: this.props.audio.audios.length === 0,
      overlay: this.props.audio.playModal
    });
    return (
      <button
        type="button"
        id="play-button"
        onClick={this.play}
        className={`${buttonStyles} play-button`}
        onMouseOver={() => this.setState((prevState) => ({ ...prevState, hover: true }))}
        onMouseOut={() => this.setState((prevState) => ({ ...prevState, hover: false }))}
        aria-label={this.props.audio.isPlaying ? 'pause audio' : 'play audio'}>
        {this.renderAudioControl(this.props.audio)}
      </button>
    );
  }
}

PlayButton.propTypes = {
  playPauseAudio: PropTypes.func.isRequired,
  pauseAudioControl: PropTypes.func.isRequired,
  audio: PropTypes.object.isRequired
};

export default PlayButton;
