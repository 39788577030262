import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Translate from '../../hocs/translate';
import IconRefresh from '../assets/icon_refresh';
import './react-img.scss';

class ReactImg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fallbackSrc: props.src,
      src: props.src,
      errored: false,
      loaded: false
    };
    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  onLoad() {
    this.setState({
      loaded: true
    });

    if (this.props.handleImageLoaded) {
      this.props.handleImageLoaded();
    }
  }

  onError() {
    if (!this.state.errored) {
      this.setState({
        src: '',
        errored: true
      });
    }
  }

  refresh() {
    this.setState({
      src: this.state.fallbackSrc,
      errored: false
    });
  }

  render() {
    const { src } = this.state;
    const { src: _1, strings, handleImageLoaded, ...props } = this.props;
    if (this.state.errored) {
      return (
        <button onClick={this.refresh} className="react-img-button react-img-button-icon">
          <IconRefresh fill="#FFF" />
          <span>{this.props.strings['* Could not download. Tap to try again.']}</span>
        </button>
      );
    } else {
      return (
        <div style={{ alignSelf: 'center' }}>
          <img src={src} onError={this.onError} onLoad={this.onLoad} alt="" {...props} />
        </div>
      );
    }
  }
}

ReactImg.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  handleImageLoaded: PropTypes.func
};

ReactImg.defaultProps = {
  strings: {
    '* Could not download. Tap to try again.': '* Could not download. Tap to try again.',
    handleImageLoaded: null
  }
};

export default Translate('ReactImg')(ReactImg);
