import { hashHistory } from 'react-router';
import { LOGOUT, RECONNECT_TRY } from '../constants';
// Check in localStorage for user's credentials 
export const auth = function () { 
	return JSON.parse(window.localStorage.getItem('celledCredentials')) 
};

/**
 * This method should be called from each try / catch block from the sagas to check for common errors.
 * 
 * @param {Object} error The error thrown by celled_client (axios)
 * @param {Object} businessError The business error to return in case common error logic was not triggered
 */
export const checkCommonErrors = (error, businessError) => {
	if (error.response) { // The request was made and the server responded with a status code that falls out of the range of 2xx
		if ((error.response.status === 401 || error.response.status === 403)) { // logout procedure
			const errorMessage = error.response ? error.response.data.message : 'Connection problem';
			window.localStorage.clear();
			hashHistory.push('/');
			return {
				type: LOGOUT,
				notification: {
					message: errorMessage,
					className: 'error',
					type: 'mainCourse',
				},
			};
		} else { // if you don't evaluate error.response.data, error.response.status and error.response.headers.
			// For now it returns the business error, it should be implemented later.
			return businessError;
		}
	  } else if (error.request) { // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser.
		// return RECONNECT_TRY to activate the reconnection attempt popup.
		return { type: RECONNECT_TRY };
	  } else { // Otherwise the business error returns.
		return businessError;
	  }
}
