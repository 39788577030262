import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { playPauseAudio } from '../../actions/audio';
import './progress-bar.scss';

const ProgressBar = ({ current, total, modifyCurrentTime }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const progressBarContainer = useRef(null);
  const dispatch = useDispatch();
  const isPlaying = useSelector((state) => state.audio.isPlaying);
  const [wasPlaying, setWasPlaying] = useState(false);

  useEffect(() => {
    // set position
    const percentage = calcPercentage(current, total);
    setPosition({
      x: Math.round(progressBarContainer.current.offsetWidth * percentage),
      y: 0
    });
  }, [current, total]);

  const handleResize = useCallback(() => {
    const percentage = calcPercentage(current, total);
    setPosition({ x: Math.round(progressBarContainer.current.offsetWidth * percentage), y: 0 });
  }, [current, total]);

  useEffect(() => {
    // set position on resize
    const debouncedHandleResize = debounce(() => handleResize(), 100);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [handleResize]);

  const calcPercentage = (currentTime, duration) => {
    return currentTime / duration;
  };

  const debounce = (fn, ms) => {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn();
      }, ms);
    };
  };

  const calcHandlePosition = (x) => {
    // account for size of drag handle
    if (progressBarContainer.current) {
      if (x < progressBarContainer.current.offsetWidth - 15) return x;

      return progressBarContainer.current.offsetWidth - 15;
    }

    return 0;
  };

  const handleProgressBarClick = (e) => {
    let positionInProgressBar =
      (e.clientX - progressBarContainer.current.offsetLeft) /
      progressBarContainer.current.offsetWidth;

    modifyCurrentTime(positionInProgressBar);
  };

  const handleDrag = (e, ui) => {
    setPosition({
      x: ui.x,
      y: 0
    });
  };

  const handleStop = () => {
    modifyCurrentTime(position.x / progressBarContainer.current.offsetWidth);
    // resume playing Audio
    if (wasPlaying) {
      dispatch(playPauseAudio());
      setWasPlaying(false);
    }
  };

  const handleStart = () => {
    // pause audio when dragged
    if (isPlaying) {
      dispatch(playPauseAudio());
      setWasPlaying(true);
    }
  };

  return (
    <div
      className="progress-bar-container"
      onClick={handleProgressBarClick}
      ref={progressBarContainer}>
      <Draggable
        onDrag={handleDrag}
        onStop={handleStop}
        onStart={handleStart}
        bounds="parent"
        position={{
          x: calcHandlePosition(position.x) || 0,
          y: 0
        }}>
        <div className="progress-bar-container__handle"></div>
      </Draggable>
      <div
        className="progress-bar-container__progress"
        style={{ width: `${position.x || 0}px` }}></div>
    </div>
  );
};

ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  modifyCurrentTime: PropTypes.func.isRequired
};

export default ProgressBar;
