
import { call, select } from 'redux-saga/effects'
import client from '../lib/celled_client'
import { getUserId } from './selectors'

export function* learnerFlowInfo() {
	try {
		const userId = yield select(getUserId)
		if (userId) {
			const learnerFlowInfo = yield call(client.getLearnerFlowInfo, userId)
			return learnerFlowInfo.data
		}
	} catch (error) {
		throw new Error(error)
	}
}
export default function* rootLearnerFlowInfoSaga() {
	yield [
		learnerFlowInfo(),
	]
}
