//
//  branding_component.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { Plugins, Capacitor } from '@capacitor/core';

import { saveAppLinkPreferences } from '../../actions/preferences';

const { App, BranchTracking } = Plugins;
export const KEY_LAUNCH_PARAM_PINCODE = 'pincode';
export const KEY_LAUNCH_PARAM_COURSE = 'selectedCourse';
export const KEY_LAUNCH_PARAM_BRANDING = 'branding';
export const ALL_APP_LINKS = [KEY_LAUNCH_PARAM_PINCODE, KEY_LAUNCH_PARAM_COURSE, KEY_LAUNCH_PARAM_BRANDING];

class AppLinkingComponent extends Component {

	componentDidMount() {
		this.register();
	}

	render () {
		return null;
	}

	static clear() {
		window.localStorage.removeItem(KEY_LAUNCH_PARAM_PINCODE);
		window.localStorage.removeItem(KEY_LAUNCH_PARAM_COURSE);
		window.localStorage.removeItem(KEY_LAUNCH_PARAM_BRANDING);
	}

	save(responseData) {
		console.log('[AppLinkingComponent] app opened with params: ' + JSON.stringify(responseData));
		const delta = {};
		for (const key of ALL_APP_LINKS) {
			if (responseData[key] != null) {
				window.localStorage.setItem(key, `${responseData[key]}`);
				delta[key] = responseData[key];
			}
		}

		if (!_.isEmpty(delta)) {
			// let event = new Event('deep_link_handled', delta); // (2)
			// window.dispatchEvent(event);
			this.props.saveAppLinkPreferences(delta); // register change in redux store.
		}
	}

	register() {

		// Approach #1: infer app links directly from URL on app-open
		if (Capacitor.isPluginAvailable('App')) {
			App.addListener('appUrlOpen', (data) => {
				const url = new URL(data.url);
				let params = new URLSearchParams(url.search);

				const pincode = params.get(KEY_LAUNCH_PARAM_PINCODE);
				const selectedCourse = params.get(KEY_LAUNCH_PARAM_COURSE) || params.get('courseCode');
				const branding = params.get(KEY_LAUNCH_PARAM_BRANDING);

				let payload = {};
				payload[KEY_LAUNCH_PARAM_PINCODE] = pincode;
				payload[KEY_LAUNCH_PARAM_COURSE] = selectedCourse;
				payload[KEY_LAUNCH_PARAM_BRANDING] = branding;
				this.save(payload);
			});
		}

		// Approach #2: wait for deep-links from Branch.io
		if (Capacitor.isPluginAvailable('BranchTracking')) {
			BranchTracking.addListener('deepLinkHandler', (data) => {
				this.save(data);
			});
			BranchTracking.initSession();
		}

		// Approach #3 : parse current local storage
		const pincode = window.localStorage.getItem(KEY_LAUNCH_PARAM_PINCODE);
		const selectedCourse = window.localStorage.getItem(KEY_LAUNCH_PARAM_COURSE);
		const branding = window.localStorage.getItem(KEY_LAUNCH_PARAM_BRANDING);
		if (!_.isEmpty(pincode) || !_.isEmpty(selectedCourse) || !_.isEmpty(branding)) {
			let payload = {};
			payload[KEY_LAUNCH_PARAM_PINCODE] = pincode;
			payload[KEY_LAUNCH_PARAM_COURSE] = selectedCourse;
			payload[KEY_LAUNCH_PARAM_BRANDING] = branding;
			this.props.saveAppLinkPreferences(payload);
		}

	}
}

function mapDispatchToProps (dispatch) {
	return bindActionCreators({ saveAppLinkPreferences }, dispatch);
}

export default connect(null, mapDispatchToProps)(AppLinkingComponent);
