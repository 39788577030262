let startTime;
let stream;
let mediaOptions;
let onStopCallback;
let onSaveCallback;
let onErrorCallback;
let recordRTC;

const constraints = { audio: true, video: false };

navigator.getUserMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia;

export class MicrophoneRecorder {
  constructor(onStop, onSave, onError, options) {
    onStopCallback = onStop;
    onSaveCallback = onSave;
    onErrorCallback = onError;
    mediaOptions = options;
  }

  startRecording() {
    var RecordRTC = require('recordrtc');
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    startTime = Date.now();
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((str) => {
          stream = str;
          recordRTC = RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/wav',
            numberOfAudioChannels: 2,
            checkForInactiveTracks: true,
            bufferSize: 16384,
            recorderType: StereoAudioRecorder,
            desiredSampRate: 64000,
            audioBitsPerSecond: 96000,
            bitsPerSecond: 96000,
          });
          recordRTC.startRecording();
        })
        .catch((error) => {
          if (onErrorCallback) {
            if (recordRTC) {
              recordRTC.destroy();
              recordRTC = null;
            }
            onErrorCallback(error);
          }
        });
    }
  }

  stopRecording() {
    var self = this;
    if (recordRTC) {
      recordRTC.stopRecording(function (audioURL) {
        var blob = self.dataViewtoMP3Blob(recordRTC.view);
        const blobObject = {
          blob: blob,
          startTime: startTime,
          stopTime: Date.now(),
          options: mediaOptions,
          blobURL: window.URL.createObjectURL(blob),
        };
        if (onStopCallback) {
          onStopCallback(blobObject);
        }
        if (onSaveCallback) {
          onSaveCallback(blobObject);
        }
        stream.getAudioTracks().forEach((track) => {
          track.stop();
        });
        recordRTC.destroy();
        recordRTC = null;
      });
    }
  }

  dataViewtoMP3Blob(view) {
    var mp3Data = this.wavBuffertoMP3Buffer(view.buffer);
    return new Blob(mp3Data, { type: 'audio/mpeg' });
  }

  wavBuffertoMP3Buffer(arrayBuffer) {
    var mp3Encoder,
      maxSamples = 1152,
      wav,
      samplesLeft,
      dataBuffer = [],
      samplesRight;
    var lamejs = require('lamejs');
    wav = lamejs.WavHeader.readHeader(new DataView(arrayBuffer));
    if (!wav) {
      return;
    }
    var dataView = new Int16Array(arrayBuffer, wav.dataOffset, wav.dataLen / 2);
    samplesLeft =
      wav.channels === 1
        ? dataView
        : new Int16Array(wav.dataLen / (2 * wav.channels));
    samplesRight =
      wav.channels === 2
        ? new Int16Array(wav.dataLen / (2 * wav.channels))
        : undefined;
    if (wav.channels > 1) {
      for (let i = 0; i < samplesLeft.length; i++) {
        samplesLeft[i] = dataView[i * 2];
        samplesRight[i] = dataView[i * 2 + 1];
      }
    }
    mp3Encoder = new lamejs.Mp3Encoder(wav.channels, wav.sampleRate, 96);
    var remaining = samplesLeft.length;
    for (let j = 0; remaining >= maxSamples; j += maxSamples) {
      let left = samplesLeft.subarray(j, j + maxSamples);
      let right;
      if (samplesRight) {
        right = samplesRight.subarray(j, j + maxSamples);
      }
      let mp3buf = mp3Encoder.encodeBuffer(left, right);
      dataBuffer.push(new Int8Array(mp3buf));
      remaining -= maxSamples;
    }
    return dataBuffer;
  }
}
