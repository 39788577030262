import { put, take, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import {
	DEMO_REQUEST,
	DEMO_REQUEST_FAILED,
	DEMO_REQUEST_SUCCEEDED,
	CHANGE_LANGUAGE,
	TOGGLE_COURSE_DETAILS,
} from '../constants';
import apiClient from '../lib/celled_client';


export function * startDemo () {
	
	while (true) {
		
		const demoRequest = yield take(DEMO_REQUEST);
		
		try {
			const demoSuccess = yield call(apiClient.demoRequest, demoRequest.payload);
			yield put({
				type: DEMO_REQUEST_SUCCEEDED,
				message: demoSuccess.data.message,
				token: demoSuccess.data.token,
				userId: demoSuccess.data.userId,
				firstTime: demoSuccess.data.firstTime,
			});
			yield put({
				type: CHANGE_LANGUAGE,
				payload: demoSuccess.data.language,
			});
			if (demoRequest.payload.enableSurvey === false && demoRequest.payload.enableAssessment === false) {
				yield call(delay, 1000);
				yield put({
					type: TOGGLE_COURSE_DETAILS,
				});
			}
		}
		catch (error) {
			var errorMessage = error.response.data.message;
			yield put({
				type: DEMO_REQUEST_FAILED,
				notification: {
					message: errorMessage,
					className: 'error',
					type: 'signup',
					unique: true,
				},
			})
		}
	}
}

export default function * rootDemoSaga () {
	yield [
		startDemo(),
	];
};
