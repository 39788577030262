import { put, take, call } from 'redux-saga/effects';/**
 * Created by nico on 05/07/17.
 */
import apiClient from '../lib/celled_client';
import { EASY_LOGIN_REQUEST, LOGIN_FAILED, LOGIN_SUCCESSFUL, CHANGE_LANGUAGE } from '../constants';

export function * easyLogin () {

	while (true) {

		const easyLoginRequest = yield take(EASY_LOGIN_REQUEST)
		try {
			const loginSuccess = yield call(apiClient.easyLoginRequest, easyLoginRequest.payload)
			yield put({
				type: LOGIN_SUCCESSFUL,
				token: loginSuccess.data.token,
				userId: loginSuccess.data.userId,
				firstTime: loginSuccess.data.firstTime
			});
			yield put({
				type: CHANGE_LANGUAGE,
				payload: loginSuccess.data.language,
			});
			localStorage.setItem('celledPhoneNumber', JSON.stringify(easyLoginRequest.payload.phoneNumber))
		}

		catch (error) {
			let errorMessage = 'Connection problem';

			if (error.response) {
				errorMessage = error.response.data.message;
			}

			yield put({
				type: LOGIN_FAILED,
				notification: {
					message: errorMessage,
					className: 'error',
					type: 'login',
					unique: true,
				},
			})
		}
	}
}

export default function * rootEasyLoginSaga () {
	yield [
		easyLogin(),
	];
};
