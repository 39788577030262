import moment from 'moment';
import 'moment/locale/es';
import store from '../../store';

export function getDate() {
  return moment().utc().format();
}

export function formatDate(date, format = 'ddd, MMM D, h:mm A') {
  const state = store.getState();
  const language = state.i18n.language;

  if (language) {
    return moment(date).locale(language).format(format);
  }
  return moment(date).local().format(format);
}
