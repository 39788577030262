import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { manualReconnect } from '../../actions/main_course';
import CountDown from '../../components/others/countdown';
import Translate from '../../hocs/translate';
import config from '../../config.json';
import './reconnect.scss';

class Reconnect extends Component {
  renderReconnectContent(reconnectState) {
    if (reconnectState === 'waiting') {
      return (
        <div className="reconnect-labels">
          <button className="reconnect-button" onClick={this.props.manualReconnect}>
            {this.props.strings['Trying to reconnect in']}
          </button>
          <CountDown resetOnFinish countDownInSeconds={config.automaticReconnectTimeout / 1000} />
        </div>
      );
    }

    return (
      <div className="reconnect-labels">
        <button className="reconnect-button" onClick={this.props.manualReconnect}>
          {this.props.strings['Reconnecting...']}
        </button>
      </div>
    );
  }

  render() {
    if (this.props.reconnect.active) {
      return (
        <div className="reconnect-wrapper">
          {this.renderReconnectContent(this.props.reconnect.state)}
        </div>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  return { reconnect: state.mainCourse.reconnect };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ manualReconnect }, dispatch);
}

export default Translate('Reconnect')(connect(mapStateToProps, mapDispatchToProps)(Reconnect));

Reconnect.defaultProps = {
  strings: {
    'Trying to reconnect in': 'Trying to reconnect in',
    'Reconnecting...': 'Reconnecting...'
  }
};
