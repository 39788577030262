import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router';
import Translate from '../../hocs/translate';
import IconChevron from '../assets/icon_chevron';

class BackButton extends Component {
  render() {
    return (
      <>
        {this.props.to !== '' ? (
          <Link className="back-button-link" to={this.props.to}>
            <IconChevron className="back-button__icon" direction="left" /> {this.props.strings.Back}
          </Link>
        ) : (
          <button type="button" onClick={this.props.onClick} className="back-button">
            <IconChevron className="back-button__icon" direction="left" /> {this.props.strings.Back}
          </button>
        )}
      </>
    );
  }
}

BackButton.defaultProps = {
  strings: {
    Back: 'Back'
  },
  to: ''
};

BackButton.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func
};

export default Translate('BackButton')(BackButton);
