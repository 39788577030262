export function focusNextInput (event) {

	let inputs = document.getElementsByTagName('input');
	for (let i = 0; i < inputs.length; i++) {
		if (inputs[i] === event.target && inputs[i + 1]) {

			let isAndroid = event.charCode === 13 && /Android/.test(navigator.userAgent);
			let isIOS = event.charCode === 13 && /iPad|iPhone|iPod/.test(navigator.userAgent);
			let enterOnDesktop = event.charCode === 13 || event.charCode === 0;

			if (isAndroid || isIOS || enterOnDesktop) {
				event.preventDefault();
				inputs[i + 1].focus();
				break;
			}
		}
	}
}
