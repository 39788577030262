import {
  SHOW_CERTIFICATE_INIT,
  SHOW_CERTIFICATE,
  FETCH_CERTIFICATE_INIT,
  FETCH_CERTIFICATE,
  FETCH_CERTIFICATE_FAIL,
  DOWNLOAD_CERTIFICATE_INIT,
  DOWNLOADING_CERTIFICATE,
  GET_CERTIFICATE_THUMBNAIL,
  GET_CERTIFICATE_THUMBNAIL_SUCCESS,
  GET_CERTIFICATE_THUMBNAIL_FAIL,
  GET_QUESTIONNAIRES_ON_APP,
  GET_QUESTIONNAIRES_ON_APP_SUCCESS
} from '../constants';
import { loadCoursesList } from './courses_list';
import { put, take, call, select } from 'redux-saga/effects';
import { delay, takeLatest, takeEvery } from 'redux-saga';
import client from '../lib/celled_client';
import { hashHistory } from 'react-router';
import { downloadFile } from '../lib/download_file';
import { downloadCertificateFail } from '../actions/chat';
import { getUserId } from './selectors';
import moment from 'moment-timezone';
import { Plugins, Capacitor } from '@capacitor/core';
import config from '../config.json';

const API_BASE_PATH = config.celledApi.baseUrl;
const { Browser } = Plugins;

export function* showCertificate() {
  while (true) {
    const result = yield take(SHOW_CERTIFICATE_INIT);
    const userId = yield select(getUserId);
    yield call(loadCoursesList, { userId: userId, courseId: result.payload });
    hashHistory.push({ pathname: '/course_details', search: `?courseCode=${result.payload}` });
    yield put({ type: SHOW_CERTIFICATE, payload: result.payload });
  }
}

export function* fetchCertificateWatcher() {
  yield takeLatest(FETCH_CERTIFICATE_INIT, fetchCertificateSaga);
}

export function* fetchCertificateSaga(action) {
  if (action) {
    let maxRetries = action.payload.maxRetries;
    while (maxRetries < 100) {
      try {
        const thumbnail = yield call(
          client.fetchCertificateThumbnail,
          action.payload.courseAttendanceId
        );
        maxRetries = 150;
        yield put({
          type: FETCH_CERTIFICATE,
          payload: {
            courseAttendanceId: action.payload.courseAttendanceId,
            thumbnail
          }
        });
      } catch (err) {
        if (err.response.status === 404) {
          yield delay(100);
          yield put({
            type: FETCH_CERTIFICATE_INIT,
            payload: {
              courseAttendanceId: action.payload.courseAttendanceId,
              maxRetries: action.payload.maxRetries + 1
            }
          });
        } else {
          yield put({ type: FETCH_CERTIFICATE_FAIL, payload: err });
        }
      }
    }
    if (maxRetries !== 150)
      yield put({
        type: FETCH_CERTIFICATE_FAIL,
        payload: new Error('Max retries reached, please try again.')
      });
  }
}

export function* watchCertificateThumbnailSaga() {
  yield takeEvery(GET_CERTIFICATE_THUMBNAIL, fetchCertificateThumbnailSaga);
}

export function* fetchCertificateThumbnailSaga(action) {
  if (action) {
    try {
      const thumbnail = yield call(
        client.fetchCertificateThumbnail,
        action.payload.courseAttendanceId
      );
      yield put({
        type: GET_CERTIFICATE_THUMBNAIL_SUCCESS,
        payload: {
          courseAttendanceId: action.payload.courseAttendanceId,
          thumbnail
        }
      });
    } catch (err) {
      yield put({
        type: GET_CERTIFICATE_THUMBNAIL_FAIL,
        payload: {
          courseAttendanceId: action.payload.courseAttendanceId,
          error: err
        }
      });
    }
  }
}

export function* downloadCertificate() {
  while (true) {
    const result = yield take(DOWNLOAD_CERTIFICATE_INIT);
    yield put({ type: DOWNLOADING_CERTIFICATE, payload: { downloadingCertificate: true } });
    try {
      if (Capacitor.isNative) {
        Browser.open({ url: `${API_BASE_PATH}/certificates/getCertificate/${result.payload}` });
        yield put({ type: DOWNLOADING_CERTIFICATE, payload: { downloadingCertificate: false } });
      } else {
        const certificate = yield call(client.fetchCertificate, result.payload);
        const { data } = yield call(client.getCAInfoForCertificateName, result.payload);
        const fileName = `${data.courseName}-${moment(data.finishDate)
          .tz(data.learnerTimezone ? data.learnerTimezone : 'America/Los_Angeles')
          .format('MM-DD-YYYY')}`;
        downloadFile(certificate.data, fileName, 'jpeg', 'image/jpeg');
      }
      yield put({ type: DOWNLOADING_CERTIFICATE, payload: { downloadingCertificate: false } });
    } catch (err) {
      yield put(downloadCertificateFail(true));
      yield put({ type: DOWNLOADING_CERTIFICATE, payload: { downloadingCertificate: false } });
    }
  }
}

export function* getQuestionnairesOnApp() {
  while (true) {
    yield take(GET_QUESTIONNAIRES_ON_APP);
    const learnerId = yield select(getUserId);
    const { data } = yield call(client.getQuestionnairesOnApp, learnerId);
    yield put({ type: GET_QUESTIONNAIRES_ON_APP_SUCCESS, payload: data });
  }
}

export default function* rootChatSaga() {
  yield [
    showCertificate(),
    downloadCertificate(),
    fetchCertificateWatcher(),
    fetchCertificateSaga(),
    watchCertificateThumbnailSaga(),
    fetchCertificateThumbnailSaga(),
    getQuestionnairesOnApp()
  ];
}
