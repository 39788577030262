//
//  branding_manager.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//
const Promise = require('bluebird');

const { KEY_LAUNCH_PARAM_BRANDING } = require('../../components/others/app_linking_component');

const BRANDING_STORAGE_ID = 'currentBranding'; // seen in localStorage
const DEFAULT_THEME_NAME = 'celled';

export default class BrandingManager {
  static saveAndLoad(themeName) {
    return BrandingManager.save(themeName)
      .then(function () {
        BrandingManager.load();
      })
      .catch(function () {});
  }

  static async load() {
    // (1) check if we have a pending theme to load
    // this request will come from a smart-link
    let requestedTheme = window.localStorage.getItem(KEY_LAUNCH_PARAM_BRANDING);
    if (requestedTheme && requestedTheme.length > 0) {
      window.localStorage.removeItem(KEY_LAUNCH_PARAM_BRANDING);
      await BrandingManager.save(requestedTheme); // will only succeed if the theme is valid.
    }

    // (2) if not, load a validated theme from localStorage
    let brandId = window.localStorage.getItem(BRANDING_STORAGE_ID);

    if (!brandId) {
      window.localStorage.setItem(BRANDING_STORAGE_ID, DEFAULT_THEME_NAME);
      brandId = DEFAULT_THEME_NAME;
    }

    // set brand class
    const loadingScreenElement = document.querySelector('.loading-screen');

    if (loadingScreenElement) {
      loadingScreenElement.classList.add(brandId);
    }
  }

  static save(themeName) {
    return new Promise((resolve, reject) => {
      if (!themeName) {
        themeName = DEFAULT_THEME_NAME; // use default if blank
      }

      // don't do anything if the current theme is already what we want it to be
      var currentStylePath = window.localStorage.getItem(BRANDING_STORAGE_ID);
      if (currentStylePath === themeName) {
        resolve(); // theme already set!
        return;
      }

      // valid theme exists, so save into storage
      window.localStorage.setItem(BRANDING_STORAGE_ID, themeName);
      resolve();
      return;
    });
  }

  static isValidFilePath(url) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open('HEAD', url, true);

      xhr.onload = function (e) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(new Error(`[BrandingManager] ${url} does not exist.`));
          }
        }
      };
      xhr.onerror = function (e) {
        reject(new Error(`[BrandingManager] ${url} does not exist.`));
      };

      xhr.send();
    });
  }
}
