import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  downloadCertificate,
  downloadCertificateFail,
  getCertificateThumbnail
} from '../../actions/chat';
import Loader from 'react-loader-spinner';
import Translate from '../../hocs/translate';
import IconRefresh from '../assets/icon_refresh';
import './certificate.scss';

class Certificate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: undefined,
      pendingCertificate: true,
      failed: false
    };

    this.refresh = this.refresh.bind(this);
    this.fetchCertificateThumbnail = this.fetchCertificateThumbnail.bind(this);
  }

  async componentDidMount() {
    this.fetchCertificateThumbnail();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.certificatesThumbnail[this.props.courseAttendanceId] !==
      nextProps.certificatesThumbnail[this.props.courseAttendanceId]
    ) {
      const thumbnail = nextProps.certificatesThumbnail[this.props.courseAttendanceId];
      if (thumbnail) {
        if (thumbnail.failed) return this.setState({ failed: true });
        this.setState({
          src: nextProps.certificatesThumbnail[this.props.courseAttendanceId],
          pendingCertificate: false
        });
      }
    }
  }

  refresh() {
    this.setState({
      failed: false,
      pendingCertificate: true
    });
    this.props.downloadCertificateFail(false);
    this.fetchCertificateThumbnail();
  }

  async fetchCertificateThumbnail() {
    this.props.getCertificateThumbnail(this.props.courseAttendanceId);
  }

  render() {
    return (
      <div className="cl-certificate-container">
        {this.state.failed || this.props.downloadingCertificateFail ? (
          <button onClick={this.refresh} className="cl-certificate-error">
            <IconRefresh />
            <br />
            <span>{this.props.strings['retry']}</span>
          </button>
        ) : this.state.pendingCertificate || this.props.downloadingCertificate ? (
          <div>
            <h3>
              {
                this.props.strings[
                  `${this.state.pendingCertificate ? 'fetchCertificate' : 'downloadingCertificate'}`
                ]
              }
            </h3>
            <div className="loader">
              <Loader type="ThreeDots" color="#37586D" width="35" height="10" />
            </div>
          </div>
        ) : (
          <div className="cl-certificate-image-container">
            <img
              src={URL.createObjectURL(this.state.src)}
              className="cl-certificate-image"
              alt="courseAttendance certificate"
            />
            <img
              src="images/downloadCertificateIcon.png"
              className="cl-certificate-download-button"
              onClick={() => this.props.downloadCertificate(this.props.courseAttendanceId)}
              alt="download icon"
            />
          </div>
        )}
      </div>
    );
  }
}

Certificate.propTypes = {
  courseAttendanceId: PropTypes.string
};

Certificate.defaultProps = {
  strings: {
    fetchCertificate: 'Looking for your certificate...',
    retry: "We couldn't find your certificate. Please click to try again.",
    downloadingCertificate: 'Preparing your certificate to download...'
  }
};

function mapStateToProps(state) {
  return {
    downloadingCertificate: state.chat.downloadingCertificate,
    downloadingCertificateFail: state.chat.downloadingCertificateFail,
    certificatesThumbnail: state.chat.certificatesThumbnail
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { downloadCertificate, downloadCertificateFail, getCertificateThumbnail },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Translate('Certificate')(Certificate));
