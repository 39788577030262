import React from 'react';
import PropTypes from 'prop-types';

const IconPerson = ({ className }) => {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 7.5C9.57187 7.5 11.25 5.82188 11.25 3.75C11.25 1.67812 9.57187 0 7.5 0C5.42812 0 3.75 1.67812 3.75 3.75C3.75 5.82188 5.42812 7.5 7.5 7.5ZM7.5 8.4375C4.99687 8.4375 0 9.69375 0 12.1875V14.0625C0 14.5781 0.421875 15 0.9375 15H14.0625C14.5781 15 15 14.5781 15 14.0625V12.1875C15 9.69375 10.0031 8.4375 7.5 8.4375Z"
        fill="white"
      />
    </svg>
  );
};

IconPerson.propTypes = { className: PropTypes.string };

IconPerson.defaultProps = {
  className: ''
};

export default IconPerson;
