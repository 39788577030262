import React, { useState } from 'react';
import {
  validatePasswordLength,
  validatePasswordMatch,
  validatePasswordEmpty
} from '../../utils/validations';
import Translate from '../../hocs/translate';
import IconEye from '../../components/icons/icon_eye';
import IconError from '../../components/assets/icon_error';
import PropTypes from 'prop-types';

const FormPassword = ({
  focusNextInput,
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
  passwordError,
  handleUpdateError,
  handleEnterSubmit,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((prevState) => !prevState);
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowPasswordConfirm((prevState) => !prevState);
  };

  const renderError = () => {
    if (passwordError) {
      let message = '';

      if (!validatePasswordMatch(password, confirmPassword))
        message = props.strings['Passwords do not match'];

      if (!validatePasswordLength(password))
        message = props.strings['Password must be at least 6 letters and/or numbers'];

      if (!validatePasswordEmpty(password))
        message = props.strings['Please enter in your new password'];

      return (
        <div className="notificationStyles form-item">
          <IconError className="notificationStyles__icon" />
          {message}
        </div>
      );
    }
  };

  const handleKeyDown = (e, func) => {
    if (e.key === 'Enter' && func !== null) {
      func(e);
    }
  };

  return (
    <div>
      <div className="form-box form-password">
        <label htmlFor="password" className="visually-hidden">
          {props.strings.Password}
        </label>
        <div className="form-horizontal">
          <input
            className={`form-input ${passwordError ? 'form-input--error' : ''}`}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={props.strings['Create a password']}
            type={showPassword ? 'text' : 'password'}
            aria-label={props.strings['Create a password']}
            onKeyDown={(e) => handleKeyDown(e, focusNextInput)}
          />
          <button
            className="form-input__show-password-button"
            aria-label={`${showPassword ? props.strings['hide'] : props.strings['show']} ${
              props.strings['password']
            }`}
            onClick={(e) => handleShowPassword(e)}
            type="button">
            <IconEye active={showPassword} />
          </button>
        </div>
      </div>
      <div className="form-box">
        <div className="form-horizontal">
          <input
            className={`form-input ${passwordError ? 'form-input--error' : ''}`}
            id="password-confirm"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={props.strings['Confirm password']}
            type={showPasswordConfirm ? 'text' : 'password'}
            onKeyDown={(e) => handleKeyDown(e, handleEnterSubmit)}
          />
          <button
            className="form-input__show-password-button"
            aria-label={`${showPasswordConfirm ? props.strings['hide'] : props.strings['show']} ${
              props.strings['password']
            }`}
            onClick={(e) => handleShowConfirmPassword(e)}
            type="button">
            <IconEye active={showPasswordConfirm} />
          </button>
        </div>

        {passwordError && renderError('password')}
      </div>
    </div>
  );
};

FormPassword.propTypes = {
  focusNextInput: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  passwordError: PropTypes.bool.isRequired,
  handleUpdateError: PropTypes.func.isRequired,
  handleEnterSubmit: PropTypes.func
};

FormPassword.defaultProps = {
  strings: {
    'Create a password': 'Create a password',
    'Confirm password': 'Confirm password',
    'Please enter in your new password': 'Please enter in your new password',
    'Password must be at least 6 letters and/or numbers':
      'Password must be at least 6 letters and/or numbers',
    show: 'show',
    hide: 'hide',
    password: 'password',
    'Passwords do not match': 'Passwords do not match'
  },
  handleEnterSubmit: null
};

export default Translate('FormPassword')(FormPassword);
