import { addNotification } from '../actions/notifications';

// Check if a dispatched action has a "notification" key.
// In case it does, show the value of that key as a notification on the UI.

const notificationMiddleware = store => next => action => {
	
	if ('notification' in action) {
		store.dispatch(addNotification(action.notification));
	}
	
	return next(action);
};

export default notificationMiddleware;
