import { LOGIN_REQUEST, LOGOUT, LOGIN_RESET } from '../constants';

export function loginRequest(credentials) {
  return {
    type: LOGIN_REQUEST,
    payload: credentials
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

export function loginReset() {
  return {
    type: LOGIN_RESET
  };
}
