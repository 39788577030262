import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class CountDown extends Component {
  constructor(props) {
    super(props);

    this.state = { counter: this.props.countDownInSeconds };

    this.showCounter = this.showCounter.bind(this);
    this.updateCounter = this.updateCounter.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(this.updateCounter, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  updateCounter() {
    let newCounterValue = this.state.counter - 1;

    if (newCounterValue < 0) {
      if (this.props.resetOnFinish) {
        this.setState({ counter: this.props.countDownInSeconds });
      } else {
        this.setState({ counter: 0 });
        clearInterval(this.timer);
      }
    } else {
      this.setState({ counter: newCounterValue });
    }
  }

  showCounter(secondsLeft) {
    let minutes = Math.floor(secondsLeft / 60);
    let seconds = Math.floor(secondsLeft % 60);

    function addZero(numb) {
      if (numb < 10) {
        return `0${numb}`;
      }
      return numb;
    }
    return `${addZero(minutes)}:${addZero(seconds)}`;
  }

  render() {
    return <p className="countdown">{this.showCounter(this.state.counter)}</p>;
  }
}

CountDown.defaultProps = {
  resetOnFinish: false
};

CountDown.propTypes = {
  countDownInSeconds: PropTypes.number.isRequired,
  resetOnFinish: PropTypes.bool
};
