import { put, take, call } from 'redux-saga/effects';
import apiClient from '../lib/celled_client';
import { checkCommonErrors } from '../lib/auth';
import {
  LOAD_USER_PREFERENCES,
  LOAD_USER_PREFERENCES_SUCCESS,
  LOAD_USER_PREFERENCES_FAILED,
  TOGGLE_SMS_REMINDER,
  ADD_NOTIFICATION,
  TOGGLE_USE_RTL
} from '../constants';
import { changeLanguage } from '../actions/i18n';
import { getAchievements } from '../actions/achievements';

export function* loadUserPreferences(userId) {
  try {
    const preferencesData = yield call(apiClient.loadUserPreferences, userId);
    yield put({ type: LOAD_USER_PREFERENCES_SUCCESS, payload: preferencesData.data.learner });
    yield put(changeLanguage(preferencesData.data.learner.language, true, userId));
    yield put(getAchievements());
  } catch (error) {
    let errorPayload = {
      type: LOAD_USER_PREFERENCES_FAILED,
      notification: {
        message: 'Connection problem',
        className: 'error',
        unique: true,
        type: 'preference_data'
      }
    };
    yield put(checkCommonErrors(error, errorPayload));
  }
}

function* loadUserPreferencesWatcher() {
  while (true) {
    const preferencesData = yield take(LOAD_USER_PREFERENCES);
    yield call(loadUserPreferences, preferencesData.userId);
  }
}

export function* toggleSmsReminder(userId, toggle) {
  try {
    yield call(apiClient.updateConfig, userId, 'receiveSmsReminders', toggle.toString());
    const preferencesData = yield call(apiClient.loadUserPreferences, userId);

    if (toggle === false) {
      // if we are disabling,
      yield call(apiClient.updatePushToken, userId, ''); // nullify/empty that learner's push token
    }

    yield put({ type: LOAD_USER_PREFERENCES_SUCCESS, payload: preferencesData.data.learner });
    yield put({
      type: ADD_NOTIFICATION,
      payload: {
        message: 'Preferences successfully changed',
        unique: true,
        type: 'preference_data'
      }
    });
  } catch (error) {
    let errorPayload = {
      type: LOAD_USER_PREFERENCES_FAILED,
      notification: {
        message: 'Connection problem',
        className: 'error',
        unique: true,
        type: 'preference_data'
      }
    };
    yield put(checkCommonErrors(error, errorPayload));
  }
}

function* toggleSmsReminderWatcher() {
  while (true) {
    const smsReminder = yield take(TOGGLE_SMS_REMINDER);
    yield call(toggleSmsReminder, smsReminder.userId, smsReminder.toggle);
  }
}

export function* toggleuseRTL() {
  while (true) {
    const { payload: { userId, toggle } } = yield take(TOGGLE_USE_RTL);
    try {
      yield put({
        type: LOAD_USER_PREFERENCES_SUCCESS, payload: { useRTL: toggle }
      });
      yield call(apiClient.updateConfig, userId, 'useRTL', toggle);
      yield put({
        type: ADD_NOTIFICATION,
        payload: {
          message: 'Preferences successfully changed',
          unique: true,
          type: 'preference_data'
        }
      });
    } catch (error) {
      const errorPayload = {
        type: LOAD_USER_PREFERENCES_FAILED,
        notification: {
          message: 'Connection problem',
          className: 'error',
          unique: true,
          type: 'preference_data'
        }
      };
      yield put(checkCommonErrors(error, errorPayload));
      //Rollback if the update failed.
      const preferencesData = yield call(apiClient.loadUserPreferences, userId);
      yield put({
        type: LOAD_USER_PREFERENCES_SUCCESS, payload: preferencesData.data.learner
      });
    }
  }
}

export default function* rootPreferencesSaga() {
  yield [loadUserPreferencesWatcher(), toggleSmsReminderWatcher(), toggleuseRTL()];
}
