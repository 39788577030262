import PropTypes from 'prop-types';
import React from 'react';
import TextMessageBubble from './text_bubble';
import RichContentBubble from '../../forms/rich_content_bubble';
import { formatDate } from '../../../utils/time_format';
import './chat-message.scss';

const ChatMessage = (props) => {
  let bubbleAlignment = props['text-align'] === 'right' ? 'chat-right-bubble' : 'chat-left-bubble';
  bubbleAlignment = props.text === 'typeAudio' ? 'chat-right-bubble' : bubbleAlignment;
  let containerAlignment = 'bubble-container enable-text-selection';
  let bubbleStyle = props.action && { cursor: 'pointer' };

  const chatMessageTime = (dateString) => {
    const date = formatDate(dateString);
    return date[0].toUpperCase() + date.slice(1);
  };

  return (
    <div style={bubbleStyle}>
      <div className={bubbleAlignment}>
        <div className={containerAlignment}>
          {renderBubbleContent(props)}
          <div className="chat-bubble-footer">
            {props.date && <p className="chat-message-time">{chatMessageTime(props.date)}</p>}
            {props.subtext && <p className="chat-message-info">{props.subtext}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderBubbleContent(props) {
  if (props.text && props.htmlText && props.htmlText.length > 0) {
    return <RichContentBubble {...props} />;
  }

  return <TextMessageBubble {...props} />;
}

ChatMessage.propTypes = {
  type: PropTypes.string,
  htmlText: PropTypes.string,
  'text-align': PropTypes.string.isRequired,
  multimedia: PropTypes.shape({
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
    courseAttendanceId: PropTypes.string,
    courseCode: PropTypes.number,
    blob: PropTypes.object,
    error: PropTypes.bool
  }),
  text: PropTypes.string,
  date: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    payload: PropTypes.any.isRequired
  }),
  submitAnswer: PropTypes.func,
  openFullScreenFrame: PropTypes.func,
  closeFullScreenFrame: PropTypes.func,
  handleChatImageLoaded: PropTypes.oneOfType([PropTypes.func, PropTypes.any])
};

export default ChatMessage;
