import React from 'react';
import Translate from '../../hocs/translate';

const NotFound = (props) => {
	return <div>{props.strings['Page not found']}</div>;
};

NotFound.defaultProps = {
	strings: {
		'Page not found': 'Page not found',
	},
};

export default Translate('NotFound')(NotFound);
