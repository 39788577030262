//
//  activity_tracking.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//
import { Plugins, Capacitor } from '@capacitor/core';
import client, { getRequestHeadersWithToken } from '../celled_client';
import config from '../../config.json';

const Promise = require('bluebird');
const moment = require('moment');
const _ = require('lodash');

const API_GATEWAY_PATH = config.gatewayApi.baseUrl;
const { ActivityTracking } = Plugins;
const KEY_STARTED_ACTIVITY_COLLECTION = 'KEY_STARTED_ACTIVITY_COLLECTION';

/**
 * ActivityTrackingManager is a ReactJS-client library
 * to interface with the Capacitor native plug-ins responsible
 * for activity tracking.
 */
const TAG = '[ActivityTrackingManager]';
export default class ActivityTrackingManagerAndroid {
  constructor() {
    console.log(`${TAG} - running Android pipeline`);
    this.isAvailable = Capacitor.isPluginAvailable('ActivityTracking'); // && Capacitor.isPluginAvailable('PassiveNetworking');
    console.log(`${TAG} - ActivityTracking Capacitor Plugin Available: ${this.isAvailable}`);
    this.registerListeners();
  }

  /**
   * Configuring Android for Activity Tracking 
   * @param {String} learnerId
   * @return {Boolean} success/failure
   */
  configure(learnerId) {
    console.log(`[ActivityTrackingManager] configure()`);
    if (!this.isAvailable) {
      return Promise.resolve(false);
    }

    const headers = getRequestHeadersWithToken();
    return ActivityTracking.configureNetworking({
      headers, baseUrl: API_GATEWAY_PATH});
  }

  /**
   * Start tracking activity in the background.
   * Calls configure() for session before starting.
   * Must call getPermissions before calling start.
   * @param {String} learnerId
   * @return {Boolean} success/failure
   */
  start(learnerId) {
    if (!this.isAvailable) {
      return Promise.resolve(false);
    }

    console.log(`[ActivityTrackingManager] start()`);
    return this.configure(learnerId)
    .then(function() {
      window.localStorage.setItem(
        KEY_STARTED_ACTIVITY_COLLECTION,
        JSON.stringify(true)
      );
      return ActivityTracking.startPassiveCollection({
        fromStartDate: moment().subtract(2, 'weeks').toISOString(),
        learnerId: learnerId
      }).catch(function (err) {
          console.error(`[ActivityTrackingManager] unable to configure.`);
          window.localStorage.setItem(
            KEY_STARTED_ACTIVITY_COLLECTION,
            JSON.stringify(false)
          );
          return false;
      });
    })
  }

  async registerListeners() {
    // listen to Permission-related events
    ActivityTracking.addListener('onAuthorized', async (result) => {
      console.log( `[ActivityTrackingManager] onAuthorized: ${JSON.stringify(result)}`);
    });

    this.listenForDataAndDeliver();
  }
  /**
   * Listen for incoming data from the native app 
   * to the web compatibility layer
   */
  async listenForDataAndDeliver() {
    ActivityTracking.addListener('activityResult', async (result) => {
      console.log(`[ActivityTrackingManager] - [listenForDataAndDeliver] result: ` + JSON.stringify(result));
      const data = _.get(result, 'data', null);
      if (result && result.header) {
        await client.uploadActivity(result).then(function (response) {
          console.log(`[listenForDataAndDeliver] response: ` + JSON.stringify(response.status));
          if (response.status === 200) {
            // successful upload, move activity anchor
            ActivityTracking.updateDataDeliveryAnchor();
          }
        });
      }
    });
  }


  /**
   * STOP tracking activity in the background.
   * @return {Boolean} success/failure
   */
  stop() {
    if (!this.isAvailable) {
      return Promise.resolve(false);
    }

    const hasStartedCollection = JSON.parse(
      window.localStorage.getItem(KEY_STARTED_ACTIVITY_COLLECTION)
    );
    console.log(`[ActivityTrackingManager] stop() ${KEY_STARTED_ACTIVITY_COLLECTION}: ${hasStartedCollection}`);
    if (hasStartedCollection === false) {
      return Promise.resolve(true); // did not start collection, no need to disable
    }

    window.localStorage.setItem(
      KEY_STARTED_ACTIVITY_COLLECTION,
      JSON.stringify(false)
    );
    return ActivityTracking.stopPassiveCollection();
  }

  /**
   * Request native permissions to HealthKit (iOS) activity data.
   * Permissions must be obtained before starting data collection.
   * @return {Boolean} true if permissions were granted.
   */
  getPermissions() {
    if (!this.isAvailable) {
      return Promise.resolve(false);
    }

    console.log(`[ActivityTrackingManager] getPermissions()`);
    // refer to https://developer.android.com/reference/android/health/connect/HealthPermissions
    // One main difference though with HealthKit is that Android DOES require explicit permissions declared
    // in the AndroidManifest.xml file, and the user must grant them at runtime.
    return ActivityTracking.getHealthAuthorization({
      types: [
        'android.permission.health.READ_STEPS',
        'android.permission.health.READ_DISTANCE'
      ],
    })
      .then(function (permission) {
        console.log(`[ActivityTrackingManager] getPermissions() ${JSON.stringify(permission)}`);
        return permission && permission.success === true;
      })
      .catch(function (err) {
        console.log(`[ActivityTrackingManager] unable to get permissions, ${err}`);
        return false;
      });
  }

}
