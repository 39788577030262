import React, { useEffect, useState } from 'react';
import { hashHistory } from 'react-router';
import { formatUSPhoneNumber } from '../../utils/phone_format';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import Translate from '../../hocs/translate';
import IconError from '../../components/assets/icon_error';
import IconEye from '../../components/icons/icon_eye';

const EnterPasswordComponent = ({ loginRequest, strings, phoneNumber, loginFailed }) => {
  useEffect(() => {
    if (localStorage.getItem('celledCredentials') !== null) {
      hashHistory.push('/active_course');
    }
    // Do not autofocus on KaiOS - WEBAPP-1959
    if (/KAIOS/.test(navigator.userAgent) === false) {
      var input = document.getElementsByClassName('form-input');
      input[0].focus();
    }
    document.title = 'Cell-Ed Sign In • Essential Skills on the Go';
  }, []);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    setPasswordError(false);

    if (password === '') {
      setPasswordError(true);
      return;
    }
    var credentials = { password };
    loginRequest(credentials);
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const renderClientFormValidation = () => {
    if (passwordError || loginFailed) {
      return (
        <div className="notificationStyles form-item">
          <IconError className="notificationStyles__icon" />
          {loginFailed
            ? strings['Invalid password, please try again.']
            : strings['PasswordFieldRequired']}
        </div>
      );
    }
  };

  return (
    <div className="app-full-h">
      <form className="onboarding-form onboarding-form--enter-password" onSubmit={loginSubmit}>
        <h2 className="form-title">{strings['Welcome back!']}</h2>
        <p className="form-subtitle">{formatUSPhoneNumber(phoneNumber)}</p>
        <p className="form-description">
          {strings['Please enter your password to access the app.']}
        </p>

        <div className="form-box">
          <label htmlFor="password" className="visually-hidden">
            {strings['Password']}
          </label>
          <div className="form-horizontal">
            <input
              className={`form-input ${passwordError || loginFailed ? 'form-input--error' : ''}`}
              id="password"
              placeholder={strings['Enter your password']}
              value={password}
              onChange={passwordHandler}
              type={showPassword ? 'text' : 'password'}
              aria-label={strings['Enter your password']}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <button
              className="form-input__show-password-button"
              aria-label={`${showPassword ? strings['hide'] : strings['show']} ${
                strings['password']
              }`}
              onClick={toggleShowPassword}
              type="button">
              <IconEye active={showPassword} />
            </button>
          </div>
        </div>

        {renderClientFormValidation()}

        <div className="form-gap"></div>
        <div className="onboarding-form__footer">
          <div className="form-button-view">
            <button className="button form-button form-item box-sizing-border-box" type="submit">
              {strings['Log In']}
            </button>
          </div>
          <div className="login-reset-password">
            <Link className="reset-link" to="/reset_password">
              {strings['Forgot password?']}
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

EnterPasswordComponent.defaultProps = {
  strings: {
    'Log In': 'Log In',
    'Enter your password': 'Enter your password',
    'Forgot password?': 'Forgot password?',
    PasswordHint: 'Password entered when you signed up',
    PasswordFieldRequired: 'Please enter your password',
    'Welcome back!': 'Welcome back!',
    'Please enter your password to access the app.':
      'Please enter your password to access the app.',
    show: 'show',
    hide: 'hide',
    password: 'password',
    'Invalid password, please try again.': 'Invalid password, please try again.'
  }
};

EnterPasswordComponent.propTypes = {
  loginRequest: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  strings: PropTypes.object,
  loginFailed: PropTypes.bool
};

export default Translate('EnterPasswordComponent')(EnterPasswordComponent);
