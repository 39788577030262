import PropTypes from 'prop-types';
import React from 'react';
import ReactImg from '../../react_img/ReactImg';
import ReactCertificate from '../../react_certificate/ReactCertificate';
import emoji from 'react-easy-emoji';

const MultimediaElement = ({ multimediaObject, onClick, handleChatImageLoaded }) => {
  const renderOption = {
    youtube: renderVideo,
    vimeo: renderVideo,
    image: renderImage,
    certificate: renderCertificate,
    emoji: renderEmoji,
    pdf: renderPdf,
    web: renderWeb
  };

  return (
    <div className="chat-multimedia">
      {renderOption[multimediaObject.type](multimediaObject, onClick, handleChatImageLoaded) ||
        null}
    </div>
  );
};

function renderVideo(multimediaObject) {
  return (
    <div>
      <iframe
        src={parseVideoUrl(multimediaObject.url, multimediaObject.type)}
        width="100%"
        height="auto"
        frameBorder="0"
        allowFullScreen
        title="video"
      />
    </div>
  );
}

function renderImage(multimediaObject, onClick, handleChatImageLoaded) {
  return (
    <ReactImg
      onClick={onClick.bind(null, multimediaObject.url)}
      alt={multimediaObject.description}
      src={multimediaObject.url}
      handleImageLoaded={handleChatImageLoaded}
      className="chat-image"
    />
  );
}

function renderCertificate(multimediaObject, onClick) {
  return (
    <ReactCertificate
      onClick={onClick.bind(null, multimediaObject.url)}
      alt={multimediaObject.description}
      src={multimediaObject.blob}
      error={multimediaObject.error}
      courseAttendanceId={multimediaObject.courseAttendanceId}
      courseCode={multimediaObject.courseCode}
      className="chat-image"
    />
  );
}

function renderWeb(multimediaObject, onClick) {
  return (
    <button onClick={onClick.bind(null, multimediaObject.url)}>
      {multimediaObject.description || multimediaObject.url}
    </button>
  );
}

function customEmoji(input, handleChatImageLoaded) {
  const parseEmoji = emoji(input)[0].props;
  if (parseEmoji.src) {
    return (
      <ReactImg
        alt={parseEmoji?.alt || ''}
        src={parseEmoji.src}
        handleImageLoaded={handleChatImageLoaded}
        className="chat-emoticon__emoji"
      />
    );
  }
  return input;
}

function renderEmoji(multimediaObject, onClick, handleChatImageLoaded) {
  return (
    <div className="chat-emoticon">{customEmoji(multimediaObject.url, handleChatImageLoaded)}</div>
  );
}

function renderPdf(multimediaObject) {
  return (
    <div>
      <iframe
        src={`https://docs.google.com/viewer?url=${multimediaObject.url}&embedded=true`}
        className="chat-image"
        frameBorder="0"
        allowFullScreen
        title="pdf"
      />
    </div>
  );
}

function parseVideoUrl(url, platform) {
  if (platform === 'youtube') {
    const videoUrl = url.split('=');
    return `https://www.youtube.com/embed/${videoUrl[1]}?rel=0&amp;controls=0&amp;showinfo=0`;
  }

  if (platform === 'vimeo') {
    const videoUrl = url.split('com');
    return `https://player.vimeo.com/video${videoUrl[1]}?color=ffffff&title=0&byline=0&portrait=0`;
  }
}

MultimediaElement.propTypes = {
  multimediaObject: PropTypes.object,
  handleChatImageLoaded: PropTypes.oneOfType([PropTypes.func, PropTypes.any])
};

export default MultimediaElement;
