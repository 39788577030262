import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hashHistory, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import {
  forgotPasswordRequest,
  confirmCode,
  resendForgotPasswordConfirmationCode,
  resetPasswordRequest,
  changePhoneNumber,
  resetState
} from '../../actions/reset-password';
import { focusNextInput } from '../../utils/inputs';
import { formatUSPhoneNumber } from '../../utils/phone_format';
import { validPassword } from '../../utils/validations';
import ReactTelInput from 'react-telephone-input';
import BackButton from '../../components/buttons/back_button';
import SignupConfirmationForm from '../../components/signup/signup_confirmation_form';
import IconError from '../../components/assets/icon_error';

import FormPassword from '../../components/forms/form_password';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      dialCode: '',
      completePhoneNumberError: false,
      password: '',
      confirmPassword: '',
      passwordError: false
    };
    // Enter phone number
    this.setPhoneNumberAndDialCode = this.setPhoneNumberAndDialCode.bind(this);
    this.submitResetPassword = this.submitResetPassword.bind(this);
    // Enter code
    this.submitConfirmCode = this.submitConfirmCode.bind(this);
    this.submitResetCode = this.submitResetCode.bind(this);
    this.changePhoneNumber = this.changePhoneNumber.bind(this);
    // Enter password
    this.setPassword = this.setPassword.bind(this);
    this.setConfirmPassword = this.setConfirmPassword.bind(this);
    this.submitUpdatePassword = this.submitUpdatePassword.bind(this);
    this.storeCredentials = this.storeCredentials.bind(this);
    this.handleUpdateError = this.handleUpdateError.bind(this);
  }
  /**
   * Lifecycle
   * *****************************************************************************************************************
   */
  componentDidUpdate() {
    if (this.props.resetPassword.enterPhone) {
      document.title = 'Cell-Ed Reset Password • Essential Skills on the Go';
    }
    if (this.props.resetPassword.enterCode) {
      document.title = 'Cell-Ed Confirm Password Verification • Essential Skills on the Go';
    }
    if (this.props.resetPassword.enterPassword) {
      document.title = 'Cell-Ed New Password • Essential Skills on the Go';
    }
    if (this.props.resetPassword.resetSuccess) {
      setTimeout(() => {
        this.storeCredentials(this.props.userData.userId, this.props.userData.token);
        hashHistory.push('/active_course');
      }, 500);
    }
  }
  /**
   * Local bindings
   * *****************************************************************************************************************
   */
  // Enter phone number ******************************************************************************************************************************************
  /**
   * Setter phone number and dial code.
   * @param {String} enteredPhone
   * @param {String} selectedCountry
   */
  setPhoneNumberAndDialCode(enteredPhone, selectedCountry) {
    this.setState({
      phoneNumber: enteredPhone,
      dialCode: selectedCountry.dialCode
    });
  }
  /**
   * Clean phone number.
   * @param {String} number
   */
  cleanPhoneNumber(number) {
    var cleanNumber = number.replace(/\(|\)|\s|-/g, '');
    return cleanNumber;
  }
  /**
   * This method executes the password reset logic,
   */
  submitResetPassword(e) {
    e.preventDefault();
    const { phoneNumber, dialCode } = this.state;
    this.setState({ completePhoneNumberError: false });
    if (phoneNumber === '') {
      this.setState({ completePhoneNumberError: true });
      return;
    } else {
      this.props.forgotPasswordRequest(this.cleanPhoneNumber(`+${dialCode}${phoneNumber}`));
    }
  }
  // Enter code **************************************************************************************************************************************************
  /**
   * This method executes the code confirmation logic.
   * @param {Object} confirmationData
   */
  submitConfirmCode(confirmationData) {
    confirmationData.phoneNumber = this.props.resetPassword.phoneNumber;
    this.props.confirmCode(confirmationData);
  }
  /**
   * This method executes the logic of request of generation / sending of new code.
   */
  submitResetCode(e) {
    e.preventDefault();
    this.props.resendForgotPasswordConfirmationCode(this.props.resetPassword.phoneNumber);
  }
  /**
   * This method ends with the re-entry of the phone number.
   */
  changePhoneNumber() {
    // return to reset password flow
    hashHistory.push('/reset_password');
    // reset store
    this.props.resetState();
    // reset local state
    this.setState((state) => ({ ...state, phoneNumber: '', dialCode: '' }));
  }
  // Enter password **********************************************************************************************************************************************
  /**
   * Setter password
   * @param {string} value
   */
  setPassword(value) {
    this.setState({ ...this.state, password: value }, () => {
      if (this.state.passwordError) {
        // if in state of error, validate password updates and remove error message once user satisfies all password criteria
        if (validPassword(this.state.password, this.state.confirmPassword)) {
          this.setState({ ...this.state, passwordError: false });
        }
      }
    });
  }

  /**
   * Setter confirm password
   * @param {string} value
   */
  setConfirmPassword(value) {
    this.setState({ ...this.state, confirmPassword: value }, () => {
      if (this.state.passwordError) {
        // if in state of error, validate password updates and remove error message once user satisfies all password criteria
        if (validPassword(this.state.password, this.state.confirmPassword)) {
          this.setState({ ...this.state, passwordError: false });
        }
      }
    });
  }
  /**
   * This method executes the logical password update.
   */
  submitUpdatePassword(e) {
    e.preventDefault();
    let newPassword = this.state.password;
    let phoneNumber = this.props.resetPassword.phoneNumber;
    let confirmationCode = this.props.resetPassword.confirmationCode;
    let data = { newPassword, phoneNumber, confirmationCode };

    if (validPassword(newPassword, this.state.confirmPassword)) {
      this.setState({ ...this.state, passwordError: false });
      this.props.resetPasswordRequest(data);
    } else {
      this.setState({ ...this.state, passwordError: true });
    }
  }

  storeCredentials(userId, token) {
    const loginStorage = {
      celledAuthToken: token,
      celledUserId: userId
    };
    localStorage.setItem('celledCredentials', JSON.stringify(loginStorage));
  }

  toggleShowPassword(e) {
    e.preventDefault();
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  }

  toggleShowPasswordConfirm(e) {
    e.preventDefault();
    this.setState({ ...this.state, showPasswordConfirm: !this.state.showPasswordConfirm });
  }

  handleUpdateError(payload) {
    this.setState({ ...this.state, passwordError: payload });
  }

  /**
   * Renders
   * *****************************************************************************************************************
   */
  render() {
    return this.props.resetPassword.enterPassword
      ? this.renderEnterPassword()
      : this.props.resetPassword.enterCode
      ? this.renderEnterCode()
      : this.renderEnterPhoneNumber();
  }
  /**
   * Enter phone number
   * *****************************************************************************************************************
   */
  renderEnterPhoneNumber() {
    return (
      <div className="reset-password-container">
        <div className="reset-password-page-header">
          <BackButton onClick={browserHistory.goBack} />
          <p className="reset-password-step-count">
            {this.props.strings['Step']} 1 {this.props.strings['of']} 3
          </p>
        </div>
        <div className="app-full-h">
          <form className="reset-password-form onboarding-form">
            <h2 className="form-title">{this.props.strings['Reset your password']}</h2>
            <p className="form-description">
              {
                this.props.strings[
                  'Enter your cell phone number. We will send you a text message with a verification code.'
                ]
              }
            </p>
            <div className="form-box">
              <ReactTelInput
                initialValue=""
                defaultCountry="us"
                onChange={this.setPhoneNumberAndDialCode}
                value={this.state.phoneNumber}
                flagsImagePath="images/flags.png"
                classNames="form-input"
                autoFormat
                preferredCountries={['us']}
                onEnterKeyPress={focusNextInput}
                aria-label={this.props.strings['Enter your cell phone number']}
                placeholder={this.props.strings['Enter your cell phone number']}
              />
            </div>
            {this.renderPhoneNumberValidation()}
            <div className="form-gap" />
            <div className="onboarding-form__footer">
              <div className="form-button-view">
                <button
                  className="button form-item box-sizing-border-box"
                  aria-label="Continue Button"
                  onClick={this.submitResetPassword}>
                  {this.props.strings.Continue}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  renderPhoneNumberValidation() {
    if (this.state.completePhoneNumberError) {
      return (
        <div className="notificationStyles form-item">
          <IconError className="notificationStyles__icon" />
          {this.props.strings['Enter your cell phone number']}
        </div>
      );
    }
  }
  /**
   * Enter code
   * *****************************************************************************************************************
   */
  renderEnterCode() {
    return (
      <div className="reset-password-container app-full-h">
        <div className="reset-password-page-header">
          <BackButton onClick={this.changePhoneNumber} />
          <p className="reset-password-step-count">
            {this.props.strings['Step']} 2 {this.props.strings['of']} 3
          </p>
        </div>
        <div className="onboarding-form onboarding-form--reset-password-confirm-code">
          <h2 className="form-title">{this.props.strings['Reset your password']}</h2>
          <p className="form-subtitle ">{`${formatUSPhoneNumber(
            this.props.resetPassword.phoneNumber
          )}`}</p>
          <p className="form-description">
            {
              this.props.strings[
                'Check your text message. We just sent you a 5-digit verification code to the number listed above.'
              ]
            }
          </p>

          <SignupConfirmationForm submitConfirmationDigits={this.submitConfirmCode} />

          <button
            onClick={this.submitResetCode}
            className="button-link form-item"
            aria-label={this.props.strings['I need a new code']}>
            {this.props.strings['I need a new code']}
          </button>
          <button
            onClick={this.changePhoneNumber}
            className="button-link form-item"
            aria-label={this.props.strings['Enter different mobile number']}>
            {this.props.strings['Enter different mobile number']}
          </button>
        </div>
      </div>
    );
  }
  /**
   *  Enter password
   * *****************************************************************************************************************
   */
  renderEnterPassword() {
    return (
      <div className="login-container app-full-h">
        <div className="reset-password-page-header">
          <BackButton onClick={this.changePhoneNumber} />
          <p className="reset-password-step-count">
            {this.props.strings['Step']} 3 {this.props.strings['of']} 3
          </p>
        </div>
        <div className="app-full-h onboarding-form">
          <h2 className="form-title">{this.props.strings['Reset your password']}</h2>
          <p className="form-subtitle ">{`${formatUSPhoneNumber(
            this.props.resetPassword.phoneNumber
          )}`}</p>
          <p className="form-description">
            {this.props.strings['Create a new password. It needs to be 6 characters or more.']}
          </p>

          <FormPassword
            focusNextInput={focusNextInput}
            setPassword={this.setPassword}
            setConfirmPassword={this.setConfirmPassword}
            passwordError={this.state.passwordError}
            handleUpdateError={this.handleUpdateError}
            password={this.state.password}
            confirmPassword={this.state.confirmPassword}
            handleEnterSubmit={this.submitUpdatePassword}
          />

          <div className="form-gap"></div>
          <div className="onboarding-form__footer">
            <div className="form-button-view">
              <div className="form-button-view">
                <button
                  className="button form-item box-sizing-border-box"
                  aria-label={this.props.strings['Continue']}
                  onClick={this.submitUpdatePassword}>
                  {this.props.strings['Continue']}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.defaultProps = {
  strings: {
    // Enter phone number
    'Reset your password': 'Reset your password',
    Continue: 'Continue',
    'Enter your cell phone number': 'Enter your cell phone number',
    'Enter your cell phone number. We will send you a text message with a verification code.':
      'Enter your cell phone number. We will send you a text message with a verification code.',
    Step: 'Step',
    of: 'of',
    // Enter code
    'Confirmation in progress...': 'Confirmation in progress...',
    'Enter the code': 'Enter the code',
    'I need a new code': 'I need a new code',
    'Enter different mobile number': 'Enter different mobile number',
    'We just sent the text code to:': 'We just sent the text code to:',
    "Let's verify your phone": "Let's verify your phone",
    'Check your text message. We just sent you a 5-digit verification code to the number listed above.':
      'Check your text message. We just sent you a 5-digit verification code to the number listed above.',
    // Enter password
    'Create a new password. It needs to be 6 characters or more.':
      'Create a new password. It needs to be 6 characters or more.'
  }
};

function mapStateToProps(state) {
  return {
    resetPassword: state.resetPassword,
    userData: state.mainApp
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      forgotPasswordRequest,
      confirmCode,
      resendForgotPasswordConfirmationCode,
      resetPasswordRequest,
      changePhoneNumber,
      resetState
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
