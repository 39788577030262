import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTelInput from 'react-telephone-input';
import Translate from '../../hocs/translate';
import apiClient from '../../lib/celled_client';
import { getSignupPublicAccountDetails, resetPublicAccountDetails } from '../../actions/signup';
import AppLinkingComponent from '../../components/others/app_linking_component';
import IconError from '../../components/assets/icon_error';
import LoginFooter from '../../components/login_footer/';

class EnterPhoneComponent extends Component {
  constructor() {
    super();

    this.state = {
      phoneNumber: '',
      dialCode: '',
      receiveSmsReminders: true,
      validationErrors: false
    };

    this.requestSimpleLogin = this.requestSimpleLogin.bind(this);
    this.phoneNumberHandler = this.phoneNumberHandler.bind(this);
    this.renderClientFormValidation = this.renderClientFormValidation.bind(this);
    this.renderPhoneNumberFormatValidation = this.renderPhoneNumberFormatValidation.bind(this);
    this.checkInvalidPhoneNumberFormat = this.checkInvalidPhoneNumberFormat.bind(this);
    this.resetInferredPincode = this.resetInferredPincode.bind(this);
  }

  componentDidMount() {
    // Do not autofocs on KaiOS - WEBAPP-1959
    if (/KAIOS/.test(navigator.userAgent) === false) {
      let input = document.getElementsByClassName('form-input');
      input[0].childNodes[1].focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.appLinkData) return;

    const currentPincode = this.props.appLinkData.pincode;
    const prevPincode = prevProps.appLinkData ? prevProps.appLinkData.pincode : null;
    if (currentPincode !== prevPincode && currentPincode !== null && currentPincode.length > 0) {
      this.props.getSignupPublicAccountDetails(currentPincode);
    }
  }

  static getPhoneNumberFromLocalStorage() {
    return localStorage.getItem('celledPhoneNumber').replace('"', '');
  }

  phoneNumberHandler(enteredPhone, selectedCountry) {
    this.setState({
      phoneNumber: enteredPhone,
      dialCode: selectedCountry.dialCode,
      validationErrors: false
    });
  }

  renderPhoneNumberFormatValidation() {
    if (
      this.checkInvalidPhoneNumberFormat(
        EnterPhoneComponent.cleanPhoneNumber(this.state.phoneNumber)
      ) &&
      this.state.phoneNumber
    ) {
      return (
        <div className="notificationStyles form-item">
          <IconError className="notificationStyles__icon" />
          {this.props.strings['Please enter a valid phone number']}
        </div>
      );
    }
  }

  static cleanPhoneNumber(number) {
    return number.replace(/\(|\)|\s|-/g, '');
  }

  renderClientFormValidation() {
    if (!this.state.phoneNumber) {
      return (
        <div className="notificationStyles form-item">
          <IconError className="notificationStyles__icon" />
          {this.props.strings['Please enter your cell phone number']}
        </div>
      );
    }
  }

  checkInvalidPhoneNumberFormat(phonenumber) {
    let phoneNumberUScompliance = false;

    if (phonenumber.length !== 10 && this.state.dialCode === '1') phoneNumberUScompliance = true;
    return phoneNumberUScompliance;
  }

  requestSimpleLogin(e) {
    e.preventDefault();

    if (!this.state.phoneNumber) {
      this.setState({ validationErrors: true });
    } else if (
      this.checkInvalidPhoneNumberFormat(
        EnterPhoneComponent.cleanPhoneNumber(this.state.phoneNumber)
      )
    ) {
      this.setState({ validationErrors: true });
    } else {
      this.setState({ validationErrors: false });
      var phoneNumber = EnterPhoneComponent.cleanPhoneNumber(
        `+${this.state.dialCode}${this.state.phoneNumber}`
      );
      this.props.requestSimpleLogin({
        phoneNumber: phoneNumber,
        receiveSms: this.state.receiveSmsReminders
      });
    }
  }

  handleCountryCodeDefault() {
    return apiClient.findIp();
  }

  resetInferredPincode(e) {
    e.preventDefault();

    AppLinkingComponent.clear();
    this.props.resetPublicAccountDetails();
  }

  getInferredPincodeDescription(publicAccount) {
    if (!publicAccount) return '';

    const localizedText = this.props.strings['You are part of'];
    return `${localizedText} ${
      publicAccount.publicName ? publicAccount.publicName : publicAccount.name
    }`;
  }

  render() {
    const { publicAccount } = this.props;

    return (
      <div className="app-full-h">
        <form className="onboarding-form" onSubmit={this.requestSimpleLogin}>
          <h2 className="form-title welcome">{this.props.strings['Welcome']}</h2>
          <p className="form-description">
            {this.props.strings['Please enter your cell phone number below.']}
          </p>
          <div className="form-box">
            <label htmlFor="phoneNumber" className="visually-hidden">
              {this.props.strings['Enter your cell phone number']}
            </label>
            <ReactTelInput
              inputId="phoneNumber"
              placeholder={this.props.strings['Enter your cell phone number']}
              defaultCountry="us"
              onChange={this.phoneNumberHandler}
              asyncCountryCodeDefault={this.handleCountryCodeDefault}
              value={this.state.phoneNumber}
              flagsImagePath="images/flags.png"
              className={`form-input ${
                this.state.validationErrors ? 'react-tel-input--error' : ''
              }`}
              autoFormat
              preferredCountries={['us']}
              aria-label={this.props.strings['Enter your cell phone number']}
            />
          </div>
          {this.state.validationErrors && this.renderPhoneNumberFormatValidation()}
          {this.state.validationErrors && this.renderClientFormValidation()}
          {publicAccount && (
            <div
              className="form-subtitle form-item animation-enter"
              style={{ textAlign: 'center', marginTop: 25 }}>
              <p style={{ fontWeight: 'bold' }}>
                {this.getInferredPincodeDescription(publicAccount)}
              </p>
              <p>(PIN {publicAccount.pincode})</p>
              <div style={{ marginTop: 5 }} onClick={this.resetInferredPincode}>
                {this.props.strings['If not, please click here']}
              </div>
            </div>
          )}
          <div className="form-gap"></div>
          <div className="onboarding-form__footer">
            <div className="form-button-view">
              <button className="button form-item box-sizing-border-box" type="submit">
                {this.props.strings['Continue']}
              </button>
            </div>
            <LoginFooter />
          </div>
        </form>
      </div>
    );
  }
}

EnterPhoneComponent.defaultProps = {
  strings: {
    Welcome: 'Welcome',
    Continue: 'Continue',
    'Enter your cell phone number': 'Enter your cell phone number',
    'Please enter your cell phone number': 'Please enter your cell phone number',
    'Please enter a valid phone number': 'Please enter a valid phone number',
    'Please enter your cell phone number below.': 'Please enter your cell phone number below.',
    'You are part of': 'You are part of',
    'If not, please click here': (
      <span>
        If not, please <u>click here</u>
      </span>
    )
  }
};

function mapStateToProps(state) {
  return {
    publicAccount: state.signup.publicAccount,
    appLinkData: state.preferences.appLinkData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getSignupPublicAccountDetails, resetPublicAccountDetails }, dispatch);
}

export default Translate('EnterPhoneComponent')(
  connect(mapStateToProps, mapDispatchToProps)(EnterPhoneComponent)
);
