import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NavBar from '../containers/navbar/navbar_main_burger';
import Notifications from './notifications/notifications';
import { changeLanguage } from '../actions/i18n';
import { getUserId, userIsLogged, getDeviceInfo } from '../actions/main_app';
import { toggleSwitchModal } from '../actions/chat';
import { toggleuseRTL } from '../actions/preferences';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { auth } from '../lib/auth';
import TimeOutModal from './time_out_modal/time_out_modal_main';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import AudioComponent from './audio/audio_component';
import BrandingComponent from '../components/others/branding_component';
import Reconnect from './reconnect/reconnect';
import ActivityTrackingComponent from '../components/activity_tracking/activity_tracking_component';
import { loadAppDeviceInfo } from '../lib/app_device_info_manager';
import AppLinkingComponent from '../components/others/app_linking_component';
import ScreenOnComponent from '../components/others/screen_on_component';
import { Plugins, Capacitor } from '@capacitor/core';
import * as arrowKeyNavigation from 'arrow-key-navigation';
import SwitchFlowModal from '../components/switch_flow_modal/SwitchFlowModal';

const { SplashScreen, App: AppCapacitor } = Plugins;
//--------------------------- Native Android backButton event ------------------------------
let navBackButtonRef = null;

export function addNavBackButton() {
  navBackButtonRef = AppCapacitor.addListener('backButton', () => window.history.back());
}

export function removeNavBackButton() {
  if (navBackButtonRef !== null) {
    navBackButtonRef.remove();
    navBackButtonRef = null;
  }
}
// -----------------------------------------------------------------------------------------
class App extends Component {
  constructor(props) {
    super();
    this.setAuth(props);
    this.setLanguage(props);
    loadAppDeviceInfo(props.getDeviceInfo);
    this.parsePath = this.parsePath.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  componentDidMount() {
    if (/KAIOS/.test(navigator.userAgent)) {
      // if we are runninig in KaiOS, enable arrow keys for navigation
      arrowKeyNavigation.register();
    }

    SplashScreen.hide();
    this.publishWindowHeight();
  }

  parsePath(path) {
    return path.replace('/', '');
  }

  render() {
    return (
      <div
        className={`app-layout app-full-vh disable-text-selection ${this.props.brandingTheme ? this.props.brandingTheme : 'celled'
          }`}>
        <NavBar location={this.props.location} theme={this.props.brandingTheme} />
        <Reconnect />
        <AppLinkingComponent />
        {Capacitor.isNative && <ScreenOnComponent />}
        {this.props.courseLoaded && <ActivityTrackingComponent />}
        {/* {this.props.courseLoaded && <PushRegistrationComponent />} */}
        {this.props.courseLoaded && <BrandingComponent />}
        {this.props.loggedIn && <AudioComponent location={this.props.location.pathname} />}
        <main role="main" className={`main main--${this.parsePath(this.props.location.pathname)}`}>
          <TransitionGroup
            childFactory={(child) =>
              React.cloneElement(child, {
                classNames: 'page',
                timeout: 400
              })
            }>
            <CSSTransition key={this.props.location.pathname} timeout={400} unmountOnExit>
              {React.cloneElement(this.props.children, {
                key: this.props.location.pathname
              })}
            </CSSTransition>
          </TransitionGroup>
        </main>
        <TimeOutModal />
        <Notifications />
        {this.props.showSwitchModal && (
          <SwitchFlowModal
            toggleSwitchModal={this.props.toggleSwitchModal}
            location={this.props.location}
          />
        )}
      </div>
    );
  }

  // Check if user is logged and set the phoneNumber in the state
  setAuth(props) {
    if (auth()) {
      props.userIsLogged();
      props.getUserId(auth().celledUserId);
    }
  }

  // Set default language same as user agent
  setLanguage(props) {
    let appLanguage = 'en'; // <-- default
    try {
      let agentLang = window.navigator.language || window.navigator.userLanguage;
      let localStorageLang = JSON.parse(window.localStorage.getItem('celledLanguage'));
      if (localStorageLang) {
        appLanguage = localStorageLang;
      } else {
        agentLang = agentLang.split('-')[0];
        let isLangSupported = props.language.supportedLanguages[agentLang] !== undefined;
        if (isLangSupported) {
          appLanguage = agentLang;
        }
      }
    } catch (e) {
      appLanguage = 'en';
    }

    props.changeLanguage(appLanguage);
  }

  // this is used to support a responsive app screen size across devices,
  // '--vh' is exposed as a property accessible via SCSS.
  publishWindowHeight() {
    if (Capacitor.isNative) return;
    // only process changes if we are on the web, native in-app screen size will not change.

    const publish = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    };

    window.addEventListener('resize', publish);
    window.addEventListener('orientationchange', publish);
    publish();
  }

  changeLanguage(e) {
    this.props.changeLanguage(e);
  }

  // Pass language configuration in Context to all components
  getChildContext() {
    return {
      currentLanguage: this.props.language.language
    };
  }
}

App.childContextTypes = {
  currentLanguage: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    language: state.i18n,
    courseLoaded: state.mainCourse.loaded,
    loggedIn: state.mainApp.userIsLogged,
    showSwitchModal: state.chat.showSwitchModal,
    brandingTheme: state.preferences.preferencesData.brandingTheme
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeLanguage,
      getUserId,
      userIsLogged,
      getDeviceInfo,
      toggleSwitchModal,
      toggleuseRTL
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
