import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      canplay: false,
      playing: false,
      paused: false,
      ended: false,
    };
  }

  componentDidMount() {
    const audio = this.audioEl;

    this.updateVolume(this.props.volume);

    audio.addEventListener(
      'loadstart',
      (e) => {
        this.setState({
          loading: true,
          canplay: false,
          playing: false,
          paused: false,
          ended: false,
        });
      },
      false
    );

    audio.addEventListener(
      'error',
      (e) => {
        this.setState({
          loading: false,
          canplay: false,
          playing: false,
          paused: false,
          ended: false,
        });
        this.props.onError({ index: this.props.index });
      },
      false
    );

    audio.addEventListener(
      'canplaythrough',
      (e) => {
        this.setState({
          loading: false,
          canplay: true,
          playing: false,
          paused: false,
          ended: false,
        });
        this.props.onCanPlayThrough({ index: this.props.index });
      },
      false
    );

    audio.addEventListener(
      'play',
      (e) => {
        this.setState({
          loading: false,
          canplay: false,
          playing: true,
          paused: false,
          ended: false,
        });
        this.props.onPlay({ index: this.props.index });
      },
      false
    );

    audio.addEventListener(
      'abort',
      (e) => {
        this.setState({
          loading: false,
          canplay: true,
          playing: false,
          paused: false,
          ended: false,
        });
        this.clearListenTrack();
        this.props.onAbort(e);
      },
      false
    );

    audio.addEventListener(
      'ended',
      (e) => {
        this.setState({
          loading: false,
          canplay: true,
          playing: false,
          paused: false,
          ended: true,
        });
        this.clearListenTrack();
        this.props.onEnded({ index: this.props.index });
      },
      false
    );

    audio.addEventListener(
      'pause',
      (e) => {
        this.setState({
          loading: false,
          canplay: true,
          playing: false,
          paused: true,
          ended: false,
        });
        this.clearListenTrack();
        this.props.onPause({ index: this.props.index });
      },
      false
    );

    audio.addEventListener(
      'loadedmetadata',
      (e) => {
        this.props.onLoadedMetadata({
          index: this.props.index,
          duration: audio.duration,
        });
      },
      false
    );

    audio.addEventListener(
      'volumechange',
      (e) => {
        this.props.onVolumeChanged(e);
      },
      false
    );

    if (!this.state.loading && !this.state.canplay) {
      const audio = this.audioEl;
      audio.load();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.play !== this.props.play) {
      this.play(nextProps.play);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.setState({
        loading: false,
        canplay: false,
        playing: false,
        paused: false,
        ended: false,
      });

      if (this.audioEl) {
        const audio = this.audioEl;
        audio.load();
      }
    }
  }

  setListenTrack() {
    if (!this.listenTracker) {
      const listenInterval = this.props.listenInterval;
      this.listenTracker = setInterval(() => {
        this.props.onListen({
          index: this.props.index,
          currentTime: this.audioEl.currentTime,
        });
      }, listenInterval);
    }
  }

  clearListenTrack() {
    if (this.listenTracker) {
      clearInterval(this.listenTracker);
      this.listenTracker = null;
    }
  }

  updateVolume(volume) {
    if (typeof volume === 'number' && volume !== this.audioEl.volume) {
      this.audioEl.volume = volume;
    }
  }

  play(play) {
    if (play === true) {
      if (this.state.canplay) {
        this.clearListenTrack();
        this.audioEl.play();
        this.setListenTrack();
      }
    } else {
      this.clearListenTrack();
      this.audioEl.pause();
    }
  }

  render() {
    const incompatibilityMessage = this.props.children || (
      <p>Your browser does not support the audio element.</p>
    );
    const controls = !(this.props.controls === false);
    const title = this.props.title ? this.props.title : this.props.src;
    const conditionalProps = {};
    if (this.props.controlsList) {
      conditionalProps.controlsList = this.props.controlsList;
    }
    return (
      <audio
        autoPlay={this.props.autoPlay}
        className={`audio-player-${this.props.index}`}
        controls={controls}
        crossOrigin={this.props.crossOrigin}
        id={this.props.id}
        loop={this.props.loop}
        muted={this.props.muted}
        onPlay={this.onPlay}
        preload={this.props.preload}
        ref={(ref) => {
          this.audioEl = ref;
        }}
        src={this.props.src}
        style={this.props.style}
        title={title}
        {...conditionalProps}
      >
        {incompatibilityMessage}
      </audio>
    );
  }
}

AudioPlayer.defaultProps = {
  index: null,
  autoPlay: false,
  children: null,
  className: '',
  controls: false,
  controlsList: '',
  crossOrigin: null,
  id: '',
  listenInterval: 500,
  loop: false,
  muted: false,
  onAbort: () => {},
  onCanPlay: () => {},
  onCanPlayThrough: () => {},
  onEnded: () => {},
  onError: () => {},
  onListen: () => {},
  onPause: () => {},
  onPlay: () => {},
  onSeeked: () => {},
  onVolumeChanged: () => {},
  onLoadedMetadata: () => {},
  preload: 'auto',
  src: null,
  style: {},
  title: '',
  volume: 1.0,
  play: false,
};

AudioPlayer.propTypes = {
  index: PropTypes.number,
  autoPlay: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.string,
  controls: PropTypes.bool,
  controlsList: PropTypes.string,
  crossOrigin: PropTypes.string,
  id: PropTypes.string,
  listenInterval: PropTypes.number,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  onAbort: PropTypes.func,
  onCanPlay: PropTypes.func,
  onCanPlayThrough: PropTypes.func,
  onEnded: PropTypes.func,
  onError: PropTypes.func,
  onListen: PropTypes.func,
  onLoadedMetadata: PropTypes.func,
  onPause: PropTypes.func,
  onSeeked: PropTypes.func,
  onVolumeChanged: PropTypes.func,
  preload: PropTypes.oneOf(['auto']),
  src: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  volume: PropTypes.number,
  play: PropTypes.bool,
};

export default AudioPlayer;
