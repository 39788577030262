import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import Translate from '../../hocs/translate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadCoursesList,
  toggleCourseAttendance,
  toggleCertificate
} from '../../actions/courses_list';
import { switchCourse } from '../../actions/course_details';
import { toggleCourseDetails } from '../../actions/main_course';
import { loadContext } from '../../actions/main_course';
import _ from 'lodash';

export class RecursiveOptionsList extends Component {
  constructor() {
    super();

    this.state = {
      currentOptions: null,
      selectedTopic: null
    };
  }

  renderChildren(children, id) {
    if (this.props.pathLink) {
      let queryParams = '';
      let i = 0;
      _.forEach(this.props.queryParams, function (value, key) {
        queryParams += key.includes('topicCode') ? `${key + (i ? i : '')}=${value}` : '';
        i++;
      });
      queryParams += `&topicCode${i ? i : ''}=${id}`;
      hashHistory.push({
        pathname: this.props.pathLink,
        search: `?${queryParams}`
      });
      return;
    }
    if (children.courses && children.courses.length) {
      this.setState({
        currentOptions: children
      });
    } else {
      this.setState({
        currentOptions: null,
        showCourses: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      Array.isArray(prevProps.options) &&
      prevProps.options.length &&
      prevProps.options[0]._id !== this.props.options[0]._id
    ) {
      this.setState((prevState, props) => ({
        currentOptions: this.props.options
      }));
    }
  }

  componentDidMount() {
    this.setState((prevState, props) => ({
      currentOptions: this.props.options
    }));
  }

  render() {
    let options = this.state.currentOptions;

    if (options && !Array.isArray(options)) {
      options = [options];
    }

    return (
      <div className="course-list-page">
        {options &&
          options.map((option) => {
            return (
              <button
                key={option._id}
                className="options-list-topic"
                onClick={(e) => this.renderChildren(option, option._id)}>
                {option.displayTitle[this.props.selectedLanguage]}
              </button>
            );
          })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    coursesList: state.coursesList,
    userId: state.mainApp.userId,
    selectedLanguage: state.i18n.language
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCoursesList,
      switchCourse,
      toggleCourseAttendance,
      toggleCertificate,
      toggleCourseDetails,
      loadContext
    },
    dispatch
  );
}

RecursiveOptionsList.defaultProps = {
  strings: {
    'Learning Programs': 'Learning Programs'
  }
};

export default Translate('RecursiveOptionsList')(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(RecursiveOptionsList)
);
