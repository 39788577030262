import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './modal-main.scss';

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.style = this.props.style;
  }

  render() {
    return (
      <div
        className={`modal ${this.props.className ? this.props.className : ''}`}
        onClick={this.props.outerClick}>
        <div className="modal__container">{this.props.children}</div>
      </div>
    );
  }
}

Modal.propTypes = {
  outerClick: PropTypes.func,
  width: PropTypes.string,
  className: PropTypes.string
};

Modal.defaultProps = {
  style: {},
  className: ''
};
