// -- Dependencies
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showFloatBubble } from '../../../actions/float-bubble';
import { ReactMic } from '../../react_mic';

// -- Components
import Translate from '../../../hocs/translate';
import ReactTelInput from 'react-telephone-input';
import IconPlus from '../../assets/icon_plus';
import './user-input.scss';

export class UserInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseValue: '',
      recording: false,
      loading: false,
      error: false
    };
    this.responseHandler = this.responseHandler.bind(this);
    this.submitUserAnswer = this.submitUserAnswer.bind(this);
    this.submitOnPress = this.submitOnPress.bind(this);
    this.renderNormalInput = this.renderNormalInput.bind(this);
    this.renderPhoneInput = this.renderPhoneInput.bind(this);
    this.renderMultipleChoice = this.renderMultipleChoice.bind(this);
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.renderAudioInput = this.renderAudioInput.bind(this);
    this.onData = this.onData.bind(this);
    this.onStart = this.onData.bind(this);
    this.onStop = this.onStop.bind(this);
    this.onError = this.onError.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ loading: false });
  }

  responseHandler(e) {
    const newPhoneValue = e.target ? e.target.value : e;
    this.setState({ responseValue: newPhoneValue });
  }

  submitUserAnswer() {
    this.props.submitUserAnswer(this.state.responseValue, this.props.inputType);
    this.setState({ responseValue: '' });
  }

  startRecording() {
    this.props.startRecording();
    this.setState({ recording: true, loading: false });
  }

  stopRecording() {
    this.setState({ recording: false });
    this.setState({ recording: false, loading: true });
  }

  submitOnPress(event) {
    let key = event.keyCode || event.charCode;
    if (key === 13 && this.state.responseValue !== '') {
      this.submitUserAnswer();
    }
  }

  renderMultipleChoice() {
    return (
      <div className="disable-input-box">{this.props.strings['Select an option from above']}</div>
    );
  }

  renderNormalInput(textFieldType = 'text') {
    return (
      <input
        style={{ direction: this.props.useRTL ? 'rtl' : 'unset' }}
        aria-labelledby="input-main"
        className="input-main"
        id="input-main"
        value={this.state.responseValue}
        onChange={this.responseHandler}
        onKeyPress={this.submitOnPress}
        placeholder={this.props.strings['enter your response now']}
        aria-placeholder={this.props.strings['enter your response now']}
        autoFocus={!this.props.userEnvIsMobile}
        type={textFieldType}
        autoComplete="off"
      />
    );
  }

  renderPhoneInput() {
    return (
      <ReactTelInput
        initialValue=""
        defaultCountry="us"
        onChange={this.responseHandler}
        value={this.state.responseValue}
        flagsImagePath="images/flags.png"
        className="form-input"
        style={{ width: '100%' }}
        autoFormat
        preferredCountries={['us']}
        onEnterKeyPress={this.submitOnPress}
        aria-label="Enter your phone number"
        placeholder={this.props.strings['Enter your phone number']}
      />
    );
  }

  renderAudioInput(textFieldType = 'text') {
    return (
      <ReactMic
        record={this.state.recording}
        loading={this.state.loading}
        className="label-box"
        onStop={this.onStop}
        onData={this.onData}
        onError={this.onError}
        error={this.state.error}
        strokeColor="#000000"
        backgroundColor="#FFFFFF"
        strings={this.props.strings}
      />
    );
  }

  returnInputType(inputType) {
    let inputTypes = {
      password: this.renderNormalInput('password'),
      'phone-number': this.renderPhoneInput(),
      'multiple-choice': this.renderMultipleChoice(),
      audio: this.renderAudioInput()
    };
    return inputTypes[inputType] || this.renderNormalInput();
  }

  onData(recordedBlob) { }

  onStart() {
    this.setState({ recording: true, loading: false });
  }

  onStop(recordedBlob) {
    this.setState({ recording: false, loading: true });
    var payload = {
      audio: true,
      name: recordedBlob.stopTime - recordedBlob.startTime + '.mp3',
      recordedBlob: recordedBlob
    };
    this.props.stopRecording(payload, 'user', this.props.inputType);
  }

  onError() {
    this.setState({ recording: false, loading: false, error: true });
    window.location.reload();
  }

  render() {
    return (
      <div className="input-holder">
        {this.props.questionnairesOnApp && this.props.questionnairesOnApp.length > 0 && (
          <button
            className="input-pop-up"
            onClick={() => this.props.showFloatBubble(!this.props.isFloatBubbleOpen)}>
            <IconPlus minus={this.props.isFloatBubbleOpen} />
          </button>
        )}
        <div className={`input-container ${!this.props.enable ? 'input-container--disabled' : ''}`}>
          {this.props.enable ? (
            this.returnInputType(this.props.inputType)
          ) : (
            <div className="disable-input-box">
              {this.props.inputType === 'audio'
                ? this.props.strings['Wait to record']
                : this.props.strings['Wait to type your response']}
            </div>
          )}

          {this.props.inputType === 'audio' ? (
            this.state.recording ? (
              <button
                className="input-button"
                aria-label="Stop Button"
                onClick={this.stopRecording}>
                {this.props.strings.STOP}
              </button>
            ) : this.state.loading ? (
              <button className="input-button" aria-label="Loading Button">
                <spam>
                  <i className="material-icons button-icon">update</i>
                </spam>
              </button>
            ) : (
              <button
                className="input-button"
                aria-label="Record Button"
                onClick={this.props.enable ? this.startRecording : null}>
                {this.props.strings.RECORD}
              </button>
            )
          ) : (
            <button
              className={`input-button ${this.state.responseValue === '' ? 'input-button--disabled' : ''
                }`}
              aria-disabled={this.state.responseValue === ''}
              disabled={this.state.responseValue === ''}
              aria-label="Send"
              onClick={
                this.props.enable && this.state.responseValue !== '' ? this.submitUserAnswer : null
              }>
              {this.props.strings.SEND}
            </button>
          )}
        </div>
      </div>
    );
  }
}

UserInput.defaultProps = {
  strings: {
    SEND: 'SEND',
    RECORD: 'RECORD',
    STOP: 'STOP',
    'Wait to type your response': 'Wait to type your response',
    'enter your response now': 'enter your response now',
    'Select an option from above': 'Select an option from above',
    'Enter your phone number': 'Enter your phone number',
    'Wait to record': 'Wait to record',
    'Click RECORD to start': 'Click RECORD to start',
    'Click STOP to finish': 'Click STOP to finish',
    'Loading...': 'Loading...'
  }
};

UserInput.propTypes = {
  enable: PropTypes.bool.isRequired,
  inputType: PropTypes.string,
  submitUserAnswer: PropTypes.func.isRequired,
  startRecording: PropTypes.func.isRequired,
  stopRecording: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    userEnvIsMobile: state.mainApp.infoDevice.userEnvIsMobile,
    isFloatBubbleOpen: state.chat.isFloatBubbleOpen,
    questionnairesOnApp: state.chat.questionnairesOnApp,
    useRTL: state.preferences.preferencesData.useRTL
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ showFloatBubble }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Translate('UserInput')(UserInput));
