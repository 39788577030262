import React from 'react';
import PropTypes from 'prop-types';

const IconHome = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true">
      <path
        d="M7.04132 15.0393V10.236H10.9543V15.0393C10.9543 15.5677 11.3945 16 11.9326 16H14.8673C15.4054 16 15.8456 15.5677 15.8456 15.0393V8.31462H17.5086C17.9586 8.31462 18.1738 7.76704 17.8315 7.47884L9.65326 0.244971C9.28152 -0.0816572 8.71413 -0.0816572 8.3424 0.244971L0.164202 7.47884C-0.168404 7.76704 0.0370287 8.31462 0.487025 8.31462H2.15006V15.0393C2.15006 15.5677 2.59027 16 3.12831 16H6.06307C6.60111 16 7.04132 15.5677 7.04132 15.0393Z"
        fill="white"
      />
    </svg>
  );
};

IconHome.propTypes = { className: PropTypes.string };

IconHome.defaultProps = {
  className: ''
};

export default IconHome;
