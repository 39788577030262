import React, { Component } from 'react';
import CoursesElements from '../../components/courses_list/list_elements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  loadCoursesList,
  toggleCourseAttendance,
  toggleCertificate
} from '../../actions/courses_list';
import { switchCourse } from '../../actions/course_details';
import Translate from '../../hocs/translate';
import { hashHistory } from 'react-router';
import { loadContext } from '../../actions/main_course';
import { toggleCourseDetails } from '../../actions/main_course';
import BackButton from '../../components/buttons/back_button';
import _ from 'lodash';
import helpBg from '../../../images/help-bg.png';

export class CoursesList extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      selectedTopic: null,
      selectedSubTopic: null,
      nextAction: '',
      showCourses: false
    };
  }

  componentDidMount() {
    let selectedTopicCourse = _.find(this.props.coursesList.courses.groups, {
      id: this.props.location.query.topicCode
    });
    if (_.isNil(selectedTopicCourse)) {
      hashHistory.push('/me');
    } else {
      let selectedSubTopicCourse = this.props.coursesList.courses;
      _.forEach(this.props.location.query, function (value, key) {
        if (key.includes('topicCode')) {
          selectedSubTopicCourse = _.find(selectedSubTopicCourse.groups, { id: value });
        }
      });
      this.setState({
        selectedTopic: selectedTopicCourse,
        selectedSubTopic: selectedSubTopicCourse
      });
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.props.coursesList.courseSwitched) {
      hashHistory.push('/active_course');
    }
  }

  goBack() {
    hashHistory.push(`/subtopics?topicCode=${this.props.location.query.topicCode}`);
  }

  render() {
    return (
      <div className="course-groups-subtopics-container">
        <div className="course-groups-subtopics-content">
          <BackButton onClick={this.goBack} />
          <h1 className="subtopics-page-title">
            {this.state.selectedTopic ? this.state.selectedTopic.name : ''}{' '}
            {this.state.selectedSubTopic ? this.state.selectedSubTopic.name : ''}
          </h1>
          <CoursesElements
            toggleCourseAttendance={this.props.toggleCourseAttendance}
            toggleCertificate={this.props.toggleCertificate}
            toggleCourseDetails={this.props.toggleCourseDetails}
            courses={this.state.selectedSubTopic ? this.state.selectedSubTopic.courses : []}
            switchCourse={this.props.switchCourse}
            useRTL={this.props.useRTL}
          />
        </div>
        <div className="course-groups-subtopics-container__footer">
          <img
            className="course-groups-subtopics-container__footer-image"
            src={helpBg}
            role="presentation"
            alt=""
          />
        </div>
      </div>
    );
  }
}

CoursesList.defaultProps = {
  selectedTopic: null,
  strings: {
    'Switch Course Continue': 'Are you sure you want to continue with:',
    'Switch Course Start': 'Are you sure you want to start:',
    'Switch Course Redo': 'Are you sure you want to redo:',
    YES: 'YES'
  }
};

function mapStateToProps(state) {
  return { coursesList: state.coursesList, userId: state.mainApp.userId, useRTL: state.preferences.preferencesData.useRTL };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCoursesList,
      switchCourse,
      toggleCourseAttendance,
      toggleCertificate,
      toggleCourseDetails,
      loadContext
    },
    dispatch
  );
}

export default Translate('CoursesList')(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(CoursesList)
);
