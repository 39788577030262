import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hashHistory } from 'react-router';
import Modal from '../../components/modal/modal_main';
import { logout } from '../../actions/login';
import { dismissTimeout, timeoutLogout } from '../../actions/timeout';
import config from '../../config.json';
import Translate from '../../hocs/translate';
import './time-out-modal-main.scss';

class TimeOutModal extends Component {
  constructor() {
    super();

    this.proceedWithLogout = this.proceedWithLogout.bind(this);
    this.dismissTimeout = this.dismissTimeout.bind(this);
    this.updateCounter = this.updateCounter.bind(this);
    this.showCounter = this.showCounter.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.timeout.sessionTimedOut && nextProps.timeout.sessionTimedOut) {
      const timeToLogout = config.sessionManagement.sessionExpirationTime / 1000;
      this.setState({ counter: timeToLogout });
      this.timer = setInterval(this.updateCounter, 1000);
    }

    if (this.props.timeout.sessionTimedOut && !nextProps.timeout.sessionTimedOut) {
      clearInterval(this.timer);
    }

    if (nextProps.timeout.sessionExpired) {
      window.localStorage.removeItem('celledCredentials');
    }
  }

  // TODO build generic counter component
  updateCounter() {
    var newCounterValue = this.state.counter - 1;
    this.setState({ counter: newCounterValue });
  }

  showCounter(secondsLeft) {
    var minutes = Math.floor(secondsLeft / 60);
    var seconds = Math.floor(secondsLeft % 60);

    function addZero(numb) {
      if (numb < 10) {
        return `0${numb}`;
      }
      return numb;
    }
    return `${addZero(minutes)}:${addZero(seconds)}`;
  }

  dismissTimeout() {
    this.props.dismissTimeout();
  }

  proceedWithLogout() {
    this.props.logout();
    hashHistory.push('/');
  }

  render() {
    if (this.props.timeout.sessionExpired) {
      return (
        <Modal>
          <div className="timeout-modal">
            <h3 className="timeout-title">{this.props.strings['Your session has expired']}</h3>
            <button className="timeout-subtitle" onClick={this.proceedWithLogout}>
              {this.props.strings['Please login again']}
            </button>
          </div>
        </Modal>
      );
    } else if (this.props.timeout.sessionTimedOut) {
      return (
        <Modal outerClick={this.dismissTimeout.bind(this)}>
          <div className="timeout-modal">
            <h3 className="timeout-title">{this.props.strings['ARE YOU STILL HERE?']}</h3>
            <button className="timeout-subtitle" onClick={this.dismissTimeout}>
              {this.props.strings['Yes!']}
            </button>
            <p className="timeout-text">{this.props.strings['Log off in:']}</p>
            <p className="timeout-text">{this.showCounter(this.state.counter)}</p>
          </div>
        </Modal>
      );
    }

    return null;
  }
}

TimeOutModal.defaultProps = {
  strings: {
    'ARE YOU STILL HERE?': 'ARE YOU STILL HERE?',
    'Yes!': 'Yes!',
    'Log off in:': 'Log off in:',
    'Your session has expired': 'Your session has expired',
    'Please login again': 'Please login again'
  }
};

function mapStateToProps(state) {
  return { timeout: state.timeout, mainCourse: state.mainCourse };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout, dismissTimeout, timeoutLogout }, dispatch);
}

export default Translate('TimeOutModal')(
  connect(mapStateToProps, mapDispatchToProps)(TimeOutModal)
);
