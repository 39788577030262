import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SpinnerCelled from '../../components/spinner_celled/SpinnerCelled'
import Translate from '../../hocs/translate';
import { fetchCertificate, showCertificate } from '../../actions/chat'

class ReactCertificate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			src: props.src,
			errored: false,
			pendingCertificate: true
		};

		this.refresh = this.refresh.bind(this);
	}

	componentDidMount() {
		this.props.fetchCertificate(this.props.courseAttendanceId)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.error) return this.setState({
			pendingCertificate: false,
			errored: true
		})
		if (this.props.src !== nextProps.src) return this.setState({
			src: nextProps.src,
			pendingCertificate: false,
			errored: false
		})
	}

	refresh() {
		this.setState({
			pendingCertificate: true,
			errored: false,
		})
		this.props.fetchCertificate(this.props.courseAttendanceId)
	}

	render() {
		return (
			this.state.errored
				? <div onClick={this.refresh} className="react-img-button react-img-button-icon">
					<i className="material-icons">refresh</i>
					<br />
					<span>
						{this.props.strings['error']}
					</span>
				</div>
				: <div>
					<div className="chat-text">{this.state.pendingCertificate ? this.props.strings['loading'] : this.props.strings['successText']}
					</div>
					{this.state.pendingCertificate
						? <div>
							<SpinnerCelled
								maxWidth='200px'
							/>
						</div>
						: <img
							style={{ maxWidth: '100%' }}
							onClick={() => this.props.showCertificate(this.props.courseCode)}
							className='chat-image'
							src={URL.createObjectURL(this.state.src)}
							alt="courseAttendance certificate"
						/>
					}
				</div>
		)
	}
}

ReactCertificate.propTypes = {
	src: PropTypes.object,
	fallbackSrc: PropTypes.object,
	courseAttendanceId: PropTypes.string,
	error: PropTypes.bool,
	fetchCertificate: PropTypes.func
};

ReactCertificate.defaultProps = {
	strings: {
		'error': 'Could not create the certificate. Tap to try again.',
		'loading': 'We are creating your certificate!',
		'successText': 'Congratulations'
	},
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchCertificate, showCertificate }, dispatch);
}

export default connect(null, mapDispatchToProps)(Translate('ReactCertificate')(ReactCertificate)) 