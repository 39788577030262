import React from 'react';
import PropTypes from 'prop-types';

const IconPause = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={fill} />
      <rect x="14" y="13" width="5" height="19" fill="white" />
      <rect x="25" y="13" width="5" height="19" fill="white" />
    </svg>
  );
};

IconPause.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
};

IconPause.defaultProps = {
  fill: '#EE433C',
  className: ''
};

export default IconPause;
