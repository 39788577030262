import React, { Component } from 'react';
import Translate from '../../hocs/translate';
import './contact-us.scss';

class ContactUs extends Component {
  constructor() {
    super();

    this.state = { email: '', message: '', showEmailForm: true };
    this.messageHandler = this.messageHandler.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  messageHandler(e) {
    this.setState({ message: e.target.value });
  }

  componentDidMount() {
    this.setState({ showEmailForm: true });
  }

  sendEmail(e) {
    e.preventDefault();
    const { message } = this.state;
    this.setState({ showEmailForm: false });
    this.props.sendEmail(this.props.userId, message);
  }

  render() {
    if (this.props.emailSuccess && !this.state.showEmailForm) {
      return (
        <div>
          <h3 className="help-page-form__subtitle">
            {this.props.strings['Thank you. A coach will help you as soon as possible']}
          </h3>
        </div>
      );
    }
    return (
      <form className="help-page-form" onSubmit={this.sendEmail}>
        <p>
          {
            this.props.strings[
            'Type your message in the box below. Your coach will respond in the next 24 hours.'
            ]
          }
        </p>

        <label htmlFor="message" className="visually-hidden">
          Enter your message:{' '}
        </label>
        <textarea
          style={{ direction: this.props.useRTL ? 'rtl' : 'unset' }}
          className="help-page-form__text-area"
          required
          placeholder={this.props.strings['Enter your message']}
          value={this.state.message}
          onChange={this.messageHandler}
        />
        <button
          className="help-page-form__button"
          type="submit"
          aria-label={this.props.strings['Send Message']}>
          {this.props.strings['Send Message']}
        </button>
      </form>
    );
  }
}

ContactUs.defaultProps = {
  strings: {
    'Thank you. A coach will help you as soon as possible':
      'Thank you. A coach will help you as soon as possible',
    'SEND US A MESSAGE': 'SEND US A MESSAGE',
    'Enter your message': 'Enter your message',
    'Send Message': 'Send Message',
    'Type your message in the box below. Your coach will respond in the next 24 hours.':
      'Type your message in the box below. Your coach will respond in the next 24 hours.'
  }
};

export default Translate('ContactUs')(ContactUs);
