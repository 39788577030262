import {
  TOGGLE_COURSES,
  SHOW_CERTIFICATE,
  TOGGLE_RESOURCES,
  TOGGLE_PROFILE,
  TOGGLE_ACHIEVEMENTS,
  TIMEOUT_DISMISSED,
  DISPLAY_WIZENOZE,
  CLOSE_WIZENOZE,
} from '../constants';

const initialSate = {
  showCourses: false,
  showResources: false,
  showProfile: false,
  showAchievements: false,
  showPersonalInformation: false,
  showLoginInformation: false,
  showLiveCoach: false,
  restart: false,
  displayWizenoze: false,
  plugins: {},
  url: null,
};

export default function (state = initialSate, action) {
  switch (action.type) {
    case TOGGLE_COURSES:
      return { ...state, showCourses: !state.showCourses };
    case TOGGLE_RESOURCES:
      return { ...state, showResources: !state.showResources };
    case TOGGLE_PROFILE:
      return { ...state, showProfile: !state.showProfile };
    case TOGGLE_ACHIEVEMENTS:
      return { ...state, showAchievements: !state.showAchievements };
    case SHOW_CERTIFICATE:
      return { ...state, showCourses: true };
    case TIMEOUT_DISMISSED:
      return { ...state, restart: true };
    case DISPLAY_WIZENOZE:
      return {
        ...state,
        plugins: { wizenoze: { searchResult: [] } },
        displayWizenoze: true,
        url: action.payload,
      };
    case CLOSE_WIZENOZE:
      return {
        ...state,
        plugins: { wizenoze: { searchResult: [] } },
        displayWizenoze: false,
        url: null,
      };
    default:
      return state;
  }
}
