import {
  TOGGLE_LIVE_COACH,
  TOGGLE_CONTACT_US,
  TOGGLE_FAQ,
  SEND_EMAIL,
  LOAD_HELP_DATA,
  GET_COACH_INFO
} from '../constants';

export function toggleLiveCoach() {
  return {
    type: TOGGLE_LIVE_COACH
  };
}

export function loadHelpData(userId) {
  return {
    type: LOAD_HELP_DATA,
    payload: userId
  };
}

export function getCoachInfo(userId) {
  return {
    type: GET_COACH_INFO,
    payload: userId
  };
}

export function toggleContactUs(openOrClose) {
  return {
    type: TOGGLE_CONTACT_US,
    payload: openOrClose
  };
}

export function toggleFaq() {
  return {
    type: TOGGLE_FAQ
  };
}

export function sendEmail(userId, message) {
  return {
    type: SEND_EMAIL,
    payload: { userId, message }
  };
}
