import { PLUGIN_WIZENOZE_SEARCH, PLUGIN_WIZENOZE_SEARCH_SUCCESS, PLUGIN_WIZENOZE_SEARCH_FAILED } from './actions';
import { CLOSE_WIZENOZE } from '../../constants';

const initialState = {
	failed: false,
	success: false,
	waitingResponse: false,
	searchResult: null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case PLUGIN_WIZENOZE_SEARCH:
			return { ...state, failed: false, waitingResponse: true };
		case PLUGIN_WIZENOZE_SEARCH_FAILED:
			return { ...state, failed: true, waitingResponse: false, searchResult: null	};
		case PLUGIN_WIZENOZE_SEARCH_SUCCESS:
			return { ...state, failed: false, success: true, waitingResponse: false, searchResult: action.payload.searchResult };
		case CLOSE_WIZENOZE: 
			return { ...state, failed: false, success: true, waitingResponse: false, searchResult: {} }
		default:
			return state;
	}
}
