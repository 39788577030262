import {
	PLAY_AUDIO,
	PLAY_PAUSE_AUDIO,
	AUDIO_FINISHED,
	UPDATE_AUDIO_PROGRESS,
	UPDATE_AUDIO_METADATA,
	PAUSE_AUDIO_CONTROL,
	PLAY_AUDIO_CONTROL,
	PLAY_ACTION_ERROR,
	BUFFERING,
	REMOVE_AUDIO_MODAL,
	SUBMIT_ANSWER,
} from '../constants';

const initialState = {
	url: [],
	audios: [], // contenedor de audios 
	autoPlay: true, // indica si el primer audio se reproduce automaticamente 
	isPlaying: false, // indica si algun audio esta en reproduccion 
	finished: false, // indica si todos los audios retminaron la reproduccion 
	canPlay: false, // cuando todos los audios estan en este estado el global esta true, caso parcial es false
	currentTime: 0, // tiempo de reproduccion de la sumatoria de audios 
	currentIndex: 0, // indice del current audio 
	duration: 0, // duracion total de la suma de la duracion de todos los audios
	quickInputEnable: false,
	buffering: false,
	playModal: true,
	scrollable: true,
	stopForDetail: false
};

export default function (state = initialState, action) {
	switch (action.type) {
		case PLAY_AUDIO: {
			return { ...state, ...action.payload, audios: buildMetadata(action.payload), isPlaying: false, duration: 0, currentTime: 0, finished: false, canPlay: false };
		}
		case PLAY_ACTION_ERROR: {
			return { ...state, ...action.payload, isPlaying: true, duration: 0, currentTime: 0 };
		}
		case AUDIO_FINISHED: {
			return { ...state, isPlaying: false, finished: true };
		}
		case PLAY_PAUSE_AUDIO: {
			return { ...state, isPlaying: !state.isPlaying };
		}
		case PLAY_AUDIO_CONTROL: {
			return { ...state, isPlaying: true };
		}
		case PAUSE_AUDIO_CONTROL: {
			return { ...state, isPlaying: false };
		}
		case UPDATE_AUDIO_PROGRESS: {
			return { ...state, ...action.payload };
		}
		case UPDATE_AUDIO_METADATA: {
			return { ...state, ...action.payload };
		}
		case BUFFERING: {
			return { ...state, buffering: action.payload };
		}
		case REMOVE_AUDIO_MODAL: {
			return { ...state, playModal: false };
		}
		case SUBMIT_ANSWER:
			return { ...initialState, playModal: false, isPlaying: false, duration: 0, currentTime: 0, finished: false, canPlay: false };
		default:
			return state

	}
}

function buildMetadata(payload) {
	let audios = [];
	if (payload && payload.url && Array.isArray(payload.url)) {
		payload.url.forEach(function callback(value, index) {
			audios.push({
				src: value, // url del audio 
				duration: 0, // duracion total del audio 
				currentTime: 0, // tiempo actual de reproduccion del audio 
				error: false, // indica si existe un error con el audio 
				canPlay: false,  // variable de control para verificar si se puede dar play 
				play: false, // variable de control para dar play
				isPlaying: false, // indica si el audio esta siendo reproducido 
				isPaused: false, // indica si el audio esta pausado
				isLoaded: false, // indica si la metadata esta cargada
				ended: false // indica que el audio termino de reproducirse 
			});
		});
	}
	return audios;
}
