import React, { Component } from 'react'
import { string, number, bool, func } from 'prop-types';
import { MicrophoneRecorder } from '../libs/MicrophoneRecorder';


export default class ReactMic extends Component {
	constructor(props) {
		super(props);
		this.state = {
			microphoneRecorder: null,
		}
	}

	componentDidMount() {
		const { onSave, onStop, onStart, onData, onError, audioElem, audioBitsPerSecond, mimeType } = this.props;
		const options = {
			audioBitsPerSecond: audioBitsPerSecond,
			mimeType: mimeType
		}
		if (!audioElem) {
			this.setState({
				microphoneRecorder: new MicrophoneRecorder(onStart, onStop, onSave, onData, onError, options),
			}, () => { });
		}
	}

	render() {
		const { loading, record, onStop } = this.props;
		const { microphoneRecorder } = this.state;
		if (record) {
			if (microphoneRecorder) {
				microphoneRecorder.startRecording();
			}
		} else {
			if (microphoneRecorder) {
				microphoneRecorder.stopRecording(onStop);
			}
		}
		return (<div className={this.props.className}>{record ? this.props.strings['Click STOP to finish'] : loading ? this.props.strings['Loading...'] : this.props.strings['Click RECORD to start']}</div>);
	}
}

ReactMic.propTypes = {
	backgroundColor: string,
	strokeColor: string,
	className: string,
	audioBitsPerSecond: number,
	mimeType: string,
	height: number,
	record: bool.isRequired,
	loading: bool.isRequired,
	onStart: func,
	onStop: func,
	onData: func,
	onError: func
};

ReactMic.defaultProps = {
	strings: {},
	backgroundColor: 'rgba(255, 255, 255, 0.5)',
	strokeColor: '#000000',
	className: 'label-box',
	audioBitsPerSecond: 128000,
	mimeType: 'audio/wav',
	record: false,
	loading: false,
	width: 640,
	height: 100,
	visualSetting: 'sinewave'
}
