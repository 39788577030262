import {
	TIMEOUT_ACTIVATE,
	TIMEOUT_TRIGGERED,
	TIMEOUT_LOGOUT,
	TIMEOUT_DISMISSED,
} from '../constants'

const initialState = {
	active: false,
	sessionTimedOut: false,
	sessionExpired: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case TIMEOUT_ACTIVATE:
			return { ...state, active: true };
		case TIMEOUT_TRIGGERED:
			return { ...state, sessionTimedOut: true };
		case TIMEOUT_DISMISSED:
			return { ...state, sessionTimedOut: false };
		case TIMEOUT_LOGOUT:
			return { ...state, sessionExpired: true };
		default:
			return state;
	}
}
