import React from 'react';
import Proptypes from 'prop-types';
import AchievementsCertsCarousel from './achievements_certs_carousel';
import Translate from '../../hocs/translate';
import IconCertificates from '../icons/icon_certificate';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const AchievementsList = ({ achievementsList, language, strings }) => {
  const AchievementsArray = (props) => {
    const { values } = props;
    if (values)
      return (
        <div className="achievements-list__array-content">
          <div className="achievements-list__value">{values.length}</div>
          <AchievementsCertsCarousel courseAttendancesIds={values.map((item) => item._id)} />
        </div>
      );

    return (
      <div className="achievements-list__array-content">
        <IconCertificates className="achievements-list__icon-certificate" />
        <div>{strings['There are no courses completed yet. Continue studying!']}</div>
      </div>
    );
  };
  return (
    <ul className="achievements-list">
      <ResponsiveMasonry>
        <Masonry columnsCount={3}>
          {achievementsList.map((listItem, index) => (
            <li
              className="achievements-list__list-item"
              key={`${listItem.type}_${language}_${index}`}>
              <h4 className="achievements-list__title">{listItem[language].title}</h4>
              {listItem.type !== 'ACHIEVEMENTS_COURSES_COMPLETED' ? (
                <div className="achievements-list__value">{listItem[language].value}</div>
              ) : (
                <AchievementsArray values={listItem[language].value} />
              )}
            </li>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </ul>
  );
};

AchievementsList.propTypes = {
  achievementsList: Proptypes.array.isRequired,
  language: Proptypes.string.isRequired
};

AchievementsList.defaultProps = {
  strings: {
    'There are no courses completed yet. Continue studying!':
      'There are no courses completed yet. Continue studying!'
  }
};

export default Translate('AchievementsList')(AchievementsList);
