import { CHANGE_LANGUAGE, LOGOUT } from '../constants';
import config from '../config.json';

const initialState = {
	language: 'en',
	supportedLanguages: config.supportedLanguages
};

export default function (state = initialState, action) {
	switch (action.type) {
		case CHANGE_LANGUAGE:
			return { ...state, language: action.payload };
		case LOGOUT:
			let savedLanguage = JSON.parse(window.localStorage.getItem('celledLanguage'))
			return { ...state, language: savedLanguage };
		default:
			return state;
	}
}
