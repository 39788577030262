import {
	SWITCH_LESSON_REQUEST,
	SWITCH_LESSON,
	SWITCH_COURSE_FAILED,
	SWITCH_COURSE_SUCCESS
} from '../constants';
import { put, take, call, select } from 'redux-saga/effects';
import apiClient from '../lib/celled_client';
import { checkCommonErrors } from '../lib/auth';
import { learnerFlowInfo } from './learner_flow_info'
import { toggleSwitchModal } from '../actions/chat'
import { toggleLessonConfirmationModal } from '../actions/course_details'
import { getUserId } from './selectors';

export function* switchLesson() {
	while (true) {
		yield take(SWITCH_LESSON_REQUEST)
		try {
			const flowInfo = yield call(learnerFlowInfo)
			if (flowInfo.canSwitch) {
				yield put(toggleLessonConfirmationModal())
			} else {
				yield put(toggleSwitchModal())
			}
		} catch (error) {
			// There was no time to improve the error handler here...
			var errorMessage = error.response.data.message;
			let errorPayload = {
				type: SWITCH_COURSE_FAILED,
				notification: {
					message: errorMessage,
					className: 'error',
					type: 'switchCourse',
					unique: true,
				},
			};
			yield put(checkCommonErrors(error, errorPayload));
		}
	}
}

export function* switchLessonFetch() {
	while (true) {
		const data = yield take(SWITCH_LESSON)
		const userId = yield select(getUserId);
		try {
			yield call(apiClient.switchCourse, userId, data.courseCode)
			yield put({
				type: SWITCH_COURSE_SUCCESS,
				notification: {
					message: 'You have successfully switched your course',
					type: 'switchCourse',
					unique: true,
				},
			})
		} catch (error) {
			var errorMessage = error.response.data.message;
			let errorPayload = {
				type: SWITCH_COURSE_FAILED,
				notification: {
					message: errorMessage,
					className: 'error',
					type: 'switchCourse',
					unique: true,
				},
			};
			yield put(checkCommonErrors(error, errorPayload));
		}
	}
}

export default function* rootSwitchLessonSaga() {
	yield [
		switchLesson(),
		switchLessonFetch()
	];
};
