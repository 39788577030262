import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  showCertificate,
  getQuestionnairesOnApp,
  toggleQuestionnairesModal
} from '../../../actions/chat';
import { switchQuestionnaire, switchQuestionnaireRequest } from '../../../actions/questionnaire';
import { showFloatBubble } from '../../../actions/float-bubble';
import {
  submitAnswer,
  openFullScreenFrame,
  closeFullScreenFrame
} from '../../../actions/main_course';
import ChatMessage from './chat_message';
import ChatHeaderMessage from './chat_header_message';
import LoadingDots from '../loading_dots';
import MultipleChoiceInput from '../input/multiple_choice_input';
import { VelocityTransitionGroup } from 'velocity-react';
import PinchZoomPan from './PinchZoomPan';
import ReactImg from '../../react_img/ReactImg';
import { Plugins, Capacitor } from '@capacitor/core';
import { addNavBackButton, removeNavBackButton } from '../../../containers/app';
import Translate from '../../../hocs/translate';
import Modal from '../../modal/modal_main';
import IconChevron from '../../assets/icon_chevron';
import { CSSTransition } from 'react-transition-group';
import './chat.scss';
const { Keyboard } = Plugins;

export class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageExpanded: false,
      imageUrl: '',
      expandedImageContainerWidth: 0,
      expandedImageContainerHeight: 0,
      questionnairesOnApp: [],
      selectedQuestionnaire: {}
    };
    this.loadPreviousHistory = this.loadPreviousHistory.bind(this);
    this.handleBackButtonWeb = this.handleBackButtonWeb.bind(this);
    this.handleBackButtonAndroid = this.handleBackButtonAndroid.bind(this);
    this.toggleImageExpansion = this.toggleImageExpansion.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.selectQuestionnaire = this.selectQuestionnaire.bind(this);
    this.questionnaireChosen = this.questionnaireChosen.bind(this);
    this.hidePopUp = this.hidePopUp.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.chatMessageContainer = React.createRef();
    this.handleChatImageLoaded = this.handleChatImageLoaded.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.scrollToBottom, false);

    const isOnMobile = Capacitor.isPluginAvailable('Keyboard');
    if (isOnMobile) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
      Keyboard.addListener('keyboardDidShow', (info) => {
        this.scrollToBottom();
      });
    }
    this.props.getQuestionnairesOnApp();

    this.scrollToBottom();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.chat.scrollTop && !prevProps.chat.scrollTop) {
      this.scrollToTop();
    }

    if (this.props.chat.scrollBottom && this.props.messages !== prevProps.messages) {
      this.scrollToBottom();
    }

    if (
      this.props.input.type !== prevProps.input.type &&
      this.props.input.type === 'multiple-choice'
    ) {
      // if we have changed input types, and are now requesting multiple choice,
      this.scrollToBottom(); // then scroll to bottom
    }
    if (this.props.chat.questionnairesOnApp !== prevProps.chat.questionnairesOnApp) {
      this.setState({ questionnairesOnApp: this.props.chat.questionnairesOnApp });
    }
  }

  loadPreviousHistory() {
    const url = this.props.chat.url;
    const pageNumber = this.props.chat.pageNumber;
    this.props.loadPreviousHistory(url, pageNumber);
  }

  scrollToTop() {
    if (this.chatMessages) {
      this.chatMessages.scrollTop = 0;
    }
  }

  scrollToBottom() {
    if (this.chatMessageContainer?.current?.lastChild) {
      this.chatMessageContainer.current.lastChild.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    } else if (this.chatMessageContainer?.current) {
      this.chatMessageContainer.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }

  handleChatImageLoaded() {
    if (this.props.chat.scrollBottom) this.scrollToBottom();
  }

  handleBackButtonWeb(handleImg) {
    if (!this.state.imageExpanded) {
      window.onpopstate = () => {
        handleImg();
        window.onpopstate = null;
      };
    } else {
      window.history.back();
      window.onpopstate = null;
    }
    handleImg();
  }

  handleBackButtonAndroid(handleImg) {
    const catchEvent = new Event('backbutton');
    const handleEvent = () => {
      handleImg();
      addNavBackButton();
    };
    if (!this.state.imageExpanded) {
      removeNavBackButton();
      document.addEventListener('backbutton', handleEvent, { once: true });
      handleImg();
    } else {
      document.dispatchEvent(catchEvent);
    }
  }

  toggleImageExpansion(imageUrl) {
    this.setState((prevState) => ({ imageExpanded: !prevState.imageExpanded, imageUrl }));
  }

  onImgLoad(img) {
    const targetWidth =
      img.target.naturalWidth > window.screen.width ? window.screen.width : img.target.naturalWidth;

    const targetHeight =
      img.target.naturalHeight > window.screen.height
        ? img.target.naturalHeight
        : window.screen.height;

    this.setState((prevState) => {
      return {
        expandedImageContainerWidth: targetWidth,
        expandedImageContainerHeight: targetHeight
      };
    });
  }

  renderMessages() {
    return this.props.messages.map((message, index) => {
      // a message is considered "active" when it is the message that is currently being answered.
      const isActive = index + 1 === this.props.messages.length;

      if (message.header) {
        return <ChatHeaderMessage {...message} key={index} />;
      } else {
        return (
          <ChatMessage
            {...message}
            key={index}
            toggleImageExpansion={this.toggleImageExpansion}
            mapActions={this.mapActions}
            isActive={isActive}
            submitAnswer={this.props.submitAnswer}
            openFullScreenFrame={this.props.openFullScreenFrame}
            closeFullScreenFrame={this.props.closeFullScreenFrame}
            handleChatImageLoaded={this.handleChatImageLoaded}
          />
        );
      }
    });
  }

  selectQuestionnaire(questionnaire) {
    this.setState({ selectedQuestionnaire: questionnaire });
    this.props.switchQuestionnaireRequest();
  }

  questionnaireChosen(questionnaireId) {
    this.props.switchQuestionnaire(questionnaireId);
    this.props.toggleQuestionnairesModal();
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.props.showFloatBubble(!this.props.isFloatBubbleOpen);
    }
  }

  hidePopUp(e) {
    if (e.target.getAttribute('name') === 'chatMain' && this.props.chat.isFloatBubbleOpen)
      return this.props.showFloatBubble(false);
  }

  render() {
    return (
      <div
        onClick={(e) => this.hidePopUp(e)}
        name="chatMain"
        className={`chat chat-background ${this.props.useRTL ? 'chat-rtl' : ''}`}
        ref={(chatMessages) => {
          this.chatMessages = chatMessages;
        }}>
        {this.props.showPaginator && (
          <div className="chat-center-container">
            <button className="chat__prev-messages-button" onClick={this.loadPreviousHistory}>
              <IconChevron direction="up" />
              {this.props.previousMessagesText}
              <IconChevron direction="up" />
            </button>
          </div>
        )}
        <div className="chat__message-container" ref={this.chatMessageContainer}>
          {this.renderMessages()}

          {/* render multiple choice options */}
          {this.props.input.enable && this.props.input.type === 'multiple-choice' && (
            <MultipleChoiceInput
              submitAnswer={this.props.submitUserAnswer}
              options={this.props.input.options}
              scrollToBottom={this.scrollToBottom}
              audioUrl={this.props.audioUrl || []}
            />
          )}
        </div>

        {this.props.isLoading && <LoadingDots />}
        {/* render multimedia image expanded TODO: replace with react-transition-group and image expanded vh bug */}
        <VelocityTransitionGroup
          component="div"
          enter="transition.expandIn"
          leave="transition.expandOut">
          {this.state.imageExpanded && (
            <div onClick={this.toggleImageExpansion} className="chat-image-container">
              <PinchZoomPan
                width={this.state.expandedImageContainerWidth}
                height={this.state.expandedImageContainerHeight}>
                {(x, y, scale) => (
                  <ReactImg
                    src={this.state.imageUrl}
                    onLoad={this.onImgLoad}
                    style={{
                      pointerEvents: scale === 1 ? 'auto' : 'none',
                      transform: `translate3d(${x}px, ${y}px, 0) scale(${scale})`,
                      transformOrigin: '0 0',
                      width: 'auto',
                      height: 'auto',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      alignSelf: 'center'
                    }}
                  />
                )}
              </PinchZoomPan>
            </div>
          )}
        </VelocityTransitionGroup>

        <CSSTransition
          in={this.props.chat.isFloatBubbleOpen}
          appear
          mountOnEnter
          unmountOnExit
          classNames="slide-up"
          timeout={200}>
          <div className="chat-main-float-container">
            <div className="chat-main-float-bubble">
              {this.state.questionnairesOnApp.map((questionnaire, key) => (
                <button
                  aria-label={questionnaire.name}
                  key={key}
                  className="multiple-choice-option chat-main-float-element"
                  onClick={() => this.selectQuestionnaire(questionnaire)}
                  onKeyPress={() => this.selectQuestionnaire(questionnaire)}>
                  <div className="chat-main-float-element-text">{questionnaire.name}</div>
                </button>
              ))}
            </div>
          </div>
        </CSSTransition>
        {this.props.chat.showQuestionnaireModal && (
          <Modal className="chat-main-modal">
            <h4 className="chat-main-modal__headline">
              {this.props.strings['questionnaireConfirmation']}
            </h4>
            <p className="chat-main-modal__subhead">{this.state.selectedQuestionnaire.name}</p>

            <div className="chat-main-modal__buttons">
              <button
                alt="No"
                className="button cl-box-button-close"
                onClick={() => this.props.toggleQuestionnairesModal()}>
                No
              </button>
              <button
                alt={this.props.strings['Yes']}
                className="button cl-box-button-confirmation"
                onClick={() => this.questionnaireChosen(this.state.selectedQuestionnaire._id)}>
                {this.props.strings['Yes']}
              </button>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

Chat.defaultProps = {
  strings: {
    questionnaireConfirmation: 'Are you sure you want to select:',
    Yes: 'Yes'
  }
};

Chat.propTypes = {
  messages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  previousMessagesText: PropTypes.string.isRequired,
  loadPreviousHistory: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    chat: state.chat,
    courses: state.coursesList.courses,
    os: state.mainApp.infoDevice.os,
    useRTL: state.preferences.preferencesData.useRTL
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showCertificate,
      getQuestionnairesOnApp,
      submitAnswer,
      openFullScreenFrame,
      closeFullScreenFrame,
      showFloatBubble,
      switchQuestionnaire,
      switchQuestionnaireRequest,
      toggleQuestionnairesModal
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Translate('Chat')(Chat));
