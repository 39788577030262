import {
  LOAD_USER_PREFERENCES_SUCCESS,
  LOAD_USER_PREFERENCES_FAILED,
  LOAD_APP_LINK_PREFERENCES_SUCCESS,
} from '../constants';

const initialState = {
  loadingPreferences: true,
  preferencesData: {
    isValidPhoneNumber: false,
    useRTL: localStorage.getItem('webappDirection') === "rtl"
  },
  failed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_PREFERENCES_SUCCESS:
      if (action.payload.useRTL) {
        localStorage.setItem('webappDirection', 'rtl')
      } else {
        localStorage.removeItem('webappDirection');
      }
      return {
        ...state,
        preferencesData: { ...state.preferencesData, ...action.payload },
        loadingPreferences: false
      };
    case LOAD_USER_PREFERENCES_FAILED:
      return { ...state, failed: true, loadingPreferences: false };
    case LOAD_APP_LINK_PREFERENCES_SUCCESS:
      return { ...state, appLinkData: { ...action.payload } };
    default:
      return state;
  }
}
