import React from 'react';
import PropTypes from 'prop-types';

import Translate from '../../hocs/translate';
import './coach-contact-phone.scss';

const CoachContactPhone = ({ coachPhone, strings }) => {
  return (
    <p className="coach-contact-phone">
      {strings['You can also contact your coach at']}{' '}
      <a className="coach-contact-phone__phone-link" href={`tel:${coachPhone}`}>
        {coachPhone}
      </a>
      .
    </p>
  );
};

CoachContactPhone.propTypes = {
  coachPhone: PropTypes.string.isRequired
};

CoachContactPhone.defaultProps = {
  strings: { 'You can also contact your coach at': 'You can also contact your coach at' }
};

export default Translate('CoachContactPhone')(CoachContactPhone);
