// Password Validations

/** 
// Validate Password Length 
* @param {string}  password - user's password
* Checks passwords for minimum length of 6 characters
**/
export function validatePasswordLength(password) {
  return password.length > 5;
}

/** 
// Validate Password Match 
* @param {string}  password - user's password
* @param {string}  confirmPassword - user's retyped password to confirm
* Checks password and passwordConfirm for match
**/
export function validatePasswordMatch(password, confirmPassword) {
  return password === confirmPassword;
}

/** 
// Validate Empty Password
* @param {string}  password 
**/
export function validatePasswordEmpty(password) {
  return password !== '';
}

/** 
// Valid Password
* @param {string}  password - user's password
* @param {string}  confirmPassword  - user's retyped password to confirm
* Checks Passwords for the follow criteria: 
* 1. password has minimum length
* 2. password matches confirmPassword
* 3. check for empty password
**/
export function validPassword(password, confirmPassword) {
  return (
    validatePasswordLength(password) &&
    validatePasswordMatch(password, confirmPassword) &&
    validatePasswordEmpty(password)
  );
}
