import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Certificate from '../courses_list/certificate';

export default function AchievementsCertsCarousel({ courseAttendancesIds }) {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    className: 'achievements-certs-carousel'
  };
  return (
    <Slider {...settings}>
      {courseAttendancesIds.map((id, key) => (
        <Certificate key={key} courseAttendanceId={id} />
      ))}
    </Slider>
  );
}

AchievementsCertsCarousel.propTypes = {
  courseAttendancesIds: PropTypes.arrayOf(PropTypes.string).isRequired
};
