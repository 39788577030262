//
//  app_device_info_manager.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//

import { Plugins } from '@capacitor/core';
const { Device } = Plugins;

const moment = require('moment-timezone'); // moment-timezone

const APP_DEVICE_INFO_CACHE = 'deviceInfoCache';

export const getAppDeviceInfo = () => {
  return JSON.parse(window.localStorage.getItem(APP_DEVICE_INFO_CACHE));
};

export const loadAppDeviceInfo = (getDeviceInfo) => {
  Device.getInfo().then((info) => {
    let userEnvIsMobile;
    switch (info.operatingSystem) {
      case 'android':
        userEnvIsMobile = true;
        break;
      case 'ios':
        userEnvIsMobile = true;
        break;
      case 'windows':
        userEnvIsMobile = false;
        break;
      case 'mac':
        userEnvIsMobile = false;
        break;
      default:
        userEnvIsMobile = false;
        break;
    }
    const deviceInfo = {
      id: info.uuid,
      model: info.model,
      platform: info.platform,
      manufacturer: info.manufacturer,
      os: info.operatingSystem,
      osVersion: info.osVersion,
      appVersion: info.appVersion,
      appBuild: info.appBuild,
      timezone: moment.tz.guess(),
      userEnvIsMobile,
    };

    saveDeviceInfo(APP_DEVICE_INFO_CACHE, deviceInfo);
    if (getDeviceInfo) {
      getDeviceInfo(deviceInfo);
    }
  });
};

function saveDeviceInfo(key, value) {
  if (window.localStorage.getItem(key) !== JSON.stringify(value)) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}
