import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  signupConfirmation,
  requestNewConfirmationCode,
  changePhoneNumber
} from '../../actions/signup';
import { backToEnterPhone } from '../../actions/simple_login';
import { hashHistory } from 'react-router';
import SignupConfirmationForm from '../../components/signup/signup_confirmation_form';
import BackButton from '../../components/buttons/back_button';
import { formatUSPhoneNumber } from '../../utils/phone_format';
import './signup-confirm.scss';

const SignupConfirm = ({
  signup,
  userData,
  changePhoneNumber,
  simpleLogin,
  strings,
  requestNewConfirmationCode,
  signupConfirmation
}) => {
  useEffect(() => {
    document.title = 'Cell-Ed Authentication • Essential Skills on the Go';
  }, []);

  useEffect(() => {
    if (signup.confirmationSuccess) {
      const loginStorage = {};
      loginStorage.celledAuthToken = userData.token;
      loginStorage.celledUserId = userData.userId;
      localStorage.setItem('celledCredentials', JSON.stringify(loginStorage));
      hashHistory.push('/active_course');
    }
  }, [signup.confirmationSuccess, userData.token, userData.userId]);

  const handleChangePhoneNumber = () => {
    changePhoneNumber();
    hashHistory.push('/simple-login');
  };

  const submitConfirmationDigits = (confirmationData) => {
    confirmationData.phoneNumber = simpleLogin.phoneNumber;

    signupConfirmation(confirmationData);
  };

  const renderConfirmationResponse = () => {
    const notificationStyles = { width: '100%', textAlign: 'center' };

    if (signup.waitingResponse) {
      return <div style={notificationStyles}>{strings['Confirmation in progress...']}</div>;
    }
  };

  const handleRequestNewConfirmationCode = () => {
    requestNewConfirmationCode(simpleLogin.phoneNumber);
  };

  return (
    <div className="confirmation-page-container signup-confirm">
      <div className="login-page-header">
        <BackButton onClick={handleChangePhoneNumber} />
      </div>
      <div className="form-title">{strings['Create your profile']}</div>
      <p className="form-subtitle">{formatUSPhoneNumber(simpleLogin.phoneNumber)}</p>
      <p className="form-description">
        {
          strings[
            'Check your text message. We just sent you a 5-digit verification code to the number listed above.'
          ]
        }
      </p>

      <SignupConfirmationForm submitConfirmationDigits={submitConfirmationDigits} />

      {renderConfirmationResponse()}

      <button onClick={handleRequestNewConfirmationCode} className="signup-confirm__button">
        {strings['I need a new code']}
      </button>

      <button onClick={handleChangePhoneNumber} className="signup-confirm__button">
        {strings['Enter a different number']}
      </button>
    </div>
  );
};

SignupConfirm.defaultProps = {
  strings: {
    'Confirmation in progress...': 'Confirmation in progress...',
    'Check your text message. We just sent you a 5-digit verification code to the number listed above.':
      'Check your text message. We just sent you a 5-digit verification code to the number listed above.',
    'I need a new code': 'I need a new code',
    'Enter a different number': 'Enter a different number',
    'Create your profile': 'Create your profile',
    Step: 'Step',
    of: 'of'
  }
};

function mapStateToProps(state) {
  return { signup: state.signup, userData: state.mainApp, simpleLogin: state.simpleLogin };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { signupConfirmation, requestNewConfirmationCode, changePhoneNumber, backToEnterPhone },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupConfirm);
