import React, { Component } from 'react';
import Translate from '../../hocs/translate';
import PropTypes from 'prop-types';
import './live-coach-main.scss';

class LiveCoach extends Component {
  render() {
    return (
      <div className="live-coach-main">
        <p>
          {this.props.strings['Your coach is']} {this.props.coachName}.{' '}
          {this.props.strings['Welcome!']}
        </p>
      </div>
    );
  }
}

LiveCoach.propTypes = {
  coachName: PropTypes.string.isRequired
};

LiveCoach.defaultProps = {
  strings: {
    'Your coach is': 'Your coach is',
    'Welcome!': 'Welcome!'
  }
};

export default Translate('LiveCoach')(LiveCoach);
