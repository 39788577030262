import React, { Component } from 'react';
import Translate from '../../hocs/translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadPreferencesData } from '../../actions/preferences';
import { pauseAudioControl } from '../../actions/audio';
import _ from 'lodash';
import Resource from '../../components/resource/resource';
import PinchZoomPan from '../../components/active_course/chat/PinchZoomPan';
import { VelocityTransitionGroup } from 'velocity-react';
import ReactImg from '../../components/react_img/ReactImg';
import { displayWizenoze } from '../../actions/me';
import { openFullScreenFrame, closeFullScreenFrame } from '../../actions/main_course';
import './resource-main.scss';

class Resources extends Component {
  constructor() {
    super();
    this.state = {
      resources: [],
      plugins: {},
      imageExpanded: false,
      imageUrl: '',
      expandedImageContainerWidth: 0,
      expandedImageContainerHeight: 0
    };
    this.toggleResource = this.toggleResource.bind(this);
    this.toggleImageExpansion = this.toggleImageExpansion.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.openWizenoze = this.openWizenoze.bind(this);
  }

  componentWillMount() {
    this.props.loadPreferencesData(this.props.userId);
  }

  // UNSAFE_componentWillReceiveProps (nextProps) {
  // 	let resources = nextProps.preferences.preferencesData.resources;

  // 	if (resources) {
  // 		_.each(resources, (resource) => {
  // 			_.set(resource, 'show', false);
  // 		});

  // 		this.setState({ resources: resources });
  // 	}
  // 	let plugins = nextProps.preferences.preferencesData.plugins;
  // 	this.setState({ plugins: plugins });
  // }

  componentDidMount() {
    let resources = this.props.preferences.preferencesData.resources;
    if (resources) {
      _.each(resources, (resource) => {
        _.set(resource, 'show', false);
      });

      this.setState({ resources: resources });
    }
    let plugins = this.props.preferences.preferencesData.plugins;
    this.setState({ plugins: plugins });
  }

  toggleResource(index) {
    let resources = this.state.resources;
    resources[index].show = !resources[index].show;
    this.setState({ resources: resources });
  }

  toggleImageExpansion(imageUrl) {
    this.setState((prevState) => {
      return { imageExpanded: !prevState.imageExpanded, imageUrl: imageUrl };
    });
  }

  onImgLoad(img) {
    // console.log(img.target.naturalWidth, img.target.naturalHeight);
    const targetWidth =
      img.target.naturalWidth > window.screen.width ? window.screen.width : img.target.naturalWidth;

    const targetHeight = window.screen.height;

    this.setState((prevState) => {
      return {
        expandedImageContainerWidth: targetWidth,
        expandedImageContainerHeight: targetHeight
      };
    });
  }

  openWizenoze(url) {
    this.props.displayWizenoze(url);
  }

  render() {
    if (this.props.preferences.loadingPreferences) {
      return <div>Loading...</div>;
    }

    return (
      <div className="resource-main">
        {this.state.resources.length === 0 && !this.state.plugins.wizenoze && (
          <div className="resource-description">
            {' '}
            {this.props.strings['No resources available.']}
          </div>
        )}
        {this.state.resources.map((resource, i) => {
          return (
            <div className="resource-main-section" key={i}>
              <h4 className="resource-title">{resource.title}</h4>

              <div className="resource-description">
                <span dangerouslySetInnerHTML={{ __html: resource.description }} />{' '}
                <Resource
                  multimediaObject={resource}
                  toggleImageExpansion={this.toggleImageExpansion}
                  displayWizenoze={this.openWizenoze}
                  openFullScreenFrame={this.props.openFullScreenFrame}
                />
              </div>
            </div>
          );
        })}
        <VelocityTransitionGroup
          component="div"
          enter="transition.expandIn"
          leave="transition.expandOut">
          {this.state.imageExpanded && (
            <div onClick={this.toggleImageExpansion} className="resource-image-container">
              <PinchZoomPan
                width={this.state.expandedImageContainerWidth}
                height={this.state.expandedImageContainerHeight}>
                {(x, y, scale) => (
                  <ReactImg
                    src={this.state.imageUrl}
                    onLoad={this.onImgLoad}
                    style={{
                      pointerEvents: scale === 1 ? 'auto' : 'none',
                      transform: `translate3d(${x}px, ${y}px, 0) scale(${scale})`,
                      transformOrigin: '0 0',
                      width: 'auto',
                      height: 'auto',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      alignSelf: 'center'
                    }}
                  />
                )}
              </PinchZoomPan>
            </div>
          )}
        </VelocityTransitionGroup>
        {this.state.plugins.wizenoze && (
          <div className="profile-option-container">
            <button onClick={this.openWizenoze.bind(null)} className="languageButton wizenozebtn">
              {this.props.strings['Search on the Internet']}
            </button>
          </div>
        )}
      </div>
    );
  }
}

Resources.defaultProps = {
  strings: {
    'No resources available.': 'No resources available.',
    'Search on the Internet': 'Search on the Internet'
  }
};

function mapStateToProps(state) {
  return { preferences: state.preferences, userId: state.mainApp.userId };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadPreferencesData,
      pauseAudioControl,
      displayWizenoze,
      openFullScreenFrame,
      closeFullScreenFrame
    },
    dispatch
  );
}

export default Translate('Resources')(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Resources)
);
