import React from 'react';
import PropTypes from 'prop-types';

const NextArrow = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="10"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.90586 9.49997H18.5001V0.780396L15.2097 4.11876C13.626 2.50998 11.4501 1.49997 9.0309 1.49997C4.89913 1.49997 1.4489 4.38365 0.513949 8.26694L0.406982 8.71122L2.68991 9.4833L2.81239 8.94239C3.46716 6.05064 6.00589 3.89997 9.0309 3.89997C10.795 3.89997 12.3802 4.63732 13.5314 5.82151L9.90586 9.49997ZM14.8587 4.4749C14.9755 4.59384 15.089 4.71625 15.1989 4.84197L15.1989 4.842C13.681 3.106 11.487 2 9.03084 2C7.80155 2 6.63559 2.27106 5.58781 2.75757C6.63561 2.27105 7.80158 1.99997 9.0309 1.99997C11.3092 1.99997 13.3619 2.95158 14.8587 4.4749ZM18 8.99997H18.0001V1.99997L18 2V8.99997Z"
        fill={fill}
      />
    </svg>
  );
};

NextArrow.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
};

NextArrow.defaultProps = {
  fill: '#333333',
  className: ''
};
export default NextArrow;
