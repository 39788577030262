import { put, take, call } from 'redux-saga/effects';
/**
 * Created by Seb
 */
import apiClient from './api';
import { PLUGIN_WIZENOZE_SEARCH, PLUGIN_WIZENOZE_SEARCH_SUCCESS, PLUGIN_WIZENOZE_SEARCH_FAILED } from './actions';

export function * search () {

	while (true) {
		const searchRequest = yield take(PLUGIN_WIZENOZE_SEARCH)
		try {
			const wizenozeSearchAPIRequest = yield call(apiClient.searchRequest,searchRequest.payload);
			yield put({
				type:  PLUGIN_WIZENOZE_SEARCH_SUCCESS,
				payload: { searchResult: wizenozeSearchAPIRequest.data },
			});

		} catch (error) {
			yield put({
				type: PLUGIN_WIZENOZE_SEARCH_FAILED,
				searchResult: []
			});
		}
	}
}

export default function * rootWizenozeSaga () {
	yield [
		search(),
	];
};
