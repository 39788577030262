import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Translate from '../../../hocs/translate';
import Select from 'react-select';
import './multiple-choice-input.scss';

export class MultipleChoiceInput extends Component {
  constructor() {
    super();

    this.submitAnswer = this.submitAnswer.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.selectOptions = this.selectOptions.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
  }

  submitAnswer(answerValue) {
    if(answerValue){
      this.props.submitAnswer(answerValue);
    }
  }

  renderOptions(options) {
    const isSingleOption = options.length === 1;
    return options.map((option, index) => {
      return (
        <button
          type="button"
          className={`multiple-choice-option ${
            isSingleOption ? 'multiple-choice-option__single' : ''
          }`}
          key={index}
          onClick={this.submitAnswer.bind(null, option)}>
          {option}
        </button>
      );
    });
  }

  selectOptions(options) {
    return options.map((option) => ({ value: option, label: option }));
  }

  handleMenuOpen() {
    this.props.scrollToBottom();
  }

  render() {
    const isDropdown = this.props.options.length > 3;

    return (
      <div className="multiple-choice">
        <div className="multiple-choice-heading">
          {this.props.options.length === 1
            ? this.props.strings['Click below to continue']
            : this.props.strings['Select an option below']}
        </div>

        {isDropdown ? (
          <div className="multiple-choice__dropdown">
            <Select
              className="multiple-choice__select"
              classNamePrefix="react-select"
              options={this.selectOptions(this.props.options)}
              onChange={(option) => this.submitAnswer(option.value)}
              isSearchable={false}
              onMenuOpen={() => this.handleMenuOpen()}
              defaultMenuIsOpen
            />
          </div>
        ) : (
          <div className="multiple-choice-container">{this.renderOptions(this.props.options)}</div>
        )}
      </div>
    );
  }
}

export default Translate('MultipleChoiceInput')(MultipleChoiceInput);

MultipleChoiceInput.defaultProps = {
  strings: {
    'Tap on any of the options below': 'Tap on any of the options below',
    'Click below to continue': 'Click below to continue',
    'Select an option below': 'Select an option below'
  }
};

MultipleChoiceInput.propTypes = {
  options: PropTypes.array.isRequired,
  submitAnswer: PropTypes.func.isRequired,
  scrollToBottom: PropTypes.func.isRequired
};
