import React from 'react';
import PropTypes from 'prop-types';
import './icon-chevron.scss';

const IconChevron = ({ direction, color, className }) => {
  return (
    <svg
      className={`icon-chevron icon-chevron--${direction} ${className}`}
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true">
      <path
        d="M0.342004 7.64465C0.796051 8.11845 1.52951 8.11845 1.98356 7.64465L6.50073 2.9309L11.0179 7.64465C11.472 8.11845 12.2054 8.11845 12.6595 7.64465C13.1135 7.17084 13.1135 6.40547 12.6595 5.93166L7.31569 0.355353C6.86164 -0.118451 6.12818 -0.118451 5.67414 0.355353L0.330362 5.93166C-0.112042 6.39332 -0.112042 7.17084 0.342004 7.64465Z"
        fill={color}
      />
    </svg>
  );
};

IconChevron.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  color: PropTypes.string,
  className: PropTypes.string
};

IconChevron.defaultProps = {
  direction: 'down',
  color: '#333333',
  className: ''
};

export default IconChevron;
