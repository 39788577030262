//
//  push_notifications.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//
import { take, call } from 'redux-saga/effects';
import apiClient from '../lib/celled_client';
import { UPDATE_USER_PUSH_TOKEN } from '../constants';

function * updatePushToken () {
	while (true) {
		const result = yield take(UPDATE_USER_PUSH_TOKEN);
		const { payload, userId } = result;
		if (payload !== null) {
			try {
				yield call(apiClient.updatePushToken, userId, payload)
			} catch (e) {
				console.error(e);
			}
		}
	}
}

export default function * rootPushNotificationsSaga () {
	yield [
		updatePushToken(),
	];
};
