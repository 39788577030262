import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Translate from '../../hocs/translate';
import { playAudioControl } from '../../actions/audio';
import Modal from '../modal/modal_main';
import './switch-flow-modal.scss';

class SwitchFlowModal extends Component {
  constructor(props) {
    super(props);

    this.handleSwitchBehavior = this.handleSwitchBehavior.bind(this);
  }

  handleSwitchBehavior() {
    if (
      this.props.location.pathname === '/me' ||
      this.props.location.pathname === '/course_details'
    )
      hashHistory.push({ pathname: '/' });
    if (this.props.active) this.props.playAudioControl();
    this.props.toggleSwitchModal();
  }

  render() {
    return (
      <Modal>
        <div className="switch-flow-modal">
          <h4 className="switch-flow-modal__headline">
            {this.props.strings['Cannot Switch Course']}
          </h4>
          <div>
            <button
              alt={this.props.strings.OK}
              className="switch-flow-modal__button"
              onClick={() => this.handleSwitchBehavior()}>
              {this.props.strings.OK}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

SwitchFlowModal.defaultProps = {
  strings: {
    OK: 'OK',
    'Cannot Switch Course':
      'In order to access the courses, you need to complete the questions. Thank you.'
  }
};

function mapStateToProps(state) {
  return {
    active: state.courseDetails.data.active
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ playAudioControl }, dispatch);
}

export default Translate('SwitchFlowModal')(
  connect(mapStateToProps, mapDispatchToProps)(SwitchFlowModal)
);
