import {
  TOGGLE_LIVE_COACH,
  TOGGLE_CONTACT_US,
  TOGGLE_FAQ,
  EMAIL_SUCCESS,
  TIMEOUT_DISMISSED,
  LOAD_HELP_DATA_SUCCESS,
  GET_COACH_INFO_SUCCESS
} from '../constants';

const initialSate = {
  showLiveCoach: false,
  showContactUs: false,
  showFaq: false,
  emailSuccess: false,
  restart: false,
  coachInfo: {}
};

export default function (state = initialSate, action) {
  switch (action.type) {
    case LOAD_HELP_DATA_SUCCESS:
      return { ...state, helpData: action.payload };
    case GET_COACH_INFO_SUCCESS:
      return { ...state, coachInfo: action.payload };
    case TOGGLE_LIVE_COACH:
      return { ...state, showLiveCoach: !state.showLiveCoach };
    case TOGGLE_CONTACT_US:
      return { ...state, showContactUs: action.payload || !state.showContactUs };
    case TOGGLE_FAQ:
      return { ...state, showFaq: !state.showFaq };
    case EMAIL_SUCCESS:
      return { ...state, emailSuccess: true };
    case TIMEOUT_DISMISSED:
      return { ...state, restart: true };
    default:
      return state;
  }
}
