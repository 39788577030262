import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_CONFIRM,
  FORGOT_PASSWORD_SUCCESSFUL,
  FORGOT_PASSWORD_FAILED,
  CHANGE_PHONE_NUMBER,
  RESET_PASSWORD_CONFIRMATION_REQUEST,
  RESET_PASSWORD_CONFIRMATION_SUCCESSFUL,
  RESET_PASSWORD_CONFIRMATION_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESSFUL,
  RESET_PASSWORD_FAILED,
  RESEND_FORGOT_PASSWORD_REQUEST,
  RESEND_FORGOT_PASSWORD_SUCCESSFUL,
  RESEND_FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD_RESET_STATE
} from '../constants';

const initialState = {
  // View control
  enterPhone: true,
  enterCode: false,
  enterPassword: false,
  // Others
  confirmCode: false,
  phoneNumber: null,
  confirmationCode: null,
  confirmationSuccess: false,
  confirmationFailed: false,
  resetSuccess: false // si
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_RESET_STATE:
      return initialState;
    case FORGOT_PASSWORD_CONFIRM:
      return {
        ...state,
        phoneNumber: action.phoneNumber,
        enterPhone: false,
        enterCode: true,
        enterPassword: false
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        phoneNumber: action.payload,
        enterPhone: false,
        enterCode: true,
        enterPassword: false
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        enterPhone: false,
        enterCode: false,
        enterPassword: false
      };
    case FORGOT_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        enterPhone: false,
        enterCode: true,
        enterPassword: false
      };
    case RESEND_FORGOT_PASSWORD_REQUEST:
    case RESEND_FORGOT_PASSWORD_SUCCESSFUL:
    case RESEND_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        enterPhone: false,
        enterCode: true,
        enterPassword: false
      };
    case CHANGE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: null,
        enterPhone: true,
        enterCode: false,
        enterPassword: false
      };
    case RESET_PASSWORD_CONFIRMATION_REQUEST:
      return {
        ...state,
        confirmationCode: action.payload.confirmationCode,
        confirmationFailed: true,
        confirmationSuccess: false,
        enterPhone: false,
        enterCode: true,
        enterPassword: false
      };
    case RESET_PASSWORD_CONFIRMATION_FAILED:
      return {
        ...state,
        confirmationFailed: true,
        confirmationSuccess: false,
        confirmationCode: null,
        enterPhone: false,
        enterCode: true,
        enterPassword: false
      };
    case RESET_PASSWORD_CONFIRMATION_SUCCESSFUL:
      return {
        ...state,
        confirmationFailed: false,
        confirmationSuccess: true,
        enterPhone: false,
        enterCode: false,
        enterPassword: true
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetSuccess: false,
        enterPhone: false,
        enterCode: false,
        enterPassword: true
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetSuccess: false,
        enterPhone: false,
        enterCode: false,
        enterPassword: true
      };
    case RESET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        resetSuccess: true,
        enterPhone: false,
        enterCode: false,
        enterPassword: true
      };
    default:
      return state;
  }
}
