import { put, take, call } from 'redux-saga/effects';
import apiClient from '../lib/celled_client';
import { checkCommonErrors } from '../lib/auth';
import { LOAD_COURSES_DETAILS, LOAD_COURSES_DETAILS_SUCCESS, LOAD_COURSES_DETAILS_FAILED } from '../constants';

export function * loadCourseDetails (userId, courseCode) {

	try {
		const coursesDetails = yield call(apiClient.loadCourseDetails, userId, courseCode)
		yield put({ type: LOAD_COURSES_DETAILS_SUCCESS, payload: coursesDetails.data })
	} catch (error) {
		let errorPayload = {
			type: LOAD_COURSES_DETAILS_FAILED,
			notification: {
				message: "Course details couldn't be load",
				type: 'courses_list',
				className: 'error',
			},
		};
		yield put(checkCommonErrors(error, errorPayload));
	}
}

function * loadCoursesDetailsWatcher () {

	while (true) {
		const loadCourses = yield take(LOAD_COURSES_DETAILS)
		yield call(loadCourseDetails, loadCourses.payload.userId, loadCourses.payload.courseCode)
	}
}

export default function * rootCoursesDetailsSaga () {
	yield [
		loadCoursesDetailsWatcher(),
	];
};
