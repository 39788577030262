import {
  TOGGLE_COURSES,
  TOGGLE_RESOURCES,
  TOGGLE_PROFILE,
  DISPLAY_WIZENOZE,
  CLOSE_WIZENOZE,
  TOGGLE_ACHIEVEMENTS,
} from '../constants';

export function toggleCourses() {
  return {
    type: TOGGLE_COURSES,
  };
}

export function toggleResources() {
  return {
    type: TOGGLE_RESOURCES,
  };
}

export function toggleProfile() {
  return {
    type: TOGGLE_PROFILE,
  };
}

export function toggleAchievements() {
  return {
    type: TOGGLE_ACHIEVEMENTS,
  };
}

export function displayWizenoze(url) {
  return {
    type: DISPLAY_WIZENOZE,
    payload: url,
  };
}

export function closeWizenoze() {
  return {
    type: CLOSE_WIZENOZE,
  };
}
