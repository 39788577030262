import React from 'react';
import PropTypes from 'prop-types';

const IconHelp = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2157 9.60784C19.2157 14.9141 14.9141 19.2157 9.60784 19.2157C4.30158 19.2157 0 14.9141 0 9.60784C0 4.30158 4.30158 0 9.60784 0C14.9141 0 19.2157 4.30158 19.2157 9.60784ZM10.7962 11.3122C10.8058 11.293 10.8154 11.2738 10.825 11.2642C11.0865 10.8807 11.4508 10.5613 11.82 10.2375C12.672 9.49058 13.5507 8.72018 13.2558 7.08475C12.9772 5.46103 11.6801 4.12554 10.0564 3.88534C8.07718 3.59711 6.32855 4.8173 5.80012 6.56593C5.63678 7.12319 6.05953 7.69005 6.636 7.69005H6.82816C7.22208 7.69005 7.53914 7.41142 7.67365 7.06554C7.9811 6.21044 8.88423 5.62436 9.88345 5.83573C10.8058 6.02789 11.4784 6.94064 11.3919 7.88221C11.3268 8.6158 10.8027 9.02154 10.2212 9.47178C9.85825 9.75273 9.47297 10.051 9.16286 10.4571L9.15325 10.4475C9.1366 10.4642 9.12315 10.4872 9.10921 10.5111C9.09902 10.5286 9.08856 10.5466 9.07639 10.5628C9.06198 10.5868 9.04516 10.6108 9.02835 10.6349C9.01154 10.6589 8.99472 10.6829 8.98031 10.7069C8.89384 10.8414 8.82659 10.9759 8.76894 11.1297C8.76414 11.1489 8.75453 11.1633 8.74492 11.1777C8.73531 11.1921 8.7257 11.2065 8.7209 11.2257C8.71129 11.2353 8.71129 11.245 8.71129 11.2546C8.596 11.6004 8.51914 12.0136 8.51914 12.5036H10.4503C10.4503 12.2634 10.4791 12.052 10.5464 11.8502C10.5656 11.783 10.5944 11.7157 10.6232 11.6485L10.6233 11.6485C10.6329 11.61 10.6425 11.5716 10.6617 11.5428C10.7001 11.4659 10.7481 11.3891 10.7962 11.3122L10.7962 11.3122L10.7962 11.3122L10.7962 11.3122ZM9.55976 15.8529C10.223 15.8529 10.7607 15.3152 10.7607 14.652C10.7607 13.9887 10.223 13.451 9.55976 13.451C8.89647 13.451 8.35878 13.9887 8.35878 14.652C8.35878 15.3152 8.89647 15.8529 9.55976 15.8529Z"
        fill="white"
      />
    </svg>
  );
};

IconHelp.propTypes = { className: PropTypes.string };

IconHelp.defaultProps = {
  className: ''
};

export default IconHelp;
