export const PLUGIN_WIZENOZE_SEARCH = 'PLUGIN_WIZENOZE_SEARCH';
export const PLUGIN_WIZENOZE_SEARCH_SUCCESS = 'PLUGIN_WIZENOZE_SEARCH_SUCESS';
export const PLUGIN_WIZENOZE_SEARCH_FAILED = 'PLUGIN_WIZENOZE_SEARCH_FAILED';

export function searchWithWizenoze (searchTerm, level, requestedPage) {
	return {
		type: exports.PLUGIN_WIZENOZE_SEARCH,
		payload: { searchTerm, level, requestedPage }
	}
}

