import {
  LOAD_CONTEXT,
  RELOAD_CONTEXT,
  PROCESS_RELOAD_CONTEXT,
  PLAY_ACTION,
  MESSAGE_ACTION,
  MESSAGE_HEADER_ACTION,
  WAIT_FOR_USER_INPUT_ACTION,
  ENABLE_INPUT,
  LOAD_NEXT_MODULE_ACTION,
  PLAY_AUDIO,
  PLAY_ACTION_ERROR,
  AUDIO_FINISHED,
  PROCESS_ACTIONS,
  MODULE_SUCCESSFULLY_LOADED,
  MODULE_LOAD_FAILED,
  VALIDATION_FAILED,
  ADD_MESSAGE,
  ADD_HEADER_MESSAGE,
  SUBMIT_ANSWER,
  CLEAR_MESSAGES_ACTION,
  CLEAR_MESSAGES,
  UPDATE_UI,
  SET_UI_ELEMENT,
  LOAD_MESSAGES,
  LOAD_HISTORY,
  LOAD_HISTORY_NEXT_PAGE,
  LOGOUT,
  RECONNECT_ACTIVATE,
  RECONNECT_DEACTIVATE,
  RECONNECT_TRY,
  REDIRECT,
  JUMP,
  REMOVE_AUDIO_MODAL
} from '../constants';
import config from '../config.json';
import apiClient, { axiosRetryOn } from '../lib/celled_client';
import { checkCommonErrors } from '../lib/auth';
import { getUserId } from './selectors';
import { put, take, call, actionChannel, race, flush, select } from 'redux-saga/effects';
import { delay, buffers } from 'redux-saga';
import _ from 'lodash';
import { browserHistory } from 'react-router';

// --------------------------------------------------------------------
// ----------------------------- HELPERS ------------------------------
// --------------------------------------------------------------------

function getInputType(actionsArray) {
  const enableInput = _.find(actionsArray, {
    type: WAIT_FOR_USER_INPUT_ACTION
  });
  if (enableInput) {
    return { type: enableInput['input-type'], options: enableInput.options };
  } else {
    return false;
  }
}

function hasAutoAnswer(actionsArray) {
  const enableInput = _.find(actionsArray, { type: LOAD_NEXT_MODULE_ACTION });
  if (enableInput) {
    return true;
  } else {
    return false;
  }
}

function shouldEnableInputForAction(action, allActionsToBeProcessed) {
  let shouldEnableInput = _.isBoolean(action.shouldEnableInput) ? action.shouldEnableInput : false;
  return shouldEnableInput ? !hasAutoAnswer(allActionsToBeProcessed) : false;
}

function groupAudios(actions) {
  let results = [];
  actions.forEach(function (action) {
    if (action.type === 'PLAY_ACTION') {
      if (_.isArray(action.url)) {
        results.push(action);
      } else {
        action.url = [action.url];
        results.push(action);
      }
    } else {
      results.push(action);
    }
  });
  return results;
}

/**
 * This method prepares and fetches additional information that the client
 * can send to our server at launch (+ initial context delivery phase)
 */
function getInitialLoadingModuleContext() {
  const loadKey = window.sessionStorage.getItem(LOAD_CONTEXT);
  let isNewAppSession = false;
  if (loadKey === null) {
    window.sessionStorage.setItem(LOAD_CONTEXT, new Date().getTime());
    isNewAppSession = true;
  }

  // using SessionStorage, inform the server if the current instance
  // is representing a brand new tab / app launch
  return { isNewAppSession };
}

// --------------------------------------------------------------------
// ------------------ (REDUX) GENERATOR FUNCTIONS ---------------------
// --------------------------------------------------------------------

// Will process each action from the server and execute them sequentially
export function* processServerActions() {
  // Take all PROCESS_ACTIONS dispatched actions,
  // in case one is already running, it will queue the next

  const queuedActions = yield actionChannel(PROCESS_ACTIONS, yield buffers.expanding());

  while (true) {
    const nextActions = yield take(queuedActions);
    // Iterate through all the actions array
    // and build the generator function that will
    // execute the actions

    try {
      const { logout, jump } = yield race({
        logout: take(LOGOUT),
        jump: take(JUMP),
        processedActions: call(translateActions, nextActions)
      });

      if (jump && jump.payload) {
        yield call(callJump, jump.payload.jumpURL);
      }
      // If user logout clear all actions queue
      if (logout) {
        yield flush(queuedActions);
      }
    } catch (error) {
      // In case an error is catched, reset the function
      // and start listening to new 'queuedActions' actions

      yield call(processServerActions);
    }
  }
}

function* reconnectTry(reconnectRequest = null) {
  while (true) {
    // if reconnect isn't active and is the first failed request,
    // start listening to a reconnect request (connection problem)
    if (!reconnectRequest) {
      reconnectRequest = yield take(RECONNECT_ACTIVATE);
    }
    // once a reconnection has been requested, listen to the first action to be executed
    try {
      let { automaticReconnect, logout } = yield race({
        automaticReconnect: call(delay, config.automaticReconnectTimeout),
        manualReconnect: take(RECONNECT_TRY),
        logout: take(LOGOUT)
      });

      if (logout) {
        throw new Error('logout');
      }

      // if automatic reconnect is executed, dispatch reconnect try action to show new state on reconnect component
      else if (automaticReconnect) {
        yield put({ type: RECONNECT_TRY });
      }

      // execute the same request that triggered the connection problem
      const retryRequestResponse = yield call(axiosRetryOn, reconnectRequest.payload);
      // in case the request was successfull,
      yield put({
        type: PROCESS_ACTIONS,
        payload: groupAudios(retryRequestResponse.data)
      });
      yield put({ type: RECONNECT_DEACTIVATE });
      reconnectRequest = null;
    } catch (error) {
      if (error.message === 'logout') {
        yield put({ type: RECONNECT_DEACTIVATE });
        yield call(reconnectTry, null);
      } else {
        // in case the reconnection try failed again, execute the reconnect method with the same request
        yield call(reconnectTry, reconnectRequest);
      }
    }
  }
}

export function* translateActions(nextActions) {
  let actionsToBeProcessed = nextActions.payload;
  let quickAnswer;

  if (!Array.isArray(actionsToBeProcessed)) {
    actionsToBeProcessed = [actionsToBeProcessed];
  }

  for (let action of actionsToBeProcessed) {
    switch (action.type) {
      case SET_UI_ELEMENT:
        yield put({
          type: UPDATE_UI,
          payload: {
            element: action['ui-element'],
            value: action.value
          }
        });
        break;

      case LOAD_MESSAGES:
        if (config.isDemoMode === false) {
          const result = yield call(apiClient.loadHistory, action.url, 0);
          let historyMessages = _.find(result.data, { type: 'MESSAGE_ACTION' }).payload;
          let historyMetadata = _.find(result.data, { type: 'SET_UI_ELEMENT' });
          yield put({
            type: LOAD_HISTORY,
            payload: historyMessages,
            url: action.url,
            pageNumber: 0,
            scrollTop: false,
            scrollBottom: true
          });
          yield put({
            type: UPDATE_UI,
            payload: {
              element: historyMetadata['ui-element'],
              value: historyMetadata.value
            }
          });
        }
        break;
      case LOAD_NEXT_MODULE_ACTION:
        yield call(loadModule, action.url, action.method);
        break;

      case PLAY_ACTION:
        let url = Array.isArray(action.url) ? action.url : [action.url];
        if (!quickAnswer) {
          yield put({
            type: PLAY_AUDIO,
            payload: {
              url: url,
              autoPlay: action.autoplay,
              quickInputEnable: shouldEnableInputForAction(action, actionsToBeProcessed),
              inputType: getInputType(actionsToBeProcessed),
              scrollable: action.scrollable === undefined ? true : action.scrollable
            }
          });
          if (url.length <= 0) {
            yield put({ type: REMOVE_AUDIO_MODAL });
          }
          ({ quickAnswer } = yield race({
            quickAnswer: take(SUBMIT_ANSWER),
            audioFinished: take(AUDIO_FINISHED)
          }));
        }

        break;

      case PLAY_ACTION_ERROR:
        if (!quickAnswer && action.url && action.url.length > 0) {
          yield put({
            type: PLAY_AUDIO,
            payload: {
              url: Array.isArray(action.url) ? action.url : [action.url],
              autoPlay: action.autoplay,
              quickInputEnable: shouldEnableInputForAction(action, actionsToBeProcessed),
              inputType: getInputType(actionsToBeProcessed),
              scrollable: action.scrollable === undefined ? true : action.scrollable
            }
          });

          ({ quickAnswer } = yield race({
            quickAnswer: take(SUBMIT_ANSWER),
            audioFinished: take(AUDIO_FINISHED)
          }));
        } else {
          yield put({ type: REMOVE_AUDIO_MODAL });
        }

        break;

      case MESSAGE_ACTION:
        yield put({ type: ADD_MESSAGE, payload: action.payload });
        break;
      case MESSAGE_HEADER_ACTION:
        yield put({ type: ADD_HEADER_MESSAGE, payload: action.payload });
        break;

      case CLEAR_MESSAGES_ACTION:
        yield put({ type: CLEAR_MESSAGES });
        break;

      case WAIT_FOR_USER_INPUT_ACTION:
        const validationUrl = action.validateUrl || '/learner/validate-sms';
        // In case an answer has been submitted during the PLAY_AUDIO action
        // proceed and call the server with that data
        if (quickAnswer) {
          yield call(validateAnswer, quickAnswer.payload.answer, validationUrl);
          quickAnswer = null;
        }

        // else continue with the normal answer flow
        else {
          yield put({
            type: ENABLE_INPUT,
            payload: { type: action['input-type'], options: action.options }
          });
          const answer = yield take(SUBMIT_ANSWER);
          yield call(validateAnswer, answer.payload.answer, validationUrl);
        }
        break;

      case REDIRECT:
        const newActions = yield call(apiClient.callRedirect, action.payload);
        yield put({
          type: PROCESS_ACTIONS,
          payload: groupAudios(newActions.data)
        });
        break;

      default:
        if (!action.type) break;
        // some actions we don't need to process under this saga,
        // they can be forwarded directly to state reducers.

        // NOTE: custom controller steps (questionnaires) actions
        // are forwarded through here, see reducers/questionnaires.js
        yield put({ type: action.type, payload: action.payload || {} });
        break;
    }
  }
}

// Will validate the user answer against the server endpoint

export function* validateAnswer(answer, validationUrl) {
  const userId = yield select(getUserId);
  try {
    if (answer.audio) {
      let fileData = new FormData();
      var fileOfBlob = new File([answer.recordedBlob.blob], answer.name);
      fileData.append('file_upload', fileOfBlob);
      const audioFile = yield call(apiClient.uploadFile, fileData);
      let nextActions = yield call(
        apiClient.validateAnswer,
        userId,
        audioFile.data.savedFiles[0].url,
        validationUrl
      );
      yield put({
        type: PROCESS_ACTIONS,
        payload: groupAudios(nextActions.data)
      });
    } else {
      let nextActions = yield call(apiClient.validateAnswer, userId, answer, validationUrl);
      yield put({
        type: PROCESS_ACTIONS,
        payload: groupAudios(nextActions.data)
      });
    }
  } catch (error) {
    let errorPayload = {
      type: VALIDATION_FAILED,
      notification: {
        message: 'Connection problem',
        className: 'error',
        type: 'mainCourse'
      }
    };
    yield put(checkCommonErrors(error, errorPayload));
  }
}

// Will load the next module according the user's state in the course

export function* loadModule(url, method) {
  const userId = yield select(getUserId);
  try {
    const newModule = yield call(apiClient.loadModule, userId, url, method);
    // Load the new module in the UI

    yield put({ type: MODULE_SUCCESSFULLY_LOADED });

    // Send the new action to be processed

    yield put({ type: PROCESS_ACTIONS, payload: groupAudios(newModule.data) });
  } catch (error) {
    let errorPayload = {
      type: MODULE_LOAD_FAILED,
      notification: {
        message: 'Connection problem',
        className: 'error',
        type: 'mainCourse'
      }
    };
    yield put(checkCommonErrors(error, errorPayload));
  }
}

// Loads ui-elements and message history

export function* loadInitialContext(context = {}) {
  const userId = yield select(getUserId);
  try {
    let selectedCourse = window.localStorage.getItem('selectedCourse');
    if (selectedCourse) {
      yield call(apiClient.switchCourse, userId, selectedCourse);
      window.localStorage.removeItem('selectedCourse');
    }
    // browserHistory.push({ pathname: `${config.celledApi.baseUrl}/` });
    const newModule = yield call(apiClient.loadContext, userId, context);
    yield put({ type: PROCESS_ACTIONS, payload: groupAudios(newModule.data) });
    yield call(loadModule);
  } catch (error) {
    let errorPayload = {
      type: MODULE_LOAD_FAILED,
      notification: {
        message: 'Connection problem',
        className: 'error',
        type: 'mainCourse'
      }
    };
    if (
      (error.response && error.response.data && error.response.data.code === 1017) ||
      (error.response && error.response.data && error.response.data.code === 1001)
    ) {
      errorPayload = {
        type: MODULE_LOAD_FAILED,
        notification: {
          message: error.response.data.message,
          className: 'error',
          type: 'mainCourse'
        }
      };

      // TODO-santi: why is this here? is this a retry attempt? what are codes 1017 and 1001?
      browserHistory.push({ pathname: config.celledApi.baseUrl });
      const newModule = yield call(apiClient.loadContext, userId, context);
      yield put({
        type: PROCESS_ACTIONS,
        payload: groupAudios(newModule.data)
      });
      yield call(loadModule);
    }

    yield put(checkCommonErrors(error, errorPayload));
  }
}

// Watcher to load the module when first rendering the web app

export function* loadInitialModule() {
  while (true) {
    yield take(LOAD_CONTEXT);
    yield call(loadInitialContext, getInitialLoadingModuleContext());
  }
}

export function* reloadInitialModule() {
  while (true) {
    yield take(RELOAD_CONTEXT);
    yield put({ type: PROCESS_RELOAD_CONTEXT, reload: true });
    yield call(loadInitialContext);
  }
}

function* callJump(jumpURL) {
  const userId = yield select(getUserId);
  const result = yield call(apiClient.jump, userId, jumpURL);
  yield put({ type: PROCESS_ACTIONS, payload: groupAudios(result.data) });
}

export function* loadHistory() {
  while (true) {
    let payload = yield take(LOAD_HISTORY_NEXT_PAGE);
    let url = payload.history.url;
    let pageNumber = payload.history.pageNumber;
    let result = yield call(apiClient.loadHistory, url, pageNumber);
    let historyMessages = _.find(result.data, { type: 'MESSAGE_ACTION' }).payload;
    let historyMetadata = _.find(result.data, { type: 'SET_UI_ELEMENT' });
    yield put({
      type: LOAD_HISTORY,
      payload: historyMessages,
      url: url,
      pageNumber: pageNumber,
      scrollTop: true,
      scrollBottom: false
    });
    yield put({
      type: UPDATE_UI,
      payload: {
        element: historyMetadata['ui-element'],
        value: historyMetadata.value
      }
    });
  }
}

export default function* rootMainCourseSaga() {
  yield [
    loadInitialModule(),
    reloadInitialModule(),
    processServerActions(),
    reconnectTry(),
    loadHistory()
  ];
}
