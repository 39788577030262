import { SIMPLE_LOGIN_REQUEST, SIMPLE_LOGIN_ENTER_PHONE} from '../constants';

export function requestSimpleLogin({ phoneNumber, receiveSms, language }) {
	return ({
		type: SIMPLE_LOGIN_REQUEST,
		payload: { phoneNumber, receiveSms, language },
	})
}

export function backToEnterPhone() {
	return ({
		type: SIMPLE_LOGIN_ENTER_PHONE
	})
}


