import rootGoogleAnalyticsSaga from './googleAnalytics';
import rootSignupSaga from './signup';
import rootLoginSaga from './login';
import rootDemoSaga from './demo';
import rootMainCourseSaga from './main_course';
import rootCoursesListSaga from './courses_list';
import rootChatSaga from './chat';
import rootPreferencesSaga from './preferences';
import rootHelpSaga from './help';
import rootCoursesDetailsSaga from './course_details';
import rooti18nSaga from './i18n';
import rootResetPasswordSaga from './reset-password';
import rootEasyLoginSaga from './easy_login';
// import rootTimeoutSaga from './timeout';
import rootLearnerFlowInfoSaga from './learner_flow_info';
import rootSwitchCourseSaga from './switch_course';
import rootSwitchLessonSaga from './switch_lesson';
import rootSwitchQuestionnaireSaga from './switch_questionnaire';
import rootSimpleLoginSaga from './simple_login';
import rootWizenozeSaga from '../plugins/wizenoze/sagas';
import rootPushNotificationsSaga from './push_notifications';
import rootAchievementsSaga from './achievements';
import rootMainAppSaga from './main_app';

export default function* rootSaga() {
  yield [
    rootGoogleAnalyticsSaga(),
    rootSignupSaga(),
    rootLoginSaga(),
    rootDemoSaga(),
    rootMainCourseSaga(),
    rootCoursesListSaga(),
    rootChatSaga(),
    rootPreferencesSaga(),
    rootHelpSaga(),
    rootCoursesDetailsSaga(),
    rooti18nSaga(),
    rootResetPasswordSaga(),
    rootEasyLoginSaga(),
    //		rootTimeoutSaga(),
    rootLearnerFlowInfoSaga(),
    rootSwitchCourseSaga(),
    rootSwitchLessonSaga(),
    rootSwitchQuestionnaireSaga(),
    rootSimpleLoginSaga(),
    rootWizenozeSaga(),
    rootPushNotificationsSaga(),
    rootAchievementsSaga(),
    rootMainAppSaga()
  ];
}
