import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { PropTypes } from 'prop-types';
import Translate from '../../hocs/translate';
import LogoCelledIcon from '../../components/assets/logo_celled_icon';
import LogoCelledHorizontal from '../../components/assets/logo_celled_horizontal';
import LanguageSelector from '../language_selector/language_selector';
import { useMediaQuery } from 'react-responsive';
import IconPerson from '../../components/assets/icon_person';
import IconHome from '../../components/assets/icon_home';
import IconHelp from '../../components/assets/icon_help';
import LogoSvs from '../../components/assets/logo_svs';
import logoCdss from '../../../images/logo-cdss.png';
import './nav-component.scss';

const NavComponent = ({ theme, logged, strings, location, useRTL }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const renderLogo = () => {
    if (theme === 'svs') return <LogoSvs />;
    if (theme === 'cdss')
      return <img src={logoCdss} alt="California Department of Social Services" />;
    return isMobile ? <LogoCelledIcon /> : <LogoCelledHorizontal />;
  };

  return (
    <nav className="nav-component" role="navigation" style={{ direction: useRTL ? 'rtl' : 'unset' }}>
      <div className="container row nav-component__container">
        <div className="nav-component__logo">
          <Link
            to={'/active_course'}
            aria-label={strings.HOME}
            title={strings.HOME}
            className="nav-component__logo-link">
            {renderLogo()}
          </Link>
        </div>

        {logged ? (
          <ul className="nav-component__list">
            <li className="nav-component__list-item">
              <Link
                to={'/active_course'}
                activeClassName="active"
                className="nav-component__link"
                aria-label={strings.HOME}>
                <IconHome className="nav-component__link-icon" />
                <span className="nav-component__link-text">{strings.HOME}</span>
              </Link>
            </li>
            <li className="nav-component__list-item">
              <Link
                to={'/help'}
                activeClassName="active"
                className="nav-component__link"
                aria-label={strings.HELP}>
                <IconHelp className="nav-component__link-icon" />
                <span className="nav-component__link-text">{strings.HELP}</span>
              </Link>
            </li>
            <li className="nav-component__list-item">
              <Link
                to={'/me'}
                activeClassName="active"
                className={` ${['/subtopics', '/course_details'].includes(location.pathname) ? 'active' : null
                  } nav-component__link`}
                aria-label={strings.ME.toLowerCase()}>
                <IconPerson className="nav-component__link-icon" />
                <span className="nav-component__link-text">{strings.ME}</span>
              </Link>
            </li>
          </ul>
        ) : (
          <LanguageSelector isLogged={false} />
        )}
      </div>
    </nav>
  );
};

NavComponent.propTypes = {
  theme: PropTypes.oneOf(['svs', 'cdss', ''])
};

NavComponent.defaultProps = {
  strings: {
    'HELLO FROM CELL-ED!': 'HELLO FROM CELL-ED!',
    HOME: 'Home',
    HELP: 'Help',
    ME: 'Me',
    'If you have any questions just click here.': 'If you have any questions just click here.',
    menu: 'menu'
  },
  theme: ''
};

function mapStateToProps(state) {
  return {
    logged: state.mainApp.userIsLogged,
    useRTL: state.preferences.preferencesData.useRTL
  };
}

export default Translate('NavBar')(connect(mapStateToProps, null)(NavComponent));
