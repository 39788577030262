import {
  LOAD_COURSES_DETAILS,
  LOAD_COURSES_DETAILS_SUCCESS,
  LOAD_COURSES_DETAILS_FAILED,
  TOGGLE_COURSE_DETAILS,
  TOGGLE_SWITCH_COURSE_MODAL,
  TOGGLE_SWITCH_LESSON_MODAL
} from '../constants';

const initialState = {
  data: {},
  loaded: 'loading',
  courseStart: false,
  showCourseConfirmationModal: false,
  showLessonConfirmationModal: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_COURSE_DETAILS:
      let courseStart = action.courseStart || false;
      return { ...state, courseStart: courseStart };
    case LOAD_COURSES_DETAILS:
      return { ...state, loaded: 'loading' };
    case LOAD_COURSES_DETAILS_SUCCESS:
      return { ...state, loaded: 'success', data: action.payload };
    case LOAD_COURSES_DETAILS_FAILED:
      return { ...state, loaded: 'failed', failed: true };
    case TOGGLE_SWITCH_COURSE_MODAL:
      return { ...state, showCourseConfirmationModal: !state.showCourseConfirmationModal };
    case TOGGLE_SWITCH_LESSON_MODAL:
      return { ...state, showLessonConfirmationModal: !state.showLessonConfirmationModal };
    default:
      return state;
  }
}
