const getVendorPrefixedProperty = (property) => {
	const prefixes = ['webkit', 'moz', 'ms', 'o'];
	
	if (property in document) return property;
	
	for (let i = 0; i < prefixes.length; i++) {
		if ((prefixes[i] + property) in document) {
			return prefixes[i] + property;
		}
			
	}

	return null;
};

export default getVendorPrefixedProperty
