import { put, take, call } from 'redux-saga/effects';
/**
 * Created by nico on 05/07/17.
 */
import apiClient from '../lib/celled_client';
import {
  SIMPLE_LOGIN_REQUEST,
  SIMPLE_LOGIN_NEW_LEARNER,
  SIMPLE_LOGIN_CREATE_PASSWORD,
  SIMPLE_LOGIN_ENTER_PASSWORD,
  SIMPLE_LOGIN_CONFIRM_CODE,
  FORGOT_PASSWORD_CONFIRM,
} from '../constants';

export function* simpleLogin() {
  while (true) {
    const simpleLoginRequest = yield take(SIMPLE_LOGIN_REQUEST);
    try {
      const onBoardingStatusRequest = yield call(
        apiClient.checkOnboardingStatus,
        simpleLoginRequest.payload
      );
      // LEARNER MUST LOG IN
      if (onBoardingStatusRequest.data.existingPassword) {
        yield put({
          type: SIMPLE_LOGIN_ENTER_PASSWORD,
          phoneNumber: onBoardingStatusRequest.data.phoneNumber,
          account: onBoardingStatusRequest.data.account,
        });
      } else {
        if (onBoardingStatusRequest.data.confirmed) {
          // LEARNER MUST ENTER NEW PASSWORD TO USE THE WEBAPP
          yield put({
            type: SIMPLE_LOGIN_CREATE_PASSWORD,
            phoneNumber: onBoardingStatusRequest.data.phoneNumber,
            account: onBoardingStatusRequest.data.account,
          });
        } else {
          // NEEDS TO CONFIRM THE CODE
          yield put({
            type: FORGOT_PASSWORD_CONFIRM, // To set phone number on reset_password state.
            phoneNumber: onBoardingStatusRequest.data.phoneNumber,
          });
          // Redirects to confirm_password_reset on simple_login_container.js because it is in a code confirmation state.
          yield put({
            type: SIMPLE_LOGIN_CONFIRM_CODE, // To redirect
            phoneNumber: onBoardingStatusRequest.data.phoneNumber,
          });
        }
      }
    } catch (error) {
      //LEARNER DOESN'T EXIST
      yield put({
        type: SIMPLE_LOGIN_NEW_LEARNER,
        phoneNumber: simpleLoginRequest.payload.phoneNumber,
      });
    }
  }
}

export default function* rootSimpleLoginSaga() {
  yield [simpleLogin()];
}
