import React from 'react';
import './icon-close.scss';

export default function IconClose() {
  return (
    <div className="icon-close">
      <span />
      <span />
    </div>
  );
}
