import {
  SIGNUP_REQUEST,
  SIGNUP_EXISTING_LEARNER,
  SIGNUP_FAILED,
  SIGNUP_SUCCESSFUL,
  SIGNUP_CONFIRMATION_REQUEST,
  SIGNUP_CONFIRMATION_FAILED,
  SIGNUP_CONFIRMATION_SUCCESSFUL,
  SIGNUP_PUBLIC_ACCOUNT_DETAILS_SUCCESSFUL,
  SIGNUP_PUBLIC_ACCOUNT_DETAILS_RESET,
  CHANGE_PHONE_NUMBER,
  VERIFY_PINCODE_FAILED,
  VERIFY_PINCODE_SUCCESSFUL,
  RESET_PINCODE_TRY
} from '../constants';

const INITIAL_STATE = {
  failed: false,
  success: false,
  waitingResponse: false,
  signupData: {},
  confirmationFailed: false,
  confirmationSuccess: false,
  verifyPincodeFailed: false,
  verifyPincodeSuccess: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNUP_REQUEST:
    case SIGNUP_EXISTING_LEARNER:
      return { ...state, failed: false, waitingResponse: true, signupData: action.payload };
    case SIGNUP_FAILED:
      return { ...state, failed: true, waitingResponse: false };
    case SIGNUP_SUCCESSFUL:
      let newUserData = { ...state.signupData, ...action.payload.data };
      return { ...state, failed: false, success: true, waitingResponse: false, ...newUserData };
    case SIGNUP_CONFIRMATION_REQUEST:
      return { ...state, waitingResponse: true };
    case SIGNUP_PUBLIC_ACCOUNT_DETAILS_SUCCESSFUL:
      return { ...state, publicAccount: action.payload.data.account };
    case SIGNUP_PUBLIC_ACCOUNT_DETAILS_RESET:
      return { ...state, publicAccount: null };
    case CHANGE_PHONE_NUMBER:
      return { ...state, success: false };
    case SIGNUP_CONFIRMATION_FAILED:
      return { ...state, confirmationFailed: true, waitingResponse: false };
    case SIGNUP_CONFIRMATION_SUCCESSFUL:
      return {
        ...state,
        confirmationFailed: false,
        confirmationSuccess: true,
        waitingResponse: false
      };
    case RESET_PINCODE_TRY:
      return { ...state, verifyPincodeFailed: false, verifyPincodeSuccess: false };
    case VERIFY_PINCODE_FAILED:
      return { ...state, verifyPincodeFailed: true, verifyPincodeSuccess: false };
    case VERIFY_PINCODE_SUCCESSFUL:
      return { ...state, verifyPincodeSuccess: true, verifyPincodeFailed: false };
    default:
      return state;
  }
}
