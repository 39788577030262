import { SHOW_FLOAT_BUBBLE } from '../constants';

// change state in 'me' and 'courses_list' reducers to show
// certificate corresponding to current course

export function showFloatBubble (isOpen) {
	return {
		type: SHOW_FLOAT_BUBBLE,
		payload: isOpen,
	}
}