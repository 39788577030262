// -- Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { keepLearnerlastTime } from '../../actions/questionnaire'
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
export class InlineFormTimer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			counter: '--:--',
			innerDate: null,
			timerInterval: null,
		};

		this.getInnerDate = this.getInnerDate.bind(this);
		this.updateCountdown = this.updateCountdown.bind(this);
		this.updateCountup = this.updateCountup.bind(this);
		this.stopTimer = this.stopTimer.bind(this);
		this.pauseTimer = this.pauseTimer.bind(this);
		this.startTimer = this.startTimer.bind(this);
	}

	componentDidMount() {
		if (this.props.isActive) return this.startTimer();
		this.setState({ counter: this.props.learnerlastTime })
	}

	componentWillUnmount() {
		this.pauseTimer();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isActive !== prevProps.isActive) {
			if (!this.props.isActive) this.pauseTimer()
		}
	}

	startTimer() {
		const { mode } = this.props;
		this.setState({
			counter: '--:--',
			timerInterval: setInterval(mode === 'down' ? this.updateCountdown : this.updateCountup, 1000),
			innerDate: this.getInnerDate()
		});
	}

	pauseTimer() {
		if (this.state.timerInterval !== null) {
			clearInterval(this.state.timerInterval);
		}
		this.props.keepLearnerlastTime(this.state.counter)
	}

	stopTimer() {
		this.pauseTimer();
		const { mode, counter } = this.state;
		if (mode === 'down') this.setState({ counter: '--:--', timerInterval: null });
		if (this.props.onEnded) this.props.onEnded(counter);
	}

	getInnerDate() {
		const { amount, unit, mode, activeTimerStartDate } = this.props;
		if (mode === 'down') {
			const startDate = moment(activeTimerStartDate || Date.now()).add(amount, unit).toDate();
			return startDate.getTime();
		} else {
			return moment(activeTimerStartDate || Date.now()).toDate();
		}
	}

	padNumber(number) {
		if (number < 10) {
			return `0${number}`;
		}
		return `${number}`;
	}

	updateCountdown() {
		const { innerDate } = this.state;
		if (!innerDate) return;
		// Get today's date and time
		const now = new Date().getTime();
		// Find the distance between now and the count down date
		let distance = innerDate - now;
		// Duratiion calc
		let duration = moment.duration(distance * 1000, 'milliseconds');
		duration = moment.duration(distance - 1000, 'milliseconds');
		let minutes = this.padNumber(duration.minutes());
		let seconds = this.padNumber(duration.seconds());
		this.setState({ counter: `${minutes}:${seconds}` });
		// If the count down is finished, stop the timer
		if (distance < 0) {
			this.stopTimer();
		}
	}

	updateCountup() {
		const { innerDate } = this.state;
		if (!innerDate) return;
		// Get today's date and time
		const now = Date.now();
		// Find the distance between now and the count up date
		let distance = now - innerDate;
		// Duratiion calc
		let duration = moment.duration(distance * 1000, 'milliseconds');
		duration = moment.duration(distance + 1000, 'milliseconds');

		let minutes = this.padNumber(duration.minutes());
		let seconds = this.padNumber(duration.seconds());

		let hours = duration.hours();
		let counterText = `${minutes}:${seconds}`;
		if (hours > 0) {
			hours = this.padNumber(hours);
			counterText = `${hours}:${minutes}:${seconds}`;
		}

		this.setState({ counter: counterText });
	}

	render() {
		const { counter } = this.state;
		return (
			<div>
				<h5>
					{counter}
				</h5>
			</div>
		)
	}
}

InlineFormTimer.propTypes = {
	amount: PropTypes.number,
	unit: PropTypes.string,
	mode: PropTypes.string,
	onEnded: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		activeTimerStartDate: _.get(state, 'questionnaires.activeTimerStartDate', null),
		learnerlastTime: state.questionnaires.learnerlastTime
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ keepLearnerlastTime }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InlineFormTimer);
