import { PLAY_PAUSE_AUDIO, UPDATE_AUDIO_PROGRESS, UPDATE_AUDIO_METADATA, PLAY_AUDIO_CONTROL, PAUSE_AUDIO_CONTROL, REMOVE_AUDIO_MODAL, BUFFERING, CLICK_PROGRESS_AUDIO_PROGRESS_BAR } from '../constants';


export function playPauseAudio () {
	return {
		type: PLAY_PAUSE_AUDIO,
	}
}

export function playAudioControl () {
	return {
		type: PLAY_AUDIO_CONTROL,
	}
}

export function pauseAudioControl () {
	return {
		type: PAUSE_AUDIO_CONTROL,
	}
}

export function updateAudioProgress (duration, currentTime, currentIndex) {
	return {
		type: UPDATE_AUDIO_PROGRESS,
		payload: { duration, currentTime, currentIndex },
	}
}

export function updateAudiosMetadata (state) {
	return {
		type: UPDATE_AUDIO_METADATA,
		payload: state,
	}
}

export function removeAudioModal () {
	return {
		type: REMOVE_AUDIO_MODAL,
	}
}

export function buffering (bool) {
	return {
		type: BUFFERING,
		payload: bool,
	}
}

export function clickAudioProgress () {
	return {
		type: CLICK_PROGRESS_AUDIO_PROGRESS_BAR,
	}
}
