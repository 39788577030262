//
//  activity_tracking.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//
import { Plugins, Capacitor } from '@capacitor/core';
import { getRequestHeadersWithToken } from '../celled_client';
import config from '../../config.json';

const Promise = require('bluebird');
const moment = require('moment');
const API_GATEWAY_PATH = config.gatewayApi.baseUrl;
const { ActivityTracking, PassiveNetworking } = Plugins;
const KEY_STARTED_ACTIVITY_COLLECTION = 'KEY_STARTED_ACTIVITY_COLLECTION';

/**
 * ActivityTrackingManager is a ReactJS-client library
 * to interface with the Capacitor native plug-ins responsible
 * for activity tracking.
 */
export default class ActivityTrackingManagerIOS {

  constructor() {
    console.log("[ActivityTrackingManager] running iOS pipeline");
    this.isAvailable =
      Capacitor.isPluginAvailable('ActivityTracking') &&
      Capacitor.isPluginAvailable('PassiveNetworking');
  }

  /**
   * Configuring iOS for Activity Tracking requires defining
   * network endpoints for background URL sessions
   * @param {String} learnerId
   * @return {Boolean} success/failure
   */
  configure(learnerId) {
    if (!this.isAvailable) {
      return Promise.resolve(false);
    }

    const headers = getRequestHeadersWithToken();
    const routes = [
      {
        type: 'HKDATA',
        url: `${API_GATEWAY_PATH}/function/services-activity-upload`,
      },
    ];

    return Promise.all([
      PassiveNetworking.setHeaders(headers),
      PassiveNetworking.setEndpoints({ endpoints: routes }),
    ]);
  }

  /**
   * Start tracking activity in the background.
   * Calls configure() for session before starting.
   * Must call getPermissions before calling start.
   * @param {String} learnerId
   * @return {Boolean} success/failure
   */
  start(learnerId) {
    if (!this.isAvailable) {
      return Promise.resolve(false);
    }

    console.log(`[ActivityTrackingManager] start()`);
    return this.configure(learnerId)
      .then(function () {
        window.localStorage.setItem(
          KEY_STARTED_ACTIVITY_COLLECTION,
          JSON.stringify(true)
        );
        return ActivityTracking.startPassiveCollection({
          fromStartDate: moment().subtract(2, 'weeks').toISOString(),
        });
      })
      .catch(function (err) {
        console.error(`[ActivityTrackingManager] unable to configure.`);
        return false;
      });
  }

  /**
   * STOP tracking activity in the background.
   * @return {Boolean} success/failure
   */
  stop() {
    if (!this.isAvailable) {
      return Promise.resolve(false);
    }

    const hasStartedCollection = JSON.parse(
      window.localStorage.getItem(KEY_STARTED_ACTIVITY_COLLECTION)
    );
    console.log(`[ActivityTrackingManager] stop() ${hasStartedCollection}`);
    if (hasStartedCollection === false) {
      return Promise.resolve(true); // did not start collection, no need to disable
    }

    window.localStorage.setItem(
      KEY_STARTED_ACTIVITY_COLLECTION,
      JSON.stringify(false)
    );
    return ActivityTracking.stop();
  }

  /**
   * Request native permissions to HealthKit (iOS) activity data.
   * Permissions must be obtained before starting data collection.
   * @return {Boolean} true if permissions were granted.
   */
  getPermissions() {
    if (!this.isAvailable) {
      return Promise.resolve(false);
    }

    console.log(`[ActivityTrackingManager] getPermissions()`);
    return ActivityTracking.getHealthAuthorizaton({
      types: [
        'HKQuantityTypeIdentifierStepCount',
        'HKQuantityTypeIdentifierDistanceWalkingRunning',
        'HKQuantityTypeIdentifierFlightsClimbed',
      ],
    })
      .then(function (permission) {
        return permission.success === true;
      })
      .catch(function (err) {
        console.log(`[ActivityTrackingManager] unable to get permissions`);
        return false;
      });
  }

}
