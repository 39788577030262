// -- Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Parser as HtmlParser } from 'html-to-react';
import InlineFormEnableBackgroundAudio from './inline_form_background_audio_enabler';
import InlineFormTimer from './inline_form_timer';

const CONTENT_TYPE = Object.freeze({
  FORM_TIMER: 'formTimer',
  FORM_BACKGROUND_AUDIO_ENABLER: 'backgroundAudioEnabler'
});

export class RichContentBubble extends Component {
  constructor(props) {
    super(props);

    this.state = {
      htmlText: props.htmlText,
      counter: '1:00',
      formTimer: {
        displayFormTimer: false,
        key: '',
        amount: 0,
        unit: '',
        mode: ''
      },
      backgroundAudio: {
        key: '',
        displayBackgroundAudio: false
      }
    };

    this.onEnded = this.onEnded.bind(this);
    this.chatTextRef = React.createRef();
  }

  componentDidMount() {
    this.findContentMatches();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.htmlText !== prevProps.htmlText) {
      this.setState({ htmlText: this.props.htmlText });
      this.findContentMatches();
    }
    if (this.props.isActive !== prevProps.isActive) {
      if (!this.props.isActive) this.findContentMatches();
    }
  }

  findContentMatches() {
    let htmlText = this.state.htmlText;
    const regex = new RegExp(/\{\{(.*?)\}\}/, 'g');
    const inlineMatches = htmlText.matchAll(regex);
    for (const match of inlineMatches) {
      const firstMatch = match[0];
      htmlText = htmlText.replace(firstMatch, '');

      const rawText = firstMatch.replace(/{/g, '').replace(/}/g, '');
      const parameters = rawText.split(' ');
      const contentType = parameters[0] || firstMatch || '';
      switch (contentType) {
        case CONTENT_TYPE.FORM_TIMER:
          const { amount, unit, mode } = this.extractFormTimerArgs(parameters);
          this.setState({
            formTimer: {
              displayFormTimer: true,
              key: contentType,
              amount,
              unit,
              mode
            }
          });
          break;
        case CONTENT_TYPE.FORM_BACKGROUND_AUDIO_ENABLER:
          this.setState({ displayBackgroundAudio: true, key: contentType });
          break;
        default:
          break;
      }
    }
    this.setState({ htmlText }); // update state
  }

  onEnded(time) {
    this.props.submitAnswer(`${time}`, 'user', 'text');
  }

  extractFormTimerArgs(parameters) {
    let result = { amount: 6, unit: 'm', mode: 'down' };
    if (parameters.length > 1 && parameters[1]) {
      if (['up', 'down'].includes(parameters[1])) {
        result.mode = parameters[1];
      }
    }
    if (parameters.length > 2 && parameters[2]) {
      let args = parameters[2];
      let unit = args.charAt(args.length - 1);
      if (['s', 'm'].includes(unit)) {
        let amount = args.substring(0, args.length - 1);
        if (!isNaN(amount)) {
          result.unit = unit;
          result.amount = Number(amount);
        }
      } else {
        if (!isNaN(args)) {
          result.amount = args;
        }
      }
    }
    return result;
  }

  render() {
    var {
      htmlText,
      formTimer: { displayFormTimer, key, amount, unit, mode },
      backgroundAudio: { displayBackgroundAudio, key: backgrounAudioKey }
    } = this.state;
    const htmlToReactParser = new HtmlParser();
    const htmlContent = htmlToReactParser.parse(htmlText);

    return (
      <div className="chat-text" ref={this.chatTextRef}>
        {htmlContent}
        {displayFormTimer && (
          <InlineFormTimer
            key={key}
            amount={amount}
            unit={unit}
            mode={mode}
            onEnded={this.onEnded}
            isActive={this.props.isActive}
          />
        )}
        {this.props.isActive && displayBackgroundAudio && (
          <InlineFormEnableBackgroundAudio key={backgrounAudioKey} />
        )}
      </div>
    );
  }
}

RichContentBubble.propTypes = {
  htmlText: PropTypes.string.isRequired
};

export default RichContentBubble;
