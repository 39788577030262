import { combineReducers } from 'redux';
import signup from './signup';
import demo from './demo';
import notifications from './notifications';
import login from './login';
import i18n from './i18n';
import mainCourse from './main_course';
import coursesList from './courses_list';
import mainApp from './main_app';
import me from './me';
import preferences from './preferences';
import help from './help';
import courseDetails from './course_details';
import resetPassword from './reset_password';
import audio from './audio';
import input from './input';
import chat from './chat';
import timeout from './timeout';
import { LOGOUT } from '../constants';
import simpleLogin from './simple_login';
import wizenoze from '../plugins/wizenoze/reducers';
import questionnaires from './questionnaires';
import achievements from './achievements';

// App's top level reducer

const appReducer = combineReducers({
  mainApp,
  signup,
  demo,
  notifications,
  login,
  i18n,
  timeout,
  mainCourse,
  coursesList,
  preferences,
  me,
  help,
  courseDetails,
  resetPassword,
  audio,
  input,
  chat,
  simpleLogin,
  wizenoze,
  questionnaires,
  achievements,
});

// Reset state if LOGOUT action is dispatched

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
