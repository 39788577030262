import {
  DEMO_REQUEST,
  DEMO_REQUEST_FAILED,
  DEMO_REQUEST_SUCCEEDED,
} from '../constants';

const INITIAL_STATE = {
  failed: false,
  success: false,
  waitingResponse: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case DEMO_REQUEST:
      return { ...state, failed: false, waitingResponse: true };
    case DEMO_REQUEST_FAILED:
      return { ...state, failed: true, waitingResponse: false };
    case DEMO_REQUEST_SUCCEEDED:
      return { ...state, failed: false, success: true, waitingResponse: false };
    default:
      return state;
  }
}
