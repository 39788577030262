import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PlayerControls from '../../components/active_course/player_controls';
import Chat from '../../components/active_course/chat/chat';
import UserInput from '../../components/active_course/input/user_input';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  loadContext,
  reLoadContext,
  audioFinished,
  submitAnswer,
  enableUserInput,
  addMessage,
  walkTroughFinished,
  startRecording,
  stopRecording,
  jump,
  loadPreviousHistory,
  closeFullScreenFrame
} from '../../actions/main_course';
import {
  playPauseAudio,
  pauseAudioControl,
  removeAudioModal,
  updateAudiosMetadata,
  clickAudioProgress
} from '../../actions/audio';
import { hashHistory } from 'react-router';
import { logout } from '../../actions/login';

import Modal from '../../components/modal/modal_main';
import getVendorPrefixedProperty from '../../utils/vendor_prefixer';

import classNames from 'classnames';
import Loader from 'react-loader-spinner';
import { Plugins, Capacitor } from '@capacitor/core';
import './courses-main.scss';
const { App, Browser } = Plugins;

export class ActiveCourse extends Component {
  constructor() {
    super();
    this.submitUserAnswer = this.submitUserAnswer.bind(this);
    this.logoutAndRedirect = this.logoutAndRedirect.bind(this);
    this.goToCourseDetails = this.goToCourseDetails.bind(this);
    this.jumpNext = this.jumpNext.bind(this);
    this.jumpPrevious = this.jumpPrevious.bind(this);
    this.loadContextInForeground = this.loadContextInForeground.bind(this);
    this.loadContextIfNeeded = this.loadContextIfNeeded.bind(this);
    this.listenerPauseOnBackground = this.listenerPauseOnBackground.bind(this);
    this.openCapacitorBrowser = this.openCapacitorBrowser.bind(this);
    this.iFrameGoBack = this.iFrameGoBack.bind(this);
    this.iFrameOnLoad = this.iFrameOnLoad.bind(this);
    this.closeFullScreenFrame = this.closeFullScreenFrame.bind(this);
    this.courseTitle = this.courseTitle.bind(this);

    this.state = {
      visitedUrlCount: 0, // handy to determine whether to render or not the Back button
      goingBack: false // needed to make sure we distinguish in iFrameOnLoad if we need to increment or decrement the counter
    };
  }

  closeFullScreenFrame() {
    this.setState({ visitedUrlCount: 0 });
    this.props.closeFullScreenFrame();
  }

  async componentDidMount() {
    document.title = 'Cell-Ed Taking a Course • Essential Skills on the Go';
    await this.loadContextInForeground();
    this.listenerPauseOnBackground();
    hashHistory.listen(() => {
      this.closeFullScreenFrame();
    });
  }

  // @deprecated: UNSAFE_componentWillReceiveProps is legacy and should be avoided in new code.
  // https://reactjs.org/docs/react-component.html#unsafe_UNSAFE_componentWillReceiveProps
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.courseSwitched) {
      this.props.reLoadContext(this.props.userInformation.userId);
      window.location.reload();
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.mainCourse.fullScreenFrameUrl !== prevProps.mainCourse.fullScreenFrameUrl) {
      await this.openCapacitorBrowser();
    }

    // language preference changed, reload course
    if (this.props.language !== prevProps.language) {
      this.props.reLoadContext(this.props.userInformation.userId);
      window.location.reload();
    }
  }

  async openCapacitorBrowser() {
    const { fullScreenFrameUrl } = this.props.mainCourse;
    if (fullScreenFrameUrl === null) return;

    await Browser.open({ url: fullScreenFrameUrl });
    this.props.closeFullScreenFrame(); // close right away to clear ref to the previously opened URL
  }

  // Load the user context (audio, message history, lessons, etc.)
  loadContextIfNeeded() {
    if (this.props.mainCourse.loaded !== 'success') {
      this.props.loadContext(this.props.userInformation.userId);
    }
  }

  // iOS-only: load the user context only if the app is in the foreground
  async loadContextInForeground() {
    const isIOS = Capacitor.platform.startsWith('ios');
    if (!isIOS || !Capacitor.isPluginAvailable('App')) {
      return this.loadContextIfNeeded(); // load the app normally if not in iOS
    }

    // App.getState is only available in latest app versions
    const appState = App.getState ? await App.getState() : null;
    if (appState && appState.isActive === false) {
      // Cell-Ed is running in the background
      // Case: Activity Tracking wakes up Cell-Ed in the background
      // to fetch and send health informaton to our servers.
      App.addListener('appStateChange', (appState) => {
        // let's listen for a change in that state
        if (appState && appState.isActive === true) {
          this.loadContextIfNeeded(); // & load Cell-Ed only if we go into the foreground
        }
      });
      return;
    }

    this.loadContextIfNeeded();
  }

  /**
   * Attempts to pause the audio player when the user context is in the background.
   * Currently this logic is only triggered in iOS/Android.
   */
  listenerPauseOnBackground() {
    // NOTE: this code was ported from play_button.js

    // pause audio when tab becomes hidden
    const isAndroid = /Android/.test(navigator.userAgent);
    const isIOS = /(?=.*AppleWebKit).* Mobile.*$/.test(navigator.userAgent);
    const isMobileUA = isAndroid || isIOS;

    // workaround to add event listener in mobile
    if (isMobileUA) {
      const visibilityProp = getVendorPrefixedProperty('hidden');
      if (visibilityProp) {
        let visibilityEvent = visibilityProp.replace(/[H|h]idden/, '') + 'visibilitychange';
        document.addEventListener(visibilityEvent, () => {
          const isBackgroundAudioEnabled = this.props.audioData.isBackgroundAudioEnabled;

          // if we have requested to play the audio in the background,
          if (isBackgroundAudioEnabled === true) {
            return;
          } // let it play

          // otherwise, pause the audio.
          if (document[visibilityProp]) {
            this.props.pauseAudioControl();
          }
        });
      }
    }
  }

  submitUserAnswer(answer, type) {
    this.props.submitAnswer(answer, 'user', type);
  }

  logoutAndRedirect() {
    window.localStorage.removeItem('celledCredentials');
    this.props.logout();
    hashHistory.push('/');
  }

  jumpNext() {
    let url = this.props.mainCourse.moduleData['lesson-nav-next'].url;
    this.props.jump(url, true);
  }

  jumpPrevious() {
    let url = this.props.mainCourse.moduleData['lesson-nav-prev'].url;
    this.props.jump(url, false);
  }

  goToCourseDetails() {
    hashHistory.push({
      pathname: '/course_details',
      search: `?courseCode=${this.props.mainCourse.moduleData.courseCode}`
    });
  }

  // this method mainly keeps track of the number of pages that were loaded within the iframe
  iFrameOnLoad(event) {
    if (this.state.goingBack) {
      this.setState({
        visitedUrlCount: this.state.visitedUrlCount - 1,
        goingBack: false
      });
    } else {
      this.setState({
        visitedUrlCount: this.state.visitedUrlCount + 1,
        goingBack: false
      });
    }
  }

  iFrameGoBack() {
    this.setState({ goingBack: true });
    window.history.back();
  }

  courseTitle(title) {
    const parseTitle = title.split(' - ');
    const subtitle = parseTitle.slice(1).join(' ');

    return (
      <>
        <span className="courses-main__page-title--primary">{parseTitle[0]}</span>
        {subtitle && <span className="courses-main__page-title--secondary"> {subtitle}</span>}
      </>
    );
  }

  render() {
    let courseMainStyle = classNames({
      'cm-page-container': true,
      'start-modal': true,
      offline: this.props.mainCourse.reconnect.active
    });

    switch (this.props.mainCourse.loaded) {
      case 'expired':
        return (
          <div className="cm-page-container">
            <p>{this.props.strings['Your sessions has expired']}</p>
            <p className="text-button" onClick={this.logoutAndRedirect}>
              {this.props.strings['Please sign in again']}
            </p>
          </div>
        );
      case 'success':
      case 'failed':
        return (
          <div className={courseMainStyle}>
            {this.props.audioData.playModal && this.props.audioData.url.length > 0 && (
              <Modal>
                {
                  this.props.strings[
                  'To start, click the PLAY button. Please use headphones or the speaker function.'
                  ]
                }
              </Modal>
            )}

            <div className="courses-main__header">
              <div className="courses-main__header-container" style={{ direction: this.props.useRTL ? 'rtl' : 'unset' }}>
                {this.props.mainCourse.moduleData['main-title']
                  ? (
                    <h1 className="courses-main__page-title">
                      {this.props.mainCourse.moduleData.courseCode ? (
                        <button
                          type="button"
                          className={`courses-main__page-title-button${this.props.useRTL ? '--rtl' : '--ltr'}`}
                          onClick={this.goToCourseDetails}>
                          {this.courseTitle(this.props.mainCourse.moduleData['main-title'])}
                        </button>
                      ) : (
                        <>{this.courseTitle(this.props.mainCourse.moduleData['main-title'])}</>
                      )}
                    </h1>
                  )
                  // Adding an empty box to preserve alignment,
                  : <div />
                }
                {(this.props.audioData?.audios?.length > 0 ||
                  this.props.chat.loadingValidation) && (
                    <PlayerControls
                      audio={this.props.audioData}
                      playPauseAudio={this.props.playPauseAudio}
                      pauseAudioControl={this.props.pauseAudioControl}
                      enableUserInput={this.props.enableUserInput}
                      removeAudioModal={this.props.removeAudioModal}
                      jumpNext={this.jumpNext}
                      jumpPrevious={this.jumpPrevious}
                      moduleData={this.props.mainCourse.moduleData}
                      updateAudiosMetadata={this.props.updateAudiosMetadata}
                      clickAudioProgress={this.props.clickAudioProgress}
                    />
                  )}
              </div>
            </div>
            <Chat
              messages={this.props.chat.messages}
              isLoading={this.props.chat.loadingValidation}
              input={this.props.input}
              submitUserAnswer={this.submitUserAnswer}
              previousMessagesText={this.props.strings['Show previous messages']}
              loadPreviousHistory={this.props.loadPreviousHistory}
              showPaginator={this.props.mainCourse.moduleData['history-pagination']}
              audioUrl={this.props.audioData.url || []}
            />
            <UserInput
              enable={this.props.input.enable}
              inputType={this.props.input.type}
              submitUserAnswer={this.submitUserAnswer}
              startRecording={this.props.startRecording}
              stopRecording={this.props.stopRecording}
            />
          </div>
        );
      default:
        return (
          <div className="loading">
            <Loader type="TailSpin" color="#61C7B9" height="150" width="150" />
          </div>
        );
    }
  }
}

ActiveCourse.defaultProps = {
  strings: {
    'Show previous messages': 'Show previous messages',
    'Your sessions has expired': 'Your sessions has expired',
    'Please sign in again': 'Please sign in again',
    'There was a problem loading the course': 'There was a problem loading the course',
    'Please refresh the page': 'Please refresh the page',
    MESSAGES: 'MESSAGES',
    'Loading...': 'Loading...',
    Back: 'Back',
    'Go Back': 'Go Back',
    'Close Search Results': 'Close Search Results',
    'Questions? Just click HELP.': 'Questions? Just click HELP.',
    'When you need to answer, text here.': 'When you need to answer, text here.',
    'Press PLAY anytime to start the course.': 'Press PLAY anytime to start the course.',
    'Join Cell-Ed GROUPS to connect with other Cell-Ed learners.':
      'Join Cell-Ed GROUPS to connect with other Cell-Ed learners.',
    'Got it!': 'Got it!',
    Next: 'Next',
    Close: 'Close',
    'Welcome! To start, please press the play button':
      'Welcome! To start, please press the play button',
    'To start, click the PLAY button. Please use headphones or the speaker function.':
      'To start, click the PLAY button. Please use headphones or the speaker function.'
  }
};

ActiveCourse.contextTypes = {
  ableWalkThrough: PropTypes.func
};

function mapStateToProps(state) {
  return {
    mainCourse: state.mainCourse,
    userInformation: state.mainApp,
    audioData: state.audio,
    input: state.input,
    chat: state.chat,
    courseSwitched: state.coursesList.courseSwitched,
    reloaded: state.reloaded,
    language: state.i18n.language,
    useRTL: state.preferences.preferencesData.useRTL
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadContext,
      reLoadContext,
      audioFinished,
      submitAnswer,
      logout,
      enableUserInput,
      addMessage,
      walkTroughFinished,
      playPauseAudio,
      pauseAudioControl,
      removeAudioModal,
      startRecording,
      stopRecording,
      jump,
      updateAudiosMetadata,
      clickAudioProgress,
      loadPreviousHistory,
      closeFullScreenFrame
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(ActiveCourse);
