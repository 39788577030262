import React, { Component } from 'react';
import Translate from '../../hocs/translate';
import classNames from 'classnames';
import Modal from '../../components/modal/modal_main';
export class LessonsList extends Component {
  constructor() {
    super();
    this.state = {
      selectedUnitId: null,
      selectedUnitName: '',
      selectedLessonId: null
    };
    this.toggleLesson = this.toggleLesson.bind(this);
    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.accept = this.accept.bind(this);
  }

  renderModules(modules) {
    return modules.map((module, index) => {
      return (
        <p className="course-details-modules" key={index}>
          {module.name}{' '}
          {module.took && <i className="material-icons course-details-done-icon">done</i>}
        </p>
      );
    });
  }

  renderLessons(lessons) {
    return lessons.map((lesson, index) => {
      let modulesCompleted = 0;
      let totalModules = lesson.modules.length;
      lesson.modules.forEach((module) => {
        module.took && modulesCompleted++;
      });
      let completedPercentage = (modulesCompleted / totalModules) * 100;

      const strings = this.props.strings;
      const progressData = Math.round(completedPercentage);
      let progressBarStyle = classNames({
        progressLessonStatus: true,
        'progress-bar-completed': progressData && progressData === 100
      });
      let programLessonOverview = classNames({
        detailslessonlist: true,
        detailslessonlistDisabled: !lesson.allowSkipping,
        detailslessonlistnew: progressData < 1
      });
      let progressLessonStatus =
        progressData > 0
          ? this.props.strings['Lesson'] + ' ' + modulesCompleted + '/' + totalModules
          : strings.NEW;
      return (
        <button
          key={index}
          className={programLessonOverview}
          aria-label={`start ${this.props.strings.Unit} ${index + 1}, ${
            lesson.displayTitleLesson || lesson.name
          }, ${progressLessonStatus} course`}
          onClick={
            lesson.allowSkipping
              ? this.confirm.bind(
                  null,
                  lesson.id,
                  lesson.displayTitleLesson || lesson.name,
                  lesson.modules[0].id
                )
              : null
          }>
          <h2 className="unit-number">
            {this.props.strings.Unit} {index + 1}
          </h2>
          <h2 className="lesson-title">{lesson.displayTitleLesson || lesson.name}</h2>
          <div className={progressBarStyle}>{progressLessonStatus}</div>
          {this.state[lesson.name] && (
            <p className="course-details-modules" key={index}>
              {parseMessage(lesson.description)}
            </p>
          )}
        </button>
      );
    });
  }

  confirm(unitId, unitName, lessonId) {
    this.props.switchLessonRequest();
    this.setState({
      selectedUnitId: unitId,
      selectedUnitName: unitName,
      selectedLessonId: lessonId
    });
  }

  cancel() {
    this.props.toggleLessonConfirmationModal();
    this.setState({
      selectedUnitId: null,
      selectedUnitName: '',
      selectedLessonId: null
    });
  }

  accept() {
    let url = `jump/unit/${this.state.selectedUnitId}/lesson/${this.state.selectedLessonId}`;
    this.cancel();
    this.props.jump(url);
  }

  toggleLesson(lessonName) {
    this.setState({ [lessonName]: !this.state[lessonName] });
  }

  render() {
    return (
      <div className="course-details-lessons-container">
        {this.props.showLessonConfirmationModal && (
          <Modal>
            <div className="course-details-modal">
              <h3 className="course-details-modal__headline">
                {this.props.strings['Are you sure you want to start the unit:']}
              </h3>
              <p className="course-details-modal__course-name">{this.state.selectedUnitName}</p>
              <div className="course-details-modal__buttons">
                <button
                  alt={this.props.strings.NO}
                  className="button cl-box-button-close"
                  onClick={this.cancel}>
                  {this.props.strings.NO}
                </button>
                <button
                  alt={this.props.strings.YES}
                  className="button cl-box-button-confirmation"
                  onClick={this.accept}>
                  {this.props.strings.YES}
                </button>
              </div>
            </div>
          </Modal>
        )}
        {this.renderLessons(this.props.lessons)}
      </div>
    );
  }
}

function parseMessage(message) {
  if (message) {
    let stringMessage = message.split(/\n|\r|\r\n/);
    if (stringMessage.length > 1) {
      return stringMessage.map((line, index) => {
        if (line !== '' && !/^\s+$/.test(line)) {
          return <p key={index}>{line}</p>;
        } else {
          return <hr key={index} style={{ visibility: 'hidden', margin: '2px 0' }} />;
        }
      });
    }
    return message;
  }
}

LessonsList.defaultProps = {
  strings: {
    START: 'START',
    Continue: 'Continue',
    PROGRESS: 'PROGRESS',
    COMPLETED: 'COMPLETED',
    YES: 'YES',
    NO: 'NO',
    'Are you sure you want to start the unit:': 'Are you sure you want to start the unit:',
    Unit: 'Unit',
    Lesson: 'Lesson',
    NEW: 'NEW'
  }
};

export default Translate('LessonsList')(LessonsList);
