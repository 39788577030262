import { put, take, call, select } from 'redux-saga/effects';
import { GET_ACHIEVEMENTS, GET_ACHIEVEMENTS_ERROR } from '../constants';
import { getAchievementsSuccess } from '../actions/achievements';
import client from '../lib/celled_client';
import { checkCommonErrors } from '../lib/auth';
import { getUserId } from './selectors';

export function* getAchievements() {
  while (yield take(GET_ACHIEVEMENTS)) {
    const userId = yield select(getUserId);
    try {
      const payload = yield call(client.getAchievements, userId);
      yield put(getAchievementsSuccess(payload.data.body));
    } catch (error) {
      const errorPayload = {
        type: GET_ACHIEVEMENTS_ERROR,
        notification: {
          message: 'Error fetching Achievements',
          className: 'error',
          unique: true,
          type: 'preference_data'
        }
      };
      yield put(checkCommonErrors(error, errorPayload));
    }
  }
}

export default function* rootAchievementsSaga() {
  yield [getAchievements()];
}
