import { LOAD_USER_PREFERENCES, LOAD_APP_LINK_PREFERENCES_SUCCESS, TOGGLE_SMS_REMINDER, TOGGLE_USE_RTL } from '../constants';

export function saveAppLinkPreferences(data) {
	return {
		type: LOAD_APP_LINK_PREFERENCES_SUCCESS,
		payload: { ...data }
	}
}

export function loadPreferencesData(userId) {
	return {
		type: LOAD_USER_PREFERENCES,
		userId: userId,
	}
}

export function toggleSmsReminder(userId, toggle) {
	return {
		type: TOGGLE_SMS_REMINDER,
		userId: userId,
		toggle: toggle,
	}
}

export function toggleuseRTL(userId, toggle) {
	return {
		type: TOGGLE_USE_RTL,
		payload: { userId, toggle }
	}
}
