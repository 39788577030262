import React from 'react';
import { Parser as HtmlParser } from 'html-to-react';
import PropTypes from 'prop-types';

// -- Components
import MultimediaElement from './multimedia_element';

const TextMessageBubble = ({ handleChatImageLoaded, multimedia, ...props }) => {
  return (
    <div>
      <div className="chat-text">{parseMessage(props.text)}</div>

      {multimedia && (
        <MultimediaElement
          multimediaObject={multimedia}
          onClick={
            multimedia.type === 'web' ? props.openFullScreenFrame : props.toggleImageExpansion
          }
          handleChatImageLoaded={handleChatImageLoaded}
        />
      )}
    </div>
  );
};

function parseMessage(message) {
  if (message) {
    if (message.includes('amazonaws.com') || message.includes('typeAudio')) {
      return <p>Your Recording</p>;
    } else {
      let stringMessage = message.split(/\n|\r|\r\n/);
      if (stringMessage.length > 1) {
        return stringMessage.map((line, index) => {
          if (line !== '' && !/^\s+$/.test(line)) {
            return <p key={index}>{renderHtml(line)}</p>;
          } else {
            return <hr key={index} style={{ visibility: 'hidden', margin: '2px 0' }} />;
          }
        });
      }
      return renderHtml(message);
    }
  }
}

function renderHtml(htmlInput) {
  const htmlToReactParser = new HtmlParser();
  return htmlToReactParser.parse(htmlInput);
}

TextMessageBubble.propTypes = {
  handleChatImageLoaded: PropTypes.oneOfType([PropTypes.func, PropTypes.any])
};

export default TextMessageBubble;
