import { TIMEOUT_DISMISSED, TIMEOUT_RESET, TIMEOUT_LOGOUT, TIMEOUT_ACTIVATE } from '../constants';


export function activateTimeout () {
	return {
		type: TIMEOUT_ACTIVATE,
	}
}

export function resetTimeoutTimer () {
	return {
		type: TIMEOUT_RESET,
	}
}

export function dismissTimeout () {
	return {
		type: TIMEOUT_DISMISSED,
	}
}

export function timeoutLogout () {
	return {
		type: TIMEOUT_LOGOUT,
	}
}
