import React from "react";
import "./icon-certificate.scss";
import Proptypes from "prop-types";

export default function IconCertificate({ className }) {
  return (
    <svg
      className={className}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 420 341.25">
      <defs>
        <clipPath id="clip-path" transform="translate(-190 -268.75)">
          <rect className="cls-1" width="800" height="800" />
        </clipPath>
      </defs>
      <title>certificate icon achievement</title>
      <g className="cls-2">
        <path
          className="cls-3"
          d="M590.31,570.62H550.94V557.5h39.37a6.56,6.56,0,0,0,6.56-6.56V288.44a6.55,6.55,0,0,0-6.56-6.56H209.69a6.55,6.55,0,0,0-6.56,6.56v262.5a6.56,6.56,0,0,0,6.56,6.56H459.06v13.12H209.69A19.68,19.68,0,0,1,190,550.94V288.44a19.68,19.68,0,0,1,19.69-19.69H590.31A19.68,19.68,0,0,1,610,288.44v262.5a19.68,19.68,0,0,1-19.69,19.68"
          transform="translate(-190 -268.75)"
        />
        <path
          className="cls-3"
          d="M432.81,544.38h-210a6.57,6.57,0,0,1-6.56-6.57V301.56a6.56,6.56,0,0,1,6.56-6.56H577.19a6.56,6.56,0,0,1,6.56,6.56V419.69H570.62V308.12H229.38V531.25H432.81Z"
          transform="translate(-190 -268.75)"
        />
        <path
          className="cls-3"
          d="M505,540.59a15.87,15.87,0,0,1,14.22,8.77,2.64,2.64,0,0,0,3.16,1.49,2.69,2.69,0,0,0,2.16-2.84,15.94,15.94,0,0,1,25.18-13.25,2.57,2.57,0,0,0,3.46-.18,2.72,2.72,0,0,0,.65-3.56A16,16,0,0,1,570,507.55a2.62,2.62,0,0,0,3-1.8,2.7,2.7,0,0,0-1.06-3.45A16,16,0,0,1,575.32,474a2.88,2.88,0,0,0-.65-5.56,16,16,0,0,1-10.08-26.67,2.72,2.72,0,0,0,.22-3.61,2.62,2.62,0,0,0-3.32-1,16,16,0,0,1-21.29-18.91,2.7,2.7,0,0,0-1.44-3.28,2.56,2.56,0,0,0-3.4.66,15.93,15.93,0,0,1-27.62-6.83,2.8,2.8,0,0,0-5.48,0,15.93,15.93,0,0,1-27.61,6.83,2.55,2.55,0,0,0-3.39-.66,2.69,2.69,0,0,0-1.45,3.28,16,16,0,0,1-21.29,18.91,2.62,2.62,0,0,0-3.32,1,2.74,2.74,0,0,0,.23,3.62,16,16,0,0,1-10.08,26.66,2.88,2.88,0,0,0-.66,5.56,16,16,0,0,1,3.42,28.31,2.71,2.71,0,0,0-1.06,3.45,2.62,2.62,0,0,0,3,1.8A16,16,0,0,1,456.2,531a2.72,2.72,0,0,0,.66,3.57,2.56,2.56,0,0,0,3.46.18A15.94,15.94,0,0,1,485.5,548a2.68,2.68,0,0,0,2.15,2.85,2.64,2.64,0,0,0,3.16-1.49A15.89,15.89,0,0,1,505,540.59m16.55,23.47a15.62,15.62,0,0,1-14-8.78,2.77,2.77,0,0,0-3.72-1.28,2.74,2.74,0,0,0-1.28,1.28,15.94,15.94,0,0,1-30.19-7.45,2.81,2.81,0,0,0-1.53-2.63,2.7,2.7,0,0,0-2.9.21,16,16,0,0,1-23.26-20.61,2.81,2.81,0,0,0-.17-3,2.67,2.67,0,0,0-2.67-1.19,16,16,0,0,1-11-29.13,2.82,2.82,0,0,0,1.24-2.76,2.75,2.75,0,0,0-1.84-2.35,16,16,0,0,1,3.75-30.95,2.7,2.7,0,0,0,2.33-1.81,2.84,2.84,0,0,0-.56-3,16,16,0,0,1,17.68-25.63,2.62,2.62,0,0,0,2.86-.5,2.82,2.82,0,0,0,.88-2.91,16,16,0,0,1,27.52-14.44,2.67,2.67,0,0,0,2.78.9,2.72,2.72,0,0,0,2.08-2.14,15.93,15.93,0,0,1,31.06,0,2.71,2.71,0,0,0,2.08,2.14,2.64,2.64,0,0,0,2.77-.9,16,16,0,0,1,27.52,14.44,2.82,2.82,0,0,0,.88,2.91,2.62,2.62,0,0,0,2.86.5,16,16,0,0,1,17.67,25.64,2.83,2.83,0,0,0-.56,3,2.7,2.7,0,0,0,2.33,1.81,16,16,0,0,1,3.75,30.94,2.76,2.76,0,0,0-1.84,2.36,2.83,2.83,0,0,0,1.24,2.76,16,16,0,0,1-11,29.13,2.7,2.7,0,0,0-2.66,1.18,2.85,2.85,0,0,0-.17,3.06,16,16,0,0,1-23.28,20.64,2.62,2.62,0,0,0-2.89-.21,2.84,2.84,0,0,0-1.54,2.63,15.74,15.74,0,0,1-12.16,15.75,16.82,16.82,0,0,1-3.94.44"
          transform="translate(-190 -268.75)"
        />
        <path
          className="cls-3"
          d="M505,439.38a39.37,39.37,0,1,0,39.38,39.37A39.37,39.37,0,0,0,505,439.38m0,91.87a52.5,52.5,0,1,1,52.5-52.5,52.49,52.49,0,0,1-52.5,52.5"
          transform="translate(-190 -268.75)"
        />
        <path
          className="cls-3"
          d="M537.81,610a6.5,6.5,0,0,1-3.38-.94L505,591.4l-29.43,17.66a6.57,6.57,0,0,1-9-2.24,6.64,6.64,0,0,1-.9-4.1l6.57-59.07,13,1.45L480.17,591l21.45-12.87a6.56,6.56,0,0,1,6.76,0L529.83,591l-5.1-45.9,13-1.44,6.57,59.06a6.57,6.57,0,0,1-5.78,7.27,6.28,6.28,0,0,1-.75,0"
          transform="translate(-190 -268.75)"
        />
        <rect
          className="cls-3"
          x="59.06"
          y="59.06"
          width="13.13"
          height="13.13"
        />
        <rect
          className="cls-3"
          x="85.31"
          y="59.06"
          width="52.5"
          height="13.13"
        />
        <rect
          className="cls-3"
          x="98.44"
          y="98.44"
          width="223.13"
          height="13.13"
        />
        <rect
          className="cls-3"
          x="111.56"
          y="124.69"
          width="144.38"
          height="13.13"
        />
        <rect
          className="cls-3"
          x="98.44"
          y="150.94"
          width="131.25"
          height="13.13"
        />
        <rect
          className="cls-3"
          x="137.81"
          y="177.19"
          width="91.88"
          height="13.13"
        />
        <path
          className="cls-3"
          d="M253.7,516.2l-9.28-9.28,19.69-19.68a6.55,6.55,0,0,1,9.28,0l8.49,8.48,15-15.05L306.2,490l-19.68,19.69a6.57,6.57,0,0,1-9.28,0l-8.49-8.48Z"
          transform="translate(-190 -268.75)"
        />
      </g>
    </svg>
  );
}

IconCertificate.propTypes = { className: Proptypes.string };

IconCertificate.defaultProps = { className: "" };
