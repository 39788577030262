import React from 'react';

const LogoCelledIcon = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.3635 15C30.3635 18.9782 28.764 22.7936 25.9169 25.6066C23.0698 28.4196 19.2082 30 15.1818 30C11.1553 30 7.29377 28.4196 4.44664 25.6066C1.5995 22.7936 0 18.9782 0 15"
        fill="#EE423B"
      />
      <path
        d="M0 15C0 11.0218 1.5995 7.20644 4.44664 4.3934C7.29377 1.58035 11.1553 0 15.1818 0C19.2082 0 23.0698 1.58035 25.9169 4.3934C28.764 7.20644 30.3635 11.0218 30.3635 15"
        fill="#9BD5C8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06982 5.78067C9.12927 5.62577 9.22016 5.48452 9.3369 5.36561C9.45364 5.2467 9.59377 5.15264 9.74866 5.08921C10.3237 4.81987 10.9506 4.67601 11.5869 4.66742C12.3267 4.61731 13.0688 4.72419 13.7634 4.98088C14.0457 5.07959 14.2984 5.24682 14.4982 5.46721C14.6128 5.60191 14.6741 5.77302 14.6708 5.94892V13.8016H14.5705C14.5635 13.6931 14.5301 13.5879 14.4733 13.4948C14.4165 13.4017 14.3378 13.3236 14.2439 13.2668C14.0261 13.1203 13.7911 13.0003 13.5441 12.9096C12.582 12.5983 11.5509 12.5592 10.5675 12.7966C10.1622 12.872 9.78134 13.0428 9.45707 13.2945C9.37574 13.3539 9.30839 13.4299 9.25966 13.5175C9.21093 13.605 9.18198 13.7019 9.1748 13.8016H9.07216L9.06982 5.78067Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1825 1.91772C19.1825 1.97304 19.1825 2.02605 19.1825 2.07906V9.87408C19.0822 9.87408 18.9889 9.88791 18.8956 9.90174C18.5391 9.96442 18.1974 10.0918 17.8878 10.2774C17.1654 10.6811 16.5255 11.2142 16.0006 11.8493C15.8332 12.0317 15.7068 12.2469 15.6297 12.4809C15.6297 12.5085 15.5783 12.5247 15.5224 12.5662V12.361C15.5224 9.9778 15.5224 7.59612 15.5224 5.21597C15.5168 4.98374 15.5706 4.75386 15.6787 4.54756C16.1551 3.59693 16.9049 2.80637 17.8342 2.27498C18.1401 2.10226 18.4766 1.98883 18.8256 1.94077C18.8446 1.93604 18.8627 1.92825 18.8792 1.91772H19.1825Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0977 13.7507C21.0686 13.7176 21.0436 13.6813 21.023 13.6424C20.9329 13.4058 20.7565 13.211 20.5285 13.0962C20.1711 12.9027 19.7817 12.7739 19.3784 12.7159C18.5585 12.5695 17.7186 12.5695 16.8987 12.7159C16.739 12.751 16.5828 12.8003 16.4321 12.8634C16.3668 12.8887 16.3201 12.9556 16.2618 13.0017C16.2305 13.0216 16.1977 13.0393 16.1638 13.0547L16.1172 13.0178C16.1741 12.8594 16.2427 12.7053 16.3225 12.5568C16.7637 11.8903 17.3764 11.3518 18.0977 10.9964C18.6582 10.6872 19.2946 10.5379 19.936 10.5654H19.9989V5.19281C19.9989 5.02225 20.0199 5.00381 20.1786 5.07065C20.3802 5.15258 20.5752 5.24967 20.7618 5.36106C20.8871 5.45483 21.0021 5.56145 21.1047 5.67913L21.0977 13.7507Z"
        fill="white"
      />
      <path
        d="M10.3179 20.1098V18.1207L10.5511 17.8903H12.5667L12.8 18.1207V20.1098L12.5667 20.3403H10.5395L10.3179 20.1098Z"
        fill="#231F20"
      />
      <path
        d="M13.9968 20.1098V18.1207C14.0691 18.047 14.1438 17.9755 14.2301 17.8903H16.2433L16.4649 18.1207V20.1098L16.2433 20.3403H14.2301C14.1531 20.2573 14.0785 20.1836 13.9968 20.1098Z"
        fill="#231F20"
      />
      <path
        d="M17.5566 20.1098V18.1207L17.7759 17.8903H19.7914L20.0247 18.1207V20.1098L19.7914 20.3403H17.7759L17.5566 20.1098Z"
        fill="#231F20"
      />
      <path
        d="M10.3179 23.6179V21.6288L10.5511 21.3983H12.5667L12.8 21.6288V23.6179C12.7253 23.6916 12.6507 23.7631 12.5667 23.8484H10.5395C10.4672 23.7631 10.3925 23.6916 10.3179 23.6179Z"
        fill="#231F20"
      />
      <path
        d="M13.9968 23.6179V21.6288C14.0691 21.555 14.1438 21.4813 14.2301 21.3983H16.2433L16.4649 21.6288V23.6179L16.2433 23.8484H14.2301C14.1531 23.7631 14.0785 23.6916 13.9968 23.6179Z"
        fill="#231F20"
      />
      <path
        d="M17.5566 23.6179V21.6288L17.7759 21.3983H19.7914L20.0247 21.6288V23.6179L19.7914 23.8484H17.7759L17.5566 23.6179Z"
        fill="#231F20"
      />
      <path
        d="M10.3179 27.1259V25.1345L10.5511 24.9178H12.5667L12.8 25.1345V27.1259C12.7253 27.1997 12.6507 27.2711 12.5667 27.3564H10.5395C10.4672 27.2711 10.3925 27.1973 10.3179 27.1259Z"
        fill="#231F20"
      />
      <path
        d="M13.9968 27.1259V25.1345L14.2301 24.9178H16.2433L16.4649 25.1345V27.1259L16.2433 27.3564H14.2301C14.1531 27.2711 14.0785 27.1973 13.9968 27.1259Z"
        fill="#231F20"
      />
      <path
        d="M17.5566 27.1259V25.1345L17.7759 24.9178H19.7914L20.0247 25.1345V27.1259L19.7914 27.3564H17.7759L17.5566 27.1259Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default LogoCelledIcon;
