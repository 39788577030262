import React from 'react';
import './spinner-celled.scss';

export default function SpinnerCelled(props) {
  return (
    <div style={{ maxWidth: `${props.maxWidth}` }} className="gral-celled-spinner">
      <img src="images/celled_logo_spinner.png" className="base" alt="logo" />
      <div className="dots">
        <div className="dot1 dot-gral" alt="dot1" />
        <div className="dot2 dot-gral" alt="dot2" />
        <div className="dot3 dot-gral" alt="dot3" />
        <div className="dot4 dot-gral" alt="dot4" />
        <div className="dot5 dot-gral" alt="dot5" />
        <div className="dot6 dot-gral" alt="dot6" />
        <div className="dot7 dot-gral" alt="dot7" />
        <div className="dot8 dot-gral" alt="dot8" />
        <div className="dot9 dot-gral" alt="dot9" />
      </div>
    </div>
  );
}
