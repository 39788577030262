import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeLanguage } from '../../actions/i18n';
import Translate from '../../hocs/translate';
import { ENGLISH, ENGLISH_KEY } from '../../constants';
import { getSupportedLanguages } from '../../actions/main_app';
import './language-selector.scss';

class LanguageSelector extends Component {
  constructor() {
    super();
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  componentDidMount() {
    this.props.getSupportedLanguages();
  }

  changeLanguage(e, isButton) {
    const languageValue = e.target[isButton ? 'innerHTML' : 'value'];
    let language = ENGLISH;
    if (isButton) {
      language = languageValue === ENGLISH ? ENGLISH_KEY : Object.keys(this.props.languages)[0];
    } else {
      language = languageValue;
    }

    this.props.changeLanguage(language, this.props.isLogged, this.props.userId);
  }
  render() {
    const languagesAmount = Object.keys(this.props.languages).length + 1; // English is default so adding + 1
    const { currentLanguage } = this.props;
    const displayLanguage =
      currentLanguage === ENGLISH_KEY
        ? Object.values(this.props.languages)[0]?.languageName
        : ENGLISH;

    if (languagesAmount > 2 || this.props.formSelect) {
      return (
        <select
          className="language-selector"
          onChange={this.changeLanguage}
          value={currentLanguage}>
          <option value={ENGLISH_KEY}>{ENGLISH}</option>
          {Object.keys(this.props.languages).map((langKey) => (
            <option className="language-selector__option" key={langKey} value={langKey}>
              {this.props.languages[langKey].languageName}
            </option>
          ))}
        </select>
      );
    } else if (languagesAmount === 2) {
      return (
        <button
          aria-label={`Change language ${displayLanguage}`}
          className="languageButton box-sizing-border-box"
          onClick={(e) => this.changeLanguage(e, true)}>
          {displayLanguage}
        </button>
      );
    } else {
      return <div></div>;
    }
  }
}

LanguageSelector.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  formSelect: PropTypes.bool
};

LanguageSelector.defaultProps = {
  strings: {
    LANGUAGE: 'Language'
  },
  formSelect: false
};

function mapStateToProps(state) {
  return {
    userId: state.mainApp.userId,
    currentLanguage: state.i18n.language,
    languages: state.mainApp.supportedLanguages
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeLanguage, getSupportedLanguages }, dispatch);
}

export default Translate('LanguageSelector')(
  connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
);
