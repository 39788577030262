import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PlayButton from '../audio_player/play_button';
import AudioProgressBar from '../audio_player/audio_progress_bar';
import Translate from '../../hocs/translate';
import IconPrev from '../assets/icon_arrow_prev';
import IconNext from '../assets/icon_arrow_next';
import './player-controls.scss';

export class PlayerControls extends Component {
  render() {
    return (
      <div className="player-controls">
        <div className="player-controls__main">
          <PlayButton
            removeAudioModal={this.props.removeAudioModal}
            playPauseAudio={this.props.playPauseAudio}
            pauseAudioControl={this.props.pauseAudioControl}
            audio={this.props.audio}
          />
          <h2 className="player-controls__title">{this.props.moduleData['sub-title']}</h2>
          <div className="player-controls__lesson-nav">
            <button
              disabled={!this.props.moduleData['lesson-nav-prev'].enabled}
              aria-label={this.props.strings['Repeat previous lesson']}
              className={`${this.props.moduleData['lesson-nav-prev'].enabled
                ? 'player-controls__nav-button'
                : 'player-controls__nav-button player-controls__nav-button--disabled'
                } player-controls__nav-button--prev`}
              onClick={this.props.jumpPrevious}>
              <IconPrev
                className="player-controls__nav-icon"
                fill={this.props.moduleData['lesson-nav-prev'].enabled ? '#333333' : '#CCC'}
              />
              {this.props.strings['Previous Lesson']}
            </button>

            {this.props.moduleData['lesson-nav-next'].enabled && (
              <button
                aria-label={this.props.strings['Skip to next lesson']}
                className="player-controls__nav-button player-controls__nav-button--next"
                onClick={this.props.jumpNext}>
                <IconNext className="player-controls__nav-icon" />
                {this.props.strings['Next Lesson']}
              </button>
            )}
          </div>
        </div>
        <div className="player-controls__sub-controls">
          <AudioProgressBar
            enableUserInput={this.props.enableUserInput}
            updateAudiosMetadata={this.props.updateAudiosMetadata}
            clickAudioProgress={this.props.clickAudioProgress}
            audio={this.props.audio}
          />
        </div>
      </div>
    );
  }
}

PlayerControls.defaultProps = {
  strings: {
    'At any time, you can press the play button to start your course.':
      'At any time, you can press the play button to start your course.',
    'Click here to join the Celle-Ed community and talk with other Cell-Ed learners!.':
      'Click here to join the Celle-Ed community and talk with other Cell-Ed learners!.',
    'Previous Lesson': 'Previous Lesson',
    'Next Lesson': 'Next Lesson',
    'Repeat previous lesson': 'Repeat previous lesson',
    'Skip to next lesson': 'Skip to next lesson'
  }
};

PlayerControls.propTypes = {
  // Audio
  audio: PropTypes.object.isRequired,
  enableUserInput: PropTypes.func.isRequired,
  playPauseAudio: PropTypes.func.isRequired,
  pauseAudioControl: PropTypes.func.isRequired,
  updateAudiosMetadata: PropTypes.func.isRequired,
  clickAudioProgress: PropTypes.func.isRequired,
  // Others
  jumpNext: PropTypes.func.isRequired,
  jumpPrevious: PropTypes.func.isRequired,
  moduleData: PropTypes.object.isRequired
};

export default Translate('PlayerControls')(PlayerControls);
