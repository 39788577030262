import { put, take, call } from 'redux-saga/effects';
import apiClient from '../lib/celled_client';
import { checkCommonErrors } from '../lib/auth';
import { CHANGE_LANGUAGE, ADD_NOTIFICATION } from '../constants';

function * changeUserLanguage (language) {
	if (language.logged) {
		try {
			yield call(apiClient.updateConfig, language.userId, 'language', language.payload)
		} catch (error) {
			let errorPayload = {
				type: ADD_NOTIFICATION,
				payload: {
					message: "Language couldn't be set in server",
					className: 'error',
					type: 'languageChange',
				},
			};
			yield put(checkCommonErrors(error, errorPayload));
		}
	}
}

function * changeUserLanguageWatcher () {
	while (true) {
		const languageData = yield take(CHANGE_LANGUAGE)
		yield call(changeUserLanguage, languageData)
	}
}


export default function * rooti18nSaga () {
	yield [
		changeUserLanguageWatcher(),
	];
};
