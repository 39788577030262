import {
  SWITCH_QUESTIONNAIRE,
  SWITCH_QUESTIONNAIRE_REQUEST,
  SWITCH_QUESTIONNAIRE_FAILED
} from '../constants';
import { reLoadContext } from '../actions/main_course';
import { put, take, call, select } from 'redux-saga/effects';
import client from '../lib/celled_client';
import { learnerFlowInfo } from './learner_flow_info';
import { toggleSwitchModal, toggleQuestionnairesModal } from '../actions/chat';
import { checkCommonErrors } from '../lib/auth';
import { getUserId } from './selectors';

export function* switchQuestionnaire() {
  while (true) {
    yield take(SWITCH_QUESTIONNAIRE_REQUEST);
    try {
      const flowInfo = yield call(learnerFlowInfo);
      if (flowInfo.canSwitch) {
        yield put(toggleQuestionnairesModal());
      } else {
        yield put(toggleSwitchModal());
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      const errorPayload = {
        type: SWITCH_QUESTIONNAIRE_FAILED,
        notification: {
          message: errorMessage,
          className: 'error',
          type: 'switchQuestionnaire',
          unique: true
        }
      };
      yield put(checkCommonErrors(error, errorPayload));
    }
  }
}

export function* switchQuestionnaireFetch() {
  while (true) {
    const action = yield take(SWITCH_QUESTIONNAIRE);
    const userId = yield select(getUserId);
    try {
      yield call(client.switchQuestionnaire, userId, action.payload);
      yield put(reLoadContext(userId));
      window.location.reload();
    } catch (error) {
      const errorMessage = error.response.data.message;
      const errorPayload = {
        type: SWITCH_QUESTIONNAIRE_FAILED,
        notification: {
          message: errorMessage ? errorMessage : 'Questionnaire switch Failed',
          className: 'error',
          type: 'switchQuestionnaire',
          unique: true
        }
      };
      yield put(checkCommonErrors(error, errorPayload));
      yield put(reLoadContext(userId));
      window.location.reload();
    }
  }
}

export default function* rootSwitchQuestionnaireSaga() {
  yield [switchQuestionnaire(), switchQuestionnaireFetch()];
}
