import React from 'react';

const LogoCelledHorizontal = () => {
  return (
    <svg
      width="147"
      height="32"
      viewBox="0 0 147 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5068 16C32.5068 20.2435 30.7944 24.3131 27.7463 27.3137C24.6982 30.3143 20.5641 32 16.2534 32C11.9427 32 7.80862 30.3143 4.76052 27.3137C1.71241 24.3131 0 20.2435 0 16"
        fill="#EE423B"
      />
      <path
        d="M0 16C0 11.7565 1.71241 7.68687 4.76052 4.68629C7.80862 1.68571 11.9427 0 16.2534 0C20.5641 0 24.6982 1.68571 27.7463 4.68629C30.7944 7.68687 32.5068 11.7565 32.5068 16"
        fill="#9BD5C8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.71008 6.16596C9.77373 6.00073 9.87103 5.85007 9.99601 5.72323C10.121 5.59639 10.271 5.49606 10.4368 5.42841C11.0524 5.14111 11.7236 4.98766 12.4048 4.9785C13.1969 4.92504 13.9914 5.03904 14.735 5.31286C15.0372 5.41815 15.3077 5.59652 15.5217 5.8316C15.6443 5.97528 15.7099 6.1578 15.7065 6.34543V14.7216H15.5991C15.5915 14.6059 15.5558 14.4937 15.495 14.3944C15.4341 14.2951 15.3499 14.2117 15.2494 14.1512C15.0162 13.9949 14.7646 13.8669 14.5002 13.7701C13.4702 13.4381 12.3663 13.3964 11.3134 13.6497C10.8796 13.7301 10.4718 13.9122 10.1247 14.1807C10.0376 14.244 9.96549 14.3252 9.91332 14.4185C9.86115 14.5119 9.83016 14.6153 9.82247 14.7216H9.71258L9.71008 6.16596Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5366 2.04547C20.5366 2.10448 20.5366 2.16102 20.5366 2.21757V10.5323C20.4292 10.5323 20.3293 10.547 20.2294 10.5618C19.8477 10.6286 19.4819 10.7645 19.1505 10.9625C18.3772 11.3931 17.692 11.9617 17.1301 12.6392C16.9509 12.8337 16.8156 13.0632 16.733 13.3128C16.733 13.3423 16.678 13.3595 16.6181 13.4038V13.185C16.6181 10.6429 16.6181 8.10243 16.6181 5.5636C16.6122 5.31588 16.6697 5.07068 16.7854 4.85063C17.2955 3.83662 18.0982 2.99336 19.0931 2.42654C19.4206 2.24231 19.7809 2.12132 20.1545 2.07006C20.1749 2.06501 20.1943 2.0567 20.2119 2.04547H20.5366Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5869 14.6675C22.5558 14.6322 22.529 14.5934 22.507 14.5519C22.4105 14.2995 22.2217 14.0917 21.9776 13.9693C21.595 13.7629 21.178 13.6255 20.7463 13.5636C19.8685 13.4075 18.9694 13.4075 18.0915 13.5636C17.9205 13.601 17.7534 13.6537 17.592 13.7209C17.5221 13.748 17.4722 13.8193 17.4097 13.8685C17.3761 13.8897 17.3411 13.9086 17.3048 13.925L17.2549 13.8857C17.3158 13.7167 17.3892 13.5523 17.4747 13.394C17.947 12.683 18.603 12.1086 19.3752 11.7295C19.9752 11.3996 20.6566 11.2405 21.3432 11.2698H21.4106V5.53902C21.4106 5.35709 21.4331 5.33742 21.6029 5.40871C21.8188 5.4961 22.0275 5.59966 22.2273 5.71849C22.3615 5.81851 22.4846 5.93223 22.5944 6.05776L22.5869 14.6675Z"
        fill="white"
      />
      <path
        d="M11.0461 21.4505V19.3288L11.2959 19.083H13.4537L13.7034 19.3288V21.4505L13.4537 21.6964H11.2834L11.0461 21.4505Z"
        fill="#231F20"
      />
      <path
        d="M14.9846 21.4505V19.3288C15.062 19.2502 15.142 19.174 15.2344 19.083H17.3897L17.6269 19.3288V21.4505L17.3897 21.6964H15.2344C15.1519 21.6079 15.072 21.5292 14.9846 21.4505Z"
        fill="#231F20"
      />
      <path
        d="M18.7958 21.4505V19.3288L19.0305 19.083H21.1883L21.4381 19.3288V21.4505L21.1883 21.6964H19.0305L18.7958 21.4505Z"
        fill="#231F20"
      />
      <path
        d="M11.0461 25.1924V23.0707L11.2959 22.8248H13.4537L13.7034 23.0707V25.1924C13.6235 25.271 13.5436 25.3473 13.4537 25.4382H11.2834C11.206 25.3473 11.1261 25.271 11.0461 25.1924Z"
        fill="#231F20"
      />
      <path
        d="M14.9846 25.1924V23.0707C15.062 22.992 15.142 22.9133 15.2344 22.8248H17.3897L17.6269 23.0707V25.1924L17.3897 25.4382H15.2344C15.1519 25.3473 15.072 25.271 14.9846 25.1924Z"
        fill="#231F20"
      />
      <path
        d="M18.7958 25.1924V23.0707L19.0305 22.8248H21.1883L21.4381 23.0707V25.1924L21.1883 25.4382H19.0305L18.7958 25.1924Z"
        fill="#231F20"
      />
      <path
        d="M11.0461 28.9342V26.8101L11.2959 26.579H13.4537L13.7034 26.8101V28.9342C13.6235 29.0129 13.5436 29.0891 13.4537 29.1801H11.2834C11.206 29.0891 11.1261 29.0104 11.0461 28.9342Z"
        fill="#231F20"
      />
      <path
        d="M14.9846 28.9342V26.8101L15.2344 26.579H17.3897L17.6269 26.8101V28.9342L17.3897 29.1801H15.2344C15.1519 29.0891 15.072 29.0104 14.9846 28.9342Z"
        fill="#231F20"
      />
      <path
        d="M18.7958 28.9342V26.8101L19.0305 26.579H21.1883L21.4381 26.8101V28.9342L21.1883 29.1801H19.0305L18.7958 28.9342Z"
        fill="#231F20"
      />
      <path
        d="M45.4686 26.4929C44.1348 25.7823 43.026 24.7232 42.2643 23.4321C41.4783 22.0873 41.0769 20.5581 41.103 19.0068C41.0766 17.4554 41.4779 15.9261 42.2643 14.5814C43.0241 13.2927 44.1337 12.2379 45.4686 11.5353C46.9019 10.7902 48.5032 10.4139 50.1238 10.4413C51.559 10.4166 52.9792 10.732 54.2646 11.3608C55.4537 11.952 56.4358 12.8802 57.0842 14.0258L54.7316 15.5157C54.2375 14.7518 53.5486 14.1288 52.7337 13.7087C51.9148 13.3019 51.0085 13.0953 50.0914 13.1063C49.0396 13.0926 48.0021 13.3467 47.0794 13.8439C46.1938 14.3243 45.4673 15.0455 44.9866 15.9213C44.4717 16.8711 44.2137 17.9354 44.2373 19.0117C44.2111 20.0993 44.4691 21.1752 44.9866 22.1365C45.4673 23.0123 46.1938 23.7334 47.0794 24.2139C48.0021 24.7111 49.0396 24.9652 50.0914 24.9514C51.0094 24.9605 51.9161 24.7505 52.7337 24.3393C53.5491 23.9199 54.2382 23.2967 54.7316 22.5323L57.0842 23.9926C56.4335 25.1402 55.4525 26.0726 54.2646 26.6724C52.982 27.3106 51.5607 27.6313 50.1238 27.6066C48.5009 27.6334 46.898 27.25 45.4686 26.4929V26.4929Z"
        fill="white"
      />
      <path
        d="M76.1874 20.0221H62.334C62.4068 20.7184 62.6196 21.3935 62.96 22.0081C63.3005 22.6227 63.7617 23.1644 64.317 23.6017C65.4442 24.5015 66.8452 24.9514 68.5202 24.9514C70.5598 24.9514 72.2031 24.2745 73.4502 22.9207L75.1584 24.8875C74.3756 25.781 73.3863 26.4766 72.2739 26.9158C70.8813 27.4369 69.3947 27.6706 67.9065 27.6025C66.4183 27.5344 64.9602 27.166 63.6227 26.52C62.2626 25.8302 61.1348 24.767 60.376 23.4591C59.6023 22.1048 59.2076 20.5726 59.2322 19.019C59.2094 17.4798 59.5923 15.9611 60.3435 14.6109C61.0549 13.33 62.1153 12.27 63.4054 11.5501C64.7473 10.8112 66.2635 10.4338 67.8009 10.456C69.3315 10.4247 70.8426 10.7983 72.1765 11.5378C73.4478 12.2605 74.4865 13.3213 75.1734 14.5986C75.911 15.9871 76.2821 17.536 76.2523 19.1026C76.2469 19.41 76.2252 19.7169 76.1874 20.0221V20.0221ZM64.0897 14.3454C63.0853 15.2529 62.4617 16.4972 62.3415 17.834H73.2953C73.1686 16.5032 72.5459 15.2657 71.5471 14.3602C70.5082 13.4927 69.1909 13.0154 67.8286 13.0127C66.4664 13.01 65.1471 13.4821 64.1047 14.3454H64.0897Z"
        fill="white"
      />
      <path d="M80.5378 3.87952H83.6297V27.4124H80.5378V3.87952Z" fill="white" />
      <path d="M89.5262 3.87952H92.6181V27.4124H89.5262V3.87952Z" fill="white" />
      <path d="M97.4181 17.3891H106.084V20.0221H97.4181V17.3891Z" fill="white" />
      <path
        d="M126.543 20.0221H112.69C112.762 20.7183 112.975 21.3933 113.315 22.0079C113.655 22.6225 114.116 23.1643 114.67 23.6017C115.799 24.5015 117.201 24.9514 118.876 24.9514C120.914 24.9514 122.557 24.2745 123.804 22.9207L125.512 24.8875C124.727 25.7769 123.737 26.4681 122.625 26.9035C121.233 27.4245 119.747 27.6582 118.259 27.5901C116.771 27.522 115.313 27.1536 113.976 26.5077C112.616 25.8175 111.489 24.7544 110.73 23.4468C109.956 22.0925 109.561 20.5603 109.586 19.0068C109.564 17.4677 109.947 15.9492 110.697 14.5986C111.408 13.3177 112.469 12.2577 113.759 11.5378C115.102 10.7985 116.619 10.4211 118.157 10.4438C119.685 10.4185 121.192 10.7962 122.522 11.5378C123.794 12.2609 124.832 13.3216 125.519 14.5986C126.265 15.9851 126.644 17.5341 126.621 19.1026C126.611 19.4102 126.585 19.7172 126.543 20.0221V20.0221ZM114.446 14.3454C113.44 15.2516 112.816 16.4966 112.697 17.834H123.651C123.525 16.5032 122.902 15.2657 121.903 14.3602C120.864 13.4927 119.547 13.0154 118.185 13.0127C116.822 13.01 115.503 13.4821 114.461 14.3454H114.446Z"
        fill="white"
      />
      <path
        d="M147 3.87952V27.4124H144.035V24.7474C143.367 25.6645 142.468 26.3962 141.428 26.8715C140.338 27.3689 139.148 27.6209 137.947 27.6091C136.395 27.6338 134.864 27.2628 133.501 26.5322C132.207 25.8273 131.141 24.7775 130.424 23.5034C129.665 22.127 129.282 20.58 129.313 19.0141C129.281 17.4535 129.664 15.9118 130.424 14.5421C131.144 13.275 132.21 12.2317 133.501 11.5304C134.864 10.799 136.395 10.4272 137.947 10.4511C139.111 10.4392 140.265 10.6722 141.331 11.1346C142.35 11.5795 143.237 12.2725 143.908 13.1481V3.87952H147ZM141.136 24.1917C142.012 23.7015 142.727 22.9758 143.199 22.0996C143.709 21.1472 143.967 20.0833 143.948 19.0067C143.967 17.9309 143.709 16.8678 143.199 15.9164C142.729 15.0423 142.013 14.3208 141.136 13.8389C140.233 13.3597 139.223 13.1089 138.196 13.1089C137.17 13.1089 136.16 13.3597 135.257 13.8389C134.386 14.3272 133.671 15.0471 133.197 15.9164C132.681 16.8658 132.422 17.9303 132.447 19.0067C132.422 20.084 132.681 21.1493 133.197 22.0996C133.674 22.971 134.387 23.6952 135.257 24.1917C136.157 24.6821 137.168 24.9393 138.196 24.9393C139.225 24.9393 140.236 24.6821 141.136 24.1917V24.1917Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoCelledHorizontal;
