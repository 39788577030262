import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  loadCoursesList,
  toggleCourseAttendance,
  toggleCertificate
} from '../../actions/courses_list';
import { switchCourse } from '../../actions/course_details';
import Translate from '../../hocs/translate';
import { hashHistory } from 'react-router';
import { loadContext } from '../../actions/main_course';
import _ from 'lodash';
import CoursesElements from '../../components/courses_list/list_elements';
import { toggleCourseDetails } from '../../actions/main_course';
import RecursiveOptionsList from '../../components/courses_list/options_list';
import './course-list.scss';

export class CoursesList extends Component {
  constructor(props) {
    super(props);
    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.renderRecursiveOptionsList = this.renderRecursiveOptionsList.bind(this);
    this.state = {
      selectedCourse: null,
      confirmation: false,
      nextAction: '',
      showCourses: false,
      courses: null,
      loadingList: true
    };
  }

  confirm() {
    this.props.switchCourse(this.state.selectedCourse.code);
    this.setState({
      selectedCourse: null,
      confirmation: false,
      nextAction: ''
    });
  }

  cancel() {
    this.setState({
      selectedCourse: null,
      confirmation: false,
      nextAction: ''
    });
  }

  componentDidMount() {
    if (this.props.loadCourses) {
      this.props.loadCoursesList(this.props.userId);
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.props.coursesList.courseSwitched) {
      hashHistory.push('/active_course');
    }
  }

  renderRecursiveOptionsList() {
    return (
      <div>
        <RecursiveOptionsList
          pathLink="/subtopics"
          options={_.filter(this.props.coursesList.courses, (el) => el.type === 'program')}
        />
        {
          <CoursesElements
            toggleCourseAttendance={this.props.toggleCourseAttendance}
            toggleCertificate={this.props.toggleCertificate}
            toggleCourseDetails={this.props.toggleCourseDetails}
            courses={_.filter(this.props.coursesList.courses, (el) => el.type === 'course')}
            switchCourse={this.props.switchCourse}
            useRTL={this.props.useRTL}
          />
        }
      </div>
    );
  }

  render() {
    return <div>{!this.props.coursesList.loadingList && this.renderRecursiveOptionsList()}</div>;
  }
}

CoursesList.defaultProps = {
  confirmation: false,
  selectedCourse: null,
  strings: {
    'Switch Course Continue': 'Are you sure you want to continue with:',
    'Switch Course Start': 'Are you sure you want to start:',
    'Switch Course Redo': 'Are you sure you want to redo:',
    YES: 'YES',
    NO: 'NO'
  }
};

function mapStateToProps(state) {
  return {
    coursesList: state.coursesList,
    userId: state.mainApp.userId,
    coursesGroups: state.coursesList.coursesGroups,
    useRTL: state.preferences.preferencesData.useRTL
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCoursesList,
      switchCourse,
      toggleCourseAttendance,
      toggleCertificate,
      toggleCourseDetails,
      loadContext
    },
    dispatch
  );
}

export default Translate('CoursesList')(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(CoursesList)
);

CoursesList.propTypes = {
  loadCourses: PropTypes.bool.isRequired
};
