import {
  LOGIN_SUCCESSFUL,
  DEMO_REQUEST_SUCCEEDED,
  GET_USER_ID,
  USER_IS_LOGGED,
  STOP_SHOWING_WALK_THROUGH,
  SIGNUP_CONFIRMATION_SUCCESSFUL,
  WALK_THROUGH_FINISHED,
  INTRO_PASSED,
  GET_DEVICE_INFO,
  GET_VERSION_TAG_SUCCESS,
  GET_SUPPORTED_LANGUAGES_SUCCESS,
  LANGUAGES,
  ENGLISH_KEY
} from '../constants';

const initialState = {
  userId: '',
  userIsLogged: false,
  firstTime: false,
  sessionTimedOut: false,
  sessionExpired: false,
  token: null,
  infoDevice: {},
  versionTag: '',
  supportedLanguages: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        userId: action.userId,
        userIsLogged: true,
        firstTime: action.firstTime,
        token: action.token
      };
    case DEMO_REQUEST_SUCCEEDED:
      return {
        ...state,
        userId: action.userId,
        userIsLogged: true,
        firstTime: action.firstTime,
        token: action.token
      };
    case SIGNUP_CONFIRMATION_SUCCESSFUL:
      return {
        ...state,
        userId: action.userId,
        userIsLogged: true,
        firstTime: action.firstTime,
        token: action.token
      };
    case GET_USER_ID:
      return { ...state, userId: action.payload };
    case USER_IS_LOGGED:
      return { ...state, userIsLogged: true };
    case STOP_SHOWING_WALK_THROUGH:
      return { ...state, firstTime: false };
    case WALK_THROUGH_FINISHED:
      return { ...state, firstTime: false };
    case INTRO_PASSED:
      return { ...state, introPassed: true };
    case GET_DEVICE_INFO:
      return { ...state, infoDevice: action.payload };
    case GET_VERSION_TAG_SUCCESS:
      return { ...state, versionTag: action.payload };
    case GET_SUPPORTED_LANGUAGES_SUCCESS:
      return { ...state, supportedLanguages: filterLanguages(action.payload) };
    default:
      return state;
  }
}

const filterLanguages = (langData) => {
  const languages = {};
  for (let key of Object.keys(LANGUAGES)) {
    if (langData.some((el) => el === key)) {
      if (key !== ENGLISH_KEY) {
        languages[key] = LANGUAGES[key];
      }
    }
  }
  return languages;
};
