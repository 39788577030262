import React, { Component } from 'react';
import Translate from '../../hocs/translate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { focusNextInput } from '../../utils/inputs';
import { validPassword } from '../../utils/validations';
import { backToEnterPhone } from '../../actions/simple_login';
import FormPassword from '../../components/forms/form_password';
import BackButton from '../../components/buttons/back_button';

class CreatePasswordComponent extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      confirmPassword: '',
      receiveSmsReminders: true,
      passwordError: false
    };
    this.setPassword = this.setPassword.bind(this);
    this.setConfirmPassword = this.setConfirmPassword.bind(this);
    this.loginExistingLearner = this.loginExistingLearner.bind(this);
    this.renderHint = this.renderHint.bind(this);
    this.smsReminderHandler = this.smsReminderHandler.bind(this);
    this.handleUpdateError = this.handleUpdateError.bind(this);
  }

  componentDidMount() {
    // Do not autofocus on KaiOS - WEBAPP-1959
    if (/KAIOS/.test(navigator.userAgent) === false) {
      var input = document.getElementsByClassName('form-input');
      input[0].focus();
    }
    document.title = 'Cell-Ed Sign In • Essential Skills on the Go';
  }

  setPassword(value) {
    this.setState({ ...this.state, password: value }, () => {
      if (this.state.passwordError) {
        // if in state of error, validate password updates and remove error message once user satisfies all password criteria
        if (validPassword(this.state.password, this.state.confirmPassword)) {
          this.setState({ ...this.state, pincodeError: true });
        }
      }
    });
  }

  setConfirmPassword(value) {
    this.setState({ ...this.state, confirmPassword: value }, () => {
      if (this.state.passwordError) {
        // if in state of error, validate password updates and remove error message once user satisfies all password criteria
        if (validPassword(this.state.password, this.state.confirmPassword)) {
          this.setState({ ...this.state, passwordError: false });
        }
      }
    });
  }

  handleUpdateError(payload) {
    this.setState({ ...this.state, passwordError: payload });
  }

  smsReminderHandler() {
    let toggle = !this.state.receiveSmsReminders;
    this.setState({ ...this.state, receiveSmsReminders: toggle });
  }

  loginExistingLearner(e) {
    e.preventDefault();
    if (validPassword(this.state.password, this.state.confirmPassword)) {
      var { password, receiveSmsReminders } = this.state;
      var credentials = { password, receiveSmsReminders };
      this.props.loginExistingLearner(credentials);
    } else {
      this.setState({ ...this.state, passwordError: true });
    }
  }

  renderSmsReminder() {
    return (
      <div className="signup-check-box">
        <label className="signup-check-box__label container">
          <input
            id="allowSms"
            className="signup-check-box__input"
            type="checkbox"
            onChange={this.smsReminderHandler}
            checked={this.state.receiveSmsReminders}
          />
          <span className="signup-check-box__checkmark checkmark" />
          {this.props.strings['Allow my coach to send me messages']}
        </label>
      </div>
    );
  }

  render() {
    return (
      <div className="app-full-h">
        <form className="onboarding-form" onSubmit={this.loginExistingLearner}>
          <div className="login-page-header">
            <BackButton onClick={this.props.backToEnterPhone} />
          </div>
          <h2 className="form-title">{this.props.strings['Create new password']}</h2>

          <div className="form-subtitle">
            <span>{this.props.phoneNumber}</span>
          </div>

          <FormPassword
            focusNextInput={focusNextInput}
            setPassword={this.setPassword}
            setConfirmPassword={this.setConfirmPassword}
            passwordError={this.state.passwordError}
            handleUpdateError={this.handleUpdateError}
            password={this.state.password}
            confirmPassword={this.state.confirmPassword}
          />

          {this.renderSmsReminder()}

          {this.props.waitingResponse && (
            <p style={{ textAlign: 'center' }}>{this.props.strings['Requesting access']}</p>
          )}

          <div className="form-gap"></div>
          <div className="onboarding-form__footer">
            <div className="form-button-view">
              <button className="button form-button form-item box-sizing-border-box" type="submit">
                {this.props.strings['Continue']}
              </button>
            </div>
            <div className="form-item">
              <p className="form-terms">
                {this.props.strings['By clicking Continue, you agree to our ']}
                <a
                  href="https://www.cell-ed.com/terms-of-service"
                  target="_blank"
                  className="no-line-break"
                  rel="noreferrer">
                  {this.props.strings['Terms of Service']}
                </a>
              </p>
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderHint(hint, show) {
    if (show) {
      return (
        <small id="passwordHint" className="signup-hint form-item-left">
          {this.props.strings['PasswordHint']}
        </small>
      );
    }
  }
}

CreatePasswordComponent.defaultProps = {
  strings: {
    'Create new password': 'Create new password',
    Password: 'Create New Password',
    Continue: 'Continue',
    'Allow my coach to send me messages': 'Allow my coach to send me messages',
    'By clicking Continue, you agree to our ': 'By clicking Continue, you agree to our ',
    'Terms of Service': 'Terms of Service',
    PasswordHint: 'Password should be 6 characters or more.'
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ backToEnterPhone }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(Translate('CreatePasswordComponent')(CreatePasswordComponent));
