import axios from 'axios';
import config from '../config.json';
import { store } from '../../store';
import { RECONNECT_ACTIVATE } from '../constants';
import { checkCommonErrors } from '../lib/auth';
import _ from 'lodash';

import { getAppDeviceInfo, loadAppDeviceInfo } from './app_device_info_manager';
import platform from 'platform';

const API_BASE_PATH = config.celledApi.baseUrl;
const API_GATEWAY_BASE_PATH = config.gatewayApi.baseUrl;
function getUserToken() {
  return JSON.parse(window.localStorage.getItem('celledCredentials')).celledAuthToken;
}

function getDeviceInfo() {
  const deviceInfoCached = getAppDeviceInfo();
  if (!_.isEmpty(deviceInfoCached)) {
    return JSON.stringify(deviceInfoCached);
  } else {
    loadAppDeviceInfo(); // for next request
  }

  return JSON.stringify({
    id: 'no_id',
    platform: 'web', // web features are global; use as basis
    model: platform.product,
    manufacturer: platform.manufacturer,
    os: platform.os.toString(),
    osVersion: platform.os.toString(),
    appVersion: '',
    appBuild: ''
  });
}

const DEFAULT_REQUEST_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
    'device-info': getDeviceInfo()
  }
};

function getHeadersWithToken() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getUserToken()}`,
      'device-info': getDeviceInfo()
    }
  };
}
export const getRequestHeadersWithToken = getHeadersWithToken;

// create axios instance where when a request fails, an "RECONNECT_ACTIVATE" action will be dispatched
// together with the request payload

function handleErrorsInterceptor(err) {
  if (err.status === 401 && err.data.type === 'LOGOUT')
    return store.dispatch(checkCommonErrors(err, { message: err.message }));
  if (err.message === 'Network Error') {
    store.dispatch({ type: RECONNECT_ACTIVATE, payload: err.config });
  }
  throw err;
}

export const axiosRetryOn = axios.create();

axiosRetryOn.interceptors.response.use(undefined, handleErrorsInterceptor);

const client = {
  checkOnboardingStatus(requestData) {
    const url = `${API_BASE_PATH}/obstatus/${requestData.phoneNumber}`;
    return axios.get(url);
  },
  signupRequest(credentials) {
    const url = `${API_BASE_PATH}/signup`;
    const data = {
      phoneNumber: credentials.phoneNumber,
      password: credentials.password,
      pincode: credentials.pincode,
      receiveSmsReminders: credentials.receiveSmsReminders,
      language: credentials.language
    };

    return axios.post(url, JSON.stringify(data), DEFAULT_REQUEST_CONFIG);
  },
  signupExistingLearnerRequest(credentials) {
    const url = `${API_BASE_PATH}/signupPhoneLearner`;
    const data = {
      phoneNumber: credentials.phoneNumber,
      password: credentials.password,
      receiveSmsReminders: credentials.receiveSmsReminders
    };
    return axios.post(url, JSON.stringify(data), DEFAULT_REQUEST_CONFIG);
  },
  demoRequest(demoInfo) {
    const url = `${API_BASE_PATH}/startDemo`;
    const data = {
      pincode: demoInfo.pincode,
      enableSurvey: demoInfo.enableSurvey,
      enableAssessment: demoInfo.enableAssessment
    };

    return axios.post(url, JSON.stringify(data), DEFAULT_REQUEST_CONFIG);
  },

  logout(userId) {
    const url = `${API_BASE_PATH}/learner/${userId}/logout`;

    return axios.post(url, null, getHeadersWithToken());
  },

  signupConfirmation(confirmationData) {
    const url = `${API_BASE_PATH}/confirm`;
    const data = {
      phoneNumber: confirmationData.phoneNumber,
      confirmationCode: confirmationData.confirmationCode,
      language: confirmationData.language
    };

    return axios.post(url, JSON.stringify(data), DEFAULT_REQUEST_CONFIG);
  },

  getSignupPublicAccountDetails(pincode) {
    const url = `${API_BASE_PATH}/account/public?pincode=${pincode}`;
    return axios.get(url, DEFAULT_REQUEST_CONFIG);
  },

  resendConfirmationCodes(phoneNumber) {
    const url = `${API_BASE_PATH}/resendConfirmationCode`;

    const data = { phoneNumber };

    return axios.post(url, data, DEFAULT_REQUEST_CONFIG);
  },

  loginRequest(credentials) {
    const url = `${API_BASE_PATH}/signin`;
    const data = {
      phoneNumber: credentials.phoneNumber,
      password: credentials.password
    };

    return axios.post(url, JSON.stringify(data), DEFAULT_REQUEST_CONFIG);
  },

  easyLoginRequest({ phoneNumber, receiveSms, language }) {
    const url = `${API_BASE_PATH}/easy-signup`;
    const data = { phoneNumber, receiveSms, language };
    return axios.post(url, JSON.stringify(data), DEFAULT_REQUEST_CONFIG);
  },

  // In case the user has passed the actual module, call the server to load a new one

  loadModule(userId, url, method) {
    // const url = `${API_BASE_PATH}/learner/next-showcase`;
    if (!url) {
      url = '/next';
    }
    const effectiveUrl = `${API_BASE_PATH}/learner${url}`;
    const data = { From: userId };

    return axios.post(effectiveUrl, data, getHeadersWithToken());
  },

  loadContext(userId, context = {}) {
    const url = `${API_BASE_PATH}/learner/${userId}/initial-context`;
    const data = { From: userId, ...context };

    return axios.post(url, data, getHeadersWithToken());
  },

  loadHistory(urlPath, pageNumber) {
    const url = `${API_BASE_PATH}${urlPath}?pageNumber=${pageNumber}`;

    return axios.get(url, getHeadersWithToken());
  },

  // Validate user's answer against the server and wait for new actions

  validateAnswer(userId, answer, validationUrl) {
    const url = `${API_BASE_PATH}${validationUrl}`;

    const data = { From: userId, Body: { payload: answer } };

    return axiosRetryOn.post(url, data, getHeadersWithToken());
  },

  jump(userId, jumpURL) {
    const url = `${API_BASE_PATH}/learner/${jumpURL}`;

    const data = { From: userId, Body: {} };

    return axiosRetryOn.post(url, data, getHeadersWithToken());
  },

  uploadFile(data) {
    const url = `${API_BASE_PATH}/fileUpload`;
    return axios.post(url, data);
  },

  loadCoursesList(userId) {
    const url = `${API_BASE_PATH}/learner/${userId}/courses`;

    return axios.get(url, getHeadersWithToken());
  },

  stopShowingWalkThrough(userId) {
    const url = `${API_BASE_PATH}/learner/${userId}/update-status `;

    return axios.post(url, null, getHeadersWithToken());
  },

  sendEmail(userId, message) {
    const url = `${API_BASE_PATH}/learner/${userId}/help `;
    const data = {
      msgPayload: message
    };

    return axios.post(url, data, getHeadersWithToken());
  },

  loadCourseDetails(userId, courseId) {
    const url = `${API_BASE_PATH}/learner/${userId}/course/${courseId} `;

    return axios.get(url, getHeadersWithToken());
  },

  loadUserPreferences(userId) {
    const url = `${API_BASE_PATH}/learner/${userId}/profile `;

    return axios.get(url, getHeadersWithToken());
  },

  getLearnerFlowInfo(userId) {
    const url = `${API_BASE_PATH}/learner/${userId}/getLearnerFlowInfo `;

    return axios.get(url, getHeadersWithToken());
  },

  loadHelpData(userId) {
    const url = `${API_BASE_PATH}/learner/${userId}/helpData `;

    return axios.get(url, getHeadersWithToken());
  },

  getCoachInfo(userId) {
    const url = `${API_BASE_PATH}/learner/${userId}/get-coach-info `;

    return axios.get(url, getHeadersWithToken());
  },

  updateConfig(userId, option, value) {
    const url = `${API_BASE_PATH}/learner/${userId}/profile `;
    let data = {};
    data[option] = value;

    return axios.post(url, data, getHeadersWithToken());
  },

  updatePushToken(userId, fcmToken) {
    const url = `${API_BASE_PATH}/learner/${userId}/push/register `;
    const data = { fcmToken: fcmToken };
    return axios.post(url, data, getHeadersWithToken());
  },

  forgotPasswordRequest(data) {
    const url = `${API_BASE_PATH}/forgotPassword`;
    return axios.post(url, { phoneNumber: data });
  },

  resendPasswordConfirmationCode(data) {
    const url = `${API_BASE_PATH}/resendResetConfirmationCode`;
    return axios.post(url, { phoneNumber: data });
  },

  confirmPasswordCode(confirmationData) {
    const url = `${API_BASE_PATH}/resetPassword/confirmCode`;
    return axios.post(url, {
      confirmationCode: confirmationData.confirmationCode,
      phoneNumber: confirmationData.phoneNumber
    });
  },

  resetPassword(data) {
    const url = `${API_BASE_PATH}/resetPassword/reset`;
    const params = {
      phoneNumber: data.phoneNumber,
      confirmationCode: data.confirmationCode,
      password: data.newPassword
    };
    return axios.post(url, JSON.stringify(params), DEFAULT_REQUEST_CONFIG);
  },

  callRedirect(redirect) {
    const completeUrl = API_BASE_PATH + redirect.url;
    return axios({
      method: redirect.method,
      url: completeUrl,
      data: redirect.data,
      headers: getHeadersWithToken().headers
    });
  },

  switchCourse(learnerId, courseCode) {
    const url = `${API_BASE_PATH}/learner/${learnerId}/course/${courseCode}/switch`;
    let data = {};
    return axios.post(url, data, getHeadersWithToken());
  },

  findIp() {
    return axios.get(`${API_BASE_PATH}/findIp`);
  },
  getSupportedLanguages() {
    return axios.get(`${API_BASE_PATH}/getLanguages`);
  },

  fetchCertificateThumbnail(courseAttendanceId) {
    return axios.get(`${API_BASE_PATH}/certificates/getThumbnail/${courseAttendanceId}`, {
      responseType: 'blob'
    });
  },

  fetchCertificate(courseAttendanceId) {
    return axios.get(`${API_BASE_PATH}/certificates/getCertificate/${courseAttendanceId}`, {
      responseType: 'blob'
    });
  },

  getCAInfoForCertificateName(courseAttendanceId) {
    return axios.get(
      `${API_BASE_PATH}/certificates/getCAInfoForCertificateName/${courseAttendanceId}`
    );
  },

  getQuestionnairesOnApp(learnerId) {
    return axios.get(
      `${API_BASE_PATH}/learner/${learnerId}/getAppQuestionnaires`,
      getHeadersWithToken()
    );
  },
  switchQuestionnaire(learnerId, questionnaireId) {
    const url = `${API_BASE_PATH}/learner/${learnerId}/questionnaire/assessment:${questionnaireId}/switch`;
    return axios.post(url, {}, getHeadersWithToken());
  },
  getAchievements(learnerId) {
    return axios.post(
      `${API_GATEWAY_BASE_PATH}/function/services-learner-achievements`,
      { learnerId },
      getHeadersWithToken()
    );
  },
  uploadActivity(payload) {
    console.log(`[celled_client] uploadActivity: ${JSON.stringify(payload)}`)
    const url = `${API_GATEWAY_BASE_PATH}/function/services-activity-upload`;
    console.log(`[celled_client] uploadActivity: ${url}`)
    return axios.post(url, payload, getHeadersWithToken());
  },
  getVersionTag() {
    return axios.get('/versiondescriptor.json');
  },
  verifyPinCode(payload) {
    const url = `${API_BASE_PATH}/signup/verify-pin-code`;
    return axios.post(url, payload, DEFAULT_REQUEST_CONFIG);
  }
};

export default client;
