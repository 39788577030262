import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './toggle-container.scss';
import IconChevron from '../assets/icon_chevron';

export default class ToggleContainer extends Component {
  constructor() {
    super();

    // this.renderContent = this.renderContent.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  // renderContent() {
  //   if (this.props.show) {
  //     return <div className="toggle-container-container">{this.props.children}</div>;
  //   }
  //   return null;
  // }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      return this.props.toggle();
    }
  }

  render() {
    const { title, show } = this.props;
    const statusAction = show ? 'hide' : 'view';
    return (
      <div className="toggle-container">
        <button
          className="toggle-container__toggle"
          onClick={this.props.toggle}
          aria-label={`${statusAction} content for ${title}`}>
          <span className="toggle-container__label">{this.props.title}</span>
          <IconChevron direction={show ? 'up' : 'down'} />
        </button>

        <div
          className={`toggle-container__content ${
            this.props.show ? 'toggle-container__content--show' : ''
          }`}>
          {this.props.show && this.props.children}
        </div>
      </div>
    );
  }
}

ToggleContainer.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func
};
