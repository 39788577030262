//
//  activity_tracking_component.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ActivityTrackingManager from '../../lib/activity_tracking';
import SpecialFeature from '../testing/special_feature';

/**
 * ActivityTrackingComponent;
 * Links redux store + webapp together with AT.
 */
class ActivityTrackingComponent extends Component {

	componentDidUpdate(prevProps) {
		if (this.props.enableActivityTracking !== prevProps.enableActivityTracking) {
			console.log(`[ActivityTrackingManager] componentDidUpdate - enableActivityTracking: ${this.props.enableActivityTracking}`);
			this.loadIfNeeded();
		} 
		
		if (this.props.learnerShouldPromptForActivityPermissions && this.props.learnerShouldPromptForActivityPermissions !== prevProps.learnerShouldPromptForActivityPermissions) {
			console.log(`[ActivityTrackingManager] componentDidUpdate - learnerShouldPromptForActivityPermissions: ${this.props.learnerShouldPromptForActivityPermissions}`);
			this.load();
		}
	}

	loadIfNeeded () {
		if (this.props.enableActivityTracking === true) {
			this.load();
		} else if (this.props.enableActivityTracking === false) {
			const activityManager = new ActivityTrackingManager();
			return activityManager.stop();
		}
	}

	load() {
		if (!this.props.userId) return;

		const userId = this.props.userId;
		const activityManager = new ActivityTrackingManager();
		return activityManager.getPermissions().then(function(result) {
			if (result === true) {
				return activityManager.start(userId);
			}

			return true;
		});
	}

	render () {
		// using composition instead of inheritance, but achieving the same effect --
		// the SpecialFeature class takes care of loading preferences that may be
		// considered 'special features,' branding is one of them.
		return <SpecialFeature/>;
	}
}

function mapStateToProps (state) {
	return { userId: state.mainApp.userId, enableActivityTracking: state.preferences.preferencesData.enableActivityTracking, learnerShouldPromptForActivityPermissions: state.questionnaires.learnerShouldPromptForActivityPermissions }
}

export default connect(mapStateToProps, null)(ActivityTrackingComponent);
