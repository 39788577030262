import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Translate from '../../hocs/translate';
import './signup-confirmation-form.scss';

const SignupConfirmationForm = ({ submitConfirmationDigits, strings }) => {
  const [inputs, setInputs] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: ''
  });

  let inputRefs = useRef({});

  useEffect(() => {
    if (inputRefs?.current[0]) inputRefs.current[0].focus();
  }, []);

  const inputsHandler = (index, e) => {
    const change = {};

    if (e.target.value.length > 1) {
      [...e.target.value].forEach((digit, mapIndex) => {
        const calcIndex = index + mapIndex;
        if (calcIndex <= 5) {
          change[`input${calcIndex}`] = digit;
          // focus on last input of pasted values
          inputRefs.current[calcIndex - 1].focus();
        }
      });
    } else {
      change[`input${index}`] = e.target.value;
      if (e.target.value.length === 1) focusNextInput(e);
    }

    setInputs({ ...inputs, ...change });
  };

  const selectAll = (e) => {
    e.target.select();
  };

  const focusNextInput = (e) => {
    const nextSibling = e.target.nextElementSibling;
    if (nextSibling?.tagName.toLowerCase() === 'input') {
      nextSibling.focus();
    }
  };

  const focusPreviousInput = (e) => {
    const previousSibling = e.target.previousElementSibling;
    if (previousSibling?.tagName.toLowerCase() === 'input') {
      previousSibling.focus();
    }
  };

  const handleSubmitConfirmationDigits = (e) => {
    e.preventDefault();
    let confirmationCode = '';
    for (let index = 1; index <= 5; index++) {
      confirmationCode += inputs[`input${index}`];
    }
    const confirmationData = {
      confirmationCode
    };
    submitConfirmationDigits(confirmationData);
  };

  const isValid = () => {
    for (let index = 1; index <= 5; index++) {
      if (inputs[`input${index}`] === '') {
        return false;
      }
    }
    return true;
  };

  const handleOnKeyDown = (e, key) => {
    // delete key pressed
    if (e.keyCode === 8) {
      e.preventDefault();

      // clear input
      setInputs({ ...inputs, [key]: '' });
      focusPreviousInput(e);
    }
  };

  const renderInputs = () => {
    return Object.keys(inputs).map((key, index) => {
      return (
        <input
          key={key}
          ref={(ref) => (inputRefs.current[index] = ref)}
          value={inputs[key]}
          min="0"
          max="9"
          maxLength={1}
          type="number"
          onChange={(e) => inputsHandler(index + 1, e)}
          onFocus={selectAll}
          className="signup-confirmation-form__confirmation-input"
          aria-label="PIN Code Input"
          onKeyDown={(e) => handleOnKeyDown(e, key)}
        />
      );
    });
  };

  return (
    <form className="signup-confirmation-form" onSubmit={handleSubmitConfirmationDigits}>
      <div className="signup-confirmation-form__inputs">{renderInputs()}</div>
      <div className="form-gap" />
      <button
        type="submit"
        className="signup-confirmation-form__button"
        aria-label="Submit Confirmation"
        disabled={!isValid()}>
        {strings.Verify}
      </button>
    </form>
  );
};

SignupConfirmationForm.propTypes = {
  submitConfirmationDigits: PropTypes.func.isRequired
};

SignupConfirmationForm.defaultProps = {
  strings: {
    Verify: 'Verify'
  }
};

export default Translate('SignupConfirmationForm')(SignupConfirmationForm);
