import { SHOW_CERTIFICATE_INIT, GET_QUESTIONNAIRES_ON_APP, TOGGLE_SWITCH_MODAL, TOGGLE_SWITCH_QUESTIONNAIRE_MODAL, FETCH_CERTIFICATE_INIT, DOWNLOAD_CERTIFICATE_INIT, DOWNLOADING_CERTIFICATE_FAIL, GET_CERTIFICATE_THUMBNAIL } from '../constants';

// change state in 'me' and 'courses_list' reducers to show
// certificate corresponding to current course

export function showCertificate(courseId) {
	return {
		type: SHOW_CERTIFICATE_INIT,
		payload: courseId,
	}
}

export function getQuestionnairesOnApp() {
	return {
		type: GET_QUESTIONNAIRES_ON_APP
	}
}

export function toggleQuestionnairesModal() {
	return {
		type: TOGGLE_SWITCH_QUESTIONNAIRE_MODAL
	}
}

export function toggleSwitchModal() {
	return {
		type: TOGGLE_SWITCH_MODAL
	}
}

export function fetchCertificate(courseAttendanceId, maxRetries = 0) {
	return {
		type: FETCH_CERTIFICATE_INIT,
		payload: {
			courseAttendanceId,
			maxRetries
		}
	}
}

export function getCertificateThumbnail(courseAttendanceId) {
	return {
		type: GET_CERTIFICATE_THUMBNAIL,
		payload: {
			courseAttendanceId
		}
	}
}

export function downloadCertificate(courseAttendanceId) {
	return {
		type: DOWNLOAD_CERTIFICATE_INIT,
		payload: courseAttendanceId
	}
}

export function downloadCertificateFail(isError) {
	return {
		type: DOWNLOADING_CERTIFICATE_FAIL,
		payload: { downloadingCertificateFail: isError }
	}
}