import {
  SUBMIT_ANSWER,
  ADD_MESSAGE,
  ADD_HEADER_MESSAGE,
  CLEAR_MESSAGES,
  LOAD_HISTORY,
  PROCESS_ACTIONS,
  FETCH_CERTIFICATE,
  FETCH_CERTIFICATE_FAIL,
  DOWNLOADING_CERTIFICATE,
  DOWNLOADING_CERTIFICATE_FAIL,
  GET_CERTIFICATE_THUMBNAIL_SUCCESS,
  GET_CERTIFICATE_THUMBNAIL_FAIL,
  SHOW_FLOAT_BUBBLE,
  GET_QUESTIONNAIRES_ON_APP_SUCCESS,
  TOGGLE_SWITCH_MODAL,
  TOGGLE_SWITCH_QUESTIONNAIRE_MODAL
} from '../constants';

const initialState = {
  messages: [],
  loadingValidation: false,
  pageNumber: 0,
  url: '',
  scrollTop: false,
  scrollBottom: true,
  isFloatBubbleOpen: false,
  showQuestionnaireModal: false,
  showSwitchModal: false,
  certificatesThumbnail: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_HISTORY:
      return {
        ...state,
        messages: [...toArray(action.payload), ...state.messages],
        url: action.url,
        pageNumber: action.pageNumber + 1,
        scrollTop: action.scrollTop,
        scrollBottom: action.scrollBottom
      };
    case SUBMIT_ANSWER:
      return {
        ...state,
        messages: [...state.messages, ...formatNewMessage(action.payload)],
        loadingValidation: true,
        scrollTop: false,
        scrollBottom: true
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, ...toArray(action.payload)],
        scrollTop: false,
        scrollBottom: true
      };
    case ADD_HEADER_MESSAGE:
      return { ...state, messages: [...state.messages, ...toArray(action.payload)] };
    case CLEAR_MESSAGES:
      return { ...state, messages: [] };
    case FETCH_CERTIFICATE:
      return { ...state, messages: insertCertificate(state.messages, action.payload) };
    case FETCH_CERTIFICATE_FAIL:
      return { ...state, messages: insertCertificate(state.messages, action.payload, true) };
    case DOWNLOADING_CERTIFICATE:
      return {
        ...state,
        downloadingCertificate: action.payload.downloadingCertificate
      };
    case DOWNLOADING_CERTIFICATE_FAIL:
      return {
        ...state,
        downloadingCertificateFail: action.payload.downloadingCertificateFail
      };
    case GET_CERTIFICATE_THUMBNAIL_SUCCESS:
      return {
        ...state,
        certificatesThumbnail: certificatesThumbnailSuccess(
          action.payload.courseAttendanceId,
          action.payload.thumbnail,
          state.certificatesThumbnail
        )
      };
    case GET_CERTIFICATE_THUMBNAIL_FAIL:
      return {
        ...state,
        certificatesThumbnail: certificatesThumbnailFail(
          action.payload.courseAttendanceId,
          action.payload.error,
          state.certificatesThumbnail
        )
      };
    case PROCESS_ACTIONS:
      return { ...state, loadingValidation: false };
    case SHOW_FLOAT_BUBBLE:
      return { ...state, isFloatBubbleOpen: action.payload };
    case GET_QUESTIONNAIRES_ON_APP_SUCCESS:
      return { ...state, questionnairesOnApp: action.payload };
    case TOGGLE_SWITCH_QUESTIONNAIRE_MODAL:
      return { ...state, showQuestionnaireModal: !state.showQuestionnaireModal };
    case TOGGLE_SWITCH_MODAL:
      return { ...state, showSwitchModal: !state.showSwitchModal };
    default:
      return state;
  }
}

function toArray(messages) {
  if (!Array.isArray(messages)) {
    messages = [messages];
  }
  return messages;
}

function formatNewMessage(messageData) {
  const alignment = messageData.from === 'user' ? 'right' : 'left';
  let message = {
    text: messageData.type === 'audio' ? 'typeAudio' : messageData.answer,
    'text-align': alignment,
    date: messageData.date
  };

  if (messageData.type === 'password') {
    message.text = '******';
  }

  return toArray(message);
}

const insertCertificate = (messages, payload, isFail = false) => {
  return messages.map((message) => {
    if (message.multimedia && message.multimedia.description === 'certificate of completion') {
      return {
        ...message,
        multimedia: {
          ...message.multimedia,
          blob: isFail ? {} : payload.thumbnail.data,
          error: isFail
        }
      };
    }
    return message;
  });
};

const certificatesThumbnailSuccess = (caId, thumbnail, stateCertificatesThumbnail) => {
  return {
    ...stateCertificatesThumbnail,
    [caId.toString()]: thumbnail.data
  };
};

const certificatesThumbnailFail = (caId, error, stateCertificatesThumbnail) => {
  return {
    ...stateCertificatesThumbnail,
    [caId.toString()]: {
      failed: true,
      error
    }
  };
};
