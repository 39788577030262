import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Profile from './profile_main';
import Translate from '../../hocs/translate';
import { loadPreferencesData, toggleSmsReminder, toggleuseRTL } from '../../actions/preferences';

const mapStateToProps = (state) => ({
  preferences: state.preferences,
  userId: state.mainApp.userId,
  isValidPhoneNumber: state.preferences.preferencesData.isValidPhoneNumber,
  useRTL: state.preferences.preferencesData.useRTL
});

const mapDispatchToProps = (dispatch) => ({
  loadPreferencesData: bindActionCreators(loadPreferencesData, dispatch),
  toggleSmsReminder: bindActionCreators(toggleSmsReminder, dispatch),
  toggleuseRTL: bindActionCreators(toggleuseRTL, dispatch),
});

export default Translate('Profile')(connect(mapStateToProps, mapDispatchToProps)(Profile));
