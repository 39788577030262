import {
  SIMPLE_LOGIN_ENTER_PHONE,
  SIMPLE_LOGIN_NEW_LEARNER,
  SIMPLE_LOGIN_CREATE_PASSWORD,
  SIMPLE_LOGIN_ENTER_PASSWORD,
  SIMPLE_LOGIN_CONFIRM_CODE,
  RESET_PASSWORD_SUCCESSFUL,
  CHANGE_PHONE_NUMBER
} from '../constants';

const initialState = {
  enterPhone: true,
  newLearner: false,
  createPassword: false,
  enterPassword: false,
  phoneNumber: '',
  confirmCode: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIMPLE_LOGIN_ENTER_PHONE:
      return initialState;
    case SIMPLE_LOGIN_NEW_LEARNER:
      return {
        ...state,
        confirmCode: false,
        enterPhone: false,
        newLearner: true,
        createPassword: false,
        enterPassword: false,
        phoneNumber: action.phoneNumber
      };
    case SIMPLE_LOGIN_CREATE_PASSWORD:
      return {
        ...state,
        confirmCode: false,
        enterPhone: false,
        newLearner: false,
        createPassword: true,
        enterPassword: false,
        phoneNumber: action.phoneNumber
      };
    case SIMPLE_LOGIN_ENTER_PASSWORD:
      return {
        ...state,
        confirmCode: false,
        enterPhone: false,
        newLearner: false,
        createPassword: false,
        enterPassword: true,
        phoneNumber: action.phoneNumber
      };
    case RESET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        confirmCode: false
      };
    case SIMPLE_LOGIN_CONFIRM_CODE:
      return {
        ...state,
        confirmCode: true,
        phoneNumber: action.phoneNumber
      };
    case CHANGE_PHONE_NUMBER:
      return {
        ...state,
        confirmCode: false,
        phoneNumber: ''
      };
    default:
      return state;
  }
}
