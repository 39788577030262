import React from 'react';
import HubspotForm from 'react-hubspot-form';
import './login-help-form.scss';

const LoginHelpForm = () => {
  return (
    <div className="login-help-form">
      <HubspotForm portalId="6879950" formId="d734d1c9-d4a8-46da-b347-81e1824fd632" />
    </div>
  );
};

export default LoginHelpForm;
