// -- Dependencies
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateAudiosMetadata } from '../../actions/audio';

export class InlineFormEnableBackgroundAudio extends Component {

	constructor (props) {
		super(props);

		this.state = { isBackgroundAudioEnabled: false };
		this.switchBackgroundAudio = this.switchBackgroundAudio.bind(this);
	}

	componentDidMount() {
		if (!this.isBackgroundAudioEnabled) {
			this.switchBackgroundAudio(true);
		}
	}

	componentWillUnmount() {
		if (this.isBackgroundAudioEnabled) {
			this.switchBackgroundAudio(false);
		}
	}

	switchBackgroundAudio(enable) {
		let audioData = this.props.audioData;
		if (audioData) {
			audioData.isBackgroundAudioEnabled = enable;
			this.props.updateAudiosMetadata(audioData);
			this.setState({ isBackgroundAudioEnabled: enable });
		}
	}

	render() {
		return null;
	}

}

function mapStateToProps(state) {
	return { audioData: state.audio };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ updateAudiosMetadata }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InlineFormEnableBackgroundAudio);
