import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CoursesElements from '../../components/courses_list/list_elements';
import {
  loadCoursesList,
  toggleCourseAttendance,
  toggleCertificate
} from '../../actions/courses_list';
import { switchCourse } from '../../actions/course_details';
import Translate from '../../hocs/translate';
import { hashHistory } from 'react-router';
import { loadContext } from '../../actions/main_course';
import { toggleCourseDetails } from '../../actions/main_course';
import RecursiveOptionsList from '../../components/courses_list/options_list';
import BackButton from '../../components/buttons/back_button';
import _ from 'lodash';
import helpBg from '../../../images/help-bg.png';

export class CourseGroupSubtopics extends Component {
  constructor(props) {
    super(props);
    this.renderRecursiveOptionsList = this.renderRecursiveOptionsList.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      selectedTopic: null,
      nextAction: '',
      showCourses: false
    };
  }

  componentDidMount() {
    const selectedTopic = _.find(this.props.coursesList.courses, {
      _id: this.props.location.query.topicCode
    });
    if (_.isNil(selectedTopic)) {
      hashHistory.push('/me');
    } else {
      let showCourses = false;
      if (selectedTopic.courses) {
        showCourses = true;
      }
      this.setState((prevState, props) => ({
        selectedTopic: selectedTopic,
        showCourses: showCourses
      }));
      if (!selectedTopic.courses || !selectedTopic.courses.length) {
        hashHistory.push(`/courses_list?topicCode=${selectedTopic._id}`);
      }
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.props.coursesList.courseSwitched) {
      hashHistory.push('/active_course');
    }
  }

  renderRecursiveOptionsList() {
    return (
      <RecursiveOptionsList
        pathLink="/courses_list"
        queryParams={this.props.location.query}
        colAmount="1"
        options={this.state.selectedTopic.courses}
      />
    );
  }

  goBack() {
    hashHistory.push('/me');
  }

  render() {
    return (
      <div className="course-groups-subtopics-container app-full-h">
        <div className="course-groups-subtopics-main-content">
          <div className="course-groups-subtopics-header">
            <BackButton onClick={this.goBack} />
            <h1 className="subtopics-page-title">
              {this.state.selectedTopic &&
                this.state.selectedTopic.displayTitle[this.props.selectedLanguage]}
            </h1>
          </div>
          <div>
            {/* {this.state.selectedTopic && this.state.selectedTopic.courses && this.renderRecursiveOptionsList()} */}
            {this.state.showCourses && (
              <CoursesElements
                toggleCourseAttendance={this.props.toggleCourseAttendance}
                toggleCertificate={this.props.toggleCertificate}
                toggleCourseDetails={this.props.toggleCourseDetails}
                courses={_.filter(this.state.selectedTopic.courses, (el) => el.type === 'course')}
                switchCourse={this.props.switchCourse}
                useRTL={this.props.useRTL}
              />
            )}
          </div>
        </div>
        <div className="course-groups-subtopics-container__footer">
          <img
            className="course-groups-subtopics-container__footer-image"
            src={helpBg}
            role="presentation"
            alt=""
          />
        </div>
      </div>
    );
  }
}

CourseGroupSubtopics.defaultProps = {
  selectedTopic: null,
  strings: {
    'Switch Course Continue': 'Are you sure you want to continue with:',
    'Switch Course Start': 'Are you sure you want to start:',
    'Switch Course Redo': 'Are you sure you want to redo:',
    YES: 'YES'
  }
};

function mapStateToProps(state) {
  return {
    coursesList: state.coursesList,
    userId: state.mainApp.userId,
    selectedLanguage: state.i18n.language,
    useRTL: state.preferences.preferencesData.useRTL
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCoursesList,
      switchCourse,
      toggleCourseAttendance,
      toggleCertificate,
      toggleCourseDetails,
      loadContext
    },
    dispatch
  );
}

export default Translate('CourseGroupSubtopics')(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(CourseGroupSubtopics)
);
