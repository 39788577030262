import { put, take, call } from 'redux-saga/effects';
import apiClient from '../lib/celled_client';
import { checkCommonErrors } from '../lib/auth';
import {
  SEND_EMAIL,
  EMAIL_SUCCESS,
  EMAIL_FAILED,
  LOAD_HELP_DATA,
  LOAD_HELP_DATA_SUCCESS,
  GET_COACH_INFO,
  GET_COACH_INFO_SUCCESS
} from '../constants';

function* sendEmail() {
  while (true) {
    const messageData = yield take(SEND_EMAIL);

    try {
      const { userId, message } = messageData.payload;
      yield call(apiClient.sendEmail, userId, message);
      yield put({
        type: EMAIL_SUCCESS,
        notification: {
          message: 'Email sent',
          type: 'emailSubmit'
        }
      });
    } catch (error) {
      let errorPayload = {
        type: EMAIL_FAILED,
        notification: {
          message: 'Error: email could not be sent',
          className: 'error',
          type: 'emailSubmit'
        }
      };
      yield put(checkCommonErrors(error, errorPayload));
    }
  }
}

function* loadHelpDataWatcher() {
  while (true) {
    const helpData = yield take(LOAD_HELP_DATA);
    yield call(loadHelpData, helpData.payload);
  }
}

export function* loadHelpData(userId) {
  const helpData = yield call(apiClient.loadHelpData, userId);
  yield put({ type: LOAD_HELP_DATA_SUCCESS, payload: helpData.data.sections.help });
}

function* getCoachInfo() {
  while (true) {
    const coachData = yield take(GET_COACH_INFO);
    try {
      const getCoachInfo = yield call(apiClient.getCoachInfo, coachData.payload);
      yield put({ type: GET_COACH_INFO_SUCCESS, payload: getCoachInfo.data.coachInfo });
    } catch (error) {
      const errorPayload = {
        type: 'GET_COACH_INFO_FAILED',
        notification: {
          message: 'Error: load coach data failed',
          className: 'error',
          type: 'loadCoach'
        }
      };
      yield put(checkCommonErrors(error, errorPayload));
    }
  }
}

export default function* rootHelpSaga() {
  yield [sendEmail(), loadHelpDataWatcher(), getCoachInfo()];
}
