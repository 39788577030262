import { put, take, call } from 'redux-saga/effects';
import apiClient from '../lib/celled_client';
import { checkCommonErrors } from '../lib/auth';
import { LOAD_COURSES_LIST, LOAD_COURSES_SUCCESS, LOAD_COURSES_FAILED } from '../constants';

export function * loadCoursesList (payload) {
	
	try {
		const userId = payload.userId;
		const courseId = payload.courseId;
		const coursesList = yield call(apiClient.loadCoursesList, userId)
		const courses = coursesList.data;
		yield put({ type: LOAD_COURSES_SUCCESS, payload: { courses: courses, courseId: courseId } })
	}

	catch (error) {
		var errorMessage = error.response.data.message;
		let errorPayload = {
			type: LOAD_COURSES_FAILED,
			notification: {
				message: errorMessage,
				type: 'courses_list',
			},
		};
		yield put(checkCommonErrors(error, errorPayload));
	}
}

function * loadCoursesListWatcher () {
	
	while (true) {
		const result = yield take(LOAD_COURSES_LIST)
		yield call(loadCoursesList, result.payload)
	}
}

export default function * rootCoursesListSaga () {
	yield [
		loadCoursesListWatcher(),
	];
};
