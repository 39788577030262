import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { audioFinished } from '../../actions/main_course';
import { playPauseAudio, updateAudioProgress, updateAudiosMetadata, playAudioControl, buffering } from '../../actions/audio';
import { enableUserInput } from '../../actions/main_course';
import AudioPlayer from './audio_player'

export class AudioComponent extends Component {
	constructor(props) {
		super(props);
		// Actions
		this.dispatchAudioFinishAction = this.dispatchAudioFinishAction.bind(this);
		this.playPauseAudio = this.playPauseAudio.bind(this);
		this.playAudioControl = this.playAudioControl.bind(this);
		// Events from audio (native)
		this.onLoadedMetadata = this.onLoadedMetadata.bind(this);
		this.onCanPlay = this.onCanPlay.bind(this);
		this.onPlay = this.onPlay.bind(this);
		this.onPause = this.onPause.bind(this);
		this.onListen = this.onListen.bind(this);
		this.onEnded = this.onEnded.bind(this);
		this.onError = this.onError.bind(this);
	}
	/**
	 * Indica que se cargo la metadata
	 * @param {Object} data = { index, duration } 
	 * @param {Object} data = { index, duration }
	 * @param {Object} data = { index, duration } 
	 * @param {Object} data = { index, duration }
	 * @param {Object} data = { index, duration } 
	 * @param {Object} data = { index, duration }
	 * @param {Object} data = { index, duration } 
	 */
	onLoadedMetadata(data) {
		let state = this.props.audioData;
		state.audios[data.index].isLoaded = true;
		state.audios[data.index].duration = data.duration;
		state.duration = 0;
		for (let index = 0; index < state.audios.length; index++) {
			state.duration += state.audios[index].duration;
		}
		this.props.updateAudiosMetadata(state);
		this.props.updateAudioProgress(state.duration, 0, state.currentIndex);
	}
	/**
	 * Indica que el audio puede reproducirse sin parar
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 */
	onCanPlay(data) {
		let state = this.props.audioData;
		state.audios[data.index].canPlay = true;
		state.canPlay = state.audios.every((currentValue) => currentValue.canPlay);
		this.props.updateAudiosMetadata(state);
	}
	/**
	 * Indica que el audio esta reproduciendose
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 */
	onPlay(data) {
		let state = this.props.audioData;
		if (state.canPlay) {
			state.audios[data.index].isPlaying = true;
			state.audios[data.index].isPaused = false;
			this.props.updateAudiosMetadata(state);
		}
	}
	/**
	 * Indica que el audio fue pausado
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 */
	onPause(data) {
		let state = this.props.audioData;
		if (state.canPlay) {
			state.audios[data.index].isPlaying = false;
			state.audios[data.index].isPaused = true;
			this.props.updateAudiosMetadata(state);
		}
	}
	/**
	 * Indica el tiempo que se esta reproduciendo el audio
	 * @param {Object} data = { index, currentTime }
	 */
	onListen(data) {
		let state = this.props.audioData;
		if (state.canPlay) {
			state.audios[data.index].currentTime = data.currentTime;
			state.currentTime = 0;
			for (let index = 0; index < state.audios.length; index++) {
				state.currentTime += state.audios[index].currentTime;
			}
			this.props.updateAudioProgress(state.duration, state.currentTime, state.currentIndex);
			this.props.updateAudiosMetadata(state);
		}
	}
	/**
	 * Indica que termino de reproducirse el audio
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 * @param {Object} data = { index }
	 * @param {Object} data = { index } 
	 */
	onEnded(data) {
		let state = this.props.audioData;
		if (state.canPlay) {
			state.audios[data.index].isPlaying = false;
			state.audios[data.index].play = false;
			state.audios[data.index].isPaused = false;
			state.audios[data.index].ended = true;
			// esta sentencia es por que cuando termina el audio en caso que el listener no alla actualizado la duracion entonces se setea el total
			state.audios[data.index].currentTime = state.audios[data.index].duration;
			if (data.index < state.audios.length - 1) { // Se reproduce el siguinete audio por que el anterior termino.
				state.audios[data.index + 1].play = true;
				state.currentIndex = data.index + 1;
				state.finished = false;
				this.props.updateAudiosMetadata(state);
			} else { // Si termino la reproduccion de todos los audios.
				state.currentTime = state.duration; // se setea el total de duracion para fixear el intervalo del listener.
				state.finished = true;
				this.props.updateAudiosMetadata(state);
				this.props.updateAudioProgress(state.duration, state.currentTime, state.currentIndex);
				this.props.audioFinished();
			}
		}
	}
	/**
	 * Indica que ocurrio un erro al reproducirse o cargarse el audio 
	 * Indica que ocurrio un erro al reproducirse o cargarse el audio
	 * Indica que ocurrio un erro al reproducirse o cargarse el audio 
	 * Indica que ocurrio un erro al reproducirse o cargarse el audio
	 * Indica que ocurrio un erro al reproducirse o cargarse el audio 
	 * Indica que ocurrio un erro al reproducirse o cargarse el audio
	 * Indica que ocurrio un erro al reproducirse o cargarse el audio 
	 * @param {Object} data = { index }
	 */
	onError(data) {
		let state = this.props.audioData;
		state.audios[data.index].error = true;
		this.props.updateAudiosMetadata(state);
	}

	/**
	 * This method gets metadata for the audio that is currently playing.
	 * The title is based on the course that is being played.
	 */
	getAudioTitle() {
		let defaultText = 'Cell-Ed';

		if (!this.props.mainCourse.moduleData) return defaultText;

		const title = this.props.mainCourse.moduleData['main-title'];
		if (!title) return defaultText;

		return defaultText + ' - ' + title;
	}

	componentDidUpdate(prevProps) {

		let state = this.props.audioData;
		let needToUpdateState = false;
		if (state.canPlay) { // Si todos los audios se cargaron.
			if (state.playModal) {
				// Esperar el play sea cual fuere el caso.
			} else {
				if (state.isPlaying) { // Se dio un play o se esta reproduciendo.
					if (state.audios[state.currentIndex].play && !state.finished) { // Se esta reproduciendo pero no termino.
						// Esperar que termine.
					} else if (!state.audios[state.currentIndex].play && !state.finished) { // No se esta reproduciendo pero no termino.
						state.audios[state.currentIndex].play = true; // Entonces play.
						needToUpdateState = true;
					}
				} else { // No se dio play o se dio un pause o termino.
					if (state.audios[state.currentIndex].play && !state.finished) { // Si dio un pause.
						state.audios[state.currentIndex].play = false; // Entonces pause.
						needToUpdateState = true;
					} else if (!state.audios[state.currentIndex].play && !state.finished) { // No se dio play.
						if (state.autoPlay && state.currentIndex === 0 && state.currentTime === 0 && !state.audios[state.currentIndex].play) { // Si es autoPlay.
							state.isPlaying = true;
							state.audios[state.currentIndex].play = true; // Entonces play.
							needToUpdateState = true;
						} else { // No es autoPlay.
							// Esperar el play.
						}
					} else if (!state.audios[state.currentIndex].play && state.finished) { // Termino.
						// Termino entonces resetear el estado para permitir dar play nuevamente.
						state.currentIndex = 0;
						state.finished = false;
						for (let index = 0; index < state.audios.length; index++) {
							state.audios[index].play = false;
							state.audios[index].currentTime = 0;
							state.audios[index].ended = false;
						}
						needToUpdateState = true;
					} else {
						// No puede pasar.
					}
				}
			}
		} else {
			// Esperar la carga de los audios, lo maneja onCanPlay()
		}
		if (needToUpdateState) {
			this.props.updateAudiosMetadata(state);
		}
	}


	render() {
		let audio = this.props.audioData.audios && this.props.audioData.audios.length > 0 ? this.props.audioData.audios[0] : {};
		let index = 0;
		return (
			<div>
				<AudioPlayer
					key={index}
					index={index}
					src={audio.src}
					play={audio.play && this.props.audioData.isPlaying}
					onLoadedMetadata={this.onLoadedMetadata}
					onCanPlayThrough={this.onCanPlay}
					onPlay={this.onPlay}
					onPause={this.onPause}
					onListen={this.onListen}
					onEnded={this.onEnded}
					onError={this.onError}
					title={this.getAudioTitle()} />
			</div>
		)
	}

	dispatchAudioFinishAction() {
		this.props.audioFinished();
	}

	playPauseAudio() {
		this.props.playPauseAudio();
	}

	playAudioControl() {
		this.props.playAudioControl();
	}

}

function mapStateToProps(state) {
	return {
		audioData: state.audio,
		mainCourse: state.mainCourse, // used to get metadata about the audio
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			audioFinished,
			playPauseAudio,
			updateAudioProgress,
			updateAudiosMetadata,
			playAudioControl,
			buffering,
			enableUserInput,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioComponent);
