import PropTypes from 'prop-types';
import React from 'react';


const ChatHeaderMessage = (props) => {

	return (
		<div>
			<div className="chat-center-bubble">
				<div className="header-container">
					<div>{props.text}</div>
				</div>
			</div>
			<div style={{ clear: 'both' }} />
		</div>
	)
};

ChatHeaderMessage.propTypes = {
	'text': PropTypes.string
};

export default ChatHeaderMessage;
