import {
	PLAY_AUDIO,
	PLAY_ACTION_ERROR,
	AUDIO_FINISHED,
	ENABLE_INPUT,
	SUBMIT_ANSWER,
} from '../constants';

const initialState = {
	enable: false,
	type: null,
	options: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case PLAY_AUDIO: {
			if (action.payload.quickInputEnable) {
				return { ...state, ...action.payload.inputType, enable: true };
			}
			else {
				return initialState;
			}
		}
		case PLAY_ACTION_ERROR: {
			return { ...state, enable: true };
		}
		case AUDIO_FINISHED: {
			return { ...state, enable: true };
		}
		case ENABLE_INPUT:
			return { ...state, ...action.payload, enable: true };
		case SUBMIT_ANSWER:
			return initialState;
		default:
			return state;
	}
}

