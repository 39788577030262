import { SWITCH_QUESTIONNAIRE, SWITCH_QUESTIONNAIRE_REQUEST, SAVE_LEARNER_LAST_TIME } from '../constants';

export function switchQuestionnaire(questionnaireId) {
	return {
		type: SWITCH_QUESTIONNAIRE,
		payload: questionnaireId,
	}
}

export function switchQuestionnaireRequest() {
	return {
		type: SWITCH_QUESTIONNAIRE_REQUEST
	}
}

export function keepLearnerlastTime(time) {
	return {
		type: SAVE_LEARNER_LAST_TIME,
		payload: time
	}
}