import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LANGUAGES } from '../constants'

// This component will wrap the original component and replace all the strings
// with the corresponding file located in /i18n, depending of the "key" passed to the function

export default function translate (key) {

	return InnerComponent => {

		class TranslationComponent extends Component {
			
			// If no language is defined, use the default properties (EN strings) of the component

			returnStrings () {
				if (LANGUAGES[this.context.currentLanguage] && LANGUAGES[this.context.currentLanguage][key]) {
					return LANGUAGES[this.context.currentLanguage][key]
				}
				
				return undefined;
				
			}
			render () {
				return <InnerComponent {...this.props} {...this.state} strings={this.returnStrings()} />;
			}
		}

		
		TranslationComponent.contextTypes = {
			currentLanguage: PropTypes.string,
		};

		return TranslationComponent;
	};
}
