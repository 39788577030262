import React from 'react';
import { Link } from 'react-router';
import './login-footer.scss';
import Translate from '../../hocs/translate';

const LoginFooter = ({ strings }) => {
  return (
    <div className="login-footer">
      <Link className="login-footer__help-link" to="/login-help-form">
        {strings['I need help']}
      </Link>
    </div>
  );
};

LoginFooter.defaultProps = {
  strings: {
    'I need help': 'I need help'
  }
};

export default Translate('LoginFooter')(LoginFooter);
