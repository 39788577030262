import {
  MODULE_SUCCESSFULLY_LOADED,
  UPDATE_UI,
  MODULE_LOAD_FAILED,
  MODULE_SESSION_EXPIRED,
  TOGGLE_COURSE_DETAILS,
  LOAD_CONTEXT,
  RELOAD_CONTEXT,
  PROCESS_RELOAD_CONTEXT,
  RECONNECT_ACTIVATE,
  RECONNECT_DEACTIVATE,
  RECONNECT_TRY,
  REMOVE_AUDIO_MODAL,
  JUMP,
  UPDATE_FULL_SCREEN_FRAME
} from '../constants';
const initialState = {
  loadingCourse: true,
  loaded: '',
  moduleData: {
    'main-title': '',
    'sub-title': '',
    'lesson-nav-prev': { enabled: false },
    'lesson-nav-next': { enabled: false },
    'history-pagination': false
  },
  showCourseDetails: false,
  inChatRoom: false,
  reconnect: { active: false, state: '' },
  pageNumber: 0,
  fullScreenFrameUrl: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MODULE_SESSION_EXPIRED:
      return { ...state, loaded: 'expired', loadingCourse: false };
    case MODULE_LOAD_FAILED:
      return { ...state, loaded: 'failed', loadingCourse: false };
    case UPDATE_UI:
      let newData = { ...state.moduleData };
      newData[action.payload.element] = action.payload.value;
      return { ...state, moduleData: newData };
    case MODULE_SUCCESSFULLY_LOADED:
      return { ...state, loadingCourse: false, loaded: 'success' };
    case TOGGLE_COURSE_DETAILS:
      return {
        ...state,
        showCourseDetails: action.payload !== undefined ? action.payload : !state.showCourseDetails
      };
    case PROCESS_RELOAD_CONTEXT:
      return initialState;
    case RELOAD_CONTEXT:
      return { ...state, pageNumber: 0, inChatRoom: true };
    case LOAD_CONTEXT:
      return { ...state, pageNumber: state.pageNumber + 1, inChatRoom: true };
    case JUMP:
      return { ...state, inChatRoom: true };
    case RECONNECT_ACTIVATE:
      return { ...state, reconnect: { active: true, state: 'waiting' } };
    case RECONNECT_DEACTIVATE:
      return { ...state, reconnect: { active: false, state: '' } };
    case RECONNECT_TRY:
      return { ...state, reconnect: { active: true, state: 'reconnecting' } };
    case REMOVE_AUDIO_MODAL:
      return { ...state, playModal: false };
    case UPDATE_FULL_SCREEN_FRAME:
      return { ...state, fullScreenFrameUrl: action.payload };
    default:
      return state;
  }
}
