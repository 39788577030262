import {
	FORGOT_PASSWORD_REQUEST,
	CHANGE_PHONE_NUMBER,
	RESEND_FORGOT_PASSWORD_REQUEST,
	RESET_PASSWORD_CONFIRMATION_REQUEST,
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_RESET_STATE
} from "../constants";

export function forgotPasswordRequest(phoneNumber) {
	return {
		type: FORGOT_PASSWORD_REQUEST,
		payload: phoneNumber,
	};
}

export function changePhoneNumber() {
	return {
		type: CHANGE_PHONE_NUMBER,
	};
}

export function confirmCode(confirmationData) {
	return {
		type: RESET_PASSWORD_CONFIRMATION_REQUEST,
		payload: confirmationData,
	};
}

export function resendForgotPasswordConfirmationCode(phoneNumber) {
	return {
		type: RESEND_FORGOT_PASSWORD_REQUEST,
		payload: phoneNumber,
	};
}

export function resetPasswordRequest(data) {
	return {
		type: RESET_PASSWORD_REQUEST,
		payload: data,
	};
}

export function resetState() {
	return {
		type: RESET_PASSWORD_RESET_STATE
	};
}
