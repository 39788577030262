import React from 'react';
import Loader from 'react-loader-spinner';

const LoadingDots = () => {
  return (
    <div>
      <div style={{ minWidth: 70 }} className="chat-left-bubble">
        <div className="bubble-container">
          <div className="loader">
            <Loader type="ThreeDots" color="#666666" width="35" height="10" />
          </div>
        </div>
        <span role="presentation" className="bubble-tail-left" alt="">
          &nbsp;
        </span>
      </div>
      <div style={{ clear: 'both' }} />
    </div>
  );
};

export default LoadingDots;
