import React, { Component } from 'react';
import Translate from '../../hocs/translate';
import './faqs.scss';

class Faqs extends Component {
  constructor(props) {
    super(props);

    this.faqs = [
      [this.props.strings.question1, this.props.strings.answer1],
      [this.props.strings.question2, this.props.strings.answer2]
    ];
  }

  renderQuestions(content) {
    return content.map((elem, index) => {
      return (
        <div className="faqs__container" key={index}>
          <h3 className="faqs__title">{elem[0]}</h3>
          <div className="faqs__answer">{elem[1]}</div>
        </div>
      );
    });
  }

  render() {
    return <div className="faqs">{this.renderQuestions(this.faqs)}</div>;
  }
}

Faqs.defaultProps = {
  strings: {
    question1: 'How do I start my course?',
    question2: 'How do I see my certificate?',
    answer1: <p>Go to the Home page and click the PLAY button.</p>,
    answer2: (
      <p>
        You need to complete a course to see a certificate. Go to the Me page and click on Course.
        You should see the certificate next to the course you completed.
      </p>
    ),
    'Need more help?': 'Need more help?',
    'Please click': 'Please click',
    HERE: 'HERE',
    'to send us your question.': 'to send us your question.'
  }
};

export default Translate('Faqs')(Faqs);
