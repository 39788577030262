import React from 'react';
import PropTypes from 'prop-types';

const IconPlay = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={fill} />
      <path d="M33 22L16 34L16 10L33 22Z" fill="white" />
    </svg>
  );
};

IconPlay.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
};

IconPlay.defaultProps = {
  fill: '#EE433C',
  className: ''
};

export default IconPlay;
