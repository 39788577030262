import { put, take, call } from 'redux-saga/effects';

/**
 * Created by nico on 05/07/17.
 */
import apiClient from '../lib/celled_client';
import { LOGIN_REQUEST, LOGIN_FAILED, LOGIN_SUCCESSFUL, CHANGE_LANGUAGE } from '../constants';

export function* login() {
  while (true) {
    const loginRequest = yield take(LOGIN_REQUEST);
    try {
      const loginSuccess = yield call(apiClient.loginRequest, loginRequest.payload);

      // integration with LogRocket
      const resp = loginSuccess.data;
      /* 
			// [WEBAPP-2355]: LogRocket disabled until we figure how to deal with their account limits
			import LogRocket from 'logrocket';
			const accountInfoResp = resp.accountInfo;
			const accountInfo = {
				id: accountInfoResp.id,
				name: accountInfoResp.name,
				pincode: accountInfoResp.pincode,
			}
			LogRocket.identify(loginSuccess.data.userId, accountInfo); */

      yield put({
        type: LOGIN_SUCCESSFUL,
        token: resp.token,
        userId: resp.userId,
        firstTime: resp.firstTime
      });
      yield put({
        type: CHANGE_LANGUAGE,
        payload: resp.language
      });
    } catch (error) {
      yield put({
        type: LOGIN_FAILED
      });
    }
  }
}

export default function* rootLoginSaga() {
  yield [login()];
}
