import 'regenerator-runtime/runtime';
import { createStore, applyMiddleware } from 'redux';
import notificationMiddleware from './js/middleware/notifications_middleware';
import reducers from './js/reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './js/sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(notificationMiddleware, sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

export default store;
