import { CHANGE_LANGUAGE } from '../constants'

export function changeLanguage (language, logged = false, userId = null) {
	localStorage.setItem('celledLanguage', JSON.stringify(language));
	return {
		type: CHANGE_LANGUAGE,
		payload: language,
		logged: logged,
		userId: userId,
	}
}
