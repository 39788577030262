import React from 'react';
import PropTypes from 'prop-types';

function IconRefresh({ fill }) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.57 0.0973284C12.7102 -0.204073 14.8902 0.198919 16.7816 1.24549C18.6729 2.29205 20.173 3.92541 21.0561 5.89932C21.9391 7.87322 22.1574 10.0808 21.678 12.1896C21.1985 14.2984 20.0474 16.1943 18.3978 17.5915C16.7481 18.9887 14.6893 19.8116 12.5317 19.9359C10.374 20.0602 8.23445 19.4792 6.43548 18.2806C4.63653 17.082 3.27564 15.3308 2.55766 13.2909C2.3743 12.77 2.64797 12.199 3.16893 12.0157C3.68989 11.8323 4.26085 12.106 4.44421 12.6269C5.01835 14.2581 6.10647 15.6581 7.54443 16.6162C8.98237 17.5743 10.6923 18.0385 12.4166 17.9392C14.1409 17.8399 15.7864 17.1823 17.1051 16.0654C18.4238 14.9484 19.3443 13.4326 19.7277 11.7462C20.1111 10.0599 19.9366 8.29444 19.2304 6.71605C18.5243 5.13767 17.325 3.83194 15.8132 2.99544C14.3015 2.15895 12.5593 1.83692 10.849 2.07779C9.13862 2.31865 7.55266 3.1094 6.3301 4.33107C6.32297 4.3382 6.31572 4.34522 6.30838 4.35213L3.52278 6.97216H6.97834C7.53063 6.97216 7.97834 7.41988 7.97834 7.97216C7.97834 8.52445 7.53063 8.97216 6.97834 8.97216H1C0.447715 8.97216 0 8.52445 0 7.97216V1.98804C0 1.43576 0.447715 0.988041 1 0.988041C1.55228 0.988041 2 1.43576 2 1.98804V5.65877L4.92739 2.90538C6.4552 1.38343 8.435 0.398007 10.57 0.0973284Z"
        fill={fill}
      />
    </svg>
  );
}

IconRefresh.protoTypes = {
  fill: PropTypes.string
};

IconRefresh.defaultProps = {
  fill: '#333333'
};

export default IconRefresh;
