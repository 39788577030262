import { put, take, call } from 'redux-saga/effects';
import apiClient from '../lib/celled_client';
import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESSFUL,
  FORGOT_PASSWORD_FAILED,
  RESEND_FORGOT_PASSWORD_REQUEST,
  RESEND_FORGOT_PASSWORD_SUCCESSFUL,
  RESEND_FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD_CONFIRMATION_REQUEST,
  RESET_PASSWORD_CONFIRMATION_SUCCESSFUL,
  RESET_PASSWORD_CONFIRMATION_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESSFUL,
  RESET_PASSWORD_FAILED,
  LOGIN_SUCCESSFUL,
  CHANGE_LANGUAGE
} from '../constants';

export function* forgotPassword() {
  // Comienzo el flujo para resetear la contraseña de un usuario.
  // Aca el backend valida si el usuario existe, y manda un codigo de verificacion a su telefono.

  while (true) {
    const forgotPasswordRequest = yield take(FORGOT_PASSWORD_REQUEST);

    try {
      yield call(apiClient.forgotPasswordRequest, forgotPasswordRequest.payload);
      yield put({ type: FORGOT_PASSWORD_SUCCESSFUL, phoneNumber: forgotPasswordRequest.payload });
    } catch (error) {
      var errorMessage = error.response.data.message;
      yield put({
        type: FORGOT_PASSWORD_FAILED,
        notification: {
          message: errorMessage,
          className: 'error',
          type: 'phoneNumberError',
          unique: true
        }
      });
    }
  }
}

export function* resendConfirmationCode() {
  // Aca reenvia un nuevo codigo de verificacion al telefono del usuario.

  while (true) {
    const resendConfirmationCodes = yield take(RESEND_FORGOT_PASSWORD_REQUEST);

    try {
      yield call(apiClient.resendPasswordConfirmationCode, resendConfirmationCodes.payload);
      yield put({
        type: RESEND_FORGOT_PASSWORD_SUCCESSFUL,
        notification: {
          message: 'A new confirmation code was sent',
          type: 'resendResetPassCode',
          unique: true
        }
      });
    } catch (error) {
      var errorMessage = error.response.data.message;
      yield put({
        type: RESEND_FORGOT_PASSWORD_FAILED,
        notification: {
          message: errorMessage,
          className: 'error',
          type: 'resendResetPassCode',
          unique: true
        }
      });
    }
  }
}

export function* confirmCode() {
  // Aca se le manda al backend el codigo verificador ingresado por el usuario para validar si realmente es el.

  while (true) {
    const resetPasswordConfirmation = yield take(RESET_PASSWORD_CONFIRMATION_REQUEST);

    try {
      yield call(apiClient.confirmPasswordCode, resetPasswordConfirmation.payload);
      yield put({
        type: RESET_PASSWORD_CONFIRMATION_SUCCESSFUL
      });
    } catch (error) {
      var errorMessage = error.response.data.message;
      yield put({
        type: RESET_PASSWORD_CONFIRMATION_FAILED,
        notification: {
          message: errorMessage,
          className: 'error',
          type: 'confirmCode',
          unique: true
        }
      });
    }
  }
}

export function* resetPassword() {
  // Aca finalmente se cambia la contraseña del usuario. Para eso se mandan los siguientes parametros: phoneNumber, newPassword y confirmationCode.

  while (true) {
    const resetPassword = yield take(RESET_PASSWORD_REQUEST);

    try {
      const resetSuccess = yield call(apiClient.resetPassword, resetPassword.payload);
      yield put({
        type: RESET_PASSWORD_SUCCESSFUL,
        notification: {
          message: 'You have successfully changed your password',
          type: 'resetPassword',
          unique: true
        }
      });

      // set credentials and login
      yield put({
        type: LOGIN_SUCCESSFUL,
        token: resetSuccess.data.token,
        userId: resetSuccess.data.userId,
        firstTime: resetSuccess.data.firstTime
      });
      yield put({
        type: CHANGE_LANGUAGE,
        payload: resetSuccess.data.language
      });
    } catch (error) {
      var errorMessage = error.response.data.message;
      yield put({
        type: RESET_PASSWORD_FAILED,
        notification: {
          message: errorMessage,
          className: 'error',
          type: 'phoneNumberError',
          unique: true
        }
      });
    }
  }
}

export default function* rootResetPasswordSaga() {
  yield [forgotPassword(), confirmCode(), resendConfirmationCode(), resetPassword()];
}
