import { GET_ACHIEVEMENTS_SUCCESS } from "../constants";

export const initialState = {
  achievementsList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        achievementsList: action.payload,
      };
    default:
      return state;
  }
}
