import PropTypes from 'prop-types';
import React, { Component } from 'react';
import IconClose from '../assets/icon_close';
import './notification.scss';

export default class Notification extends Component {
  constructor(props) {
    super(props);

    this.closeNotification = this.closeNotification.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.clearNotification(this.props.notificationId);
    }, 4000);
  }

  clearNotification(notificationId) {
    this.props.clearNotifications(notificationId);
  }

  closeNotification() {
    this.props.clearNotification(this.props.notificationId);
  }

  render() {
    if (!this.props.avoidUINotification) {
      return (
        <div className={`notification ${this.props.style}`}>
          {this.props.message}
          <button className="notification__close-button" onClick={this.closeNotification}>
            <IconClose />
          </button>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

Notification.protoTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  notificationId: PropTypes.string,
  clearNotifications: PropTypes.func
};
