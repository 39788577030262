import React from 'react';

const LogoSvs = () => {
  return (
    <svg
      width="59"
      height="27"
      viewBox="0 0 59 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title="Society for Vascular Surgery">
      <path
        d="M14.2621 6.14921C13.3084 4.75365 11.5744 4.09948 9.79708 4.09948C7.71628 4.09948 5.59214 5.05893 5.59214 7.41395C5.59214 12.5601 17.7301 9.63813 17.7301 18.7965C17.7301 24.3352 13.3951 26.9955 8.36653 26.9955C5.20199 26.9955 2.0808 26.036 0 23.5066L3.42464 20.1485C4.55174 21.8493 6.45913 22.8524 8.49658 22.8524C10.5774 22.8524 12.9616 21.6749 12.9616 19.3199C12.9616 13.694 0.823647 16.9212 0.823647 7.76284C0.823647 2.44224 5.50544 0 10.2306 0C12.9183 0 15.5626 0.741394 17.5567 2.6603L14.2621 6.14921Z"
        fill="white"
      />
      <path
        d="M18.2935 0.610657H23.5389L30.5182 20.0177L37.7143 0.610657H42.6128L32.2956 26.2978H28.3507L18.2935 0.610657Z"
        fill="white"
      />
      <path
        d="M55.4011 6.14921C54.4474 4.75365 52.7134 4.09948 50.9361 4.09948C48.8553 4.09948 46.7311 5.05893 46.7311 7.41395C46.7311 12.5601 58.8691 9.63813 58.8691 18.7965C58.8691 24.3352 54.5341 26.9955 49.5055 26.9955C46.341 26.9955 43.2198 26.036 41.139 23.5066L44.5636 20.1485C45.6907 21.8493 47.5981 22.8524 49.6356 22.8524C51.7163 22.8524 54.1006 21.6749 54.1006 19.3199C54.1006 13.694 41.9626 16.9212 41.9626 7.76284C41.9626 2.44224 46.6444 0 51.3696 0C54.0572 0 56.7016 0.741394 58.6957 2.6603L55.4011 6.14921Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoSvs;
