import { LOAD_COURSES_DETAILS, SWITCH_COURSE_REQUEST, SWITCH_COURSE, TOGGLE_SWITCH_COURSE_MODAL, SWITCH_LESSON_REQUEST, SWITCH_LESSON, TOGGLE_SWITCH_LESSON_MODAL } from '../constants';

export function loadCourseDetails(userId, courseCode) {
	return {
		type: LOAD_COURSES_DETAILS,
		payload: { userId, courseCode },
	}
}

export function toggleCourseConfirmationModal() {
	return {
		type: TOGGLE_SWITCH_COURSE_MODAL
	}
}

export function switchCourseRequest() {
	return {
		type: SWITCH_COURSE_REQUEST
	}
}

export function switchCourse(courseCode) {
	return {
		type: SWITCH_COURSE,
		courseCode: courseCode,
	}
}

export function toggleLessonConfirmationModal() {
	return {
		type: TOGGLE_SWITCH_LESSON_MODAL
	}
}

export function switchLessonRequest() {
	return {
		type: SWITCH_LESSON_REQUEST
	}
}

export function switchLesson(courseCode) {
	return {
		type: SWITCH_LESSON,
		courseCode: courseCode,
	}
}