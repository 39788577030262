import React, { Component } from 'react';
import ProgressBar from '../others/progress_bar';
import './audio-progress-bar.scss';

class AudioProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = { progress: 0, audio: {} };
    this.modifyCurrentTime = this.modifyCurrentTime.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.audio && nextProps.audio.audios && nextProps.audio.audios.length > 0) {
      this.setState({
        audio: nextProps.audio
      });
    }
    if (nextProps.audio.currentTime !== this.props.audio.currentTime) {
      let progress = this.calculateProgress(nextProps.audio.currentTime, nextProps.audio.duration);
      this.setState({ progress: progress });
    }
  }

  // Este algoritmo es para posicionar el audio en play en el tiempo que se calcula a partir del click en la barra de progreso
  // positionInProgressBar en rango 0 .. 1
  modifyCurrentTime(positionInProgressBar) {
    this.props.clickAudioProgress();
    if (
      this.state.audio.audios &&
      this.state.audio.audios.length > 0 &&
      this.props.audio.scrollable
    ) {
      // Validad que existan audios cargados y que sea scrollable
      let newPositionOfTotalElapsedTime = this.state.audio.duration * positionInProgressBar; // Calculo del tiempo transcurrido total para la nueva posicion.
      let currentIndex = 0; // Indice del audio en ejecucion
      let currentTime = 0; // Tiempo de reproduccion del audio actual
      let totalAudioDuration = 0; // Acumulador de tiempo total de audios
      // Calculo del nuevo indice, la nueva duracion del audio en ejecucion.
      this.state.audio.audios.forEach(function (audio, index) {
        if (
          totalAudioDuration <= newPositionOfTotalElapsedTime &&
          totalAudioDuration + audio.duration >= newPositionOfTotalElapsedTime
        ) {
          currentIndex = index;
          currentTime = newPositionOfTotalElapsedTime - totalAudioDuration;
        }
        totalAudioDuration = +audio.duration; // Sumar la duracion del audio segun indice
      });
      let audioEL = document.getElementsByClassName('audio-player-' + currentIndex)[0]; // Obtener elemento del dom
      if (audioEL) {
        let stateAudio = this.state.audio;
        stateAudio.currentIndex = currentIndex; // Setear en el state global el current index.
        stateAudio.currentTime = 0;
        stateAudio.audios.forEach(function callBack(audio, index) {
          if (index < currentIndex) {
            // Si el audio es anterior.
            audioEL.currentTime = stateAudio.audios[index].duration; // Setear en el elemento del dom para todos los anterios el total de la duracion.
            stateAudio.audios[index].currentTime = stateAudio.audios[index].duration; // Setear en el state para todos los anterios el total de la duracion.
            stateAudio.currentTime = +stateAudio.audios[index].duration; // Sumar en el state global la duracion total del audio.
            stateAudio.audios[index].play = false; // Poner el play en false.
          } else if (index === currentIndex) {
            // Para el actual
            audioEL.currentTime = currentTime; // Setear el tiempo parcial en el dom.
            stateAudio.audios[index].currentTime = currentTime; // Setear el tiempo parcial en el state.
            stateAudio.currentTime = +currentTime; // Sumar el tiempo parcial en el state global.
            stateAudio.audios[index].play = stateAudio.isPlaying; // Poner en play o en pause de acuerdo al state global.
          } else {
            // Para todos los siguientes setear a 0.
            audioEL.currentTime = 0;
            stateAudio.audios[index].currentTime = 0;
            stateAudio.audios[index].play = false;
          }
        });
        this.props.updateAudiosMetadata(stateAudio);
      }
    }
  }

  pad(number) {
    return number < 10 ? '0' + number.toString() : number.toString();
  }

  roundTime(seconds) {
    let secondsRounded = Math.ceil(seconds);
    let minutesToShow = Math.floor(secondsRounded / 60);
    let secondsToShow = secondsRounded % 60;

    return `${this.pad(minutesToShow)}:${this.pad(secondsToShow)}`;
  }

  calculateProgress(currentTime, duration) {
    let percentage = currentTime / duration;
    return Math.round(100 * percentage);
  }

  render() {
    return (
      <div className="ap-indicators">
        <span className="ap-time-markers leftside">
          {this.roundTime(this.props.audio.currentTime)}
        </span>
        <ProgressBar
          current={this.props.audio.currentTime}
          total={this.props.audio.duration}
          modifyCurrentTime={this.modifyCurrentTime}
        />
        <span className="ap-time-markers rightside">
          {this.roundTime(this.props.audio.duration)}
        </span>
      </div>
    );
  }
}

export default AudioProgressBar;
