//
//  activity_tracking.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//

import ActivityTrackingManagerIOS from "./activity_tracking_ios";
import ActivityTrackingManagerAndroid from "./activity_tracking_android";
/**
 * ActivityTrackingManager is a ReactJS-client library
 * to interface with the Capacitor native plug-ins responsible
 * for activity tracking.
 */
export default class ActivityTrackingManager {
	constructor() {
		// eslint-disable-next-line no-undef
		this.instance = Capacitor.platform.startsWith('android')
			? new ActivityTrackingManagerAndroid()
			: new ActivityTrackingManagerIOS();
	}

	/**
	 * Configuring iOS for Activity Tracking requires defining
	 * network endpoints for background URL sessions
	 * @param {String} learnerId
	 * @return {Boolean} success/failure
	 */
	async configure(learnerId) {
		return await this.instance.configure(learnerId);
	}

	/**
	 * Start tracking activity in the background.
	 * Calls configure() for session before starting.
	 * Must call getPermissions before calling start.
	 * @param {String} learnerId
	 * @return {Boolean} success/failure
	 */
	async start(learnerId) {
		return await this.instance.start(learnerId);
	}

	/**
	 * STOP tracking activity in the background.
	 * @return {Boolean} success/failure
	 */
	async stop(userId) {
		return await this.instance.stop(userId);
	}

	/**
	 * Request native permissions to HealthKit (iOS) activity data.
	 * Permissions must be obtained before starting data collection.
	 * @return {Boolean} true if permissions were granted.
	 */
	async getPermissions(userId) {
		return await this.instance.getPermissions(userId);
	}
}