import PropTypes from 'prop-types';
import React from 'react';
import ReactImg from '../../react_img/ReactImg';
import emoji from 'react-easy-emoji';

const MultimediaElement = ({ multimediaObject, onClick }) => {
  if (!multimediaObject) {
    return null;
  }

  let renderOption = {
    youtube: renderVideo,
    vimeo: renderVideo,
    image: renderImage,
    emoji: renderEmoji,
    pdf: renderPdf,
    web: renderWeb
  };

  return renderOption[multimediaObject.type](multimediaObject, onClick) || null;
};

function renderVideo(multimediaObject) {
  return (
    <div className="resource-embed-container asset-video">
      <iframe
        src={parseVideoUrl(multimediaObject.url, multimediaObject.type)}
        title="video"
        width="100%"
        height="auto"
        frameBorder="0"
        allowFullScreen
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      />
    </div>
  );
}

function renderImage(multimediaObject, onClick) {
  return (
    <div className="resource-embed-image-container asset-image">
      <ReactImg
        onClick={onClick.bind(null, multimediaObject.url)}
        alt={multimediaObject.description}
        src={multimediaObject.url}
        width="100%"
        height="auto"
      />
    </div>
  );
}

function renderWeb(multimediaObject, onClick) {
  return (
    <button className="multimedia-web-link" onClick={onClick.bind(null, multimediaObject.url)}>
      {multimediaObject.title}
    </button>
  );
}

function renderEmoji(multimediaObject) {
  return <div className="multimedia-emoticon">{emoji(multimediaObject.url)}</div>;
}

function renderPdf(multimediaObject, onClick) {
  return (
    <div className="resource-embed-pdf-container asset-pdf">
      <object
        data={multimediaObject.url}
        onClick={onClick.bind(null, multimediaObject.url)}
        aria-label={multimediaObject.description}
        type="application/pdf"
        width="100%"
        height="100%"></object>
    </div>
  );
}

function parseVideoUrl(url, platform) {
  if (platform === 'youtube') {
    const videoUrl = url.split('=');
    return `https://www.youtube.com/embed/${videoUrl[1]}?rel=0&amp;controls=1&amp;showinfo=0`;
  }

  if (platform === 'vimeo') {
    const videoUrl = url.split('com');
    return `https://player.vimeo.com/video${videoUrl[1]}?color=ffffff&title=0&byline=0&portrait=1`;
  }
}

MultimediaElement.propTypes = {
  multimediaObject: PropTypes.object
};

export default MultimediaElement;
