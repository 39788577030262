import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Translate from '../../hocs/translate';
import classNames from 'classnames';
import _ from 'lodash';
import { hashHistory } from 'react-router';

export class CoursesElements extends Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      //enter key
      return this.props.toggle();
    }

    if (e.key === 'ArrowDown') {
      //arrow down
      return this.props.toggle();
    }
  }

  render() {
    return (
      <div className="cl-elements-container">
        {renderCoursesElements(
          this.props.courses,
          this.props.toggleCourseAttendance,
          this.props.toggleCertificate,
          this.props.strings,
          this.props.useRTL
        )}
      </div>
    );
  }
}

function renderCoursesElements(coursesData, toggleCourseAttendance, toggleCertificate, strings, useRTL) {
  coursesData = coursesData.filter((c) => c.online);
  return coursesData.map((course) => {
    const { type } = course;
    course = course.courseData ? course.courseData : course;
    course.id = course.id || course._id;
    const progressData = _.last(course.courseAttendances)
      ? _.last(course.courseAttendances).progress
      : null;
    let courseStyle = classNames({
      'cl-element-container': true,
      'cl-active-element': course.active,
      'cl-active-element-rtl': useRTL
    });
    let progressBarStyle = classNames({
      'progress-bar-progress': true,
      'progress-bar-completed': progressData && progressData.percentage === 100
    });
    let progressBarContainerStyle = classNames({
      'progress-bar-container-lesson': true,
      'progress-bar-container-completed': progressData && progressData.percentage === 100
    });
    let progressBarPercentageText = progressData
      ? progressData.percentage === 100
        ? strings.COMPLETED
        : `${progressData.percentage}% `
      : '0%' + strings.COMPLETED;

    return course.courseAttendances && course.courseAttendances.length > 0 ? (
      <button
        key={course.id}
        className={courseStyle}
        aria-label={`${course.displayTitle || course['course-name']} ${type}, ${progressData.percentage
          }% completed`}
        onClick={function () {
          setTimeout(
            hashHistory.push({
              pathname: '/course_details',
              search: `?courseCode=${course.code}`
            }),
            500
          );
        }}>
        <div className="cl-element-title-container">
          <span className="cl-course-name">{course.displayTitle || course['course-name']}</span>{' '}
        </div>
        <div className="cl-element-progressbar">
          <div className="course-details-progress">
            <div className={progressBarContainerStyle}>
              <div className="progress-bar-labels progress-bar-labels-lesson">
                {progressBarPercentageText}
              </div>
              <div
                className={progressBarStyle}
                style={{
                  width: `${(progressData !== null && progressData.percentage) || 0}%`
                }}></div>
            </div>
          </div>
        </div>
        {course.active && <div className="cl-course-active">{strings['Course in Progress']}</div>}
      </button>
    ) : (
      <button
        key={course.id}
        className="cl-element-container new-course"
        aria-label={`${course.displayTitle || course['course-name']} ${strings.NEW.toLowerCase()}`}
        onClick={function () {
          setTimeout(
            hashHistory.push({
              pathname: '/course_details',
              search: `?courseCode=${course.code}`
            }),
            500
          );
        }}>
        <div className="cl-element-title-container">
          <span className="cl-course-name">{course.displayTitle || course['course-name']}</span>
        </div>
        <div className="cl-element-action">{strings.NEW}</div>
      </button>
    );
  });
}

CoursesElements.propTypes = {
  toggleCourseAttendance: PropTypes.func.isRequired,
  toggleCertificate: PropTypes.func.isRequired,
  courses: PropTypes.array.isRequired
};

CoursesElements.defaultProps = {
  strings: {
    'Active Course': 'Active Course',
    'NOT TAKEN': 'NOT TAKEN',
    'In Progress': 'In Progress',
    Completed: 'Completed',
    VIEW: 'VIEW',
    'NO CERTIFICATE': 'NO CERTIFICATE',
    REDO: 'REDO',
    Continue: 'Continue',
    START: 'START',
    NEW: 'NEW',
    COMPLETED: 'COMPLETED',
    Courses: 'Courses',
    'Course in Progress': 'Course in Progress'
  }
};

export default Translate('CoursesElements')(CoursesElements);
