import { LOAD_CONTEXT,
	RELOAD_CONTEXT,
	SUBMIT_ANSWER,
	AUDIO_FINISHED,
	ENABLE_INPUT,
	ADD_MESSAGE,
	WALK_THROUGH_FINISHED,
	TOGGLE_COURSE_DETAILS,
	RECONNECT_TRY,
	RECORD_START,
	JUMP,
	LOAD_HISTORY_NEXT_PAGE,
	UPDATE_FULL_SCREEN_FRAME
} from '../constants'
import { getDate } from '../utils/time_format';
import config from '../config.json';



export function loadContext (userId) {
	return {
		type: LOAD_CONTEXT,
		payload: userId,
	}
}

export function reLoadContext (userId) {
	return {
		type: RELOAD_CONTEXT,
		payload: userId,
	}
}

export function audioFinished () {
	return {
		type: AUDIO_FINISHED,
	}
}

export function enableUserInput () {
	return {
		type: ENABLE_INPUT,
	}
}

export function submitAnswer (answer, from, type = 'text') {
	return {
		type: SUBMIT_ANSWER,
		payload: { answer, from, date: config.showDatesInBubbles ? getDate() : null, type: type },
	}
}

export function addMessage (text) {
	const message = {
		'text': text,
		'text-align': 'left',
		'date': config.showDatesInBubbles ? getDate() : null,
	};

	return {
		type: ADD_MESSAGE,
		payload: message,
	}
}

export function walkTroughFinished () {
	return {
		type: WALK_THROUGH_FINISHED,
	}
}

export function toggleCourseDetails (openOrClose) {
	return {
		type: TOGGLE_COURSE_DETAILS,
		payload: openOrClose,
	}
}

export function manualReconnect () {
	return {
		type: RECONNECT_TRY,
	}
}

export function startRecording () {
	return {
		type: RECORD_START,
	}
}

export function stopRecording (answer, from, type = 'audio') {
	return {
		type: SUBMIT_ANSWER,
		payload: { answer, from, date: getDate(), type: type },
	}
}

export function jump (jumpURL, next) {
	return {
		type: JUMP,
		payload: { jumpURL: jumpURL, next: next },
	}
}

export function loadPreviousHistory(url, pageNumber) {
	return {
		type: LOAD_HISTORY_NEXT_PAGE,
		history: { 
			url: url,
			pageNumber: pageNumber
		}
	}
}

export function openFullScreenFrame(url) {
	return {
		type: UPDATE_FULL_SCREEN_FRAME,
		payload: url
	}
}

export function closeFullScreenFrame() {
	return {
		type: UPDATE_FULL_SCREEN_FRAME,
		payload: null
	}
}