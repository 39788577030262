//
//  screen_on_component.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//
import { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Plugins, Capacitor } from '@capacitor/core'
const { CapacitorKeepScreenOn } = Plugins;

class ScreenOnComponent extends Component {

	constructor (props) {
		super(props);

		this.setScreenOn = this.setScreenOn.bind(this);
		this.keepScreenOn = null;
	}

	setScreenOn (value) {
		if (!Capacitor.isPluginAvailable('CapacitorKeepScreenOn')) return false; // update required to use this feature
		if (!_.isBoolean(value) || value === this.keepScreenOn) return false; // already set

		if (value === true) {
			CapacitorKeepScreenOn.enable();
		} else if (value === false) {
			CapacitorKeepScreenOn.disable();
		}

		this.keepScreenOn = value;
	}

	componentDidUpdate(prevProps) {
		if (this.props.audioData.isPlaying !== this.keepScreenOn) {
			this.setScreenOn(this.props.audioData.isPlaying);
		}
	}

	render () {
		return null;
	}
}

function mapStateToProps (state) {
	return { audioData: state.audio };
}

export default connect(mapStateToProps, null)(ScreenOnComponent);
