import React from 'react';
import PropTypes from 'prop-types';

const PrevArrow = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="10"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0942 9.49997H0.5V0.780396L3.79037 4.11876C5.37402 2.50998 7.54995 1.49997 9.96915 1.49997C14.1009 1.49997 17.5512 4.38365 18.4861 8.26694L18.5931 8.71122L16.3101 9.4833L16.1877 8.94239C15.5329 6.05064 12.9942 3.89997 9.96915 3.89997C8.2051 3.89997 6.6199 4.63732 5.46863 5.82151L9.0942 9.49997ZM1.00006 2.00003V8.99997H1V1.99997L1.00006 2.00003ZM3.80115 4.84196C3.91107 4.71624 4.02453 4.59383 4.14138 4.4749C5.63814 2.95158 7.69086 1.99997 9.96915 1.99997C10.8694 1.99997 11.7358 2.14536 12.5466 2.41428C11.7358 2.14537 10.8695 2 9.96921 2C7.51304 2 5.31905 3.106 3.80119 4.842L3.80115 4.84196ZM16.6754 8.83196C16.2475 6.94244 15.07 5.3427 13.4775 4.37878C14.9319 5.25916 16.0402 6.66992 16.5474 8.34742C16.5955 8.50661 16.6382 8.6682 16.6753 8.83197L16.6754 8.83196Z"
        fill={fill}
      />
    </svg>
  );
};

PrevArrow.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
};

PrevArrow.defaultProps = {
  fill: '#333333',
  className: ''
};

export default PrevArrow;
