//
//  branding_component.js
//  Cell-Ed
//
//  Copyright © 2020 Cell-Ed. All rights reserved.
//
import React, { Component } from 'react';
import { connect } from 'react-redux';

import BrandingManager from '../../lib/branding/branding_manager';
import SpecialFeature from '../testing/special_feature';

class BrandingComponent extends Component {

	componentDidUpdate(prevProps) {
		if (this.props.brandingTheme !== prevProps.brandingTheme) {
			BrandingManager.saveAndLoad(this.props.brandingTheme);
		}
	}

	render () {
		// using composition instead of inheritance, but achieving the same effect --
		// the SpecialFeature class takes care of loading preferences that may be
		// considered 'special features,' branding is one of them.
		return <SpecialFeature/>;
	}
}

function mapStateToProps (state) {
	return { brandingTheme: state.preferences.preferencesData.brandingTheme }
}

export default connect(mapStateToProps, null)(BrandingComponent);
