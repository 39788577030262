import _ from 'lodash';
import {
  LOAD_COURSES_SUCCESS,
  LOAD_COURSES_FAILED,
  SWITCH_COURSE_SUCCESS,
  SWITCH_COURSE_FAILED,
  TOGGLE_CERTIFICATE,
  SHOW_CERTIFICATE,
  TOGGLE_COURSE_ATTENDANCES,
} from '../constants';

const initialState = {
  loadingList: true,
  loadingGroups: true,
  courses: [],
  coursesGroups: [],
  failed: false,
  courseSwitched: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_COURSES_SUCCESS:
      return {
        ...state,
        courses: setCoursesAttendancesView(
          action.payload.courses,
          action.payload.courseId
        ),
        loadingList: false,
        courseSwitched: false,
      };
    case LOAD_COURSES_FAILED:
      return { ...state, failed: true, courseSwitched: false };
    case SWITCH_COURSE_SUCCESS:
      return {
        ...state,
        courseSwitched: true,
      };
    case SWITCH_COURSE_FAILED:
      return { ...state, courseSwitched: false };

    case TOGGLE_CERTIFICATE: {
      let newCoursesArray = state.courses.slice();
      return {
        ...state,
        courses: toggleCertificateView(newCoursesArray, action.payload),
      };
    }
    case TOGGLE_COURSE_ATTENDANCES: {
      let newCoursesArray = state.courses.slice();
      return {
        ...state,
        courses: toggleCoursesAttendancesView(newCoursesArray, action.payload),
      };
    }
    case SHOW_CERTIFICATE: {
      let newCoursesArray = state.courses.slice();
      return {
        ...state,
        courses: toggleCertificateView(newCoursesArray, action.payload, true),
      };
    }
    default:
      return { ...state, failed: true, courseSwitched: false };
  }
}

function toggleCertificateView(
  coursesArray,
  courseAttendanceId,
  showAlways = false
) {
  const courses = coursesArray.map((course) => {
    if (course.courseAttendances) {
      course.courseAttendances.map((courseAttendance) => {
        if (courseAttendance.id === courseAttendanceId) {
          courseAttendance.showCertificate =
            showAlways || !courseAttendance.showCertificate;
        }
        return courseAttendance;
      });
    }
    return course;
  });

  return courses;
}

function toggleCoursesAttendancesView(
  coursesArray,
  courseId,
  showAlways = false
) {
  const courses = coursesArray.map((course) => {
    if (course.id === courseId) {
      course.showCoursesAttendances =
        showAlways || !course.showCoursesAttendances;
    }
    return course;
  });
  return courses;
}

function setCoursesAttendancesView(coursesArray, courseAttendanceId) {
  _.forEach(coursesArray, function (course) {
    _.forEach(course.courseAttendances, function (ca) {
      if (ca.id === courseAttendanceId) {
        course.showCoursesAttendances = true;
      }
    });
  });
  return coursesArray;
}
