import es from '../../i18n/es';
// import fr from '../../i18n/fr';

// Main app

export const GET_USER_ID = 'GET_USER_ID';
export const USER_IS_LOGGED = 'USER_IS_LOGGED';
export const WALK_THROUGH_FINISHED = 'WALK_THROUGH_FINISHED';
export const INTRO_PASSED = 'INTRO_PASSED';
export const GET_DEVICE_INFO = 'GET_DEVICE_INFO';
export const GET_QUESTIONNAIRES_ON_APP = 'GET_QUESTIONNAIRES_ON_APP';
export const GET_QUESTIONNAIRES_ON_APP_SUCCESS = 'GET_QUESTIONNAIRES_ON_APP_SUCCESS';
export const GET_VERSION_TAG = 'GET_VERSION_TAG';
export const GET_VERSION_TAG_SUCCESS = 'GET_VERSION_TAG_SUCCESS';
export const GET_SUPPORTED_LANGUAGES = 'GET_SUPPORTED_LANGUAGES';
export const GET_SUPPORTED_LANGUAGES_SUCCESS = 'GET_SUPPORTED_LANGUAGES_SUCCESS';

// Login flow

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGOUT = 'LOGOUT';
export const LOGIN_RESET = 'LOGIN_RESET';

// Signup flow

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_EXISTING_LEARNER = 'SIGNUP_EXISTING_LEARNER';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNUP_SUCCESSFUL = 'SIGNUP_SUCCESSFUL';
export const SIGNUP_CONFIRMATION_REQUEST = 'SIGNUP_CONFIRMATION_REQUEST';
export const SIGNUP_CONFIRMATION_FAILED = 'SIGNUP_CONFIRMATION_FAILED';
export const SIGNUP_CONFIRMATION_SUCCESSFUL = 'SIGNUP_CONFIRMATION_SUCCESSFUL';
export const CHANGE_PHONE_NUMBER = 'CHANGE_PHONE_NUMBER';
export const RESEND_CONFIRM_CODES_REQUEST = 'RESEND_CONFIRM_CODES_REQUEST';
export const RESEND_CONFIRMATION_CODES_SUCCESSFUL = 'RESEND_CONFIRMATION_CODES_SUCCESSFUL';
export const RESEND_CONFIRMATION_CODES_FAILED = 'RESEND_CONFIRMATION_CODES_FAILED';
export const SIGNUP_PUBLIC_ACCOUNT_DETAILS_REQUEST = 'SIGNUP_PUBLIC_ACCOUNT_DETAILS_REQUEST';
export const SIGNUP_PUBLIC_ACCOUNT_DETAILS_SUCCESSFUL = 'SIGNUP_PUBLIC_ACCOUNT_DETAILS_SUCCESSFUL';
export const SIGNUP_PUBLIC_ACCOUNT_DETAILS_RESET = 'SIGNUP_PUBLIC_ACCOUNT_DETAILS_RESET';
export const VERIFY_PINCODE = 'VERIFY_PIN_CODE';
export const VERIFY_PINCODE_SUCCESSFUL = 'VERIFY_PIN_CODE_SUCCESSFUL';
export const VERIFY_PINCODE_FAILED = 'VERIFY_PIN_CODE_FAILED';
export const RESET_PINCODE_TRY = 'RESET_PINCODE_TRY';

// Notifications

export const CLEAR_SINGLE_NOTIFICATION = 'CLEAR_SINGLE_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

// i18n

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

// timeout

export const TIMEOUT_ACTIVATE = 'TIMEOUT_ACTIVATE';
export const TIMEOUT_RESET = 'TIMEOUT_RESET';
export const TIMEOUT_TRIGGERED = 'TIMEOUT_TRIGGERED';
export const TIMEOUT_LOGOUT = 'TIMEOUT_LOGOUT';
export const TIMEOUT_DISMISSED = 'TIMEOUT_DISMISSED';

// main course
export const RELOAD_CONTEXT = 'RELOAD_CONTEXT';
export const PROCESS_RELOAD_CONTEXT = 'PROCESS_RELOAD_CONTEXT';
export const LOAD_CONTEXT = 'LOAD_CONTEXT';
export const CALL_REDIRECT = 'CALL_REDIRECT';
export const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
export const ENABLE_INPUT = 'ENABLE_INPUT';
export const PLAY_AUDIO = 'PLAY_AUDIO';
export const AUDIO_FINISHED = 'AUDIO_FINISHED';
export const PROCESS_ACTIONS = 'PROCESS_ACTIONS';
export const MODULE_SUCCESSFULLY_LOADED = 'MODULE_SUCCESSFULLY_LOADED';
export const MODULE_SESSION_EXPIRED = 'MODULE_SESSION_EXPIRED';
export const MODULE_LOAD_FAILED = 'MODULE_LOAD_FAILED';
export const VALIDATION_FAILED = 'VALIDATION_FAILED';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_HEADER_MESSAGE = 'ADD_HEADER_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const UPDATE_UI = 'UPDATE_UI';
export const LOAD_HISTORY = 'LOAD_HISTORY';
export const LOAD_HISTORY_NEXT_PAGE = 'LOAD_HISTORY_NEXT_PAGE';
export const SAVE_AUDIO_CURRENT_TIME = 'SAVE_AUDIO_CURRENT_TIME';
export const STOP_SHOWING_WALK_THROUGH = 'STOP_SHOWING_WALK_THROUGH';
export const CHAT_ROOM_UNMOUNTED = 'CHAT_ROOM_UNMOUNTED';
export const RECORD_START = 'RECORD_START';
export const RECORD_STOP = 'RECORD_STOP';
export const JUMP = 'JUMP';

// reconnect

export const RECONNECT_ACTIVATE = 'RECONNECT_ACTIVATE';
export const RECONNECT_DEACTIVATE = 'RECONNECT_DEACTIVATE';
export const RECONNECT_TRY = 'RECONNECT_TRY';

// audio

export const PLAY_PAUSE_AUDIO = 'PLAY_PAUSE_AUDIO';
export const UPDATE_AUDIO_PROGRESS = 'UPDATE_AUDIO_PROGRESS';
export const CLICK_PROGRESS_AUDIO_PROGRESS_BAR = 'CLICK_PROGRESS_AUDIO_PROGRESS_BAR';
export const PLAY_AUDIO_CONTROL = 'PLAY_AUDIO_CONTROL';
export const PAUSE_AUDIO_CONTROL = 'PAUSE_AUDIO_CONTROL';
export const REMOVE_AUDIO_MODAL = 'REMOVE_AUDIO_MODAL';
export const BUFFERING = 'BUFFERING';
export const UPDATE_AUDIO_METADATA = 'UPDATE_AUDIO_METADATA';

// actions from server

export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const SET_UI_ELEMENT = 'SET_UI_ELEMENT';
export const PLAY_ACTION = 'PLAY_ACTION';
export const PLAY_ACTION_ERROR = 'PLAY_ACTION_ERROR';
export const MESSAGE_ACTION = 'MESSAGE_ACTION';
export const MESSAGE_HEADER_ACTION = 'MESSAGE_HEADER_ACTION';
export const WAIT_FOR_USER_INPUT_ACTION = 'WAIT_FOR_USER_INPUT_ACTION';
export const LOAD_NEXT_MODULE_ACTION = 'LOAD_NEXT_MODULE_ACTION';
export const CLEAR_MESSAGES_ACTION = 'CLEAR_MESSAGES_ACTION';
export const SHOW_DETAIL = 'SHOW_DETAIL';
export const REDIRECT = 'REDIRECT';

// Step Events
export const STEP_EVENTS = Object.freeze({
  PLATFORM_CLIENT_EXERCISE_TIMER_START_EVENT: 'PLATFORM_CLIENT_EXERCISE_TIMER_START_EVENT',
  PLATFORM_CLIENT_EXERCISE_TIMER_END_EVENT: 'PLATFORM_CLIENT_EXERCISE_TIMER_END_EVENT',
  PLATFORM_CLIENT_ACTIVITY_TRACKING_CONSENTED: 'PLATFORM_CLIENT_ACTIVITY_TRACKING_CONSENTED'
});

export const SAVE_LEARNER_LAST_TIME = 'SAVE_LEARNER_LAST_TIME';

// Chat

export const SHOW_CERTIFICATE = 'SHOW_CERTIFICATE';
export const SHOW_CERTIFICATE_INIT = 'SHOW_CERTIFICATE_INIT';
export const FETCH_CERTIFICATE = 'FETCH_CERTIFICATE';
export const FETCH_CERTIFICATE_INIT = 'FETCH_CERTIFICATE_INIT';
export const FETCH_CERTIFICATE_FAIL = 'FETCH_CERTIFICATE_FAIL';
export const GET_CERTIFICATE_THUMBNAIL = 'GET_CERTIFICATE_THUMBNAIL';
export const GET_CERTIFICATE_THUMBNAIL_SUCCESS = 'GET_CERTIFICATE_THUMBNAIL_SUCCESS';
export const GET_CERTIFICATE_THUMBNAIL_FAIL = 'GET_CERTIFICATE_THUMBNAIL_FAIL';
export const DOWNLOAD_CERTIFICATE_INIT = 'DOWNLOAD_CERTIFICATE_INIT';
export const DOWNLOADING_CERTIFICATE = 'DOWNLOADING_CERTIFICATE';
export const DOWNLOADING_CERTIFICATE_FAIL = 'DOWNLOADING_CERTIFICATE_FAIL';
export const TOGGLE_SWITCH_MODAL = 'TOGGLE_SWITCH_MODAL';
export const TOGGLE_SWITCH_QUESTIONNAIRE_MODAL = 'TOGGLE_SWITCH_QUESTIONNAIRE_MODAL';
export const TOGGLE_SWITCH_COURSE_MODAL = 'TOGGLE_SWITCH_COURSE_MODAL';
export const TOGGLE_SWITCH_LESSON_MODAL = 'TOGGLE_SWITCH_LESSON_MODAL';

// Me

export const TOGGLE_COURSES = 'TOGGLE_COURSES';
export const TOGGLE_RESOURCES = 'TOGGLE_RESOURCES';
export const TOGGLE_PROFILE = 'TOGGLE_PROFILE';
export const TOGGLE_ACHIEVEMENTS = 'TOGGLE_ACHIEVEMENTS';
export const TOGGLE_LIVECOACH = 'TOGGLE_LIVECOACH';
export const DISPLAY_WIZENOZE = 'DISPLAY_WIZENOZE';
export const CLOSE_WIZENOZE = 'CLOSE_WIZENOZE';

// Help

export const TOGGLE_LIVE_COACH = 'TOGGLE_LIVE_COACH';
export const TOGGLE_CONTACT_US = 'TOGGLE_CONTACT_US';
export const TOGGLE_FAQ = 'TOGGLE_FAQ';
export const SEND_EMAIL = 'SEND_EMAIL';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const EMAIL_FAILED = 'EMAIL_FAILED';
export const LOAD_HELP_DATA = 'LOAD_HELP_DATA';
export const LOAD_HELP_DATA_SUCCESS = 'LOAD_HELP_DATA_SUCCESS';
export const GET_COACH_INFO = 'GET_COACH_INFO';
export const GET_COACH_INFO_SUCCESS = 'GET_COACH_INFO_SUCCESS';

// Courses list

export const LOAD_COURSES_LIST = 'LOAD_COURSES_LIST';
export const LOAD_COURSES_SUCCESS = 'LOAD_COURSES_SUCCESS';
export const LOAD_COURSES_FAILED = 'LOAD_COURSES_FAILED';
export const LOAD_COURSES_GROUPS = 'LOAD_COURSES_GROUPS';
export const TOGGLE_CERTIFICATE = 'TOGGLE_CERTIFICATE';
export const TOGGLE_COURSE_ATTENDANCES = 'TOGGLE_COURSE_ATTENDANCES';

// Course details

export const LOAD_COURSES_DETAILS = 'LOAD_COURSES_DETAILS';
export const LOAD_COURSES_DETAILS_SUCCESS = 'LOAD_COURSES_DETAILS_SUCCESS';
export const LOAD_COURSES_DETAILS_FAILED = 'LOAD_COURSES_DETAILS_FAILED';
export const TOGGLE_COURSE_DETAILS = 'TOGGLE_COURSE_DETAILS';

// Preferences

export const LOAD_SURVEY_DATA = 'LOAD_SURVEY_DATA';
export const LOAD_SURVEY_DATA_SUCCESS = 'LOAD_SURVEY_DATA_SUCCESS';
export const LOAD_SURVEY_DATA_FAILED = 'LOAD_SURVEY_DATA_FAILED';
export const LOAD_USER_PREFERENCES = 'LOAD_USER_PREFERENCES';
export const LOAD_USER_PREFERENCES_SUCCESS = 'LOAD_USER_PREFERENCES_SUCCESS';
export const LOAD_USER_PREFERENCES_FAILED = 'LOAD_USER_PREFERENCES_FAILED';
export const TOGGLE_SMS_REMINDER = 'TOGGLE_SMS_REMINDER';
export const LOAD_APP_LINK_PREFERENCES_SUCCESS = 'LOAD_APP_LINK_PREFERENCES_SUCCESS';
export const TOGGLE_USE_RTL = 'TOGGLE_USE_RTL';

// Reset Password

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_CONFIRM = 'FORGOT_PASSWORD_CONFIRM';
export const FORGOT_PASSWORD_SUCCESSFUL = 'FORGOT_PASSWORD_SUCCESSFUL';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const RESEND_FORGOT_PASSWORD_REQUEST = 'RESEND_FORGOT_PASSWORD_REQUEST';
export const RESEND_FORGOT_PASSWORD_SUCCESSFUL = 'RESEND_FORGOT_PASSWORD_SUCCESSFUL';
export const RESEND_FORGOT_PASSWORD_FAILED = 'RESEND_FORGOT_PASSWORD_FAILED';
export const RESET_PASSWORD_CONFIRMATION_REQUEST = 'RESET_PASSWORD_CONFIRMATION_REQUEST';
export const RESET_PASSWORD_CONFIRMATION_SUCCESSFUL = 'RESET_PASSWORD_CONFIRMATION_SUCCESSFUL';
export const RESET_PASSWORD_CONFIRMATION_FAILED = 'RESET_PASSWORD_CONFIRMATION_FAILED';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESSFUL = 'RESET_PASSWORD_SUCCESSFUL';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_RESET_STATE = 'RESET_PASSWORD_RESET_STATE';

// Demo
export const DEMO_REQUEST = 'DEMO_REQUEST';
export const DEMO_REQUEST_SUCCEEDED = 'DEMO_REQUEST_SUCCEEDED';
export const DEMO_REQUEST_FAILED = 'DEMO_REQUEST_FAILED';

// Easy Login
export const EASY_LOGIN_REQUEST = 'EASY_LOGIN_REQUEST';

// Simple Login
export const SIMPLE_LOGIN_REQUEST = 'SIMPLE_LOGIN_REQUEST';
export const SIMPLE_LOGIN_ENTER_PHONE = 'SIMPLE_LOGIN_ENTER_PHONE';
export const SIMPLE_LOGIN_NEW_LEARNER = 'SIMPLE_LOGIN_NEW_LEARNER';
export const SIMPLE_LOGIN_CREATE_PASSWORD = 'SIMPLE_LOGIN_CREATE_PASSWORD';
export const SIMPLE_LOGIN_ENTER_PASSWORD = 'SIMPLE_LOGIN_ENTER_PASSWORD';
export const SIMPLE_LOGIN_CONFIRM_CODE = 'SIMPLE_LOGIN_CONFIRM_CODE';

// Switch Course
export const SWITCH_COURSE_REQUEST = 'SWITCH_COURSE_REQUEST';
export const SWITCH_COURSE = 'SWITCH_COURSE';
export const SWITCH_LESSON_REQUEST = 'SWITCH_LESSON_REQUEST';
export const SWITCH_LESSON = 'SWITCH_LESSON';
export const SWITCH_COURSE_SUCCESS = 'SWITCH_COURSE_SUCCESS';
export const SWITCH_COURSE_FAILED = 'SWITCH_COURSE_FAILED';

// Switch Questionnaire
export const SWITCH_QUESTIONNAIRE = 'SWITCH_QUESTIONNAIRE';
export const SWITCH_QUESTIONNAIRE_REQUEST = 'SWITCH_QUESTIONNAIRE_REQUEST';
export const SWITCH_QUESTIONNAIRE_FAILED = 'SWITCH_QUESTIONNAIRE_FAILED';

// Languages

export const ENGLISH = 'English';
export const ENGLISH_KEY = 'en';
export const LANGUAGES = {
  es
  // fr
};

// Push Notifications
export const UPDATE_USER_PUSH_TOKEN = 'UPDATE_USER_PUSH_TOKEN';

export const UPDATE_FULL_SCREEN_FRAME = 'UPDATE_FULL_SCREEN_FRAME';

export const SHOW_FLOAT_BUBBLE = 'SHOW_FLOAT_BUBBLE';

// Achievements
export const GET_ACHIEVEMENTS = 'GET_ACHIEVEMENTS';
export const GET_ACHIEVEMENTS_SUCCESS = 'GET_ACHIEVEMENTS_SUCCESS';
export const GET_ACHIEVEMENTS_ERROR = 'GET_ACHIEVEMENTS_ERROR';
