import React from 'react';
import PropTypes from 'prop-types';
import './icon-plus.scss';

const IconPlus = ({ className, minus }) => {
  return (
    <div className={`icon-plus ${minus ? `icon-plus--is-minus ${className}` : className}`}>
      <div className="icon-plus__bar icon-plus__bar--first" />
      <div className="icon-plus__bar icon-plus__bar--second " />
    </div>
  );
};

IconPlus.propTypes = {
  minus: PropTypes.bool,
  className: PropTypes.string
};

IconPlus.defaultProps = {
  minus: false,
  className: ''
};

export default IconPlus;
