import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Translate from '../../hocs/translate';
import AchievementsList from '../../components/achievements/achievements_list';
import './achievements-main.scss';

const Achievements = ({ achievements, language, strings }) => {
  const [showTotal, setShowTotal] = useState(true);
  const thisWeekRange = achievements.find((achievement) => achievement.duration === 'week');

  const handleCheckbox = () => {
    setShowTotal((prevState) => !prevState);
  };

  return (
    <div className="achievements">
      <div className="achievements__switch">
        <input
          className="achievements__check-input"
          id="achievements-checkbox"
          type="checkbox"
          checked={showTotal}
          onChange={() => handleCheckbox()}
        />
        <label htmlFor="achievements-checkbox" className="achievements__check-label">
          <div className="achievements__check-label-item achievements__check-label-item--left">
            <span className="achievements__check-label-title">{strings['this week']}</span>
            <span className="achievements__check-label-subtitle">
              {thisWeekRange[language]['sub-title']}
            </span>
          </div>
          <div className="achievements__check-label-item achievements__check-label-item--right">
            <span className="achievements__check-label-title">{strings.total}</span>
            <span className="achievements__check-label-subtitle">{strings['All Time']}</span>
          </div>
        </label>
      </div>

      <AchievementsList
        achievementsList={achievements.filter(
          (list) => list.duration === (showTotal ? 'total' : 'week')
        )}
        language={language}
      />
    </div>
  );
};

Achievements.defaultProps = {
  strings: {
    total: 'total',
    'this week': 'this week',
    'All Time': 'All Time'
  }
};

Achievements.propTypes = {
  achievements: PropTypes.array.isRequired
};

export default Translate('Achievements')(Achievements);
