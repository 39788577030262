import axios from 'axios';
import { store } from '../../../store';
import { RECONNECT_ACTIVATE } from '../../constants';

function getHeadersWithToken() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'b6fbc1fd-7bb6-44f0-9729-e4250fce550e',
    },
  };
}
// create axios instance where when a request fails, an "RECONNECT_ACTIVATE" action will be dispatched
// together with the request payload

function handleErrorsInterceptor(err) {
  if (err.message === 'Network Error') {
    store.dispatch({ type: RECONNECT_ACTIVATE, payload: err.config });
  }
  throw err;
}

export const axiosRetryOn = axios.create();

axiosRetryOn.interceptors.response.use(undefined, handleErrorsInterceptor);

const client = {
  searchRequest(payload) {
    let searchTerm = payload.searchTerm;
    let level = payload.level;
    let requestedPage = payload.requestedPage;

    console.log(`Searching for ${searchTerm}`);
    const engineId = '8302';
    const readabilityLevel = level || 1;
    const age = 12;
    var endpoint = `https://api.wizenoze.com/v1/customSearchEngines/${engineId}/search?q=${searchTerm}&startPage=${requestedPage}&readabilityLevel=${readabilityLevel}&age=${age}`;
    return axios.post(endpoint, null, getHeadersWithToken());
  },
};

export default client;
