import { take, put, call } from 'redux-saga/effects';
import client from '../lib/celled_client';
import { GET_VERSION_TAG, GET_SUPPORTED_LANGUAGES } from '../constants';
import { getVersionTagSuccess, getSupportedLanguagesSuccess } from '../actions/main_app';
import { checkCommonErrors } from '../lib/auth';

export function* getVersionTagSaga() {
  while (yield take(GET_VERSION_TAG)) {
    try {
      const versionTag = yield call(client.getVersionTag);
      yield put(getVersionTagSuccess(versionTag.data.version_tag));
    } catch (error) {
      const errorPayload = {
        type: 'GET_VERSION_TAG_FAILED',
        notification: {
          message: 'Error: load version tag data failed',
          className: 'error',
          type: 'getVersionTag'
        }
      };
      yield put(checkCommonErrors(error, errorPayload));
    }
  }
}

export function* getSupportedLanguagesSaga() {
  while (yield take(GET_SUPPORTED_LANGUAGES)) {
    try {
      const supportedLanguages = yield call(client.getSupportedLanguages);
      yield put(getSupportedLanguagesSuccess(supportedLanguages.data));
    } catch (error) {
      const errorPayload = {
        type: 'GET_SUPPORTED_LANGUAGES_FAILED',
        notification: {
          message: 'Error: load supported language data failed',
          className: 'error',
          type: 'getSupportedLanguages'
        }
      };
      yield put(checkCommonErrors(error, errorPayload));
    }
  }
}

export default function* rootMainAppSaga() {
  yield [getVersionTagSaga(), getSupportedLanguagesSaga()];
}
