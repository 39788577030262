import {
  SIGNUP_REQUEST,
  SIGNUP_EXISTING_LEARNER,
  SIGNUP_CONFIRMATION_REQUEST,
  RESEND_CONFIRM_CODES_REQUEST,
  CHANGE_PHONE_NUMBER,
  SIGNUP_PUBLIC_ACCOUNT_DETAILS_REQUEST,
  SIGNUP_PUBLIC_ACCOUNT_DETAILS_RESET,
  VERIFY_PINCODE
} from '../constants';

function cleanPhoneNumber(number) {
  var cleanNumber = number.replace(/\(|\)|\s|-/g, '');
  return cleanNumber;
}

export function resetPublicAccountDetails() {
  return {
    type: SIGNUP_PUBLIC_ACCOUNT_DETAILS_RESET
  };
}

export function getSignupPublicAccountDetails(pincode) {
  return {
    type: SIGNUP_PUBLIC_ACCOUNT_DETAILS_REQUEST,
    payload: pincode
  };
}

export function signupRequest(credentials) {
  credentials.formattedNumber = credentials.phoneNumber;
  credentials.phoneNumber = cleanPhoneNumber(credentials.phoneNumber);
  return {
    type: SIGNUP_REQUEST,
    payload: credentials
  };
}

export function signupExistingLearnerRequest(credentials) {
  credentials.formattedNumber = credentials.phoneNumber;
  credentials.phoneNumber = cleanPhoneNumber(credentials.phoneNumber);
  return {
    type: SIGNUP_EXISTING_LEARNER,
    payload: credentials
  };
}

export function signupConfirmation(confirmationData) {
  return {
    type: SIGNUP_CONFIRMATION_REQUEST,
    payload: confirmationData
  };
}

export function requestNewConfirmationCode(userId) {
  return {
    type: RESEND_CONFIRM_CODES_REQUEST,
    userId: userId
  };
}

export function changePhoneNumber() {
  return {
    type: CHANGE_PHONE_NUMBER
  };
}

export function verifyPincode(pincode) {
  return {
    type: VERIFY_PINCODE,
    payload: pincode
  };
}
