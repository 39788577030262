import { LOGIN_REQUEST, LOGIN_FAILED, LOGIN_SUCCESSFUL, LOGIN_RESET } from '../constants';

const initialState = {
  failed: false,
  success: false,
  waitingResponse: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, failed: false, waitingResponse: true };
    case LOGIN_FAILED:
      return { ...state, failed: true, waitingResponse: false };
    case LOGIN_SUCCESSFUL:
      return { ...state, failed: false, success: true, waitingResponse: false };
    case LOGIN_RESET:
      return initialState;
    default:
      return state;
  }
}
