import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import BrandingManager from './js/lib/branding/branding_manager';
import { Provider } from 'react-redux';
import store from './store';
import Routes from './js/routes';
import './styles/app.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

/* 
// [WEBAPP-2355]: LogRocket disabled until we figure how to deal with their account limits
import LogRocket from 'logrocket';
const LOG_ROCKET_APP_ID = process.env.LOG_ROCKET_APP_ID || 'hmf0yg/webapp';
LogRocket.init(LOG_ROCKET_APP_ID); */

require('velocity-animate');
require('velocity-animate/velocity.ui');
// import { DockableSagaView, createSagaMonitor } from 'redux-saga-devtools'

/** Branding Theme Loader **/
BrandingManager.load();

/** Render Webapp **/
ReactDOM.render(
  <div>
    <Provider store={store}>{Routes}</Provider>
  </div>,
  document.querySelector('.app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//
// Disabled by https://github.com/Cell-Ed/celled-2/pull/971 due to conflicts
// with Capacitor: https://github.com/ionic-team/capacitor/issues/3485
serviceWorkerRegistration.unregister();
