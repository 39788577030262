import { put, take, call } from 'redux-saga/effects';
import {
  SIGNUP_REQUEST,
  SIGNUP_EXISTING_LEARNER,
  SIGNUP_FAILED,
  SIGNUP_SUCCESSFUL,
  SIGNUP_CONFIRMATION_REQUEST,
  SIGNUP_CONFIRMATION_FAILED,
  SIGNUP_CONFIRMATION_SUCCESSFUL,
  RESEND_CONFIRM_CODES_REQUEST,
  RESEND_CONFIRMATION_CODES_SUCCESSFUL,
  RESEND_CONFIRMATION_CODES_FAILED,
  SIGNUP_PUBLIC_ACCOUNT_DETAILS_REQUEST,
  SIGNUP_PUBLIC_ACCOUNT_DETAILS_SUCCESSFUL,
  VERIFY_PINCODE,
  VERIFY_PINCODE_SUCCESSFUL,
  VERIFY_PINCODE_FAILED,
  RESET_PINCODE_TRY
} from '../constants';
import apiClient from '../lib/celled_client';

export function* signup() {
  while (true) {
    const signupRequest = yield take(SIGNUP_REQUEST);
    try {
      const signupSuccess = yield call(apiClient.signupRequest, signupRequest.payload);
      yield put({
        type: SIGNUP_SUCCESSFUL,
        payload: signupSuccess,
        notification: {
          message: signupSuccess.data.message,
          type: 'signup',
          unique: true
        }
      });
      if (signupSuccess.data.token) {
        yield put({
          type: SIGNUP_CONFIRMATION_SUCCESSFUL,
          token: signupSuccess.data.token,
          userId: signupSuccess.data.userId,
          firstTime: signupSuccess.data.firstTime,
          notification: {
            message: 'Account successfully created',
            type: 'signup',
            unique: true
          }
        });
      }
    } catch (error) {
      let errorMessage = 'Connection problem';
      let avoidUINotification = false;

      if (error.response) {
        errorMessage = error.response.data.message;
        avoidUINotification = error.response.data.avoidUINotification;
      }

      yield put({
        type: SIGNUP_FAILED,
        notification: {
          message: errorMessage,
          avoidUINotification: avoidUINotification,
          className: 'error',
          type: 'signup',
          unique: true
        }
      });
    }
  }
}

export function* signupExistingLearner() {
  while (true) {
    const signupRequest = yield take(SIGNUP_EXISTING_LEARNER);

    try {
      const signupSuccess = yield call(
        apiClient.signupExistingLearnerRequest,
        signupRequest.payload
      );
      yield put({
        type: SIGNUP_SUCCESSFUL,
        payload: signupSuccess,
        notification: {
          message: signupSuccess.data.message,
          type: 'signup',
          unique: true
        }
      });
      if (signupSuccess.data.token) {
        yield put({
          type: SIGNUP_CONFIRMATION_SUCCESSFUL,
          token: signupSuccess.data.token,
          userId: signupSuccess.data.userId,
          firstTime: signupSuccess.data.firstTime,
          notification: {
            message: 'Account successfully created',
            type: 'signup',
            unique: true
          }
        });
      }
    } catch (error) {
      let errorMessage = 'Connection problem';

      if (error.response) {
        errorMessage = error.response.data.message;
      }

      yield put({
        type: SIGNUP_FAILED,
        notification: {
          message: errorMessage,
          className: 'error',
          type: 'signup',
          unique: true
        }
      });
    }
  }
}

// Validate the confirmation code with the server, if success the account will be confirmed

export function* signupConfirmation() {
  while (true) {
    const signupConfirmation = yield take(SIGNUP_CONFIRMATION_REQUEST);

    try {
      const signupSuccess = yield call(apiClient.signupConfirmation, signupConfirmation.payload);
      yield put({
        type: SIGNUP_CONFIRMATION_SUCCESSFUL,
        token: signupSuccess.data.token,
        userId: signupSuccess.data.userId,
        firstTime: signupSuccess.data.firstTime,
        notification: {
          message: 'Account successfully created',
          type: 'signup',
          unique: true
        }
      });
    } catch (error) {
      var errorMessage = error.response.data.message;
      yield put({
        type: SIGNUP_CONFIRMATION_FAILED,
        notification: {
          message: errorMessage,
          className: 'error',
          type: 'signup',
          unique: true
        }
      });
    }
  }
}

export function* getSignupPublicAccountDetails() {
  while (true) {
    const request = yield take(SIGNUP_PUBLIC_ACCOUNT_DETAILS_REQUEST);

    try {
      const account = yield call(apiClient.getSignupPublicAccountDetails, request.payload);
      yield put({
        type: SIGNUP_PUBLIC_ACCOUNT_DETAILS_SUCCESSFUL,
        payload: account
      });
    } catch (error) {
      // only process results if we have them, nothing to catch...
    }
  }
}

// Resend a new code to the user via SMS

export function* resendConfirmationCodes() {
  while (true) {
    const resendConfirmationCodes = yield take(RESEND_CONFIRM_CODES_REQUEST);

    try {
      yield call(apiClient.resendConfirmationCodes, resendConfirmationCodes.userId);
      yield put({
        type: RESEND_CONFIRMATION_CODES_SUCCESSFUL,
        notification: {
          message: 'A new confirmation code was sent',
          type: 'signup',
          unique: true
        }
      });
    } catch (error) {
      var errorMessage = error.response.data.message;
      yield put({
        type: RESEND_CONFIRMATION_CODES_FAILED,
        notification: {
          message: errorMessage,
          className: 'error',
          type: 'signup',
          unique: true
        }
      });
    }
  }
}

export function* verifyPinCodeSaga() {
  while (true) {
    const pincode = yield take(VERIFY_PINCODE);
    yield put({
      type: RESET_PINCODE_TRY
    });

    try {
      yield call(apiClient.verifyPinCode, pincode.payload);
      yield put({
        type: VERIFY_PINCODE_SUCCESSFUL
      });
    } catch (error) {
      yield put({
        type: VERIFY_PINCODE_FAILED
      });
    }
  }
}

export default function* rootSignupSaga() {
  yield [
    signup(),
    signupExistingLearner(),
    signupConfirmation(),
    getSignupPublicAccountDetails(),
    resendConfirmationCodes(),
    verifyPinCodeSaga()
  ];
}
