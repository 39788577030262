import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { requestSimpleLogin, backToEnterPhone } from '../../actions/simple_login';
import { signupRequest, signupExistingLearnerRequest } from '../../actions/signup';
import { loginRequest, loginReset } from '../../actions/login';
import EnterPhoneComponent from './simple_login_enter_phone_component';
import NewLearnerComponent from './simple_login_new_learner_component';
import CreatePasswordComponent from './simple_login_create_password_component';
import EnterPasswordComponent from './simple_login_enter_password_component';

import BackButton from '../../components/buttons/back_button';

const SimpleLogin = ({
  confirmCode,
  signup,
  login,
  userData,
  phoneNumber,
  language,
  loginRequest,
  loginReset,
  signupRequest,
  createPassword,
  enterPassword,
  newLearner,
  enterPhone,
  backToEnterPhone,
  requestSimpleLogin,
  signupExistingLearnerRequest
}) => {
  useEffect(() => {
    if (confirmCode) {
      // Redirects to signup_confirm because it is in a code confirmation state.
      setTimeout(() => {
        hashHistory.push('/signup-confirm');
      }, 500);
    }
  }, [confirmCode]);

  useEffect(() => {
    if (signup.success) {
      if (signup.confirmed && signup.token) {
        setTimeout(() => {
          storeCredentials(signup.userId, signup.token);
          hashHistory.push('/active_course');
        }, 500);
      } else {
        setTimeout(() => {
          hashHistory.push('/signup-confirm');
        }, 500);
      }
    } else if (login.success) {
      setTimeout(() => {
        storeCredentials(userData.userId, userData.token);
        hashHistory.push('/active_course');
      }, 500);
    }
  }, [signup, login, userData]);

  const storeCredentials = (userId, token) => {
    const loginStorage = {
      celledAuthToken: token,
      celledUserId: userId
    };
    localStorage.setItem('celledCredentials', JSON.stringify(loginStorage));
  };

  const handleRequestSimpleLogin = (payload) => {
    let completePayload = Object.assign(payload, {
      language: language
    });
    requestSimpleLogin(completePayload);
  };

  const submitSignupForm = (credentials) => {
    credentials.phoneNumber = phoneNumber;
    credentials.language = language;
    signupRequest(credentials);
  };

  const handleLoginRequest = (credentials) => {
    credentials.phoneNumber = phoneNumber;
    loginRequest(credentials);
  };

  const loginExistingLearner = (credentials) => {
    credentials.phoneNumber = phoneNumber;
    signupExistingLearnerRequest(credentials);
  };

  const renderOnboardingStep = () => {
    if (newLearner) {
      return <NewLearnerComponent phoneNumber={phoneNumber} submitSignupForm={submitSignupForm} />;
    }

    if (createPassword) {
      return (
        <CreatePasswordComponent
          phoneNumber={phoneNumber}
          loginExistingLearner={loginExistingLearner}
        />
      );
    }

    if (enterPassword) {
      return (
        <EnterPasswordComponent
          phoneNumber={phoneNumber}
          loginRequest={handleLoginRequest}
          loginFailed={login.failed}
        />
      );
    }

    return (
      <EnterPhoneComponent
        waitingResponse={login.waitingResponse}
        requestSimpleLogin={handleRequestSimpleLogin}
      />
    );
  };

  return (
    <div className="login-container">
      {!enterPhone && !newLearner && !createPassword && (
        <div className="login-page-header">
          <BackButton
            onClick={() => {
              loginReset();
              backToEnterPhone();
            }}
          />
        </div>
      )}
      {renderOnboardingStep()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    login: state.login,
    signup: state.signup,
    userData: state.mainApp,
    language: state.i18n.language,
    phoneNumber: state.simpleLogin.phoneNumber,
    enterPhone: state.simpleLogin.enterPhone,
    newLearner: state.simpleLogin.newLearner,
    createPassword: state.simpleLogin.createPassword,
    enterPassword: state.simpleLogin.enterPassword,
    confirmCode: state.simpleLogin.confirmCode
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      requestSimpleLogin,
      backToEnterPhone,
      signupRequest,
      loginRequest,
      signupExistingLearnerRequest,
      loginReset
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(SimpleLogin);
